import { getPatentPiaMenu } from "@api/patentPia";
import { patentPiaCateId, patentPiaCateOtherAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

interface IMenuList {
    CHARTGROUP_CODE: string;
    CHARTGROUP_ORDER1: number;
    CHARTGROUP_ORDER2: number;
    CHART_LAYOUT_COUNT: number;
    CHARTGROUP_TITLE: string;
    CHARTGROUP_EXPLANATION: string;
    DETAILEDCHARTGROUP_YN: string;
}

const useMenu = () => {
    const [id, setId] = useAtom(patentPiaCateId);
    const [patentPiaCate, setPatentPiaCate] = useAtom(patentPiaCateOtherAtom);
    const [didMount, setDidMount] = useState(false);

    const [menuList, setMenuList] = useState<IMenuList[]>([]);

    const {
        isLoading: menuIsLoading,
        isFetching: menuFetching,
        refetch,
    } = useQuery(["menu", patentPiaCate.cate, id], async () => getPatentPiaMenu(patentPiaCate.cate, id), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        enabled: false,
        cacheTime: 0,
        staleTime: 0,
        onSuccess(data) {
            const temp = JSON.parse(data.RESULT);
            if (temp.DASHBOARD_UPPER_MENU_LIST.length > 0) {
                setMenuList(temp.DASHBOARD_UPPER_MENU_LIST[0].DASHBOARD_MENU_LIST);
            }
        },
        onError: (error: any) => {
            console.log(error);
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && id && patentPiaCate.cate) {
            refetch();
        }
    }, [didMount, id, patentPiaCate.cate]);

    return {
        menuList,
        menuIsLoading,
        menuFetching,
    };
};

export default useMenu;

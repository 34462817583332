import { IPatentPiaRes } from "types/patentPiaTypes";
import { AxiosPatentPia } from "@api/index";

// 분야별 카테고리 1depth
// 기술 techcate
// 제품/부품 procate
// 소재/물질 matcate
export const getPatentPiaApi = async (): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/techcate`).then((res) => res.data);

export const getPatentPiaCatesApi = async (id: string, itemType: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/${itemType.toLowerCase()}/${id}`).then((res) => res.data);

// 분야별 카테고리 2depth/3depth
export const getPatentPiaDepthApi = async (cate_id: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/techcate/${cate_id}`).then((res) => res.data);

// 메뉴 list
export const getPatentPiaMenu = async (itemType: string, itemId: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/dashboard/menulist/${itemType.toUpperCase()}/${itemId}`).then((res) => res.data);

// 대쉬보드 템플릿
export const getPatentPiaTemplateList = async (
    itemType: string,
    itemId: string,
    groupCode: string,
): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/dashboard/templatelist/${itemType.toUpperCase()}/${itemId}/${groupCode}`).then(
        (res) => res.data,
    );

// 메뉴별 데이터와 차트 정보 /chartdata/TECHCATE/939506/M4_TECHCATE_03_05_03_04/KEYKPI
export const getDataChart = async (url: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/${url}`).then((res) => res.data);

// 연구자 정보
export const getReseacher = async (query: string, limit: number, offset: number): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/search/related-inventor?q=${query}&LIMIT=${limit}&OFFSET=${offset}`).then(
        (res) => res.data,
    );

export const getDispute = async (query: string, limit: number, offset: number): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/search/related-dispute?q=${query}&LIMIT=${limit}&OFFSET=${offset}`).then(
        (res) => res.data,
    );

// R&D
export const getRandd = async (id: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/rndtrend/TECHCATE/${id}`).then((res) => res.data);

// patentpia
export const getPatentPiaOneApi = async (itemType: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/${itemType.toLowerCase()}`).then((res) => res.data);

// dash 거래
export const getTransaction = async (id: string): Promise<IPatentPiaRes> =>
    AxiosPatentPia.get(`/kiat/patenttransactionstrend/TECHCATE/${id}`).then((res) => res.data);

import CrossGrey from "@assets/icon/cross-grey.svg";
import Plus from "@assets/icon/plus-icon.svg";
import { device } from "@assets/index";
import { styled } from "styled-components";

export const BuyWrap = styled.div`
    width: 100%;

    @media ${device.tabletL} {
        box-sizing: border-box;
        padding: 16px;
        background-color: #fff;
    }

    .c__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        strong {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #333;
        }

        button {
            width: 14px;
            height: 14px;
            background-image: url(${Plus});
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    .item__buy {
        margin-top: 23px;

        @media ${device.tabletL} {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 16px;
            column-gap: 16px;
        }

        @media ${device.mobile} {
            grid-template-columns: repeat(1, 1fr);
        }

        .list__buy {
            display: flex;
            justify-content: left;
            margin-top: 13px;

            @media ${device.tabletL} {
                margin-top: 0;
            }

            &:first-child {
                margin-top: 0;
            }

            .c__img {
                overflow: hidden;
                width: 60px;
                height: 60px;
                text-align: center;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .p__text {
            margin-left: 8px;
        }

        .item__kinds {
            li {
                position: relative;
                display: inline-block;
                margin-left: 17px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 12px;
                color: #878aa3;

                &:after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 12px;
                    background: #ccc;
                    top: 0px;
                    right: -8px;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        .p__desc {
            margin-top: 8px;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 14px;
            color: #333;
        }

        .p__price {
            margin-top: 8px;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 12px;
            color: #878aa3;
        }
    }
`;

export const ReWrap = styled.div`
    width: 100%;
    padding: 24px 16px 24px 15px;
    margin-top: 24px;
    border-radius: 4px;
    background: #f2f2f7;
    box-sizing: border-box;

    @media ${device.tabletL} {
        box-sizing: border-box;
        padding: 16px;
        background-color: #fff;
    }

    strong {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #333;
    }

    ul {
        margin-top: 16px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }

            span {
                display: inline-block;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px;
            }

            .p__index {
                width: 16px;
                font-weight: 400;
                color: #878aa6;
                text-align: right;
            }

            .p__name {
                width: 42px;
                margin-left: 17px;
                font-weight: 500;
                color: #333;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;

                @media ${device.tabletL} {
                    width: 90px;
                }
            }

            .p__company {
                margin-left: 6px;
                width: 120px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                color: #878aa6;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;

                @media ${device.tabletL} {
                    width: calc(100% - 130px);
                }

                @media ${device.tablet} {
                    width: calc(100% - 200px);
                }
            }

            .p__num {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 300;
                color: #878aa6;
            }
        }
    }

    .c__laoding {
        width: 100%;
        height: 284px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const AgentWrap = styled.div`
    margin-top: 24px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e9ebec;

    @media ${device.tabletL} {
        box-sizing: border-box;
        padding: 16px;
        background-color: #fff;
    }

    .c__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        strong {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #333;
        }

        button {
            width: 14px;
            height: 14px;
            background-image: url(${CrossGrey});
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    .item__agnet {
        margin-top: 15px;

        .list__agent {
            display: flex;
            justify-content: left;
            margin-top: 13px;

            img {
                width: 32px;
                height: 32px;
                cursor: pointer;
            }

            &:first-child {
                margin-top: 0;
            }

            .p__text {
                margin-left: 7px;

                li {
                    display: inline-block;
                }

                .p__name {
                    font-family: "Noto Sans KR", sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    color: #434343;
                }

                .p__belong,
                .p__skill {
                    font-family: "Noto Sans KR", sans-serif;
                    font-weight: 300;
                    font-size: 12px;
                    color: #878aa6;
                }

                .p__skill {
                    margin-top: 3px;
                }
            }
        }
    }
`;

export const BannerWrap = styled.div`
    padding: 16px 0px;
    cursor: pointer;

    @media ${device.tabletL} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding-bottom: 10px;
    }

    img {
        width: 100%;
    }
`;

export const ProfileImg = styled.div<{ $url: string | undefined }>`
    flex-basis: 60px;
    height: 60px;
    background-image: url(${(props) => props.$url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
`;

import { Link } from "react-router-dom";

const Logo = () => {
    return (
        <div className="c__logo">
            <h1 className="logo">
                <Link to="/">Techgraph</Link>
            </h1>
        </div>
    );
};

export default Logo;

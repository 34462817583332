import { IScatterProps } from "types/etcTypes";
import { styled } from "styled-components";
import { COLOR } from "@colors";

const Wrap = styled.div`
    position: relative;

    .xAxisText {
        position: absolute;
        width: 100%;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #333;
        text-align: center;
        left: 0;
        bottom: -50px;
    }
`;

const Graph = styled.div`
    position: relative;
    width: 230px;
    height: 230px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    margin-left: 60px;

    .xAxis {
        position: absolute;
        width: 100%;
        height: 1px;
        background: #d5d5d5;
        left: 0;
        top: 50%;
    }

    .yAxis {
        position: absolute;
        width: 1px;
        height: 100%;
        background: #d5d5d5;
        left: 50%;
        top: 0;
    }

    .xAxis-points {
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -10px;

        span {
            position: absolute;
            font-size: 12px;

            &:nth-child(2) {
                left: 50%;
            }

            &:nth-child(3) {
                left: 100%;
            }
        }
    }

    .yAxis-points {
        display: block;
        height: 100%;
        position: absolute;
        left: -30px;
        top: 0;

        span {
            position: absolute;
            font-size: 12px;

            &:nth-child(2) {
                top: 50%;
            }

            &:nth-child(3) {
                top: 100%;
            }
        }
    }

    .yAxisText {
        writing-mode: vertical-lr;
        position: absolute;
        display: block;
        height: 220px;
        top: 0;
        left: -60px;
        text-align: center;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #333;
    }
    .xAxisText {
        position: absolute;
        display: block;
        width: 100%;
        top: 0;
        left: -60px;
        text-align: center;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #333;
    }
`;

const Point = styled.span<{ x: number; y: number; color: string }>`
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: ${(props) => props.color};
    left: ${(props) => `${props.x * 50}%`};
    bottom: ${(props) => `${props.y * 10}%`};
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 50%;
`;

const Lgend = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const LegentText = styled.p<{ index: number }>`
    line-height: 14px;
    font-size: 0;
    text-align: left;

    .point {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        vertical-align: middle;
        background-color: ${(props) => (COLOR[props.index] !== undefined ? COLOR[props.index] : "#007aff")};
    }
    .text__title {
        display: inline-block;
        margin-left: 10px;
        font-size: 10px;
        vertical-align: middle;
    }
`;
interface IProps {
    data: IScatterProps[];
}

const Scatter = ({ data }: IProps) => {
    return (
        <>
            <Wrap>
                <Graph>
                    <span className="xAxis"></span>
                    <span className="yAxis"></span>
                    <div className="xAxis-points">
                        <span>0.00</span>
                        <span>1.00</span>
                        <span>2.00</span>
                    </div>
                    <div className="yAxis-points">
                        <span>10.00</span>
                        <span>5.00</span>
                        <span>0.00</span>
                    </div>

                    {data.map((datum, index: number) => (
                        <Point
                            key={datum.id}
                            x={datum.position.x}
                            y={datum.position.y}
                            color={COLOR[index] !== undefined ? COLOR[index] : "#007aff"}
                        />
                    ))}

                    <span className="yAxisText">인용도지수/CPP</span>
                </Graph>
                <Lgend>
                    {data.map((datum, index: number) => (
                        <LegentText key={datum.id} index={index}>
                            <span className="point" />
                            <span className="text__title">{datum.title}</span>
                        </LegentText>
                    ))}
                </Lgend>
                <span className="xAxisText">시장확보지수/PFS</span>
            </Wrap>
        </>
    );
};

export default Scatter;

import { device } from "@assets/index";
import React from "react";
import ApexChart from "react-apexcharts";
import { styled } from "styled-components";

interface IProps {
    data: number[];
}

const Wrap = styled.div`
    position: absolute;
    top: -28px;
    left: 10px;

    @media ${device.mobile} {
        left: 20px;
    }
`;

const OnlyLine: React.FC<IProps> = ({ data }) => {
    return (
        <Wrap>
            <ApexChart
                type="line"
                width={80}
                height={80}
                series={[
                    {
                        name: "US",
                        data: data,
                    },
                ]}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3,
                    },
                    yaxis: {
                        show: false,
                    },
                    xaxis: {
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        labels: { show: false },
                    },
                    grid: { show: false },
                    legend: { show: false },
                    tooltip: { enabled: false },
                    colors: ["#f27c63"],
                }}
            />
        </Wrap>
    );
};

export default OnlyLine;

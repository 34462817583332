import { patentSearch } from "@api/patentSearch";
import { patentDataTemp } from "@pages/Build/PatentSearch/Alls/data";
import { useMutation } from "@tanstack/react-query";
import { getDate } from "@utils/index";
import { useEffect, useState } from "react";
import { Data, DataItem } from "../types/patentSearchType";

const usePatentSearch = (sector: string, param: string) => {
    const [disMount, setDidMount] = useState(false);
    const today = getDate();

    const { mutate, isLoading, data: patentData } = useMutation(() => patentSearch(sector, param, today));

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (disMount) {
            mutate();
        }
    }, [disMount]);

    const tempData: DataItem[] = patentDataTemp;

    let data: Data = {
        patentApplicationNum: 0,
        patentRegistrationNum: 0,
        patentState: {
            data: [],
            labels: [],
        },
        application: {
            data: [],
            label: [],
        },
        applicantStatus: {
            data: [],
            label: [],
        },
        ipc: {
            data: [],
            label: [],
        },
        agentData: [],
        agentLabel: [],
        agentNames: [],
    };

    tempData.forEach((item) => {
        switch (item.data_id) {
            case "1":
                if (item.data_set[0].pat_appl_cnt !== undefined) {
                    // 특허 출원 건수
                    data.patentApplicationNum = item.data_set[0].pat_appl_cnt;
                }
                break;
            case "2":
                if (item.data_set[0].pat_grt_cnt !== undefined) {
                    // 특허등록 건수
                    data.patentRegistrationNum = item.data_set[0].pat_grt_cnt;
                }
                break;
            case "3":
                // 국내외 특허현황
                data.patentState.data = item.data_set.map((elem) => (elem.appl_cnt !== undefined ? elem.appl_cnt : 0));
                data.patentState.labels = item.data_set.map((elem) =>
                    elem.ctry_nm !== undefined ? elem.ctry_nm : "-",
                );
                break;
            case "4":
                // 년도별 출원동향
                data.application.label = item.data_set.map((elem) => (elem.appl_yr !== undefined ? elem.appl_yr : "-"));
                data.application.data = item.data_set.map((elem) =>
                    elem.pat_appl_cnt !== undefined ? elem.pat_appl_cnt : null,
                );
                break;
            case "5":
                // 주요출원인현황
                data.applicantStatus.label = item.data_set.map((elem) =>
                    elem.com_nm !== undefined ? elem.com_nm : "-",
                );

                data.applicantStatus.data = item.data_set.map((elem) =>
                    elem.pat_appl_cnt !== undefined ? elem.pat_appl_cnt : null,
                );
                break;
            case "6":
                // 주요ipx현황
                data.ipc.label = item.data_set.map((elem) => (elem.ipc !== undefined ? elem.ipc : "-"));
                data.ipc.data = item.data_set.map((elem) =>
                    elem.pat_appl_cnt !== undefined ? elem.pat_appl_cnt : null,
                );
                break;
            case "7":
                // 대리인 등록률 현황
                let temp1: number[] = [];
                let temp2: number[] = [];
                let temp3: number[] = [];
                data.agentLabel = [];

                data.agentLabel = item.data_set
                    .map((elem) => elem.agt_ofc_nm)
                    .filter((item) => item !== undefined) as any[];

                temp1 = item.data_set.map((elem) => elem.pat_appl_cnt).filter((item) => item !== undefined) as number[];
                temp2 = item.data_set.map((elem) => elem.pat_grt_cnt).filter((item) => item !== undefined) as number[];
                temp3 = item.data_set
                    .map((elem) => elem.pat_grt_ratio)
                    .filter((item) => item !== undefined) as number[];

                data.agentData = [
                    {
                        name: "특허 출원 건수",
                        type: "column",
                        data: temp1,
                    },
                    {
                        name: "특허 등록 건수",
                        type: "column",
                        data: temp2,
                    },
                    {
                        name: "출원건수 대비 등록률",
                        type: "line",
                        data: temp3,
                    },
                ];

                data.agentNames = ["특허 출원 건수", "특허 등록 건수", "출원건수 대비 등록률"];
                break;
            default:
                break;
        }
    });

    return {
        data,
        patentData,
        isLoading,
        mutate,
    };
};

export default usePatentSearch;

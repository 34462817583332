import { patchTechStrom } from "@api/techstormApi";
import { searchSalesAtom } from "@atoms";
import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { IRequest } from "~types/etcTypes";

export const useTechStrom = (keyWord: string, type: string) => {
    const [dataTotalCount, setDataTotalCount] = useState<number>(0);
    const [didMount, setDidMount] = useState(false);
    const setSearchSales = useSetAtom(searchSalesAtom);

    const {
        mutate,
        data: techData,
        isLoading,
    } = useMutation((requestData: IRequest) => patchTechStrom(requestData), {
        onSuccess: (data) => {
            setSearchSales(data);
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    const handlePatch = () => {
        const requestBody = {
            fileCds: [],
            hasPatentOnly: false,
            industryTechCds: [],
            maxPrice: 9007199254740991,
            minPrice: 0,
            regTypeCds: [],
            saleStatusCds: [],
            scienceTechCds: [],
            techCategoryCds: [],
            techContractCds: [],
            techName: keyWord,
            techTypeCds: [],
            tradeTypeCds: [],
        };

        mutate(requestBody);
    };

    useEffect(() => {
        if (didMount && keyWord !== "" && type === "search") {
            handlePatch();
        }
    }, [keyWord, didMount, type]);

    useEffect(() => {
        if (didMount && type === "app") {
            handlePatch();
        }
    }, [keyWord, didMount]);

    useEffect(() => {
        setDataTotalCount(techData?.totalElements ?? 0);
    }, [techData?.totalElements]);

    return {
        techData,
        isLoading,
        dataTotalCount,
    };
};

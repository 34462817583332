import { getPatentDetail, getPatentSimilarity } from "@api/patentDetailApi";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const usePatentDetail = (id: string) => {
    const { data, isLoading, refetch } = useQuery(["patent_detail", id], () => getPatentDetail(id), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        enabled: false,
        onSuccess(data) {},
        onError: (error: any) => {
            console.log(error);
        },
    });

    const {
        data: patentSimilarityData,
        refetch: patentSimilarityRefetch,
        isLoading: patentSimilarityLoading,
    } = useQuery(["patent_similarity", id], () => getPatentSimilarity(id), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        enabled: false,
        onSuccess(data) {},
        onError: (error: any) => {
            console.log(error);
        },
    });

    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && id !== undefined) {
            refetch();
            patentSimilarityRefetch();
        }
    }, [didMount, id]);

    return {
        data,
        isLoading,
        patentSimilarityData,
        patentSimilarityLoading,
    };
};

export default usePatentDetail;

import { isDisabledAtom } from "@atoms";
import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";
import { useAtomValue } from "jotai";

export default function HandleButton() {
    const isDisabled = useAtomValue(isDisabledAtom);

    const { handleAddPatentSearchList, handleSearchSimilarPatentList } = useSimilarPatentSearchData();

    return (
        <div className="similar-filter-footer">
            <button type="button" className="btn-add" onClick={handleAddPatentSearchList} disabled={isDisabled}>
                추가
            </button>
            <button type="button" className="btn-search" onClick={handleSearchSimilarPatentList}>
                검색
            </button>
        </div>
    );
}

import { patchPatentApi } from "@api/searchPatent";
import { dashSelectAtom, keyWordStorageAtom } from "@atoms";
import { useMutation } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

interface IPatentPage {
    page: number;
    size: number;
    data: { title: string };
}

interface IData {
    props: IPatentPage;
}
const useRelatedPatent = ({ props }: IData) => {
    const dashSelect = useAtomValue(dashSelectAtom);

    const [didMount, setDidMount] = useState(false);
    const { mutate, data, isLoading } = useMutation((props: IPatentPage) => patchPatentApi(props), {
        onSuccess(data) {
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && props.data.title !== "") {
            mutate(props);
        }
    }, [didMount, props]);

    return {
        data,
        isLoading,
    };
};

export default useRelatedPatent;

import { getCompanyKeyWords } from "@api/searchCompanyApi";
import { keyWordStorageAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect } from "react";

const useKeyWords = () => {
    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    const {
        data: kData,
        isLoading: kLoading,
        refetch,
    } = useQuery(["keyWords", keyWordStorage], async () => getCompanyKeyWords(keyWordStorage), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        enabled: false,

        onError: (error: any) => {
            console.log(error);
        },
    });

    const handleKeyWords = (newKey: string) => {
        setKeyWordStorage(newKey);
    };

    useEffect(() => {
        if (keyWordStorage !== "") {
            refetch();
        }
    }, [keyWordStorage]);

    return {
        kData,
        kLoading,
        handleKeyWords,
    };
};

export default useKeyWords;

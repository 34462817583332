import AppsActive from "@assets/icon/apps-active.svg";
import Apps from "@assets/icon/apps.svg";
import Bell from "@assets/icon/bell.svg";
import DashActive from "@assets/icon/dash-active.svg";
import Dash from "@assets/icon/dash.svg";
import HamBtn from "@assets/icon/ham_btn.svg";
import HamBtnBlue from "@assets/icon/ham_btn_blue.svg";
import Logo from "@assets/icon/logo.svg";
import ReportsActive from "@assets/icon/reports-active.svg";
import Reports from "@assets/icon/reports.svg";
import SDashActive from "@assets/icon/s-dash-active.svg";
import SDash from "@assets/icon/s-dash.svg";
import SearchMenuActive from "@assets/icon/search-active.svg";
import Search from "@assets/icon/search-icon.svg";
import SearchMenu from "@assets/icon/search.svg";
import Square from "@assets/icon/square.svg";
import Time from "@assets/icon/time.svg";
import CloseBtn from "@assets/img/close_btn.png";
import User from "@assets/img/icon-user.png";

import { device } from "@assets/index";
import { css, styled } from "styled-components";

export const Wrap = styled.div<{ $wide: boolean }>`
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;

    @media ${device.tablet} {
        height: auto;
        padding-bottom: 120px;
        background-color: #f2f2f7;
    }

    .p__contents {
        width: ${(props) => (props.$wide ? "calc(100% - 250px)" : "calc(100% - 60px)")};

        @media ${device.tablet} {
            width: 100%;
        }

        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        overflow: hidden auto;

        .l__header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 70px;
            padding: 0px 0px 0px 20px;
            border-bottom: 1px solid ${(props) => props.theme.grey};
            box-sizing: border-box;
            background-color: #fff;

            @media ${device.tablet} {
                height: auto;
            }

            .btn__navi {
                width: 20px;
                height: 14px;
                background: ${(props) =>
                    props.$wide ? `url(${HamBtn}) no-repeat 50% 50%` : `url(${HamBtnBlue}) no-repeat 50% 50%`};

                &.mobile {
                    display: none !important;
                }

                @media ${device.tabletL} {
                    display: none !important;
                }
                @media ${device.tablet} {
                    &.mobile {
                        display: inline-block !important;
                    }
                }
            }

            .p__title {
                display: inline-block;
                margin-left: 23px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 18px;
                color: #333;

                @media ${device.tabletL} {
                    display: none !important;
                }
            }

            .c__header {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin-left: auto;
                align-items: center;
                justify-content: flex-end;
            }

            .c__state {
                display: inline-block;
                align-items: center;
                padding-right: 22px;
                border-right: 1px solid ${(props) => props.theme.grey};
                box-sizing: border-box;

                @media ${device.tabletL} {
                    display: none;
                }
            }

            p {
                display: inline-block;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: ${(props) => props.theme.blueGrey};
            }

            .p__sever_state {
                letter-spacing: 0.3px;
                .t__blue {
                    color: ${(props) => props.theme.blue};
                }
            }

            .p__version {
                margin-left: 15px;
            }

            .p__time {
                padding-left: 20px;
                margin-left: 10px;
                background-image: url(${Time});
                background-size: 13px 13px;
                background-repeat: no-repeat;
                background-position: 0% 50%;
            }

            .c__icons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 119px;
                height: 70px;
                padding: 0 30px 0 30px;
                box-sizing: border-box;
            }

            .b__square {
                width: 18px;
                height: 18px;
                background-image: url(${Square});
                background-size: 18px 18px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            .b__bell {
                position: relative;
                width: 17px;
                height: 20px;
                background-image: url(${Bell});
                background-size: 17px 20px;
                background-repeat: no-repeat;
                background-position: 50% 50%;

                .p__num {
                    position: absolute;
                    padding: 1px 6px;
                    background-color: #3798ff;
                    border-radius: 10px;
                    font-family: "Noto Sans KR", sans-serif;
                    font-size: 12px;
                    color: ${(props) => props.theme.allWhite};
                    font-weight: 700;
                    top: -13px;
                    right: -14px;
                }
            }

            .c__user {
                width: 161px;
                display: flex;
                justify-content: left;
                align-items: center;
                height: 70px;

                background-color: #f1f2f8;
            }

            .b__user {
                width: 32px;
                height: 32px;
                background-image: url(${User});
                background-size: 32px 32px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                margin-left: 14px;
            }

            .p__user {
                margin-left: 12px;
            }

            .c__infos {
                font-size: 0;
                text-align: left;

                & > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                .c__search {
                    position: relative;
                    margin-left: 20px;

                    @media ${device.laptopS} {
                        display: none;
                    }

                    input {
                        width: 360px;
                        height: 40px;
                        padding-left: 14px;
                        padding-right: 28px;
                        border: 1px solid #ced4da;
                        border-radius: 4px;
                        box-sizing: border-box;
                        font-family: "Noto Sans KR", sans-serif;
                        font-weight: 3500;
                        font-size: 14px;
                        color: #000;
                    }

                    button {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        background-image: url(${Search});
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: cover;
                        top: 11px;
                        right: 12px;
                    }
                }
            }
        }
    }

    .pop_outer {
        z-index: 5;
        position: relative;
    }

    .m_c__search {
        position: relative;
        display: none;

        @media ${device.laptopS} {
            display: block;
            padding: 6px 10px 6px;
            box-sizing: border-box;

            input {
                width: 100%;
                height: 40px;
                padding-left: 14px;
                padding-right: 28px;
                border: 1px solid #ced4da;
                border-radius: 4px;
                box-sizing: border-box;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 3500;
                font-size: 14px;
                color: #000;
            }

            button {
                position: absolute;
                width: 16px;
                height: 16px;
                background-image: url(${Search});
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: cover;
                top: 18px;
                right: 16px;
            }
        }
    }
`;
export const Navi = styled.div<{ $wide: boolean }>`
    width: ${(props) => (props.$wide ? "250px" : "60px")};
    /* height: 100vh; */
    background-color: #151529;
    transition: all 0.5s;
    .c__logo {
        padding-top: 15px;
        padding-left: 23px;
    }

    .logo {
        display: inline-block;
        padding-left: 38px;
        padding-top: 11px;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        color: ${(props) => props.theme.allWhite};
        background-image: url(${Logo});
        background-size: 30px 31px;
        background-repeat: no-repeat;
        background-position: 3px 3px;
    }

    @media ${device.tablet} {
        display: none;
    }
`;
export const WrapMobileMenu = styled.div<{ $ison: string }>`
    display: none;

    @media ${device.tablet} {
        display: block;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100%;
        height: 0;
        background-color: #0000;
        -webkit-backface-visibility: hidden;
        -webkit-transition:
            background-color 0.3s,
            height 0s 0.3s;
        transition:
            background-color 0.3s,
            height 0s 0.3s;

        ${(props) =>
            props.$ison === "true" &&
            css`
                height: 100%;
                background-color: #0000007a;
                -webkit-transition: background-color 0.3s;
                transition: background-color 0.3s;
            `}

        .wrap_side {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 100%;
            padding: 20px 20px 0 20px;
            background-color: #f2f4f7;
            -webkit-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
            -webkit-transform: translate3d(-100%, 0, 0) scale(1);
            transform: translate3d(-100%, 0, 0) scale(1);
            box-sizing: border-box;

            ${(props) =>
                props.$ison === "true" &&
                css`
                    width: 360px;
                    background-color: #141517;
                    transform: translateZ(0) scale(1);
                `}

            .logo {
                display: inline-block;
                padding-left: 38px;
                padding-top: 11px;
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                color: ${(props) => props.theme.allWhite};
                background-image: url(${Logo});
                background-size: 30px 31px;
                background-repeat: no-repeat;
                background-position: 3px 3px;
            }

            .p__menu {
                padding-top: 36px;
                font-weight: 500;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.4);
            }

            .item-menu {
                margin-top: 25px;

                & > li {
                    margin-top: 25px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .b__menu {
                    padding-left: 34px;
                    font-family: "Noto Sans KR", sans-serif;
                    font-size: 14px;
                    line-height: 21px;
                    color: rgb(145, 145, 151);
                    background-repeat: no-repeat;
                    background-position:
                        0 50%,
                        100% 0;

                    &.dash {
                        background-image: url(${Dash});
                    }

                    &.search {
                        background-image: url(${SearchMenu});
                    }

                    &.apps {
                        background-image: url(${Apps});
                    }

                    &.reports {
                        background-image: url(${Reports});
                    }

                    &.active {
                        color: rgb(255, 255, 255);

                        &.dash {
                            background-image: url(${DashActive});
                        }

                        &.search {
                            background-image: url(${SearchMenuActive});
                        }

                        &.apps {
                            background-image: url(${AppsActive});
                        }

                        &.reports {
                            background-image: url(${ReportsActive});
                        }
                    }
                }

                .list__2depth {
                    padding-left: 10px;

                    & > li {
                        margin-top: 25px;
                    }

                    a {
                        display: inline-block;
                        width: 100%;
                        padding-left: 28px;
                        font-family: "Noto Sans KR", sans-serif;
                        font-weight: 700;
                        font-size: 12px;
                        color: rgb(100, 100, 143);
                        box-sizing: border-box;
                        background-repeat: no-repeat;
                        background-position: 0px 50%;
                        background-image: url(${SDash});

                        &.active {
                            color: rgb(255, 255, 255);
                            background-image: url(${SDashActive});
                        }
                    }
                }
            }
        }

        .btn_close {
            z-index: 5;
            position: absolute;
            width: 30px;
            height: 30px;
            background-image: url(${CloseBtn});
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            top: 25px;
            right: 20px;
        }
    }
`;

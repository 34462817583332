// 검색 아이콘
export const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="17px" viewBox="0 0 16 17">
            <path
                fillRule="evenodd"
                d="M15.761,15.640 L12.918,12.606 C14.044,11.360 14.732,9.734 14.732,7.951 C14.732,4.044 11.449,0.865 7.413,0.865 C3.378,0.865 0.094,4.044 0.094,7.951 C0.094,11.859 3.378,15.039 7.413,15.039 C9.056,15.039 10.569,14.505 11.792,13.617 L14.625,16.639 C14.776,16.800 14.984,16.883 15.193,16.883 C15.378,16.883 15.562,16.819 15.709,16.690 C16.023,16.414 16.047,15.944 15.761,15.640 ZM1.630,7.951 C1.630,4.864 4.224,2.352 7.413,2.352 C10.602,2.352 13.196,4.864 13.196,7.951 C13.196,11.039 10.602,13.552 7.413,13.552 C4.224,13.552 1.630,11.039 1.630,7.951 Z"
            />
        </svg>
    );
};

// 페이지 아이콘
export const PrevIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4px" height="7px">
            <path
                fillRule="evenodd"
                fill="#3798ff"
                d="M0.008,3.490 L-0.005,3.477 L0.366,3.104 L3.255,-0.011 L4.011,0.747 L1.466,3.490 L4.011,6.234 L3.255,6.992 L0.366,3.877 L-0.005,3.504 L0.008,3.490 Z"
            />
        </svg>
    );
};

export const PrevIcon02 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4px" height="7px">
            <path
                fillRule="evenodd"
                fill="#fff"
                d="M0.008,3.490 L-0.005,3.477 L0.366,3.104 L3.255,-0.011 L4.011,0.747 L1.466,3.490 L4.011,6.234 L3.255,6.992 L0.366,3.877 L-0.005,3.504 L0.008,3.490 Z"
            />
        </svg>
    );
};

// 더보기 아이콘
export const MoreIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="10px">
            <path
                fillRule="evenodd"
                fill="rgb(154, 160, 166)"
                d="M6.417,5.199 C6.427,5.149 6.444,5.100 6.444,5.049 C6.444,4.998 6.427,4.950 6.417,4.899 C6.408,4.847 6.404,4.795 6.384,4.746 C6.364,4.697 6.327,4.656 6.297,4.612 C6.269,4.570 6.253,4.524 6.216,4.487 L2.248,0.439 C1.934,0.118 1.413,0.108 1.085,0.419 C0.758,0.729 0.749,1.242 1.065,1.563 L4.481,5.049 L1.065,8.535 C0.749,8.856 0.758,9.369 1.085,9.679 C1.245,9.831 1.451,9.907 1.656,9.907 C1.872,9.907 2.087,9.823 2.248,9.659 L6.216,5.611 C6.253,5.574 6.269,5.528 6.297,5.486 C6.327,5.442 6.364,5.401 6.384,5.353 C6.404,5.303 6.408,5.251 6.417,5.199 Z"
            />
        </svg>
    );
};

export const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
            <path
                fillRule="evenodd"
                fill="rgb(188, 190, 200)"
                d="M13.1000,7.1000 L7.1000,7.1000 L7.1000,13.1000 L5.1000,13.1000 L5.1000,7.1000 L-0.000,7.1000 L-0.000,5.1000 L5.1000,5.1000 L5.1000,-0.000 L7.1000,-0.000 L7.1000,5.1000 L13.1000,5.1000 L13.1000,7.1000 Z"
            />
        </svg>
    );
};

//메뉴-더보기 아이콘
export const MenuMoreIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4px" height="16px">
            <path
                fillRule="evenodd"
                fill="rgb(153, 153, 153)"
                d="M1.1000,-0.000 C3.105,-0.000 3.1000,0.895 3.1000,1.1000 C3.1000,3.104 3.105,3.1000 1.1000,3.1000 C0.895,3.1000 -0.000,3.104 -0.000,1.1000 C-0.000,0.895 0.895,-0.000 1.1000,-0.000 Z"
            />
            <path
                fillRule="evenodd"
                fill="rgb(153, 153, 153)"
                d="M1.1000,5.1000 C3.105,5.1000 3.1000,6.895 3.1000,7.1000 C3.1000,9.104 3.105,9.1000 1.1000,9.1000 C0.895,9.1000 -0.000,9.104 -0.000,7.1000 C-0.000,6.895 0.895,5.1000 1.1000,5.1000 Z"
            />
            <path
                fillRule="evenodd"
                fill="rgb(153, 153, 153)"
                d="M1.1000,11.1000 C3.105,11.1000 3.1000,12.895 3.1000,13.1000 C3.1000,15.104 3.105,15.1000 1.1000,15.1000 C0.895,15.1000 -0.000,15.104 -0.000,13.1000 C-0.000,12.895 0.895,11.1000 1.1000,11.1000 Z"
            />
        </svg>
    );
};

//심사관 인용, 기타 75개국
//E
export const ExaminerE = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
            <path
                fillRule="evenodd"
                fill="rgb(168, 131, 55)"
                d="M6.649,0.701 C10.321,0.701 13.298,3.678 13.298,7.350 C13.298,11.023 10.321,13.1000 6.649,13.1000 C2.977,13.1000 -0.001,11.023 -0.001,7.350 C-0.001,3.678 2.977,0.701 6.649,0.701 Z"
            />
            <text kerning="auto" fontFamily="Gulim" fill="rgb(0, 0, 0)" fontSize="9px" x="4px" y="10.9369999999999px">
                <tspan fontSize="9px" fontFamily="Source Han Sans K" fill="#FFFFFF">
                    E
                </tspan>
            </text>
        </svg>
    );
};
//B
export const ExaminerB = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
            <path
                fillRule="evenodd"
                fill="rgb(0, 0, 255)"
                d="M6.649,0.701 C10.321,0.701 13.298,3.678 13.298,7.351 C13.298,11.023 10.321,13.1000 6.649,13.1000 C2.977,13.1000 -0.000,11.023 -0.000,7.351 C-0.000,3.678 2.977,0.701 6.649,0.701 Z"
            />
            <text kerning="auto" fontFamily="Gulim" fill="rgb(0, 0, 0)" fontSize="9px" x="4px" y="10.9369999999999px">
                <tspan fontSize="9px" fontFamily="Source Han Sans K" fill="#FFFFFF">
                    B
                </tspan>
            </text>
        </svg>
    );
};
//I
export const Nation = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
            <path
                fillRule="evenodd"
                fill="rgb(44, 149, 149)"
                d="M6.649,0.701 C10.321,0.701 13.298,3.678 13.298,7.351 C13.298,11.023 10.321,13.1000 6.649,13.1000 C2.977,13.1000 -0.001,11.023 -0.001,7.351 C-0.001,3.678 2.977,0.701 6.649,0.701 Z"
            />
            <text kerning="auto" fontFamily="Gulim" fill="rgb(0, 0, 0)" fontSize="9px" x="5px" y="11.0619999999999px">
                <tspan fontSize="9px" fontFamily="Source Han Sans K" fill="#FFFFFF">
                    I
                </tspan>
            </text>
        </svg>
    );
};

//prev button
export const PrevBtn = () => {
    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.52633 4.98547L5.87007 9.32296C5.90965 9.35817 5.94159 9.40115 5.96391 9.44919C5.98624 9.49723 5.99847 9.54935 5.99987 9.60231C6.00126 9.65527 5.99179 9.70792 5.97202 9.75707C5.95225 9.80622 5.92262 9.85082 5.88495 9.88807C5.84727 9.92531 5.80235 9.95443 5.75298 9.97363C5.70361 9.99284 5.65083 10.0017 5.59789 9.99972C5.54495 9.99773 5.49298 9.98487 5.44519 9.962C5.39741 9.93912 5.35483 9.90669 5.32007 9.86671L0.70758 5.25425C0.636203 5.18176 0.596191 5.08411 0.596191 4.98238C0.596191 4.88065 0.636203 4.78296 0.70758 4.71047L5.32007 0.098019C5.39365 0.0325636 5.48938 -0.00245676 5.58782 0.000134154C5.68627 0.00272506 5.78003 0.0427076 5.85006 0.111943C5.92009 0.181178 5.96113 0.274498 5.96485 0.372905C5.96856 0.471313 5.93468 0.567446 5.87007 0.641764L1.52633 4.98547Z"></path>
        </svg>
    );
};

// 휴지통 아이콘
export const TrashIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.5px" height="21.5px">
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M5.166,4.57 C5.166,4.57 4.841,1.50 7.287,1.50 C9.716,1.50 9.409,4.57 9.409,4.57 "/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M1.772,6.634 L1.772,17.802 C1.772,17.802 2.96,19.949 3.893,19.949 C5.691,19.949 11.530,19.949 11.530,19.949 C11.530,19.949 13.227,19.423 13.227,17.802 C13.227,16.180 13.227,6.634 13.227,6.634 "/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M1.694,4.62 L13.305,4.62 C13.965,4.62 14.500,4.597 14.500,5.257 L14.500,5.433 C14.500,6.93 13.965,6.628 13.305,6.628 L1.694,6.628 C1.34,6.628 0.500,6.93 0.500,5.433 L0.500,5.257 C0.500,4.597 1.34,4.62 1.694,4.62 Z"/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M5.166,9.640 L5.166,17.372 "/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M7.712,10.499 L7.712,16.83 "/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M10.257,9.640 L10.257,17.372 "/>
        </svg>
    );
};


// 초기화 아이콘
export const ResetIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.25px" height="19.25px">
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1.5px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M16.814,5.161 C16.814,5.161 12.705,0.371 6.875,2.676 C0.995,5.0 2.526,11.373 2.526,11.373 C2.526,11.373 3.890,17.171 9.981,16.964 C16.72,16.756 17.246,11.867 17.435,9.509 "/>
            <path fillRule="evenodd" stroke="rgb(153, 153, 153)" strokeWidth="1px" strokeLinecap="butt"
                  strokeLinejoin="miter" fill="none"
                  d="M13.87,5.161 L16.814,5.161 L16.193,1.433 "/>
        </svg>
    );
};

import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";
import { TableComponentProps } from "~types/PatentSearch/SimilarPatentSearch";
import TableRow from "../TableRow";

export default function SimilarPatentTable({ title }: TableComponentProps) {
    const { numberedPatentSearchList } = useSimilarPatentSearchData();

    return (
        <div className="table-wrap">
            <h4 className="similar-title">{title}</h4>
            <div className="c__table ">
                <div className="table similar-table">
                    <TableRow rowText="권리변동여부" rowClass="state" />
                    <div className="tbody">
                        {numberedPatentSearchList.map((group, groupIndex) =>
                            group.slice(1).map((item, itemIndex) => (
                                <div className="tr" key={`group-${groupIndex}-item-${itemIndex}`}>
                                    <div className="td num">{item.no}</div>
                                    <div className="td appl-num">{item.appl_num}</div>
                                    <div className="td inv-ti">{item.inv_ti}</div>
                                    <div className="td appl-com">{item.appl_com}</div>
                                    <div className={`td state state-${item.right_yn === "Y" ? "y" : "n"}`}>
                                        {item.right_yn}
                                    </div>
                                </div>
                            )),
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useEffect, useState } from "react";
import styled from "styled-components";

interface IClaims {
    type: string;
    text: string;
}

interface IProps {
    data: IClaims[];
    id: number;
}

const ClaimComp = ({ data, id }: IProps) => {
    const [didMount, setDidMount] = useState(false);
    const [datum, setDatum] = useState<IClaims[]>([]);

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount) {
            if (id === 0) {
                setDatum(data);
            }
            if (id === 1) {
                const temp = data.filter((item) => item.type === "dependent" || item.type === "");
                setDatum(temp);
            }
            if (id === 2) {
                const temp = data.filter((item) => item.type === "independent" || item.type === "claim");
                setDatum(temp);
            }
        }
    }, [id, didMount]);

    const Independent = styled.span`
        margin-top: 6px;
        padding: 6px 12px;
        white-space: nowrap;
        color: #fff;
        border-radius: 3px;
        background: #ffbe4e;
    `;

    return (
        <div className="PD-table-scroll">
            <ul className="PD-table charge">
                <li className="tr thead">
                    <div className="td num">번호</div>
                    <div className="td content">청구항</div>
                </li>

                <ul>
                    {datum.length > 0 &&
                        datum.map((item, index) => (
                            <li className="tr" key={index}>
                                <div className="td num">
                                    {index + 1}
                                    {item.type === "claim" && <span className="label claim">대표청구항</span>}
                                    {item.type === "dependent" && <span className="label dependent">종속</span>}
                                    {item.type === "independent" && <Independent>독립</Independent>}
                                </div>
                                <div className="td content">
                                    <div>{item.text}</div>
                                </div>
                            </li>
                        ))}
                </ul>
            </ul>
        </div>
    );
};

export default ClaimComp;

import { menuCategoriesData } from "@constants/categories";
import { useLocation } from "react-router-dom";
import { MobileMenuProps } from "~types/Layout";
import { WrapMobileMenu } from "../../style";
import AppsMenu from "../AppsMenu";
import Logo from "../Logo";
import OtherMenu from "../OtherMenu";

const MobileMenu = ({ ison, handleMobile }: MobileMenuProps) => {
    const { pathname } = useLocation();

    return (
        <WrapMobileMenu $ison={ison.toString()}>
            <div className="wrap_side">
                <Logo />
                <p className="p__menu">MENU</p>
                <ul className="item-menu">
                    {menuCategoriesData.map((categories) => {
                        return categories.app ? (
                            <li key={categories.app[0].id}>
                                <button
                                    className={`b__menu apps ${pathname.includes("apps") ? `active` : ``}`}
                                    data-name="apps"
                                >
                                    Apps
                                    <span className="p__arrow" />
                                </button>

                                <ul className="list__2depth">
                                    {categories.app.map((apps) => (
                                        <AppsMenu
                                            key={apps.id}
                                            id={apps.id}
                                            includesVal={apps.includesVal}
                                            label={apps.label}
                                            path={apps.path}
                                            pathname={pathname}
                                            state={apps.state}
                                        />
                                    ))}
                                </ul>
                            </li>
                        ) : (
                            <OtherMenu
                                key={categories.id}
                                id={categories.id}
                                includesVal={categories.includesVal}
                                label={categories.label}
                                path={categories.path}
                                pathname={pathname}
                                className={categories.className}
                            />
                        );
                    })}
                </ul>
                <button className="btn_close" onClick={handleMobile} />
            </div>
        </WrapMobileMenu>
    );
};

export default MobileMenu;

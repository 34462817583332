import {
    isDisabledAtom,
    patentNumberAtom,
    patentNumberValueAtom,
    patentSearchListAtom,
    selectOptionAtom,
    selectedTextAtom,
} from "@atoms";

import { useFetchSimilarPatentSearch } from "@queries/SimilarPatentSearch";
import { formatSearchNumber } from "@utils/index";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { DataItem } from "~types/PatentSearch/SimilarPatentSearch";

export const useSimilarPatentSearchData = () => {
    const [patentSearchList, setPatentSearchList] = useAtom(patentSearchListAtom);
    const [search_num, setSearch_num] = useAtom(patentNumberValueAtom);
    const [type, setType] = useAtom(patentNumberAtom);
    const [ntb, setNtb] = useAtom(selectOptionAtom);

    const setIsDisabled = useSetAtom(isDisabledAtom);
    const setSelectedText = useSetAtom(selectedTextAtom);

    const formatNumber = formatSearchNumber(type, search_num);
    const params = { search_num: formatNumber, type, ntb };

    const { mutate } = useFetchSimilarPatentSearch();

    /**
     * @description 특허 검색 결과 리스트 업데이트
     */
    const updatePatentSearchList = useCallback(
        (newList: DataItem[][]) => {
            setPatentSearchList(newList);
        },
        [setPatentSearchList],
    );

    /**
     * @description 추가 버튼
     */
    const handleAddPatentSearchList = () => {
        mutate(params, {
            onSuccess: (data) => {
                if (data && data.DATA) {
                    const newData = data.DATA; // 추가 또는 검색한 데이터
                    const currentData = patentSearchList.flat(); // 기존 데이터
                    const isCheck = currentData.some((item) => item.appl_num === newData[0].appl_num);

                    if (!isCheck) {
                        updatePatentSearchList(addSequentialNumbers([...patentSearchList, newData]));
                    }
                }
            },
        });
    };

    /**
     * @description 검색 버튼
     */
    const handleSearchSimilarPatentList = () => {
        mutate(params, {
            onSuccess: (data) => {
                if (data && data.DATA) {
                    const newData = data.DATA;
                    updatePatentSearchList(addSequentialNumbers([newData]));
                }
            },
        });
    };

    /**
     * @description 초기화 버튼
     */
    const HandleResetPatentList = () => {
        setPatentSearchList([]);
        setSelectedText("출원 번호");
        setNtb("n");
        setType("y");
        setSearch_num("");
    };

    /**
     * @description 순번 부여
     */
    const addSequentialNumbers = useCallback((list: DataItem[][]) => {
        let counter = 1;
        return list.map((group) => group.map((item, index) => (index > 0 ? { ...item, no: counter++ } : item)));
    }, []);

    const numberedPatentSearchList = useMemo(
        () => addSequentialNumbers(patentSearchList),
        [patentSearchList, addSequentialNumbers],
    );

    /**
     * @description 검색 결과가 없을 때 버튼 비활성화
     */
    useEffect(() => {
        setIsDisabled(patentSearchList.length === 0);
    }, [patentSearchList, setIsDisabled]);

    return {
        numberedPatentSearchList,
        patentSearchList,
        search_num,
        type,
        ntb,
        setSearch_num,
        setIsDisabled,
        setType,
        setSelectedText,
        setNtb,
        setPatentSearchList,
        HandleResetPatentList,
        handleAddPatentSearchList,
        handleSearchSimilarPatentList,
    };
};

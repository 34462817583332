import { IChartValue, IConvertedResultList, IDashboadTemplateList, IDataChart } from "types/patentPiaTypes";

export const handleDataUrl = (data: IDashboadTemplateList[]) => {
    const temp = data.map((item) => {
        return {
            title: item.CHARTCONTENT_TITLE,
            url: item.DATA_URL,
            type: item.CHARTTYPE,
            chartGroup: item.CHARTGROUP_ORDER,
            sourceUrl: item.LINK_URL,
        };
    });

    return temp;
};

type DataHandler = (data: any) => void;

export const handleDataType = (data: IDataChart, func: DataHandler, chartGroup?: string) => {
    let tempPortfolio = {};

    if (data.CHART_DATA_RESULT !== null) {
        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "LINE") {
            const xTemp: string[] = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_LINE_X_VALUE.map(
                (item: IChartValue) => item.KEY,
            );
            let name = "";
            const yTemp = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map((item) => {
                let yDataTemp: number[] = [];
                if (chartGroup === "11_00") {
                    name = item.INV_NAME.VALUE;
                } else {
                    if (item.CRH === undefined) {
                        name = item.KW.VALUE;
                    } else {
                        name = item.CRH.VALUE;
                    }
                }

                yDataTemp = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_LINE_Y_VALUE.map(
                    (entry: IChartValue) => Number(item[entry.CONVERTID].VALUE),
                );
                return {
                    name: name,
                    data: yDataTemp,
                };
            });
            tempPortfolio = {
                yAxis: yTemp,
                xAxys: xTemp,
                axisLabel: {
                    x: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_LINE_X_AXISNAME[0],
                    y: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_LINE_Y_AXISNAME[0],
                },
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "HORIZONTALBAR") {
            const tempBar = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.FIELD_LIST.map((item: string) => {
                const xxx = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map((entry: IConvertedResultList) => {
                    return entry[item] !== undefined ? entry[item].VALUE : "";
                });

                const chartData: { xAxis?: (string | number)[]; yAxis?: (string | number)[] } = {};
                if (item === "KW" || item === "CRH" || item === "INV_NAME") {
                    chartData.xAxis = xxx;
                } else {
                    chartData.yAxis = xxx;
                }

                return chartData;
            });

            const result = tempBar.reduce((acc: any, obj: any) => {
                Object.keys(obj).forEach((key) => {
                    acc[key] = obj[key];
                });
                return acc;
            }, {});
            tempPortfolio = {
                yData: result.yAxis,
                xAxys: result.xAxis,
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "WINDROSE") {
            const tempFlower = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map((item) => {
                let tempCate = "";
                let tempValue = 0;

                data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.FIELD_LIST.forEach((elem: string) => {
                    if (elem === "CRH" || elem === "INV_NAME" || elem === "KW") {
                        tempCate = item[elem] !== undefined ? item[elem].VALUE : "";
                    } else {
                        tempValue = item[elem] !== undefined ? parseFloat(item[elem].VALUE) : 0;
                    }
                });

                return {
                    category: tempCate,
                    value: tempValue,
                };
            });

            tempPortfolio = {
                flowerData: tempFlower,
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "2X2MATRIX") {
            const yTemp = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map((item) => {
                let yDataTemp = {};
                let nameTemp = "";
                let xTemp = 0;
                let yTemp = 2;

                data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.FIELD_LIST.forEach((elem: string) => {
                    if (elem === "CRH" || elem === "INV_NAME") {
                        nameTemp = item[elem] !== undefined ? item[elem].VALUE : "";
                    } else if (elem === "PAT_CNT_TOT") {
                        xTemp = item[elem] !== undefined ? parseFloat(item[elem].VALUE) : 0;
                    } else {
                        yTemp = item[elem] !== undefined ? parseFloat(item[elem].VALUE) : 0;
                    }
                });

                yDataTemp = {
                    name: nameTemp,
                    data: [[xTemp, yTemp]],
                };

                return yDataTemp;
            });

            tempPortfolio = {
                yAxis: yTemp,
                axisLabel: {
                    x: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_2X2MATRIX_X_AXISNAME[0],
                    y: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_2X2MATRIX_Y_AXISNAME[0],
                },
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "WING") {
            let negativeTemp: { name?: string; data?: any[] } = {};
            let positiveTemp: { name?: string; data?: any[] } = {};

            let minMax: number[] = [];

            const totalTemp = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.FIELD_LIST.map((item: string) => {
                let chartData: { xAxis?: any[]; yAxis?: any[] } = {};

                const xxx = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
                    (entry: {
                        [key: string]: {
                            [key: string]: any;
                        };
                    }) => {
                        if (
                            item === "PAT_CNT_TOT" ||
                            item === "FCE_TOT" ||
                            item === "SHARE_INTECH_FCE_TOT" ||
                            item === "ASNPAT_TOT" ||
                            item === "FCE_PP_TOT" ||
                            item === "FOCUS_TOTECH_PAT_TOT"
                        ) {
                            return entry[item] !== undefined ? parseFloat(entry[item].VALUE) : 0;
                        } else {
                            return entry[item] !== undefined ? entry[item].VALUE : "";
                        }
                    },
                );

                if (item === "PAT_CNT_TOT") {
                    const convertAndMultiply = (currentValue: number) => -1 * currentValue;

                    const result = xxx.map(convertAndMultiply);

                    minMax.push(Math.ceil(Math.abs(Math.min(...result)) / 10) * 10);

                    negativeTemp = {
                        name: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_WING_X_AXISNAME[0],
                        data: result,
                    };
                } else if (
                    item === "FCE_TOT" ||
                    item === "SHARE_INTECH_FCE_TOT" ||
                    item === "ASNPAT_TOT" ||
                    item === "FCE_PP_TOT" ||
                    item === "FOCUS_TOTECH_PAT_TOT"
                ) {
                    minMax.push(Math.ceil(Math.abs(Math.max(...xxx)) / 10) * 10);
                    positiveTemp = {
                        name: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_WING_Y_AXISNAME[0],
                        data: xxx,
                    };
                } else {
                    chartData.xAxis = xxx;
                }

                chartData.yAxis = [negativeTemp, positiveTemp];

                return chartData;
            });

            const result = totalTemp.reduce((acc: any, obj: any) => {
                Object.keys(obj).forEach((key) => {
                    acc[key] = obj[key];
                });
                return acc;
            }, {});

            const maxNum = Math.max(...minMax);

            tempPortfolio = {
                yAxis: result.yAxis,
                xAxys: result.xAxis,
                maxNum: maxNum,
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "PIE") {
            const tempPie = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.FIELD_LIST.map((item: string) => {
                const xxx = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
                    (elem: { [key: string]: { [key: string]: any } }) => {
                        if (item !== "CRH" && item !== "INV_NAME") {
                            return elem[item] !== undefined ? parseFloat(elem[item].VALUE) : 0;
                        } else {
                            return elem[item] !== undefined ? elem[item].VALUE : "";
                        }
                    },
                );

                return {
                    [item]: xxx,
                };
            });

            const result = tempPie.reduce((acc: any, obj: any) => {
                Object.keys(obj).forEach((key) => {
                    acc[key] = obj[key];
                });
                return acc;
            }, {});

            tempPortfolio = {
                yDataNumbers: result["SHARE_INTECH_FCE_TOT"] || result["SHARE_INAPT_PAT_TOT"],
                xAxys: result["CRH"] || result["INV_NAME"],
            };
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "KEYKPI") {
            tempPortfolio = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_KPI_KEY_VAR.map(
                (item: IChartValue) => ({
                    id: item.CONVERTID,
                    title: data.CHART_DATA_RESULT.CONVERTED_HEADER[item.CONVERTID].HEADER_NAME,
                    num: Number(data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST[0][item.CONVERTID].VALUE),
                }),
            );
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "STRENGTHKPI") {
            tempPortfolio = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_KPI_STRENGTH_VAR.map(
                (item: IChartValue) => ({
                    id: item.CONVERTID,
                    title: data.CHART_DATA_RESULT.CONVERTED_HEADER[item.CONVERTID].HEADER_NAME,
                    num: Number(data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST[0][item.CONVERTID].VALUE),
                }),
            );
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "KEYGAUGE") {
            tempPortfolio = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_GAUGE_KEY_VAR.map(
                (item: IChartValue) => ({
                    id: item.CONVERTID,
                    title: data.CHART_DATA_RESULT.CONVERTED_HEADER[item.CONVERTID].HEADER_NAME,
                    num: Number(data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST[0][item.CONVERTID].VALUE),
                }),
            );
        }

        if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "TREND") {
            const xTemp = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TREND_X_VALUE.map(
                (item: IChartValue) => item.KEY,
            );
            let name = "";

            const yTemp = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
                (item: { [key: string]: { [key: string]: any } }) => {
                    let yDataTemp: number[] = [];
                    name = item.O_LOCALE.VALUE;
                    yDataTemp = data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TREND_Y_VALUE.map(
                        (entry: IChartValue) => Number(item[entry.CONVERTID].VALUE),
                    );
                    return {
                        name: name,
                        data: yDataTemp,
                    };
                },
            );

            tempPortfolio = {
                yAxis: yTemp,
                xAxys: xTemp,
                axisLabel: {
                    x: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TREND_X_AXISNAME[0],
                    y: data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TREND_Y_AXISNAME[0],
                },
            };
        }
    }

    const getNations = (name: string) => {
        switch (name) {
            case "KR":
                return "KOREA";
            case "EP":
                return "Europe";
            case "US":
                return "United States";
            case "JP":
                return "Japan";
            case "CN":
                return "China";
            default:
                return "Others";
        }
    };

    if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "WORLDMAP") {
        const temp = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
            (item: { [key: string]: { [key: string]: any } }) => {
                // europe 전체를 포함하는 id가 없어서 유럽 한 가운데인 스위스로 위치는 설정
                const id = item.O_LOCALE.VALUE === "EP" ? "CH" : item.O_LOCALE.VALUE;
                const name = getNations(item.O_LOCALE.VALUE);
                let value = 0;
                for (const entry of data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_WORLDMAP_VAR) {
                    value = item[entry.CONVERTID].VALUE;
                }

                return {
                    id,
                    name,
                    value,
                };
            },
        );

        tempPortfolio = {
            worldMap: temp,
        };
    }

    if (data.CHART_DATA_RESULT.CHART_INFO.CHART_SETTING.CHART_TYPE === "WORDCLOUD") {
        const wordTemp = data.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
            (item: { [key: string]: { [key: string]: any } }) => ({
                text: `${item.KW.VALUE},${item.PAT_CNT_TOT.VALUE}`,
                value: Number(item.KWID.VALUE),
            }),
        );

        tempPortfolio = {
            words: wordTemp,
        };
    }

    func(tempPortfolio);
};

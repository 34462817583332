import { postIndustrySearch } from "@api/patentSearch";
import { useMutation } from "@tanstack/react-query";
import { getDate } from "@utils/index";
import { useEffect, useState } from "react";
import {
    IContent,
    IDataSet,
    IGroth,
    IGrothRes,
    IKsicCompany,
    IKsicIndustry,
    INtbDataSet,
    INtbGrothRes,
    INtbKsicCompany,
    INtbKsicIndustry,
} from "./types";

const useData = (sector: string) => {
    const [didMount, setDidMount] = useState(false);

    const today = getDate();
    const { isLoading, data, mutate } = useMutation(() => postIndustrySearch(sector, today));

    const [cagr, setCagr] = useState<IContent>({
        data: [],
        label: [],
    });

    const [ksic1, setKsic1] = useState<IContent>({
        data: [],
        label: [],
    });
    const [ksic2, setKsic2] = useState<IContent>({
        data: [],
        label: [],
    });
    const [groth, setGroth] = useState<IGroth[]>([]);

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount) {
            mutate();
        }
    }, [didMount]);

    useEffect(() => {
        if (data !== undefined && data.RETURN_CNT !== 0) {
            if (data?.DATA[0].data_set !== undefined) {
                let temp: IContent = {
                    data: [],
                    label: [],
                };
                if (sector === "all") {
                    temp = {
                        data: data.DATA[0].data_set.map((item: IDataSet) => item.cagr),
                        label: data.DATA[0].data_set.map((item: IDataSet) => item.ksic_div),
                    };
                }

                if (sector === "ntb") {
                    temp = {
                        data: data.DATA[0].data_set.map((item: INtbDataSet) => item.NTB_cagr),
                        label: data.DATA[0].data_set.map((item: INtbDataSet) => item.NTB_ksic_div),
                    };
                }

                setCagr(temp);
            }

            if (data?.DATA[1].data_set !== undefined) {
                let temp: IContent = {
                    data: [],
                    label: [],
                };

                if (sector === "all") {
                    const sorted: IKsicIndustry[] = data?.DATA[1].data_set.sort(function (
                        a: IKsicIndustry,
                        b: IKsicIndustry,
                    ) {
                        if (a.appl_cnt < b.appl_cnt) {
                            return 1;
                        }
                        if (a.appl_cnt > b.appl_cnt) {
                            return -1;
                        }
                        return 0;
                    });

                    temp = {
                        data: sorted.slice(0, 150).map((item) => item.appl_cnt),
                        label: sorted.slice(0, 150).map((item) => item.ksic_sub_cls),
                    };
                }

                if (sector === "ntb") {
                    const sorted: INtbKsicIndustry[] = data?.DATA[1].data_set.sort(function (
                        a: INtbKsicIndustry,
                        b: INtbKsicIndustry,
                    ) {
                        if (a.NTB_appt_cnt < b.NTB_appt_cnt) {
                            return 1;
                        }
                        if (a.NTB_appt_cnt > b.NTB_appt_cnt) {
                            return -1;
                        }
                        return 0;
                    });

                    temp = {
                        data: sorted.slice(0, 150).map((item) => item.NTB_appt_cnt),
                        label: sorted.slice(0, 150).map((item) => item.NTB_ksic_sub_cls),
                    };
                }

                setKsic1(temp);
            }

            if (data?.DATA[2].data_set !== undefined && data?.DATA[3].data_set !== undefined) {
                let result: IGroth[] = [];
                if (sector === "all") {
                    const sortedY: IGrothRes[] = data?.DATA[2].data_set.sort(function (a: IGrothRes, b: IGrothRes) {
                        if (a.tech_grth_prd > b.tech_grth_prd) {
                            return 1;
                        }
                        if (a.tech_grth_prd < b.tech_grth_prd) {
                            return -1;
                        }
                        return 0;
                    });

                    const sortedX: IGrothRes[] = data?.DATA[3].data_set.sort(function (a: IGrothRes, b: IGrothRes) {
                        if (a.tech_grth_prd > b.tech_grth_prd) {
                            return 1;
                        }
                        if (a.tech_grth_prd < b.tech_grth_prd) {
                            return -1;
                        }
                        return 0;
                    });

                    result = sortedY.map((itemY: IGrothRes, index: number) => ({
                        x: sortedX[index].appt_cnt || 0,
                        y: itemY.pat_appl_cnt || 0,
                        value: (sortedX[index].appt_cnt || 0) + (itemY.pat_appl_cnt || 0),
                        title: itemY.tech_grth_prd,
                    }));
                }

                if (sector === "ntb") {
                    const sortedY: INtbGrothRes[] = data?.DATA[2].data_set.sort(function (
                        a: INtbGrothRes,
                        b: INtbGrothRes,
                    ) {
                        if (a.NTB_tech_grth_prd > b.NTB_tech_grth_prd) {
                            return 1;
                        }
                        if (a.NTB_tech_grth_prd < b.NTB_tech_grth_prd) {
                            return -1;
                        }
                        return 0;
                    });

                    const sortedX: INtbGrothRes[] = data?.DATA[3].data_set.sort(function (
                        a: INtbGrothRes,
                        b: INtbGrothRes,
                    ) {
                        if (a.NTB_tech_grth_prd > b.NTB_tech_grth_prd) {
                            return 1;
                        }
                        if (a.NTB_tech_grth_prd < b.NTB_tech_grth_prd) {
                            return -1;
                        }
                        return 0;
                    });

                    result = sortedY.map((itemY, index: number) => ({
                        x: sortedX[index].NTB_appt_cnt || 0,
                        y: itemY.NTB_appl_cnt || 0,
                        value: (sortedX[index].NTB_appt_cnt || 0) + (itemY.NTB_appl_cnt || 0),
                        title: itemY.NTB_tech_grth_prd,
                    }));
                }

                setGroth(result);
            }

            if (data?.DATA[4].data_set !== undefined) {
                let temp: IContent = {
                    data: [],
                    label: [],
                };

                if (sector === "all") {
                    const sorted: IKsicCompany[] = data?.DATA[4].data_set.sort(function (
                        a: IKsicCompany,
                        b: IKsicCompany,
                    ) {
                        if (a.pat_appl_cnt < b.pat_appl_cnt) {
                            return 1;
                        }
                        if (a.pat_appl_cnt > b.pat_appl_cnt) {
                            return -1;
                        }
                        return 0;
                    });

                    temp = {
                        data: sorted.slice(0, 150).map((item) => item.pat_appl_cnt),
                        label: sorted.slice(0, 150).map((item) => item.com_nm),
                    };
                }

                if (sector === "ntb") {
                    const sorted: INtbKsicCompany[] = data?.DATA[4].data_set.sort(function (
                        a: INtbKsicCompany,
                        b: INtbKsicCompany,
                    ) {
                        if (a.NTB_appl_cnt < b.NTB_appl_cnt) {
                            return 1;
                        }
                        if (a.NTB_appl_cnt > b.NTB_appl_cnt) {
                            return -1;
                        }
                        return 0;
                    });

                    temp = {
                        data: sorted.slice(0, 150).map((item: any) => item.NTB_appl_cnt),
                        label: sorted.slice(0, 150).map((item: any) => item.NTB_com_nm),
                    };
                }

                setKsic2(temp);
            }
        }
    }, [isLoading, data]);

    return {
        cagr,
        ksic1,
        groth,
        ksic2,
        isLoading,
        data,
    };
};

export default useData;

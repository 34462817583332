import { postSimilarPatentSearch } from "@api/patentSearch";
import { useMutation } from "@tanstack/react-query";
import { SimilarPatentSearchParams } from "~types/PatentSearch/SimilarPatentSearch";

const similarPatentSearchKeys = {
    SimilarPatent: () => ["similar-patent"],
};
export const useFetchSimilarPatentSearch = () => {
    return useMutation({
        mutationKey: similarPatentSearchKeys.SimilarPatent(),
        mutationFn: ({ search_num, type, ntb }: SimilarPatentSearchParams) =>
            postSimilarPatentSearch(search_num, type, ntb),
    });
};

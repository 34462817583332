import { IBarWithNegativeProps } from "types/etcTypes";
import ApexChart from "react-apexcharts";

const BarWithNegative = ({ data, categories, maxNum, height }: IBarWithNegativeProps) => {
    return (
        <>
            <ApexChart
                type="bar"
                width={`100%`}
                height={height}
                series={data}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        type: "bar",
                        stacked: true,
                    },
                    colors: ["#008FFB", "#FF4560"],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: "80%",
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: 50,
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#999"],
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                        },
                        formatter: (val) => {
                            return `${Math.abs(Number(val))}`;
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff",
                            padding: 4,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: "#fff",
                            opacity: 0.9,
                            dropShadow: {
                                enabled: false,
                                top: 1,
                                left: 1,
                                blur: 1,
                                color: "#000",
                                opacity: 0.45,
                            },
                        },
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: "#000",
                            opacity: 0.45,
                        },
                    },
                    stroke: {
                        width: 1,
                        colors: ["#fff"],
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    yaxis: {
                        min: maxNum * -1,
                        max: maxNum,
                        // title: {
                        //     text: "Age",
                        // },
                        labels: {
                            formatter: function (val) {
                                return `${val}`;
                            },
                        },
                    },
                    xaxis: {
                        categories: categories,
                        // title: {
                        //     text: "Percent",
                        // },
                        labels: {
                            formatter: function (val) {
                                return `${Math.abs(Math.round(Number(val)))}`;
                            },
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return `${Math.abs(val)}`;
                            },
                        },
                    },
                }}
            />
        </>
    );
};

export default BarWithNegative;

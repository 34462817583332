import { allPopAtom } from "@atoms";
import DashPop from "@components/DashPop";
import { useLayoutAction } from "@services/Layout";
import { popVariants } from "@utils/index";
import { AnimatePresence, motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import Menu from "./Menu";
import MenuButton from "./Menu/MenuButton";
import MobileMenu from "./Menu/MobileMenu";
import RightHeaderElement from "./RightHeaderElement";
import Title from "./Title";
import { Navi, Wrap } from "./style";

const Layout = ({ children }: PropsWithChildren) => {
    const popState = useAtomValue(allPopAtom);
    const { pathname } = useLocation();
    const {
        handleBtnClick,
        handleClick,
        handleKeyUp,
        handleMobile,
        handleNavi,
        handlePops,
        handleSearchText,
        searchInput,
        searchText,
        wideMode,
        reSize,
        ison,
    } = useLayoutAction(pathname);

    return (
        <Wrap $wide={wideMode}>
            <Navi $wide={wideMode}>
                <Menu $iswide={wideMode} /> {/* 웹 메뉴 */}
            </Navi>
            <MobileMenu ison={ison} handleMobile={handleMobile} /> {/* 모바일 메뉴 */}
            <div className="p__contents">
                <div className="l__header">
                    <div className="c__infos">
                        <MenuButton reSize={reSize} handleNavi={handleNavi} handleMobile={handleMobile} />
                        <Title
                            pathname={pathname}
                            searchInput={searchInput}
                            searchText={searchText}
                            handleSearchText={handleSearchText}
                            handleKeyUp={handleKeyUp}
                            handleClick={handleClick}
                            handleBtnClick={handleBtnClick}
                        />
                    </div>
                    <RightHeaderElement handlePops={handlePops} />
                </div>
                {children}
            </div>
            <AnimatePresence>
                {popState.dash && (
                    <motion.div
                        variants={popVariants}
                        initial="initial"
                        animate="visible"
                        exit="leaving"
                        className="pop_outer"
                    >
                        <DashPop />
                    </motion.div>
                )}
            </AnimatePresence>
        </Wrap>
    );
};

export default Layout;

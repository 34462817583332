import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";

import LoadingComp from "@components/LoadingComp";
import PageNation from "@components/PageNation";
import { usePagingTecStormData } from "@queries/TechStorm/TechTrad";
import { Wrap } from "../style";

const PAGE_SIZE = 10; // 페이지당 데이터 수

const TechTrad = () => {
    const [searchText, setSearchText] = useState("");
    const [keyWord, setKeyWord] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const searchInput = useRef<HTMLInputElement | null>(null);

    const { techData, isLoading, totalPages } = usePagingTecStormData(currentPage, PAGE_SIZE, keyWord);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.key === "Enter") {
            setKeyWord(searchText);
            setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 초기화
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.currentTarget.value !== "") {
            setSearchText("");
        }
    };

    const handleBtnClick = () => {
        setKeyWord(searchText);
        setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 초기화
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (keyWord) {
            setSearchText(keyWord);
        }
    }, [keyWord]);

    useEffect(() => {
        const clickWrapp = (event: MouseEvent) => {
            if (searchInput.current && document.activeElement !== searchInput.current) {
                if (!searchInput.current.contains(event.target as Node) && keyWord) {
                    setSearchText(keyWord);
                }
            }
        };

        document.addEventListener("click", clickWrapp);
        return () => {
            document.removeEventListener("click", clickWrapp);
        };
    }, [keyWord]);

    return (
        <Wrap>
            <div className="c__search">
                <input
                    type="text"
                    ref={searchInput}
                    onChange={handleSearch}
                    onKeyUp={handleKeyUp}
                    onClick={handleClick}
                    value={searchText}
                />
                <button onClick={handleBtnClick} />
            </div>

            <div className="l__list">
                {isLoading ? (
                    <div className="c__loading">
                        <LoadingComp />
                    </div>
                ) : (
                    <>
                        {techData?.empty ? (
                            <>
                                <strong>등록기술 (0)</strong>
                                <p className="text-empty">등록 기술이 없습니다.</p>
                            </>
                        ) : (
                            <>
                                <strong>등록기술 ({techData?.totalElements})</strong>
                                <div className="c__list">
                                    {techData?.content.map((item: any) => <Card key={item.techIdx} data={item} />)}
                                </div>
                                <div className="Pagination-wrap-techstorm">
                                    <PageNation
                                        totalPage={totalPages}
                                        currentPage={currentPage}
                                        clickPage={handlePageClick}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </Wrap>
    );
};

export default TechTrad;

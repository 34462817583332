import { useEffect, useState } from "react";
import useRelatedPatent from "./useRelatedPatent";
import { useAtomValue } from "jotai";
import { dashSelectAtom } from "@atoms";
import PageNation from "./PageNation";
import { commaNumber, handleText } from "@utils/index";
import { ListRelations } from "../ListComp/style";

const OFFSET = 3;

const RelatedPatentIllunex = () => {
    const dashSelect = useAtomValue(dashSelectAtom);

    const [props, setProps] = useState({
        page: 1,
        size: 5,
        data: { title: dashSelect.name },
    });

    const { data, isLoading } = useRelatedPatent({ props });

    const handleClick = (num: number) => {
        setProps((prev) => ({
            ...prev,
            page: num,
        }));
    };

    useEffect(() => {
        if (dashSelect.name !== "") {
            setProps((prev: any) => ({
                ...prev,
                data: {
                    title: dashSelect.name,
                },
                page: 1,
            }));
        }
    }, [dashSelect.name]);

    return (
        <>
            <div className="c__header">
                <strong>관련특허</strong>
            </div>
            <div className="c__list">
                {data !== undefined && data.content.length === 0 ? (
                    <div className="box__empty">
                        <p>검색 결과가 없습니다.</p>
                    </div>
                ) : (
                    <ul>
                        {data?.content.map((item) => (
                            <ListRelations
                                key={item.id}
                                className="hasLink"
                                onClick={() => window.open(`/consearch/patent/${item.applicate_number}`)}
                            >
                                <div>
                                    <strong>{item.invention_title && handleText(item.invention_title, 30)}</strong>
                                    <ul className="p__infos">
                                        {item.applicate_number && <li>{item.applicate_number}</li>}
                                        {item.applicate_date && <li>{item.applicate_date}</li>}
                                        {item.applicant_kor_names[0] && (
                                            <li>
                                                {item.applicant_kor_names[0] &&
                                                    handleText(item.applicant_kor_names[0], 16)}
                                            </li>
                                        )}
                                        {item.reg_status && <li>{item.reg_status}</li>}
                                    </ul>
                                </div>
                            </ListRelations>
                        ))}
                    </ul>
                )}
            </div>
            <div className="c__footer">
                <p className="text__footer">
                    <span>{data ? commaNumber(data?.totalElements) : "10,000"}</span>개 결과 중 <span>5</span>개 표시
                </p>

                <PageNation
                    totalPage={data?.totalPages ? data?.totalPages : 100000}
                    currentPage={props.page}
                    offset={OFFSET}
                    handleClick={handleClick}
                />
            </div>
        </>
    );
};

export default RelatedPatentIllunex;

import { getReseacher } from "@api/patentPia";
import { IResercherType } from "types/patentPiaTypes";
import { keyWordStorageAtom, researcherTotalPageAtom, searchResearcherAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

export const useResearcher = (limit: number, offset: number) => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const [dataTotalCount, setDataTotalCount] = useState<string>("0");
    const [realData, setRealData] = useState<IResercherType>();
    const searchResearcher = useSetAtom(searchResearcherAtom);
    const totalPage = useSetAtom(researcherTotalPageAtom);

    const { isLoading, refetch } = useQuery(
        ["reseacher", keyWordStorage],
        async () => getReseacher(keyWordStorage, limit, offset),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            cacheTime: 0,
            enabled: false,
            onSuccess(data) {
                const temp = JSON.parse(data.RESULT);
                setRealData(temp);
                searchResearcher(temp);
                if (temp.CHART_DATA_RESULT?.TOTAL_COUNT) {
                    totalPage(Math.ceil(temp.CHART_DATA_RESULT?.TOTAL_COUNT / limit));
                } else {
                    totalPage(1);
                }
            },
            onError: (error: any) => {
                console.log(error);
            },
        },
    );

    useEffect(() => {
        if (keyWordStorage !== "") {
            refetch();
        }
    }, [keyWordStorage, refetch]);

    useEffect(() => {
        setDataTotalCount(realData?.CHART_DATA_RESULT?.TOTAL_COUNT ?? "0");
    }, [realData?.CHART_DATA_RESULT?.TOTAL_COUNT]);

    return {
        isLoading,
        realData,
        dataTotalCount,
    };
};

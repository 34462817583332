import config from "@config";
import { handleText } from "@utils/index";
import styled from "styled-components";
import { AgentCard } from "../style";

const BoxImg = styled.div<{ $bg: string }>`
    background-image: url(${(props) => props.$bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`;

const Card = ({ data }: any) => {
    return (
        <AgentCard>
            <div
                className="box_name"
                onClick={() => window.open(`${config.TECHSTORMURL}/tta/detail/${data.techTrader.techTraderIdx}`)}
            >
                <BoxImg
                    className="box_img"
                    $bg={
                        data.attachList?.find((x: any) => x.attachCd === "MAC0101")?.fileUrl
                            ? data.attachList?.find((x: any) => x.attachCd === "MAC0101")?.fileUrl
                            : data.attachList[0].fileUrl
                    }
                />
                {/* <div className="box_no_img" /> */}
                <div className="box_text">
                    <p className="text_name">{data.name}</p>
                    <p className="text_company">({data.techTrader.affiliateName})</p>
                </div>
            </div>
            <p className="box_desc">{handleText(data.techTrader.summary, 45)}</p>
        </AgentCard>
    );
};

export default Card;

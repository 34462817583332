import { selectOptionAtom } from "@atoms";
import { useAtom } from "jotai";

export default function PatentClassification() {
    const [selectedOption, setSelectedOption] = useAtom(selectOptionAtom);
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => setSelectedOption(event.target.value);

    return (
        <div className="f__group">
            <div className="f__label">유사특허 범위</div>
            <div className="f__control">
                <ul className="c__radio">
                    <li className="radio-item">
                        <label className="radio-label">
                            <input
                                type="radio"
                                value="n"
                                checked={selectedOption === "n"}
                                onChange={handleRadioChange}
                            />
                            <span className="checkmark"></span>
                            <span className="radio-text">국내특허</span>
                        </label>
                    </li>
                    <li className="radio-item">
                        <label className="radio-label">
                            <input
                                type="radio"
                                value="y"
                                checked={selectedOption === "y"}
                                onChange={handleRadioChange}
                            />
                            <span className="checkmark"></span>
                            <span className="radio-text">NTB 등록특허</span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    );
}

import { timeFormat } from "@utils/index";
import { useEffect, useState } from "react";

const Clock = () => {
    const [time, setTime] = useState<string>("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentDate = new Date();
            setTime(timeFormat(currentDate));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return <p className="p__time">{time}</p>;
};

export default Clock;

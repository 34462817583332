import { TrashIcon } from "@assets/svg/InterfacesIcon";
import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";
import { TableComponentProps } from "~types/PatentSearch/SimilarPatentSearch";
import TableRow from "../TableRow";

export default function SearchPatentTable({ title }: TableComponentProps) {
    const { patentSearchList, setPatentSearchList, setIsDisabled } = useSimilarPatentSearchData();

    const handleDeleteSimilarPatentList = (index: number) => {
        setPatentSearchList((prevList) => prevList.filter((_, i) => i !== index));

        if (patentSearchList.length === 0) setIsDisabled(true);
    };

    return (
        <div className="table-wrap ">
            <h4 className="similar-title">{title}</h4>
            <div className="c__table">
                <div className="table search-table">
                    <TableRow rowText="삭제" rowClass="delete" />
                    <div className="tbody">
                        {patentSearchList.map((item, index) => (
                            <div className="tr" key={index}>
                                <div className="td num">{index + 1}</div>
                                <div className="td appl-num">{item[0].appl_num}</div>
                                <div className="td inv-ti">{item[0].inv_ti}</div>
                                <div className="td appl-com">{item[0].appl_com}</div>
                                <div className="td delete">
                                    <button
                                        type="button"
                                        className="btn-trash"
                                        onClick={() => handleDeleteSimilarPatentList(index)}
                                    >
                                        <TrashIcon />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

import ApexChart from "react-apexcharts";
import { COLOR } from "@colors";
import styled from "styled-components";
import { ILineDotsProps } from "types/etcTypes";

const Wrap = styled.div`
    position: absolute;
    width: calc(100% - 60px);
    top: 70px;
    left: 30px;
`;

const LineDots = ({ data, label, height, labelName }: ILineDotsProps) => {
    return (
        <Wrap>
            <ApexChart
                type="line"
                series={data}
                height={`${height}px`}
                width={`100%`}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        animations: {
                            enabled: false,
                        },
                    },
                    stroke: {
                        curve: "straight",
                        lineCap: "square",
                        width: 3,
                    },
                    xaxis: {
                        categories: label,
                        title: {
                            text: labelName?.x,
                        },
                    },
                    yaxis: {
                        show: true,
                        labels: {
                            formatter: (val) => {
                                return `${val.toFixed(0)}`;
                            },
                        },
                        title: {
                            text: labelName?.y,
                        },
                    },
                    markers: {
                        size: 5,
                        strokeWidth: 1,
                        hover: {
                            size: 5,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -5,
                        formatter: function (val) {
                            return val.toLocaleString("en-US");
                        },
                        style: {
                            colors: ["#333"],
                        },
                        background: {
                            enabled: false,
                        },
                    },
                    // colors: ["#f02d00", "#e8a71c", "#e6e61e", "#347c19", "#1c43e8"],
                    colors: COLOR,
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        // horizontalAlign: "center",
                        // floating: true,
                        // position: "bottom",
                        // offsetY: 10,
                    },
                }}
            />
        </Wrap>
    );
};

export default LineDots;

import useTabs from "@hooks/useTabs";

import Layout from "@components/Layout";
import SearchNavi from "@components/SearchNavi";
import { Wrap } from "@pages/Build/style";
import { Link, useParams } from "react-router-dom";
import PDbanner from "@assets/img/effectMallBanner.png";
import usePatentDetail from "../../../hooks/usePatentDetail";
import LoadingComp from "@components/LoadingComp";
import { Fragment, useEffect, useState } from "react";
import ClaimComp from "./CliamComp";
import styled from "styled-components";

const LoadingWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 160px);
`;

const jsonParsing = (string: any) => {
    const replaceStr = string.replace(/'/gi, '"');
    const json = JSON.parse(replaceStr);
    return json;
};
const arrayToString = (array: any, fieldName: any) => {
    let newArray: any = [];
    array.forEach((item: any) => {
        fieldName ? newArray.push(item[fieldName]) : newArray.push(item);
    });
    return newArray.join(", ");
};

interface IClaims {
    type: string;
    text: string;
}

const PatentDetail = () => {
    const { id } = useParams();
    const { data, isLoading, patentSimilarityData, patentSimilarityLoading } = usePatentDetail(id ? id : "");
    const [claims, setClaims] = useState<IClaims[]>([]);
    const [claimCounts, setClaimCounts] = useState({
        all: 0,
        independent: 0,
        dependent: 0,
    });

    const getInventors = (string: any) => {
        let result: any = "";
        const temp = jsonParsing(string).inventors;

        if (Array.isArray(temp)) {
            result = arrayToString(temp, "name");
        } else {
            // 배열이 아닐 때
            result = temp.name;
        }
        return result;
    };

    const allTabs = [
        {
            id: 0,
            name: `전체청구항 (${claimCounts.all}항)`,
            data: <ClaimComp data={claims} id={0} />,
        },
        {
            id: 1,
            name: `종속항 (${claimCounts.dependent}항)`,
            data: <ClaimComp data={claims} id={1} />,
        },
        {
            id: 2,
            name: `독립항 (${claimCounts.independent}항)`,
            data: <ClaimComp data={claims} id={2} />,
        },
    ];

    useEffect(() => {
        if (data !== undefined) {
            const temp: IClaims[] = data.claimMap.all.map((item: any) => ({
                type: data.claimMap.claim.includes(item)
                    ? "claim"
                    : data.claimMap.independent.includes(item)
                    ? "independent"
                    : data.claimMap.dependent.includes(item)
                    ? "dependent"
                    : "",
                text: item,
            }));

            setClaims(temp);
            setClaimCounts({
                all: temp.length,
                independent: data.claimMap.independent.length,
                dependent: data.claimMap.dependent.length,
            });
        }
    }, [data]);

    const { currentItem, changeItem, currentIndex } = useTabs(0, allTabs);
    const handleChangeTab = (id: number) => {
        changeItem(id);
    };

    return (
        <Layout>
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                <SearchNavi />

                                {/* 상세시작 */}
                                {isLoading && patentSimilarityLoading ? (
                                    <div className="PatentDetail">
                                        <LoadingWrap>
                                            <LoadingComp />
                                        </LoadingWrap>
                                    </div>
                                ) : (
                                    data !== undefined &&
                                    patentSimilarityData !== undefined && (
                                        <div className="PatentDetail">
                                            <div className="PD__section">
                                                <div className="PD-aside-wrap">
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">출원번호/일자</div>
                                                        <div className="aside-detail">
                                                            {data.patent.id && data.patent.id}

                                                            {data.patent.applicateDate &&
                                                                ` (${data.patent.applicateDate.split("-")[0]}.`}
                                                            {data.patent.applicateDate &&
                                                                `${data.patent.applicateDate.split("-")[1]}.`}
                                                            {data.patent.applicateDate &&
                                                                `${data.patent.applicateDate.split("-")[2]})`}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">공개번호/일자</div>
                                                        <div className="aside-detail">
                                                            {data.patent.openNumber}
                                                            {data.patent.openDate &&
                                                                ` (${data.patent.openDate.split("-")[0]}.`}
                                                            {data.patent.openDate &&
                                                                `${data.patent.openDate.split("-")[1]}.`}
                                                            {data.patent.openDate &&
                                                                `${data.patent.openDate.split("-")[2]})`}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">등록번호/일자</div>
                                                        <div className="aside-detail">
                                                            {data.patent.registerNumber}
                                                            {data.patent.registerDate &&
                                                                ` (${data.patent.registerDate.split("-")[0]}.`}
                                                            {data.patent.registerDate &&
                                                                `${data.patent.registerDate.split("-")[1]}.`}
                                                            {data.patent.registerDate &&
                                                                `${data.patent.registerDate.split("-")[2]})`}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">공보번호/일자</div>
                                                        <div className="aside-detail">
                                                            {data.patent.publicationNumber}
                                                            {data.patent.publicationDate &&
                                                                ` (${data.patent.publicationDate.split("-")[0]}.`}
                                                            {data.patent.publicationDate &&
                                                                `${data.patent.publicationDate.split("-")[1]}.`}
                                                            {data.patent.publicationDate &&
                                                                `${data.patent.publicationDate.split("-")[2]})`}
                                                        </div>
                                                    </div>
                                                    {data.applicant && (
                                                        <div className="PD-aside01">
                                                            <div className="aside-tit">출원인/최종 권리자</div>
                                                            <div className="aside-detail">
                                                                {data.applicant.map((item: any, index: number) => (
                                                                    <Fragment key={index}>{item.name}</Fragment>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">행정상태</div>
                                                        <div className="aside-detail">
                                                            {data.patent.regStatus && data.patent.regStatus}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">구분</div>
                                                        <div className="aside-detail">
                                                            {data.patent.originalApplicationKind &&
                                                                data.patent.originalApplicationKind}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">발명자</div>
                                                        <div className="aside-detail">
                                                            {getInventors(data.patent.inventor)}
                                                        </div>
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">IPC</div>
                                                        {data.ipcList.length > 0 &&
                                                            data.ipcList.map((item: any, index: number) => (
                                                                <p className="aside-list" key={index}>
                                                                    {`${item.ipcCode}(${item.revDate})`}
                                                                </p>
                                                            ))}
                                                    </div>
                                                    <div className="PD-aside01">
                                                        <div className="aside-tit">CPC</div>
                                                        {data.cpcList.length > 0 &&
                                                            data.cpcList.map((item: any, index: number) => (
                                                                <p
                                                                    className="aside-list"
                                                                    key={index}
                                                                >{`${item.cpcCode}(${item.revDate})`}</p>
                                                            ))}
                                                    </div>
                                                    <div className="aside-Banner">
                                                        <a
                                                            href="https://effectmall.com/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img src={PDbanner} alt="emmall" />
                                                            <div className="ban-wrap">
                                                                <p className="ban-tit">
                                                                    산업융합 검색 플랫폼 <br />
                                                                    Effect mall
                                                                </p>
                                                                <span>바로가기</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="PD-main">
                                                    <p className="main-tit">
                                                        {data.patent.inventionTitle && data.patent.inventionTitle}
                                                    </p>
                                                    <div className="PD-info01">
                                                        <div className="subTitle">
                                                            <div className="subTitle-kr">요약</div>
                                                            {/* <div className="subTitle-en">Abstract</div> */}
                                                        </div>
                                                        <div className="PD-infoDetail">
                                                            {data.patentContent.patentContent &&
                                                                data.patentContent.patentContent}
                                                        </div>
                                                    </div>

                                                    <div className="PD-info01">
                                                        <div className="subTitle">
                                                            <div className="subTitle-kr">청구항</div>
                                                            {/* <div className="subTitle-en">Exemplary Claim</div> */}
                                                        </div>
                                                    </div>

                                                    <div className="subTab-wrap">
                                                        <ul className="subTab">
                                                            {allTabs.map((item) => (
                                                                <li className="" key={item.id}>
                                                                    <button
                                                                        className={
                                                                            currentIndex === item.id
                                                                                ? "subTab-item active"
                                                                                : "subTab-item"
                                                                        }
                                                                        onClick={() => handleChangeTab(item.id)}
                                                                    >
                                                                        {item.name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    {currentItem.data}

                                                    <div className="PD-info01">
                                                        <div className="subTitle">
                                                            <div className="subTitle-kr">유사특허</div>
                                                            {/* <div className="subTitle-en">Exemplary Claim</div> */}
                                                        </div>
                                                    </div>

                                                    <div className="Table-wrap">
                                                        <div className="Table patent">
                                                            <ul className="tr thead">
                                                                <li className="td order">No</li>
                                                                <li className="td num">출원번호</li>
                                                                <li className="td name">특허명칭</li>
                                                                <li className="td ipc">IPC</li>
                                                                <li className="td date">출원일</li>
                                                                <li className="td company">출원인/최종 권리자</li>
                                                                <li className="td percent">유사도</li>
                                                            </ul>
                                                            <div className="Scroll">
                                                                {patentSimilarityData.similarity.content.map(
                                                                    (item, index) => (
                                                                        <ul className="tr" key={item.id}>
                                                                            <li className="td order">{index + 1}</li>
                                                                            <li className="td num">
                                                                                <Link
                                                                                    to={`/consearch/patent/${item.id}`}
                                                                                >
                                                                                    {item.id}
                                                                                </Link>
                                                                            </li>
                                                                            <li className="td name">
                                                                                {item.inventionTitle}
                                                                            </li>
                                                                            <li className="td ipc">
                                                                                {item.ipcList.map((item) => (
                                                                                    <p
                                                                                        key={`${item.patentId}${item.ipcCode}`}
                                                                                    >
                                                                                        {item.ipcCode}
                                                                                    </p>
                                                                                ))}
                                                                            </li>
                                                                            {item.applicateDate && (
                                                                                <li className="td date">
                                                                                    {`${
                                                                                        item.applicateDate.split("-")[0]
                                                                                    }.${
                                                                                        item.applicateDate.split("-")[1]
                                                                                    }.${
                                                                                        item.applicateDate.split("-")[2]
                                                                                    }`}
                                                                                </li>
                                                                            )}

                                                                            <li className="td company">
                                                                                <p className="title">출원인</p>
                                                                                <p>
                                                                                    {/* {
                                                                                        item.patentApplicant[0]
                                                                                            .applicant.name
                                                                                    } */}
                                                                                    {item.patentApplicant.map(
                                                                                        (item, index) => (
                                                                                            <Fragment key={index}>
                                                                                                {item.applicant.name}
                                                                                            </Fragment>
                                                                                        ),
                                                                                    )}
                                                                                </p>
                                                                            </li>
                                                                            <li className="td percent">{`${item.similarity}%`}</li>
                                                                        </ul>
                                                                    ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* PD__section */}
                                        </div>
                                    )
                                )}

                                {/* PatentDetail */}
                            </div>
                        </div>
                    </div>
                </div>
            </Wrap>
        </Layout>
    );
};

export default PatentDetail;

import { menuCategoriesData } from "@constants/categories";
import { Fragment } from "react";
import { TitleProps } from "~types/Layout";

const Title = ({
    pathname,
    searchInput,
    searchText,
    handleClick,
    handleKeyUp,
    handleSearchText,
    handleBtnClick,
}: TitleProps) => {
    return (
        <>
            {menuCategoriesData.map((category, cIdx) => (
                <Fragment key={cIdx}>
                    {category.app ? (
                        <>
                            {category.app.map((app, aIdx) => (
                                <Fragment key={aIdx}>
                                    {pathname.includes(app.includesVal) && (
                                        <span className="p__title">{app.label}</span>
                                    )}
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        <>
                            {pathname.includes(category.includesVal) && (
                                <>
                                    <span className="p__title">{category.label}</span>
                                    {category.includesVal === "consearch" && (
                                        <div className="c__search">
                                            <input
                                                type="text"
                                                ref={searchInput}
                                                onChange={handleSearchText}
                                                onKeyUp={handleKeyUp}
                                                onClick={handleClick}
                                                value={searchText}
                                            />
                                            <button onClick={handleBtnClick} />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Fragment>
            ))}
        </>
    );
};

export default Title;

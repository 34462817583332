import { commaNumber } from "@utils/index";
import { scaleLog } from "@visx/scale";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface IWords {
    text: string;
    value: number;
}
interface IProps {
    words: any[];
}

const colors = ["#fc515a", "#9097e5", "#bef8bb", "#535458", "#ecda5e", "#6db6ed", "#139895"];

const HoverComp = styled.div<{ $xpos: number; $ypos: number; color: string }>`
    position: absolute;
    top: ${(props) => `${props.$ypos - 100}px`};
    left: ${(props) => `${props.$xpos + 30}px`};
    /* top: 0; */
    /* left: 0; */
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);

    .text__title {
        font-size: 0;
        padding: 10px 6px 10px;
        border-bottom: 1px solid #ccc;

        .text {
            position: relative;
            display: inline-block;
            padding-left: 10px;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 12px;
            line-height: 1.4;
            vertical-align: middle;

            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 6px;
                height: 6px;
                top: 5px;
                left: 0;
                background: ${(props) => props.color};
            }
        }
    }

    .text__num {
        padding: 10px 6px 10px;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;

        .num {
            font-weight: 700;
        }
    }
`;

const WordCloud = ({ words }: IProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [num, setNum] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const [cnt, setCnt] = useState({
        title: "",
        num: "",
        color: "",
    });
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });

    function getRotationDegree() {
        const rand = Math.random();
        const degree = rand > 0.5 ? 60 : -60;
        return rand * degree;
    }
    const fontScale = scaleLog({
        domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
        range: [15, 20],
    });
    const fontSizeSetter = (datum: any) => fontScale(datum.value);

    const fixedValueGenerator = () => 0.5;

    type SpiralType = "archimedean" | "rectangular";

    const handleMouseMove = (event: any) => {
        setIsHovering(true);
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const componentRect = ref.current?.getBoundingClientRect();
        if (componentRect !== undefined) {
            const mouseXInComponent = mouseX - componentRect.left;
            const mouseYInComponent = mouseY - componentRect.top;
            setPosition({
                x: mouseXInComponent,
                y: mouseYInComponent,
            });
        }
    };
    const handleMouseOver = (event: React.MouseEvent<SVGTSpanElement>) => {
        const {
            currentTarget: {
                dataset: { text },
            },
        } = event;
        const {
            currentTarget: {
                dataset: { color },
            },
        } = event;

        if (text !== undefined && color !== undefined) {
            setCnt({
                title: text?.split(",")[0],
                num: text?.split(",")[1],
                color: color,
            });
        }
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    useEffect(() => {
        if (ref.current !== null) {
            setNum(ref.current.offsetWidth);
        }
    }, [ref]);

    return (
        <div className="c__wordcloud" ref={ref}>
            <Wordcloud
                width={num}
                height={600}
                words={words}
                fontSize={fontSizeSetter}
                font={"Impact"}
                padding={2}
                spiral={"rectangular"}
                rotate={0}
                random={fixedValueGenerator}
            >
                {(cloudWords) =>
                    cloudWords.map((w, i) => (
                        <svg x="0" y="0" style={{ overflow: "visible", position: "relative" }} key={w.text}>
                            <text
                                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                fill={colors[i % colors.length]}
                                fontSize={w.size}
                                fontFamily="Impact"
                                textAnchor="middle"
                            >
                                <tspan
                                    x="0"
                                    dy="0em"
                                    data-text={w.text}
                                    data-color={colors[i % colors.length]}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={() => handleMouseOut()}
                                    onMouseMove={handleMouseMove}
                                >
                                    {w.text?.split(",")[0]}
                                </tspan>
                            </text>
                        </svg>
                    ))
                }
            </Wordcloud>

            {isHovering && (
                // <div>333</div>
                <HoverComp $xpos={position.x} $ypos={position.y} color={cnt.color}>
                    <p className="text__title">
                        <span className="text">{cnt.title}</span>
                    </p>

                    <p className="text__num">
                        특허수 : <span className="num">{commaNumber(Number(cnt.num))}</span>
                    </p>
                </HoverComp>
            )}
        </div>
    );
};

export default WordCloud;

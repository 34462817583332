import { getPatentPiaTemplateList } from "@api/patentPia";
import { IDashData, IDashProps, IDashboadTemplateList } from "types/patentPiaTypes";
import { patentPiaCateId, patentPiaCateOtherAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

export interface ICate {
    T_CATE_ID: string;
    P_CATE_ID: string;
    CATE_ID: string;
    CATE: string;
    CATE_TYPE: string;
    CATE_STATE: string;
    GRADE: string;
    GRADE_SCORE: number;
    ORD_LEVEL: number;
    SUB_CATE_CNT: number;
    PAT_CNT_TOT: number;
    SCORE: number;
    ORDER: number;
    DISPLAY_ORDER: number;
}

const usePatentPia = ({ groupCode, itemId, itemType, dataUrl, index, title, chartTytpe }: IDashProps) => {
    // 분야별 카테고리에서 선택된 다음 값들을 jotai로 가져온다.
    const id = useAtomValue(patentPiaCateId);
    const patentPiaCate = useAtomValue(patentPiaCateOtherAtom);

    // https://jotai.org/docs/utilities/storage 이걸로 궁리를 해보자!!
    // id가 "" 이면
    // data의 맨 처음 id와 name이를 가져온다.
    //setPatentPiaCate 1depth에 id와 name을 넣어 준다.

    // 분야별 카테고리 팝업
    // 임시로 patentPiaCate와 똑 닮은 곳에 저장
    // 이
    // 적용 클릭 patentPiaCate 에 넣고 임시 patentPiaCate 지운다.
    // patentPiaCate의 맨 마지막 id값을

    const [dashTemp, setDashTemp] = useState<IDashboadTemplateList[]>();
    const [didMount, setDidMount] = useState(false);

    const {
        isLoading: dashTempLoading,
        isFetching: dashFetching,
        refetch,
        data: dashData,
    } = useQuery(
        ["dashTemplate", patentPiaCate.cate, id, groupCode],
        async () => getPatentPiaTemplateList(patentPiaCate.cate, id, groupCode ? groupCode : ""),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            enabled: false,
            onSuccess(data) {},
            onError: (error: any) => {
                console.log(error);
            },
        },
    );

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && id && groupCode && patentPiaCate.cate) {
            refetch();
        }
    }, [didMount, id, groupCode, patentPiaCate.cate]);

    useEffect(() => {
        if (!dashTempLoading && !dashFetching) {
            if (dashData !== undefined) {
                const temp: IDashData = JSON.parse(dashData.RESULT);

                if (temp.DASHBOARD_TEMPLATE_LIST.length > 0) {
                    setDashTemp(temp.DASHBOARD_TEMPLATE_LIST);
                }
            }
        }
    }, [dashTempLoading, dashFetching]);

    return {
        dashTemp,
        dashTempLoading,
        dashFetching,
    };
};

export default usePatentPia;

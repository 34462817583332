import { keyWordStorageAtom, newsPageAtom, searchNewsAtom } from "@atoms";
import PageNation from "@components/PageNation";
import { useAtom, useAtomValue } from "jotai";
import styled from "styled-components";

const ImageWrap = styled.div<{ $img: string }>`
    width: 120px;
    height: 110px;
    border-radius: 5px;
    background-color: #fbfbfb;
    background-image: url(${(props) => props.$img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    flex-shrink: 0;
`;

const getHours = (time: string) => {
    const currentTime = new Date();
    const targetTime = new Date(time);
    const timeDifference = currentTime.getTime() - targetTime.getTime();
    const hoursAgo = timeDifference / (1000 * 60 * 60);

    return `${Math.round(hoursAgo)}시간전`;
};

const getHighLight = (text: string, keyword: string) => {
    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
        part.toLowerCase() === keyword.toLowerCase() ? <b key={index}>{part}</b> : part,
    );
};

const News = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const data = useAtomValue(searchNewsAtom);
    const [newsPage, setNewsPage] = useAtom(newsPageAtom);

    const handlePage = (page: number) => {
        setNewsPage((prev) => ({ ...prev, page: page }));
    };

    return (
        <div className="content-wrap News">
            <div className="new-wrap">
                {/*데이터가 없을때*/}
                {keyWordStorage === "" ||
                    (data.content.length === 0 && (
                        <div className="wrap-empty">
                            <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                        </div>
                    ))}

                {/*데이터가 있을때*/}
                {data && data.content.length > 0 && (
                    <>
                        <ul className="news">
                            {data.content.map((item: any) => (
                                <li className="news-item" key={item.id} onClick={() => window.open(item.url)}>
                                    <div className="col">
                                        <span className="title">{item.title}</span>
                                        <span className="text">{getHighLight(item.content, keyWordStorage)}</span>
                                        <span className="time">{getHours(item.create_date)}</span>
                                    </div>
                                    <ImageWrap $img={item.image_url} />
                                </li>
                            ))}
                        </ul>
                        {data.totalPages > 0 && (
                            <PageNation
                                totalPage={data.totalPages}
                                currentPage={newsPage.page}
                                clickPage={handlePage}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default News;

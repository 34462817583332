import { dashSelectAtom } from "@atoms";
import { useAtom } from "jotai";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as Arrow } from "@assets/icon/arrow-down.svg";
import { useState } from "react";
import { IDashSeletion } from "types/etcTypes";
import { maxPopVariants } from "@utils/index";

const dashSeletion: IDashSeletion[] = [
    {
        id: "63637", // 광개토 제공(국가 R&D, 거래건수)
        name: "반도체", // 윕스 출원건수 할 때 필요
        name2: "반도체", // ui용
        originName: "반도체", // 광개토 분쟁정보 할 때 필요
    },
    {
        id: "58016",
        name: "디스플레이",
        name2: "디스플레이",
        originName: "디스플레이",
    },
    {
        id: "1270932",
        name: "이차전지",
        name2: "이차전지",
        originName: "2차 전지",
    },
    {
        id: "2394",
        name: "바이오",
        name2: "생명공학",
        originName: "생명공학 기술",
    },
    {
        id: "9336",
        name: "자동차",
        name2: "자동차",
        originName: "자동차",
    },
    {
        id: "44138",
        name: "로봇",
        name2: "로봇",
        originName: "로봇공학",
    },
    {
        id: "5102041",
        name: "항공우주",
        name2: "항공우주",
        originName: "우주 항공기",
    },
    {
        id: "1",
        name: "인공지능(AI)",
        name2: "인공지능(AI)",
        originName: "인공지능",
    },
    {
        id: "43761",
        name: "에너지저장장치(ESS)",
        name2: "에너지저장장치(ESS)",
        originName: "배터리의 ESS (Energy Storage System)",
    },
    {
        id: "847",
        name: "AR/VR/MR",
        name2: "AR/VR/MR",
        originName: "가상 현실",
    },

    // {
    //     id: "83629",
    //     name: "조선",
    //     originName: "선박",
    // },
    // {
    //     id: "87655",
    //     name: "철강",
    //     originName: "철 또는 강철의 제조",
    // },
    // {
    //     id: "89563",
    //     name: "가전전자",
    //     originName: "전기 장비",
    // },
    // {
    //     id: "92408",
    //     name: "기계",
    //     originName: "기계, 기계 요소",
    // },
    // {
    //     id: "43117",
    //     name: "에너지",
    //     originName: "에너지",
    // },
    // {
    //     id: "77795",
    //     name: "화학",
    //     originName: "화학 공학",
    // },
    // {
    //     id: "76208",
    //     name: "섬유",
    //     originName: "섬유, 직물",
    // },
];

const Intro = () => {
    const [dashSelect, setDashSelect] = useAtom(dashSelectAtom);

    const [isSelect, setIsSelect] = useState(false);

    const handleSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {
            currentTarget: {
                dataset: { id },
            },
        } = event;
        const {
            currentTarget: {
                dataset: { name },
            },
        } = event;

        const {
            currentTarget: {
                dataset: { orign },
            },
        } = event;
        const {
            currentTarget: {
                dataset: { name2 },
            },
        } = event;

        if (name !== undefined && id !== undefined && orign !== undefined && name2 !== undefined) {
            setDashSelect({ id: id, name: name, originName: orign, name2: name2 });
        }

        setIsSelect((prev) => !prev);
    };

    const handleClick = () => {
        setIsSelect((prev) => !prev);
    };

    return (
        <div className="c__intro">
            <div>
                <p className="p__intro">
                    <span>발명가님</span> 환영합니다.{" "}
                </p>
                <p className="p__intro2">글로벌 기술동향과 산업트랜드를 확인하세요.</p>
            </div>
            <div className="c__select">
                <span className="bg"></span>
                <button className="btn__select" onClick={handleClick}>
                    {dashSelect.name2}
                    <div className="c__arrow">
                        <Arrow />
                    </div>
                </button>
                <AnimatePresence>
                    {isSelect && (
                        <motion.ul
                            className="item__select"
                            variants={maxPopVariants}
                            initial="initial"
                            animate="visible"
                            exit="leaving"
                        >
                            {dashSeletion.map((item, index) => (
                                <li key={item.id}>
                                    <button
                                        data-name={item.name}
                                        data-name2={item.name2}
                                        data-orign={item.originName}
                                        data-id={item.id}
                                        onClick={handleSelect}
                                    >
                                        {item.name2}
                                    </button>
                                </li>
                            ))}
                        </motion.ul>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Intro;

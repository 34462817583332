import { getTechIndusty, getTechScience, patchTrader } from "@api/mainApi";
import { IPatchTrader } from "~types/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

type TraderLabel = {
    science: { [key: string]: (number | string)[] | number | string };
    industry: { [key: string]: (number | string)[] | number | string };
};

const useMain = () => {
    const [didMount, setDidMount] = useState(false);
    const [traderLabel, setTraderLabel] = useState<TraderLabel>({
        science: {},
        industry: {},
    });

    const { data: scienceData, isLoading: scienceLoading } = useQuery(["science"], () => getTechScience(), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        onSuccess(data) {
            setTraderLabel((prev) => ({
                ...prev,
                science: data.data,
            }));
        },
    });

    const { data: techIndstryData, isLoading: techIndstryLoading } = useQuery(["industry"], () => getTechIndusty(), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        onSuccess(data) {
            setTraderLabel((prev) => ({
                ...prev,
                industry: data.data,
            }));
        },
    });

    // 기술 중개사

    interface IData {
        all: string;
        name: string;
        affiliateName: string;
        techTraderNum: string;
        statusCds: string[];
    }
    const dataTrader = {
        all: "",
        name: "",
        affiliateName: "",
        techTraderNum: "",
        statusCds: ["MSC0102"],
    };
    const {
        mutate: traderMutate,
        data: traderData,
        isLoading: traderLoading,
    } = useMutation((data: IData) => patchTrader(data, 1, 9), {
        onSuccess(data) {},
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && !scienceLoading && !techIndstryLoading) {
            traderMutate(dataTrader);
        }
    }, [didMount, scienceLoading, techIndstryLoading]);

    return {
        traderLabel,
        traderData,
        traderLoading,
    };
};

export default useMain;

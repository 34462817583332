import { useSearchNaviInformation } from "@services/index";
import { commaNumber } from "@utils/index";
import { Link, useLocation } from "react-router-dom";

const SearchNavi = () => {
    const { pathname } = useLocation();
    const { searchInformationData } = useSearchNaviInformation();

    return (
        <div className="c__header">
            <div className="Tab">
                {searchInformationData.map(({ path, label, count }) => (
                    <Link key={path} to={path} className={`Tab-item ${pathname.includes(path) && "active"}`}>
                        {label}
                        {count ? `(${commaNumber(count)})` : `(0)`}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SearchNavi;

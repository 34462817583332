import { useEffect, useState } from "react";

const useCounting = (num: number, isTrue: boolean, stepNum: number) => {
    const FIXNUM = 0;
    const [count, setCount] = useState(FIXNUM.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    const handleNum = (num: number) => {
        let max = num;
        let now = max;
        const handle = setInterval(() => {
            setCount(
                Math.ceil(max - now)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            );

            if (now < 1) {
                clearInterval(handle);
            }

            const step = now / stepNum;

            now -= step;
        }, 30);
    };

    useEffect(() => {
        if (isTrue) {
            handleNum(num);
        }
    }, [isTrue, num]);

    return {
        count,
    };
};

export default useCounting;

import { patchRelatedImg } from "@api/searchCompanyApi";
import { RelatedImgProps } from "~types/types";
import { keyWordStorageAtom } from "@atoms";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

const useRelatedImg = () => {
    const [imgProps, setImgProps] = useState({
        data: {
            searchKeyword: "",
        },
        page: 1,
        size: 15,
    });

    interface IImgList {
        id: string;
        name: string;
        img: string;
    }
    const [imgList, setImgList] = useState<IImgList[]>();
    const [total, setTotal] = useState(0);

    const [didMount, setDidMount] = useState(false);

    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    const {
        mutate: imgMutate,
        isLoading: imgLoading,
        data: imgData,
    } = useMutation((imgProps: RelatedImgProps) => patchRelatedImg(imgProps), {
        onSuccess: (datum) => {
            if (datum.data !== null) {
                if (datum.data.totalPages >= imgProps.page) {
                    const temp = datum.data.content
                        .map(
                            (item) =>
                                item.product_list !== null && {
                                    id: item.id,
                                    name: item.product_list[0].product_name,
                                    img: item.product_list[0].file_path,
                                },
                        )
                        .filter((elem: IImgList | boolean): elem is IImgList => elem !== false);

                    const update = imgList ? [...imgList, ...temp] : [...temp];

                    setImgList(update);
                }

                setTotal(datum.data.totalElements);
            }
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (keyWordStorage !== "") {
            setImgList([]);

            setImgProps((prev) => ({
                ...prev,
                data: {
                    searchKeyword: keyWordStorage,
                },
                page: 1,
            }));
        }
    }, [keyWordStorage]);

    useEffect(() => {
        if (keyWordStorage !== "" && didMount) {
            imgMutate(imgProps);
        }
    }, [imgProps.data.searchKeyword]);

    const handleMoreImg = () => {
        if (imgData?.data.totalPages === imgProps.page) {
            alert("더이상 관련 이미지가 없습니다.");
        } else {
            setImgProps((prev) => ({
                ...prev,
                page: prev.page + 1,
            }));
        }
    };

    useEffect(() => {
        if (didMount) {
            imgMutate(imgProps);
        }
    }, [imgProps.page]);

    return {
        imgList,
        handleMoreImg,
        total,
    };
};

export default useRelatedImg;

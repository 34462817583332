import { useEffect, useRef, useState } from "react";

const useDraggableScrollX = (width: number) => {
    const [isDragging, setIsDragging] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const startXRef = useRef<number>(0);
    const scrollLeftRef = useRef<number>(0);

    const handleMouseMove = (event: { clientX: number }) => {
        if (isDragging && containerRef.current && contentRef.current) {
            const deltaX = event.clientX - startXRef.current;
            containerRef.current.scrollLeft = scrollLeftRef.current - deltaX;
        }
    };

    const handleMouseUp = () => {
        if (contentRef.current) {
            contentRef.current.style.transition = "0.3s ease-out";
        }

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleMouseDown = (event: { clientX: number }) => {
        if (containerRef.current && contentRef.current) {
            setIsDragging(true);
            startXRef.current = event.clientX;
            scrollLeftRef.current = containerRef.current.scrollLeft;

            containerRef.current.style.cursor = "grabbing";
            containerRef.current.style.userSelect = "none";

            contentRef.current.style.transition = "none";

            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        }
    };

    useEffect(() => {
        if (containerRef.current !== null && width !== undefined) {
            containerRef.current.scrollLeft = width;
        }
    }, [containerRef.current, width]);

    return {
        containerRef,
        contentRef,
        handleMouseDown,
    };
};

export default useDraggableScrollX;

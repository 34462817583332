import Comment from "@pages/Build/Comment";
import { CardComp, WrapTableList } from "@pages/Build/style";

import { competeIsViewAtom, reSizeAtom } from "@atoms";
import CheckBoxComp from "@components/CheckBoxComp";
import { ApplicantStatus } from "@components/Graph";
import Radar from "@components/Graph/Radar";
import ScatterChart from "@components/Graph/ScatterChart";
import { CloudDesc } from "@styles/Common/style";
import { commaNumber, popVariants } from "@utils/index";
import { AnimatePresence, motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { IRadarProps } from "types/etcTypes";
import { Content } from "../style";

interface IData {
    id: string;
    companyName: string;
    companySize: string;
    conpanyState: string;
    companyEm: string;
    companyPresent: string;
    companyLocate: string;
}
interface IProps {
    data: IData[];
    loading: boolean;
    checkItems: string[];
    handleSingle: (checked: boolean, id: string) => void;
    handleAll: (checked: boolean) => void;
    showCompetitor: () => void;
    competPatent: any;
    skillDataSet: any;
    aiPoints?: IRadarProps;
    handleRemove: () => void;
    handleRemoveAll: () => void;
}

const Competitor = ({
    data,
    checkItems,
    handleSingle,
    handleAll,
    showCompetitor,
    competPatent,
    skillDataSet,
    aiPoints,
    handleRemove,
    handleRemoveAll,
}: IProps) => {
    const reSize = useAtomValue(reSizeAtom);
    const IsView = useAtomValue(competeIsViewAtom);

    return (
        <>
            <WrapTableList>
                <div className="c__table">
                    <table>
                        {reSize > 768 ? (
                            <colgroup>
                                <col width="44px" />
                                <col width="15.8%" />
                                <col width="12.85%" />
                                <col width="12.85%" />
                                <col width="12.85%" />
                                <col width="32.8%" />
                                <col width="12.85%" />
                            </colgroup>
                        ) : (
                            <colgroup>
                                <col width="44px" />
                                <col width="40%" />
                                <col width="30%" />
                                <col width="" />
                            </colgroup>
                        )}

                        <thead>
                            <tr>
                                <th>
                                    <CheckBoxComp
                                        className={"check_filter"}
                                        id={"id"}
                                        name={"competitors"}
                                        func={(event) => handleAll(event.target.checked)}
                                        label={"all"}
                                        checked={
                                            checkItems.length !== 0 && checkItems.length === data.length ? true : false
                                        }
                                    />
                                </th>
                                <th>기업명</th>
                                <th>기업규모 </th>
                                <th>기업형태</th>
                                <th className="not_mobile">종업원수</th>
                                <th className="not_mobile">대표업종</th>
                                <th className="not_mobile">기업소재지</th>
                            </tr>
                        </thead>

                        {data.length > 0 && (
                            <tbody>
                                {data.map((datum: any) => (
                                    <tr key={datum.id}>
                                        <td>
                                            <CheckBoxComp
                                                className={"check_filter"}
                                                id={datum.id}
                                                name={"competitors"}
                                                func={(event) => handleSingle(event.target.checked, datum.id)}
                                                label={datum.companyName}
                                                checked={checkItems.includes(datum?.id) ? true : false}
                                            />
                                        </td>
                                        <td>{datum.companyName}</td>
                                        <td>{datum.companySize}</td>
                                        <td>{datum.conpanyState}</td>
                                        <td className="not_mobile">{commaNumber(Number(datum.companyEm))}명</td>
                                        <td className="not_mobile">{datum.companyPresent}</td>
                                        <td className="not_mobile">{datum.companyLocate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                    {data.length === 0 && <p className="text_empty">비교를 원하는 회사를 추가하세요.</p>}
                </div>
                <div className="c__btn">
                    <button onClick={handleRemove} disabled={checkItems.length === 0}>
                        선택 제거 {`(${checkItems.length})`}
                    </button>
                    <button onClick={handleRemoveAll} disabled={data.length === 0}>
                        전체 삭제
                    </button>
                    <button onClick={showCompetitor} disabled={checkItems.length === 0}>
                        결과보기
                    </button>
                </div>
            </WrapTableList>

            {competPatent !== undefined && skillDataSet !== undefined && aiPoints !== undefined && (
                <Content>
                    <AnimatePresence>
                        {IsView && (
                            <motion.div
                                className="l__items"
                                variants={popVariants}
                                initial="initial"
                                animate="visible"
                                exit="leaving"
                            >
                                <CardComp height={500}>
                                    <div className="p__titles">
                                        <strong>경쟁사별 특허 보유건수</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">
                                                기업을 선택하여 각 기업이 보유한 출원건수를 비교 분석 할 수 있습니다.
                                                자세히보기를 클릭하시면 기업 명칭, IPC특허분류, 기업유형 등의 필터를
                                                사용하여 원하는 조건의 빌드기업간 출원건수를 비교할 수 있습니다.
                                            </CloudDesc>
                                        </div>
                                    </div>

                                    {competPatent !== undefined && (
                                        <div>
                                            <ApplicantStatus
                                                data={competPatent.data}
                                                labels={competPatent.label}
                                                xaxisTitle={"출원건수(건)"}
                                                yaxisTitle={"기업명"}
                                                height={400}
                                            />
                                        </div>
                                    )}
                                </CardComp>

                                <CardComp height={500}>
                                    <div className="p__titles">
                                        <strong>경쟁사별 기술경쟁력 분석</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">
                                                인용도지수(CPP), 시장확보력(PFS)를 기반으로 선택 기업별 기술경쟁력을
                                                확인할 수 있습니다. 자세한 내용은 자세히 보기 버튼을 클릭하여 기술경쟁력
                                                매트릭스 분석(CPP-PFS) 차트를 확인해 주세요.
                                            </CloudDesc>
                                        </div>
                                    </div>
                                    {skillDataSet !== undefined && (
                                        <ScatterChart
                                            data={skillDataSet}
                                            label={{ x: "시장확보지수/PFS", y: "인용도지수/CPP" }}
                                            position={"right"}
                                            height={400}
                                        />
                                    )}
                                </CardComp>

                                <CardComp height={500}>
                                    <div className="p__titles">
                                        <strong>경쟁사별 특허 활동지수(AI)</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">
                                                출원인의 IPC특허분류(Subclass)별 특허활동지수(AI) 방사형 분포를 확인할
                                                수 있습니다. 특허활동지수(AI)가 1보다 크면 해당 분야에서의 집중도가 높은
                                                것을 의미하며, 특허활동지수(AI)가 1보다 작으면 해당 분야에서의 집중도가
                                                낮은 것을 의미합니다. 자세한 내용은 자세히보기 버튼을 클릭해주세요.
                                            </CloudDesc>
                                        </div>
                                    </div>
                                    {aiPoints !== undefined && (
                                        <div className="c__radar">
                                            <Radar data={aiPoints} />
                                            {/* <Radar data={radarData} /> */}
                                        </div>
                                    )}
                                </CardComp>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Content>
            )}

            <Comment>
                본 화면에서는 우측상단 검색박스를 통해 국내 100대 기업내에서 경쟁사를 추가하여 특허기술경쟁력(PFS, CPP,
                AI 등)을 비교 할 수 있습니다.
                <br />
                (Ex. 삼성디스플레이, 엘지디스플레이 경쟁사비교) 우측 상단 경쟁사비교 자세히보기를 클릭하시면 더 많은
                기업의 특허기술경쟁력을 필터 기능을 통해 비교분석 할 수 있습니다.
            </Comment>
        </>
    );
};

export default Competitor;

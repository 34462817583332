import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import { Wrap } from "@pages/Patentpia/Common/style";
import { CardComp, CardSlideComp, CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { useEffect, useState } from "react";
import GraphComp from "../Common/GraphComp";
import { handleDataUrl } from "../Common/utils";

const groupCode = "05_00";

interface IUrs {
    title: string;
    url: string;
    type: string;
    sourceUrl: string;
}

const HEIGHT = 600;

const CompanyRe = () => {
    const { dashTemp, dashTempLoading } = usePatentPia({ groupCode });
    const [urls, setUrls] = useState<IUrs[]>([]);

    useEffect(() => {
        if (dashTemp !== undefined) {
            const dataUrl = handleDataUrl(dashTemp);
            setUrls(dataUrl);
        }
    }, [dashTemp]);

    const strings = [
        "해당 기술분야별 핵심기업별 년도별 특허 공개 동향",
        "해당 기술분야별 핵심기업별 특허 공개수",
        "해당 기술분야별 핵심기업별 우리나라 심사관이 심사할때 인용한 년도별 특허수",
        "해당 기술분야별 핵심기업별 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 보유 특허수 vs. 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 보유 특허수 vs. 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 우리나라 심사관이 심사할때 인용한 년도별 특허의 점유율 동향",
        "해당 기술분야별 핵심기업별 우리나라 심사관이 심사할때 인용한 특허의 점유율",
        "해당 기술분야별 핵심기업별 년도별 특허 매입 동향",
        "해당 기술분야별 핵심기업별 특허 매입수",
        "해당 기술분야별 핵심기업별 특허 매입수",
        "해당 기술분야별 전문기업별 년도별 특허 공개 동향",
        "해당 기술분야별 전문기업별 특허 공개수",
        "해당 기술분야별 전문기업별 특허 공개수",
        "해당 기술분야별 전문기업별 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 특허당 우리나라 심사관이 심사할때 인용한 년도별 특허수",
        "해당 기술분야별 핵심기업별 특허당 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 특허당 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 전문기업별 특허당 우리나라 심사관이 심사할때 인용한 년도별 특허수",
        "해당 기술분야별 전문기업별 특허당 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 전문기업별 특허당 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 전문기업별 특허수 vs. 우리나라 심사관이 심사할때 인용한 특허",
        "해당 기술분야별 핵심기업별 특허 매입수",
        "해당 기술분야별 전문기업별 우리나라 심사관이 심사할때 인용한 년도별 특허수",
        "해당 기술분야별 전문기업별 보유 특허수 vs. 우리나라 심사관이 심사할때 인용한 특허수",
        "해당 기술분야별 핵심기업별 특허수 vs. 우리나라 심사관이 심사할때 인용한 특허수",
    ];

    return (
        <Wrap>
            {urls.length === 0 && dashTempLoading ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                urls !== undefined &&
                urls.length > 0 && (
                    <div className="l__cont two">
                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[0].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[0]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[0].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner">
                                <GraphComp url={urls[0].url} type={urls[0].type} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[1].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[1]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[1].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(1, 3).map((item, index) => (
                                        <SwiperSlide key={`1${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`1${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[3].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[2]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[3].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner">
                                <GraphComp url={urls[3].url} type={urls[3].type} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[4].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[3]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[4].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(4, 6).map((item, index) => (
                                        <SwiperSlide key={`4${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`4${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(6, 9).map((item, index) => (
                            <CardComp height={HEIGHT} key={`6${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[4 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`6${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[9].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[7]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[9].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(9, 11).map((item, index) => (
                                        <SwiperSlide key={`9${index}`}>
                                            <div className={`inner ${item.type === "PIE" ? "pie" : ""}`}>
                                                <GraphComp
                                                    url={item.url}
                                                    type={item.type}
                                                    isLegend={item.type === "PIE" ? true : false}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(11, 14).map((item, index) => (
                            <CardComp height={HEIGHT} key={`11${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[8 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`11${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[14].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[22]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[14].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(14, 16).map((item, index) => (
                                        <SwiperSlide key={`14${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`14${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(16, 19).map((item, index) => (
                            <CardComp height={HEIGHT} key={`16${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[11 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`16${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[19].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[23]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[19].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(19, 21).map((item, index) => (
                                        <SwiperSlide key={`19${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`19${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[21].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[14]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[21].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner">
                                <GraphComp url={urls[21].url} type={urls[21].type} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[22].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[24]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[22].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(22, 24).map((item, index) => (
                                        <SwiperSlide key={`22${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`22${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(24, 27).map((item, index) => (
                            <CardComp height={HEIGHT} key={`24${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[15 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`24${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[27].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[25]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[27].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(27, 29).map((item, index) => (
                                        <SwiperSlide key={`27${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`27${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(29, 32).map((item, index) => (
                            <CardComp height={HEIGHT} key={`29${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[18 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`29${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[32].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[21]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[32].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(32, 34).map((item, index) => (
                                        <SwiperSlide key={`32${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`32${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(34, 36).map((item, index) => (
                            <CardComp height={HEIGHT} key={`34${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title} map</strong>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`34${index}`} />
                                </div>
                            </CardComp>
                        ))}
                    </div>
                )
            )}
        </Wrap>
    );
};

export default CompanyRe;

import { useCallback, useRef } from "react";

export default function useInput(initialValue: string, setValue: (value: string) => void) {
    const valueRef = useRef<string>(initialValue);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, mode = "") => {
            const newValue = event.target.value;
            const numberOnlyValue = newValue.replace(/[^0-9]/g, "");

            valueRef.current = newValue;
            setValue(mode === "numberOnly" ? numberOnlyValue : newValue);
        },
        [setValue],
    );

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, maxLength: number) => {
        let pasteData = event.clipboardData.getData("Text").replace(/[^0-9]/g, "");
        event.preventDefault();

        if (pasteData.length > maxLength) {
            pasteData = pasteData.slice(0, maxLength);
        }

        setValue(pasteData);
    };

    return { handleChange, handlePaste };
}

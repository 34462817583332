import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect } from "react";
import { IFlowerChartProps } from "types/etcTypes";

const FlowerChart = ({ data, id, height }: IFlowerChartProps) => {
    am5.addLicense("AM5C400641047");

    useEffect(() => {
        const root = am5.Root.new(id);

        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                startAngle: -84,
                endAngle: 264,
                innerRadius: am5.percent(4),
            }),
        );

        const cursor = chart.set(
            "cursor",
            am5radar.RadarCursor.new(root, {
                behavior: "zoomX",
            }),
        );
        cursor.lineY.set("forceHidden", true);

        const xRenderer = am5radar.AxisRendererCircular.new(root, {
            minGridDistance: 30,
        });

        xRenderer.grid.template.set("forceHidden", true);

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "category",
                renderer: xRenderer,
            }),
        );

        const yRenderer = am5radar.AxisRendererRadial.new(root, {});
        xRenderer.labels.template.setAll({
            textType: "adjusted",
            radius: 0,
            paddingTop: 0,
            paddingBottom: 0,
            centerY: am5.p50,
            fontSize: "12px",
        });
        yRenderer.labels.template.set("centerX", am5.p50);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                min: 0,
                renderer: yRenderer,
            }),
        );

        const series = chart.series.push(
            am5radar.RadarColumnSeries.new(root, {
                name: "Series 1",
                sequencedInterpolation: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "category",
            }),
        );

        series.columns.template.setAll({
            cornerRadius: 1,
            tooltipText: "{categoryX}: {valueY}",
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.85,
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: am5.color(0x000000),
                    centerY: am5.p50,
                    centerX: am5.p50,
                    populateText: true,
                }),
            });
        });

        series.columns.template.adapters.add("fill", function (fill, target) {
            // @ts-ignore
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target) {
            // @ts-ignore
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        xAxis.data.setAll(data);
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [data]);

    return <div id={id} style={{ width: "100%", height: `${height}px` }} />;
};

export default FlowerChart;

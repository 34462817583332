import { Wrap } from "@components/CheckBoxComp/style";
import { ICheckBoxComp } from "types/etcTypes";

const CheckBoxComp = ({ className, id, name, func, label, checked }: ICheckBoxComp) => {
    return (
        <Wrap>
            <input className={className} id={id} type="checkbox" name={name} onChange={func} checked={checked} />
            <label htmlFor={id}>{label}</label>
        </Wrap>
    );
};

export default CheckBoxComp;

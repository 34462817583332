import { patentPiaCateAtom, patentPiaCateId, patentPiaCates, patentPiaCatesTemp } from "@atoms";
import { ICate } from "@hooks/usePatentPia";
import { useFetchFirstDepth } from "@queries/Patentpia/CatePop";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ICategory } from "~types/patentPiaTypes";

export const usePatentpiaAction = () => {
    const [id, setId] = useAtom(patentPiaCateId);
    const [cates, setCates] = useAtom(patentPiaCates);
    const [patentPiaCate] = useAtom(patentPiaCateAtom);
    const [, setCatesTemp] = useAtom(patentPiaCatesTemp);

    const { pathname } = useLocation();

    const [isPop, setIsPop] = useState(false);
    const [cate, setCate] = useState<ICate[]>([]);

    // const { isLoading, data } = useFirstDepth();
    const { data, isLoading } = useFetchFirstDepth(patentPiaCate);

    const handlePop = () => {
        setIsPop((prev) => !prev);
    };

    useEffect(() => {
        if (id === "") {
            if (!isLoading && data) {
                const temp: ICategory = JSON.parse(data.RESULT);
                setCate(temp.LOWER_CATE_INFO);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isLoading]);

    useEffect(() => {
        if (cate.length > 0) {
            setId(cate[0].CATE_ID);
            setCates((prev) => ({
                ...prev,
                depth1: { name: cate[0].CATE, cateId: cate[0].CATE_ID },
            }));
            setCatesTemp((prev) => ({
                ...prev,
                depth1: { name: cate[0].CATE, cateId: cate[0].CATE_ID },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cate]);

    useEffect(() => {
        if (isPop === false) {
            setCatesTemp(cates);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPop]);

    return {
        cates,
        isPop,
        pathname,
        handlePop,
    };
};

import { styled } from "styled-components";

import Search from "@assets/icon/search-icon.svg";
import CommentIcon from "@assets/img/icon-comment.png";
import QuestIcon from "@assets/img/icon-quest.png";
import { device } from "@assets/index";
import { ScrollBar } from "@styles/common";

export const Wrap = styled.div`
    width: 100%;
    height: calc(100vh - 240px - 70px);
    overflow-y: hidden;
    padding: 20px;
    background: #f2f2f7;
    display: flex;
    flex-direction: column;

    @media ${device.tabletL} {
        height: calc(100vh - 315px - 70px);
    }

    @media ${device.tablet} {
        overflow-y: initial;
        width: 100%;
        height: auto;
    }

    .c__content {
        ${ScrollBar}
        flex:1;
        height: 100%;
        margin-top: 16px;
        padding-bottom: 15px;
        padding-right: 4px;

        @media ${device.tablet} {
            overflow-y: initial;
            height: auto;
            padding-right: 0;
        }

        .l__loading_build {
            width: 100%;
            height: calc(100vh - 535px);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .c__content-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        gap: 16px;

        @media ${device.tabletL} {
            flex-direction: column;
            align-items: flex-start;
        }

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #ccc;
            position: absolute;
            bottom: 0;

            @media ${device.tabletL} {
                bottom: inherit;
                top: 36px;
            }
        }
    }

    .c__tooltip {
        margin-right: 6px;

        @media ${device.tabletL} {
            margin-right: auto;
        }

        .tooltip-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
        }

        .tooltip-text {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #333;
        }

        .tooltip-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-top: 2px;
        }

        .tooltip-icon:hover .tooltip-desc {
            display: block;
        }

        .tooltip-desc {
            width: max-content;
            text-align: start;
            left: inherit;
            right: -50%;

            @media ${device.tabletL} {
                left: -80% !important;
            }
            @media screen and (max-width: 500px) {
                left: -114px !important;
            }
        }

        .tooltip-desc:before {
            left: initial;
            right: 10px;

            @media ${device.tabletL} {
                left: 10px !important;
                right: initial !important;
            }

            @media screen and (max-width: 500px) {
                left: 112px !important;
            }
        }
    }

    .c__tab-menu {
        display: flex;
        align-items: center;

        .tab-item {
            position: relative;
            padding: 0 16px 20px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #333;
            text-align: center;
            white-space: nowrap;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: #3798ff;
            }

            &.active {
                color: #3798ff;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #3798ff;
                    left: 0;
                }
            }
        }
    }

    .c__content-state {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 8px;
        gap: 4px;

        @media ${device.tabletL} {
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }

    .btn__solid {
        height: 36px;
        background: #3a3f7c;
        color: #fff;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 12px;
        border-radius: 3px;
        padding: 0 12px;
        white-space: nowrap;
    }

    .c__search-wrap {
        width: 100%;
        max-width: 350px;
        display: flex;
        align-items: center;
        gap: 4px;

        @media ${device.tablet} {
            max-width: 100%;
        }
    }

    .c__search {
        width: 100%;
        max-width: 300px;
        position: relative;

        @media ${device.tablet} {
            max-width: 100%;
        }

        input {
            width: 100%;
            height: 36px;
            border: 1px solid #ced4da;
            border-radius: 3px;
            padding: 0 28px 0 10px;
            box-sizing: border-box;
            font-weight: 300;
            font-size: 14px;
            color: #333;
            font-family: "Noto Sans KR", sans-serif;
        }

        button {
            width: 15px;
            height: 15px;
            position: absolute;
            background-image: url(${Search});
            background-repeat: no-repeat;
            background-position: 50% 50%;
            top: 10px;
            right: 11px;
        }

        .outer-result {
            ${ScrollBar}
            z-index: 5;
            position: absolute;
            width: 100%;
            height: 200px;
            background: #fff;
            top: 36px;
            left: 0;
            border: 1px solid #ced4da;
        }

        .list-results li {
            width: 100%;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 8px 10px 8px;
            cursor: pointer;

            &:hover {
                background: #f2f2f7;
            }
        }
    }
`;

export const WrapTableList = styled.div`
    padding: 16px 16px 16px;
    background-color: #fff;
    box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);

    .c__total {
        padding-bottom: 16px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #333;

        span {
            color: #3798ff;
        }
    }

    .c__table {
        padding-bottom: 16px;

        .mobile {
            display: none !important;
        }
        @media ${device.tablet} {
            .not_mobile {
                display: none !important;
            }
        }

        table,
        .table {
            width: 100%;
            table-layout: fixed;

            th,
            .th {
                padding: 15px 8px 15px;
                background: #f6f8fb;
                text-align: center;
                border: 1px solid #e9ebec;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #333;
            }
            td,
            .td {
                padding: 15px 8px 15px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 18px;
                color: #333;
                text-align: center;
                vertical-align: middle;
                border: 1px solid #e9ebec;
                white-space: pre-wrap;
                word-break: break-all;
            }
        }
    }

    .c__btn {
        text-align: center;

        button {
            display: inline-block;
            width: 130px;
            line-height: 36px;
            border-radius: 2px;
            background-color: #3a3f7c;
            box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #fff;
            cursor: pointer;

            & + button {
                margin-left: 13px;
            }

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }

            @media ${device.tablet} {
                display: block;
                width: 100%;

                & + button {
                    margin-left: 0;
                    margin-top: 13px;
                }
            }
        }
    }

    .text_empty {
        padding: 20px 0 20px;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 16px;
        text-align: center;
    }
`;

export const CardComp = styled.div<{ height: number }>`
    position: relative;
    height: ${(props) => `${props.height}px`};
    background: #fff;
    box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;

    .p__titles {
        display: flex;
        align-items: flex-start;
        font-size: 0;
        position: relative;

        strong {
            display: inline-block;
            vertical-align: middle;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 18px;
            color: #333;
        }

        .wrap_btn {
            margin: 2px 0;
            position: relative;
            display: inline-block;
            vertical-align: middle;

            &:hover .wrap_cloud {
                display: block;
            }
        }

        button {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background-image: url(${QuestIcon});
            background-repeat: no-repeat;
            background-position: 50%;
            top: 1px;
        }
    }
`;

export const CommentWrap = styled.div`
    width: 100%;
    min-height: 98px;
    margin-top: 16px;
    padding: 16px 16px 12px;
    border: 1px solid #ccc8ea;
    border-radius: 3px;
    background: #f0eeff;

    strong {
        display: inline-block;
        padding-left: 25px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6a4ec8;
        background-image: url(${CommentIcon});
        background-repeat: no-repeat;
        background-position: 0 50%;
    }

    p {
        padding-top: 13px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
        color: #666;
    }
`;

import { ReactComponent as Arrow } from "@assets/icon/arrow-down.svg";
import { selectedTextAtom } from "@atoms";
import { useInput, useOutsideClick } from "@hooks/index";
import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";
import { useAtomValue } from "jotai";
import SelectBox from "./SelectBox";

export default function SimilarNumber() {
    const { search_num, type, setSearch_num, setType } = useSimilarPatentSearchData();
    const { ref, toggle, setToggle } = useOutsideClick<HTMLDivElement>();
    const { handleChange, handlePaste } = useInput(search_num, setSearch_num);

    const selectedText = useAtomValue(selectedTextAtom);
    const maxLength = type === "y" ? 13 : 9;

    return (
        <div className="f__group">
            <div className="f__label">특허 번호</div>
            <div className="f__control" ref={ref}>
                <div className="c__select-list">
                    <button className="c__select-button" onClick={() => setToggle(!toggle)}>
                        {selectedText}
                        <div className="c__select-arrow">
                            <Arrow />
                        </div>
                    </button>
                    <SelectBox setSearch_num={setSearch_num} toggle={toggle} setToggle={setToggle} setType={setType} />
                </div>
                <input
                    type="text"
                    className="c__input"
                    maxLength={maxLength}
                    value={search_num}
                    onPaste={(event) => handlePaste(event, maxLength)}
                    onChange={(event) => handleChange(event, "numberOnly")}
                    placeholder="선택하신 조건의 번호를 입력해 주세요."
                />
            </div>
        </div>
    );
}

import { PropsWithChildren } from "react";
import { CardCompProps } from "types/etcTypes";
import { Wrap } from "./style";

const CardComp = ({ children, title, gagets }: PropsWithChildren<CardCompProps>) => {
    return (
        <Wrap height={420}>
            <div className="c__header">
                <strong>{title}</strong>

                {gagets && gagets()}
            </div>
            {children}
        </Wrap>
    );
};

export default CardComp;

import { PrevBtn } from "@assets/svg/InterfacesIcon";
import { usePageNation } from "@services/PageNation";
import { IPageNation } from "~types/PageNation";
import RenderButton from "./RenderButton";

const PageNation = ({ totalPage, currentPage, clickPage }: IPageNation) => {
    const { pagination, handleClickPage } = usePageNation({ totalPage, currentPage, clickPage });

    return (
        <div className="Pagination-wrap">
            <ul className="Pagination">
                {/* 다음 버튼 */}
                <RenderButton
                    page={currentPage - 1}
                    isDisabled={currentPage === 1}
                    className={`b__prev${currentPage === 1 ? " disabled" : ""}`}
                    Component={PrevBtn}
                    handleClickPage={handleClickPage}
                />
                {/* 현재 페이지 */}
                {pagination.pageRange.map((p, p_idx) => (
                    <RenderButton
                        key={p_idx}
                        page={p}
                        className={`btn ${currentPage === p ? "active" : ""}`}
                        handleClickPage={handleClickPage}
                    />
                ))}
                {/* 이전 버튼 */}
                <RenderButton
                    page={currentPage + 1}
                    isDisabled={currentPage === totalPage}
                    className={`b__next${currentPage === totalPage ? " disabled" : ""}`}
                    Component={PrevBtn}
                    handleClickPage={handleClickPage}
                />
            </ul>
        </div>
    );
};

export default PageNation;

import { useCallback, useEffect, useState } from "react";
import { IPageNation } from "~types/PageNation";

export const usePageNation = ({ totalPage, currentPage, clickPage }: IPageNation) => {
    const [pagination, setPagination] = useState({
        pageOffset: 5,
        pageRange: [1],
    });

    const createPageRange = () => {
        const pageRange: number[] = [];
        const { pageOffset } = pagination;
        let startPage = 1,
            endPage = 1;

        if (currentPage > 3) {
            startPage = currentPage - 2;
        }
        endPage = startPage + pageOffset - 1;

        if (endPage > totalPage) {
            startPage = startPage - (endPage - totalPage);
            if (startPage < 1) startPage = 1;
            endPage = totalPage;
        }

        for (let i = startPage; i <= endPage; i++) {
            pageRange.push(i);
        }
        setPagination((state) => ({ ...state, pageRange }));
    };

    const handleClickPage = useCallback((page: number) => clickPage && clickPage(page), [clickPage]);

    useEffect(() => {
        createPageRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPage]);
    return {
        pagination,
        handleClickPage,
    };
};

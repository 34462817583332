import { IAgentProps } from "types/etcTypes";
import { AGENTCOLOR } from "@colors";
import { formatNumberWithCommas } from "@utils/index";
import ApexChart from "react-apexcharts";
import { styled } from "styled-components";

const Wrap = styled.div``;

const createArr = (num: number) => {
    return [...Array(num)];
};

const Agent = ({ data, label, category, height, width }: IAgentProps) => {
    const temp = createArr(data.length);
    const colorsTemp = temp.map((elem, index) => AGENTCOLOR[index]);
    const columnSeriesData = data.filter((el) => el.type === "column");
    const maxValue = Math.max(...columnSeriesData.map((el) => Math.max(...el.data)));
    const formatNumber = (num: number) => formatNumberWithCommas(num);

    return (
        <Wrap>
            <ApexChart
                width={`${width}px`}
                height={height}
                series={data}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        type: "line",
                        stacked: false,
                        offsetY: 10,
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -7,
                        style: {
                            colors: ["#5F5F5F"],
                            fontWeight: 400,
                        },
                        background: {
                            enabled: false,
                        },
                        formatter: (val, opts) => {
                            return opts.seriesIndex === 2 ? `${Number(val).toFixed(1)}%` : "";
                        },
                    },
                    stroke: {
                        width: [1, 1, 4],
                    },
                    markers: {
                        size: 2,
                    },
                    xaxis: {
                        categories: label,
                    },
                    yaxis: [
                        {
                            min: 0,
                            max: maxValue,
                            title: {
                                text: "특허 출원 건수",

                                style: {
                                    color: "#666",
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans KR",
                                    fontWeight: 400,
                                },
                            },
                            tooltip: {
                                enabled: true,
                            },
                            tickAmount: 5,
                            labels: {
                                formatter: (val) => {
                                    const yaxisNum =
                                        category === "ALL" ? Math.ceil(val / 1000) * 1000 : Math.ceil(val / 10) * 10;
                                    return `${formatNumber(yaxisNum)}`;
                                },
                            },
                        },
                        {
                            min: 0,
                            max: maxValue,
                            labels: {
                                show: false,
                                formatter: (val) => {
                                    return `${formatNumber(val)}`;
                                },
                            },
                        },
                        {
                            seriesName: "Revenue",
                            opposite: true,
                            title: {
                                text: "출원건수 대비 등록률",
                                style: {
                                    color: "#666",
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans KR",
                                    fontWeight: 400,
                                },
                            },
                            labels: {
                                formatter: (val) => {
                                    return `${val}%`;
                                },
                            },
                        },
                    ],
                    tooltip: {
                        fixed: {
                            enabled: true,
                            position: "topLeft",
                            offsetY: 30,
                            offsetX: 60,
                        },
                        y: {
                            formatter: (val, opts) => {
                                return opts.seriesIndex === 2 ? `${formatNumber(val)}%` : `${formatNumber(val)}건`;
                            },
                        },
                    },
                    legend: {
                        horizontalAlign: "left",
                        floating: true,
                        position: "top",
                        offsetY: -7,
                    },
                    colors: colorsTemp,
                }}
            />
        </Wrap>
    );
};

export default Agent;

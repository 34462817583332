import { getDispute } from "@api/patentPia";
import { keyWordStorageAtom, lawshitAtom, lawshitTotalPageAtom, searchDisputAtom } from "@atoms";
import LoadingComp from "@components/LoadingComp";
import PageNation from "@components/PageNation";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

const Lawsuit = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const [realData, setRealData] = useAtom(searchDisputAtom);

    const limit = 20;
    const [page, setPage] = useAtom(lawshitAtom);
    const totalPage = useAtomValue(lawshitTotalPageAtom);

    const handlePage = (page: number) => {
        setPage(page);
        getDispute(keyWordStorage, limit, limit * (page - 1)).then((res) => {
            const temp = JSON.parse(res.RESULT);
            setRealData(temp);
        });
    };

    useEffect(() => {
        return setPage(1);
    }, []);

    return (
        <div className="content-wrap Lawsuit">
            <div className="Table-wrap">
                {/*데이터가 없을때*/}
                {
                    keyWordStorage === "" ||
                    (realData?.CHART_DATA_RESULT === null || realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length === 0) &&
                    <div className="wrap-empty">
                        <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                    </div>
                }

                {/*데이터가 있을때*/}
                {
                    !(realData?.CHART_DATA_RESULT === null || realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length === 0) &&
                    <>
                        <div className="Table lawsuit">
                            {Object.keys(realData).length === 0 && (
                                <div className="law-loading-wrap">
                                    <div className="l__colsapn-all loading">
                                        <LoadingComp />
                                    </div>
                                </div>
                            )}

                            {Object.keys(realData).length !== 0 &&
                                realData?.CHART_DATA_RESULT.CONVERTED_HEADER !== null && (
                                    <ul className="tr thead">
                                        <div className="tr">
                                            <li className="td order">No</li>
                                            <li className="td register">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.PATENTNO.HEADER_NAME}
                                            </li>
                                            <li className="td refereeNum">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.TRIALNO.HEADER_NAME}
                                            </li>
                                            <li className="td designation">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.TRIALTITLE.HEADER_NAME}
                                            </li>
                                            <li className="td type">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.TRIAL_TYPE.HEADER_NAME}
                                            </li>
                                            <li className="td claim">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.PETITIONER.HEADER_NAME}
                                            </li>
                                            <li className="td company">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.RESPONDENT.HEADER_NAME}
                                            </li>
                                            <li className="td date">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.TRIALDATE.HEADER_NAME}
                                            </li>
                                            <li className="td year">
                                                {realData?.CHART_DATA_RESULT.CONVERTED_HEADER.TRIAL_YR.HEADER_NAME}
                                            </li>
                                        </div>
                                    </ul>
                                )}

                            {Object.keys(realData).length !== 0 &&
                                realData?.CHART_DATA_RESULT.CONVERTED_RESULT_LIST !== null && (
                                    <div className="Scroll">
                                        {realData?.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map(
                                            (item: any, index: number) => (
                                                <ul className="tr" key={index}>
                                                    <div className="td order">{limit * (page - 1) + index + 1}</div>
                                                    <div className="td register">{item.PATENTNO.VALUE}</div>
                                                    <div className="td refereeNum">{item.TRIALNO.VALUE}</div>
                                                    <div className="td designation">{item.TRIALTITLE.VALUE}</div>
                                                    <div className="td type">{item.TRIAL_TYPE.VALUE}</div>
                                                    <div className="td claim">{item.PETITIONER.VALUE}</div>
                                                    <div className="td company">{item.RESPONDENT.VALUE}</div>
                                                    <div className="td date">{`${item.TRIALDATE.VALUE.substring(
                                                        0,
                                                        4,
                                                    )}-${item.TRIALDATE.VALUE.substring(
                                                        4,
                                                        6,
                                                    )}-${item.TRIALDATE.VALUE.substring(6, 8)}`}</div>
                                                    <div className="td year">{item.TRIAL_YR.VALUE}</div>
                                                </ul>
                                            ),
                                        )}
                                    </div>
                                )}

                            {
                                realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length > 0 &&
                                <div className="wrap_pagenation">
                                    <PageNation totalPage={totalPage} currentPage={page} clickPage={handlePage} />
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Lawsuit;

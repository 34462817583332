import Gauges from "./Gauges";

interface iProps {
    data: any;
}

const COLORS = ["#ff8048", "#ea497b", "#699ceb"];

const GaugeComp = ({ data }: iProps) => {
    return (
        <div className="l__gauges">
            {data.length > 0 &&
                data !== undefined &&
                data.map((item: any, index: number) => (
                    <div className="outer" key={item.id}>
                        <div className="inner">
                            <Gauges color={COLORS[index]} num={item.num} title={item.title} />
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default GaugeComp;

import { AxiosInstance, AxiosSearchInstance } from "@api/index";
import { ApiResponse } from "~types/PatentSearch/SimilarPatentSearch";
import { IBuildCompanySearch, IBuildResult } from "~types/types";

// 기업 호출 (기업 검색)
export const postCompanySearch = async (un_cd: string, rqst_tm: string): Promise<IBuildCompanySearch[]> =>
    AxiosInstance.post(`/company`, {
        sector: "com_srch",
        rqst_tm: rqst_tm,
        un_cd: un_cd,
    })
        .then((res) => res.data.DATA)
        .catch((error) => {
            console.log("error", error);
        });

// 기업 호출 (경쟁사 검색)
export const postCompetitorSearch = async (un_cd: string, rqst_tm: string): Promise<IBuildResult> =>
    AxiosInstance.post(`/company`, {
        sector: "cmptn_com_cmpr",
        rqst_tm: rqst_tm,
        un_cd: un_cd,
    })
        .then((res) => res.data)
        .catch((error) => {
            console.log("error", error);
        });

// 특허
export const patentSearch = async (sector: string, param: string, rqst_tm: string): Promise<IBuildResult> =>
    AxiosInstance.post(`/${param}`, {
        sector: sector,
        rqst_tm: rqst_tm,
    })
        .then((res) => res.data)
        .catch((error) => {
            console.log("error", error);
        });

// 산업
export const postIndustrySearch = async (sector: string, rqst_tm: string): Promise<IBuildResult> =>
    AxiosInstance.post(`/industry`, {
        sector: sector,
        rqst_tm: rqst_tm,
    })
        .then((res) => res.data)
        .catch((error) => {
            console.log("error", error);
        });

// 유사특허검색
export const postSimilarPatentSearch = async (search_num: string, type: string, ntb: string): Promise<ApiResponse> => {
    const { data } = await AxiosSearchInstance.post("/similar", {
        search_num,
        type,
        ntb,
    });

    return Array.isArray(data["DATA"]) ? data : alert("검색 결과가 없습니다.");
};

import { MoreIcon } from "@assets/svg/InterfacesIcon";
import useRelatedImg from "./useRelatedImg";
import { useAtom } from "jotai";
import { keyWordStorageAtom } from "@atoms";
import { commaNumber } from "@utils/index";

const RelatedImg = () => {
    const { imgList, handleMoreImg, total } = useRelatedImg();
    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    return (
        <>
            <p className="sub-header">관련이미지({commaNumber(total)})</p>
            {
                keyWordStorage === "" || imgList?.length === 0 &&
                <div className="wrap-empty">
                    <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                </div>
            }

            {
                imgList && imgList.length > 0 &&
                <>
                    <div className="image">
                        <ul className="image-list">
                            {imgList.map((item) => (
                                <li className="image-item" key={`${item.id}`}>
                                    <img src={item.img} alt={item.name} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button type="button" className="btn__default btn__more" onClick={handleMoreImg}>
                        더보기
                        <MoreIcon />
                    </button>
                </>
            }
        </>
    );
};

export default RelatedImg;

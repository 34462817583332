import { MenuButtonProps } from "~types/Layout";

const MenuButton = ({ reSize, handleNavi, handleMobile }: MenuButtonProps) => {
    const mobileMode = reSize > 1024 ? "" : "mobile";
    const handleOpenCategory = reSize > 1024 ? handleNavi : handleMobile;

    return <button className={`btn__navi ${mobileMode}`} onClick={handleOpenCategory}></button>;
};

export default MenuButton;

import ApexChart from "react-apexcharts";
import styled from "styled-components";

const WrapGauge = styled.div`
    position: relative;
    top: -6%;
    height: auto;
    .apexcharts-text {
        font-size: 40px;
    }

    .p__text {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #666;
    }
`;
const BoxGauge = styled.div<{ num: number; color: string }>`
    z-index: 10;
    position: absolute;
    width: 2px;
    height: 130px;
    margin-left: -1px;
    left: 50%;
    bottom: 0;
    background: transparent;
    transition: all 1s;
    transform: rotate(${(props) => -90 + props.num * 1.8}deg);
    transform-origin: bottom;
    .box-gauge {
        position: absolute;
        width: 2px;
        height: 57px;
        top: 0;
        left: 0;
        background: ${(props) => props.color};
    }
`;

interface IProps {
    color: string;
    num: number;
    title: string;
}

const Gauges = ({ color, num, title }: IProps) => {
    return (
        <WrapGauge>
            <ApexChart
                type="radialBar"
                series={[num]}
                options={{
                    chart: {
                        width: 200,
                        offsetY: 0,
                        sparkline: {
                            enabled: true,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#ddd",
                                strokeWidth: "97%",
                                margin: 0,
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    offsetY: 5,
                                    fontSize: "40px",
                                    fontWeight: 700,
                                    color: "#333",
                                    formatter: function (val) {
                                        const value = `${val}`;
                                        return value;
                                    },
                                },
                            },
                        },
                    },
                    grid: {
                        padding: {
                            top: 0,
                            bottom: 0,
                        },
                    },
                    fill: {
                        colors: [color],
                    },
                }}
            />
            {/* <BoxGauge num={20} color={"#333"}>
                <div className="box-gauge"></div>
            </BoxGauge> */}
            <p className="p__text">{title}</p>
        </WrapGauge>
    );
};

export default Gauges;

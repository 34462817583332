import { useEffect, useRef, useState } from "react";

export default function useOutsideClick<T extends Element>() {
    const ref = useRef<T>(null);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        function onClick(event: MouseEvent) {
            const target = event.target as Node;
            if (ref.current && !ref.current.contains(target)) {
                setToggle(false);
            }
        }

        document.addEventListener("mousedown", onClick);
        return () => document.removeEventListener("mousedown", onClick);
    }, []);

    return { ref, toggle, setToggle };
}

import { keyWordStorageAtom, patentPageAtom, searchPatentListAtom } from "@atoms";
import { useFetchPatentData } from "@queries/ConvergenceSearch/Patent";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

export const usePatent = () => {
    const [dataTotalCount, setDataTotalCount] = useState<number>(0);
    const [didMount, setDidMount] = useState(false);
    const [patentPage, setPatentPage] = useAtom(patentPageAtom);
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const setSearchPatentList = useSetAtom(searchPatentListAtom);
    const { data, isLoading, mutate } = useFetchPatentData(setSearchPatentList);

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (keyWordStorage !== "") {
            setPatentPage((prev) => ({
                ...prev,
                data: {
                    title: keyWordStorage,
                },
                page: 1,
            }));
        }
    }, [keyWordStorage, setPatentPage]);

    useEffect(() => {
        if (didMount && patentPage.data.title !== "") {
            mutate(patentPage);
        }
    }, [didMount, mutate, patentPage]);

    useEffect(() => {
        setDataTotalCount(data?.totalElements ?? 0);
    }, [data?.totalElements]);

    return {
        data,
        isLoading,
        dataTotalCount,
    };
};

import usePatentSearch from "@hooks/usePatentSearch";
import { useEffect, useState } from "react";
import {
    IAgentData,
    IAgentRes,
    IAgentTemp,
    IApplicant,
    IApplicationTrend,
    IApplicationTrendRes,
    IContent,
    IIpc,
    IPie,
    ITemp,
} from "~types/PatentSearch";

export default function useData(sector: string, param: string) {
    const { isLoading, patentData, mutate } = usePatentSearch(sector, param);

    const [pie, setPie] = useState<IPie>({
        data: [],
        label: [],
        category: "",
    });

    const [applicationTrend, setApplicationTrend] = useState<IApplicationTrend>({
        data: [],
        label: [],
        category: "",
    });

    const [applicantStatus, setApplicantStatus] = useState<IApplicationTrend>({
        data: [],
        label: [],
        category: "",
    });
    const [ipc, setIpc] = useState<IApplicationTrend>({
        data: [],
        label: [],
        category: "",
    });
    const [agent, setAgent] = useState<IAgentData>({
        data: [],
        label: [],
        category: "",
    });

    useEffect(() => {
        if (patentData && patentData.RETURN_CNT === 0) {
            mutate();
        }
    }, [patentData]);

    useEffect(() => {
        if (patentData !== undefined && patentData.RETURN_CNT !== 0) {
            if (patentData?.DATA[2].data_set !== undefined) {
                if (sector === "all") {
                    const temp: IPie = {
                        label: patentData?.DATA[2].data_set.map((item: IContent) => item.ctry_nm),
                        data: patentData?.DATA[2].data_set.map((item: IContent) => item.appl_cnt),
                        category: "",
                    };

                    setPie(temp);
                }
                if (sector === "ntb") {
                    const temp: IPie = {
                        label: patentData?.DATA[2].data_set.map((item: IContent) => item.NTB_ctry_nm),
                        data: patentData?.DATA[2].data_set.map((item: IContent) => item.NTB_appl_cnt),
                        category: "",
                    };
                    setPie(temp);
                }
            }
            if (patentData?.DATA[3].data_set !== undefined) {
                let years: number[] = [];

                if (sector === "all") {
                    years = patentData?.DATA[3].data_set.map((item: IApplicationTrendRes) => Number(item.appl_yr));
                }
                if (sector === "ntb") {
                    years = patentData?.DATA[3].data_set.map((item: IApplicationTrendRes) => Number(item.NTB_appl_yr));
                }

                const minYear = Math.min(...years);
                const maxYear = Math.max(...years);

                const tempApplicationTrend: IApplicationTrend = {
                    data: [],
                    label: [],
                    category: "",
                };

                for (let year = minYear; year <= maxYear; year++) {
                    tempApplicationTrend.label.push(year.toString());

                    const yearIndex = years.indexOf(year);

                    if (sector === "all") {
                        if (yearIndex !== -1) {
                            tempApplicationTrend.data.push(
                                Number(patentData?.DATA[3].data_set[yearIndex].pat_appl_cnt),
                            );
                        } else if (yearIndex === -1) {
                            tempApplicationTrend.data.push(0);
                        }
                    }

                    if (sector === "ntb") {
                        if (yearIndex !== -1) {
                            tempApplicationTrend.data.push(
                                Number(patentData?.DATA[3].data_set[yearIndex].NTB_appl_cnt),
                            );
                        } else if (yearIndex === -1) {
                            tempApplicationTrend.data.push(0);
                        }
                    }
                }

                setApplicationTrend(tempApplicationTrend);
            }
            if (patentData?.DATA[4].data_set !== undefined) {
                let temp: ITemp = {
                    data: [],
                    label: [],
                    category: "",
                };

                if (sector === "all") {
                    temp = {
                        data: patentData?.DATA[4].data_set.map((item: IApplicant) => item.pat_appl_cnt),
                        label: patentData?.DATA[4].data_set.map((item: IApplicant) => item.com_nm),
                        category: "",
                    };
                }

                if (sector === "ntb") {
                    temp = {
                        data: patentData?.DATA[4].data_set.map((item: IApplicant) => item.NTB_appl_cnt),
                        label: patentData?.DATA[4].data_set.map((item: IApplicant) => item.NTB_com_nm),
                        category: "",
                    };
                }

                setApplicantStatus(temp);
            }

            if (patentData?.DATA[5].data_set !== undefined) {
                let temp: ITemp = {
                    data: [],
                    label: [],
                    category: "",
                };

                if (sector === "all") {
                    temp = {
                        data: patentData?.DATA[5].data_set.map((item: IIpc) => item.pat_appl_cnt),
                        label: patentData?.DATA[5].data_set.map((item: IIpc) => item.ipc),
                        category: "",
                    };
                }

                if (sector === "ntb") {
                    temp = {
                        data: patentData?.DATA[5].data_set.map((item: IIpc) => item.NTB_appl_cnt),
                        label: patentData?.DATA[5].data_set.map((item: IIpc) => item.NTB_ipc),
                        category: "",
                    };
                }

                setIpc(temp);
            }

            if (patentData?.DATA[6].data_set !== undefined) {
                let tempData: IAgentTemp[] = [
                    {
                        name: "특허 출원 건수",
                        type: "column",
                        data: [],
                    },
                    {
                        name: "특허 등록 건수",
                        type: "column",
                        data: [],
                    },
                    {
                        name: "출원건수 대비 등록률",
                        type: "line",
                        data: [],
                    },
                ];
                let tempLabel: string[] = [];

                if (sector === "all") {
                    tempData = [
                        {
                            name: "특허 출원 건수",
                            type: "column",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.pat_appl_cnt),
                        },
                        {
                            name: "특허 등록 건수",
                            type: "column",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.pat_grt_cnt),
                        },
                        {
                            name: "출원건수 대비 등록률",
                            type: "line",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.pat_grt_ratio),
                        },
                    ];

                    tempLabel = patentData?.DATA[6].data_set.map((item: IAgentRes) => item.agt_ofc_nm);
                }

                if (sector === "ntb") {
                    tempData = [
                        {
                            name: "특허 출원 건수",
                            type: "column",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.NTB_appl_cnt),
                        },
                        {
                            name: "특허 등록 건수",
                            type: "column",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.NTB_grt_cnt),
                        },
                        {
                            name: "출원건수 대비 등록률",
                            type: "line",
                            data: patentData?.DATA[6].data_set.map((item: IAgentRes) => item.NTB_grt_ratio),
                        },
                    ];

                    tempLabel = patentData?.DATA[6].data_set.map((item: IAgentRes) => item.NTB_agt_ofc_nm);
                }
                const temp = {
                    data: tempData,
                    label: tempLabel,
                    category: sector === "all" ? "ALL" : "NTB",
                };

                setAgent(temp);
            }
        }
    }, [isLoading, patentData]);

    return {
        pie,
        applicationTrend,
        applicantStatus,
        ipc,
        agent,
        patentData,
        isLoading,
    };
}

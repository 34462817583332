import { reSizeAtom } from "@atoms";
import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import GraphComp from "@pages/Patentpia/Common/GraphComp";
import { CardComp, CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { Wrap } from "../Common/style";
import { handleDataUrl } from "../Common/utils";
interface IUrs {
    title: string;
    url: string;
    type: string;
    sourceUrl: string;
}

const HEIGHT = 600;

const groupCode = "03_00";

const Patent = () => {
    const resizeValue = useAtomValue(reSizeAtom);
    const { dashTemp, dashTempLoading } = usePatentPia({ groupCode });
    const [urls, setUrls] = useState<IUrs[]>([]);

    useEffect(() => {
        if (dashTemp !== undefined) {
            const dataUrl = handleDataUrl(dashTemp);
            setUrls(dataUrl);
        }
    }, [dashTemp]);

    const strings = [
        "해당 기술분야별 핵심 KPI",
        "해당 기술분야별 강도 KPI",
        "해당 기술분야별 중요 품질 지표",
        "해당 기술분야별 년도별 특허 공개 동향",
        "해당 기술분야별 년도별 특허 등록 동향",
        "해당 기술분야별 글로벌 5개국의 년도별 특허 공개 동향",
        "해당 기술분야별 글로벌 5개국의 년도별 특허 등록 동향",
        "해당 기술분야별 글로벌 5개국의 특허 공개수",
        "해당 기술분야별 글로벌 5개국의 특허 등록수",
        "해당 기술분야별 우리나라 년도별 특허당 평균 분류수(CPC)",
    ];

    return (
        <>
            <Wrap>
                {urls.length === 0 && dashTempLoading ? (
                    <div className="c__loading">
                        <LoadingComp />
                    </div>
                ) : (
                    urls !== undefined &&
                    urls.length > 0 && (
                        <>
                            <div className="l__cont two laptopS">
                                <div className="l__cont two">
                                    {urls.slice(0, 2).map((item, index) => (
                                        <CardComp height={260} key={`0${index}`}>
                                            <GraphComp
                                                url={item.url}
                                                type={item.type}
                                                title={item.title}
                                                index={`0${index}`}
                                                desc={strings[index]}
                                                sourceUrl={item.sourceUrl}
                                            />
                                        </CardComp>
                                    ))}
                                </div>
                                <CardComp height={resizeValue < 768 ? "auto" : 260}>
                                    <GraphComp
                                        url={urls[2].url}
                                        type={urls[2].type}
                                        title={urls[2].title}
                                        desc={strings[2]}
                                        sourceUrl={urls[2].sourceUrl}
                                    />
                                </CardComp>
                            </div>

                            <div className="l__cont two">
                                {urls.slice(3, 10).map((item, index) => (
                                    <CardComp height={HEIGHT} key={`3${index}`}>
                                        <GraphWrapper>
                                            <div className="p__titles">
                                                <strong>{item.title}</strong>
                                                <div className="wrap_btn">
                                                    <button />
                                                    <CloudDesc className="wrap_cloud">{strings[3 + index]}</CloudDesc>
                                                </div>
                                            </div>
                                            <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                                데이터 출처
                                            </SoureceMoveBtn>
                                        </GraphWrapper>
                                        <div className="inner">
                                            <GraphComp url={item.url} type={item.type} index={`3${index}`} />
                                        </div>
                                    </CardComp>
                                ))}
                            </div>
                        </>
                    )
                )}
            </Wrap>
        </>
    );
};

export default Patent;

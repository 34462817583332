export const ScrollBar = `
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar {
        width: 6px;
        padding-right: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bcbec8;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        // background-color: #000;
    }
`;

export const ScrollBarX = `
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bcbec8;
        border-radius: 10px;
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        // background-color: #000;
    }
`;

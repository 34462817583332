import { IPageNationButton } from "~types/PageNation";

const RenderButton = ({ page, className, isDisabled, Component, handleClickPage }: IPageNationButton) => {
    return (
        <li className="Pagination-item">
            <button className={className} onClick={() => handleClickPage(page)} disabled={isDisabled}>
                {Component ? <Component /> : page}
            </button>
        </li>
    );
};

export default RenderButton;

import { styled } from "styled-components";
import { device } from "@assets/index";
import { ScrollBarX } from "@styles/common";

export const SimilarPatentSearchWrap = styled.div`
    font-family: "Noto Sans KR", sans-serif;
    display: flex;
    gap: 15px;

    @media ${device.tabletL} {
        flex-direction: column-reverse;
    }

    .similar-table {
        width: 100%;

        @media ${device.tabletL} {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }

    .similar-table,
    .similar-filter-card {
        overflow: hidden;
    }

    .table-wrap {
        &:not(:first-child) {
            margin-top: 22px;
        }

        @media ${device.tabletL} {
            padding: 16px 16px 16px;
            background-color: #fff;
            box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .c__table {
        ${ScrollBarX}
        padding: 0;

        @media ${device.tabletL} {
            padding-right: 1px;
        }

        .table {
            min-width: 760px;
            margin-top: 16px;
            border: 1px solid #e9ebec;
        }

        .tr {
            display: flex;
            align-items: stretch;
        }

        .th,
        .td {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none !important;

            &:not(:last-child) {
                border-right: 1px solid #e9ebec !important;
            }
        }

        .tbody .tr {
            border-top: 1px solid #e9ebec;
        }

        .td.state-n {
            color: #999;
        }

        .td.state-y {
            color: #3798ff;
        }

        /* 테이블 간격 */

        .num,
        .delete {
            min-width: 60px;
            max-width: 60px;
        }

        .appl-num {
            min-width: 135px;
            max-width: 135px;
        }

        .inv-ti {
            flex: 1;
            min-width: 250px;
        }

        .state {
            min-width: 112px;
            max-width: 112px;
        }

        .appl-com {
            min-width: 200px;
        }

        .search-table .appl-com {
            max-width: 407px;
        }

        .similar-table .appl-com {
            max-width: 355px;
        }
    }

    .similar-title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        letter-spacing: -0.05em;
    }

    .similar-filter-card {
        width: 320px;
        height: fit-content !important;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        @media ${device.tabletL} {
            width: 100%;
        }
    }

    .similar-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        margin: 0 16px;
        border-bottom: 1px solid #e9ebec;
    }

    .similar-filter-body {
        padding: 24px 16px;
    }

    .similar-filter-footer {
        margin-top: auto;
        display: flex;
        align-items: center;

        @media ${device.tabletL} {
            padding: 0 16px 24px;
            gap: 16px;
            flex-direction: row-reverse;
        }

        button {
            height: 40px;
            font-weight: 700;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            @media ${device.tabletL} {
                border-radius: 2px;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .btn-add {
        color: #3a3f7c;
        border-top: 1px solid #3a3f7c;

        @media ${device.tabletL} {
            border: 1px solid #3a3f7c;
        }

        &:not(:disabled):hover {
            color: #2828a3;
        }

        &:disabled {
            color: #b8b8b8;
            border-color: #c7c7c7;
        }
    }

    .btn-search {
        background-color: #3a3f7c;
        color: #fff;
    }
`;

import { patentPiaCates, patentPiaCatesTemp } from "@atoms";
import useCate from "@hooks/useCate";
import { ICate } from "@hooks/usePatentPia";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { IResults } from "~types/Patentpia";
import { ICateGoryParse } from "~types/patentPiaTypes";

export const useThirdDepth = (id: string) => {
    const [cates] = useAtom(patentPiaCates);
    const [, setCatesTemp] = useAtom(patentPiaCatesTemp);

    const { depthData, depthLoading } = useCate(id, 3);
    const [results, setResults] = useState<IResults[] | []>([]);
    const [cate, setCate] = useState<ICate[]>([]);
    const [selectedValue, setSelectedValue] = useState<any>("");

    useEffect(() => {
        if (!depthLoading) {
            if (depthData !== undefined) {
                const temp: ICateGoryParse = JSON.parse(depthData.RESULT);
                setCate(temp.LOWER_CATE_INFO);
            }
        }
    }, [depthData, depthLoading]);

    useEffect(() => {
        if (cate.length > 0) {
            const temp = cate.map((item, index) => ({
                id: index,
                cateId: item.CATE_ID,
                name: item.CATE,
                num1: item.PAT_CNT_TOT,
                num2: item.SUB_CATE_CNT,
                hot: item.GRADE === "S" ? true : false,
                data: item.CATE_ID,
                ordLevel: item.ORD_LEVEL,
            }));

            setResults(temp);
        }

        if (cate.length === 0) {
            setResults([]);
        }
    }, [cate]);

    useEffect(() => {
        if (cates["depth3"].cateId !== "" && results.length > 0) {
            const index = results.findIndex((item) => item.cateId === cates["depth3"].cateId);

            if (index === -1) {
                setSelectedValue("");
            } else {
                setSelectedValue(results[index].cateId);
            }
        }
    }, [results, cates]);

    const handleRadioChange = (id?: number, ordLevel?: number, name?: string, cateId?: string) => {
        if (id !== undefined && ordLevel !== undefined && name !== undefined && cateId !== undefined) {
            setSelectedValue(cateId);
            setCatesTemp((prev) => ({
                ...prev,
                depth3: { name: name, cateId: cateId },
            }));
        }
    };

    return {
        results,
        selectedValue,
        handleRadioChange,
    };
};

import Layout from "@components/Layout";
import { Wrap } from "@pages/Build/style";
import { Outlet } from "react-router-dom";
import "./index.scss";
import SearchNavi from "@components/SearchNavi";

const ConvergenceSearch = () => {
    return (
        <Layout>
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <SearchNavi />
                        <Outlet />
                    </div>
                </div>
            </Wrap>
        </Layout>
    );
};

export default ConvergenceSearch;

import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Main from "@pages/Main";
import Search from "@pages/Search";
import TechStrom from "@pages/TechStorm";
import IndustrySearch from "@pages/Build/IndustrySearch";
import CompanySearch from "@pages/Build/CompanySearch";
import PatentSearch from "@pages/Build/PatentSearch";
import TechTrad from "@pages/TechStorm/TechTrad";
import Build from "@pages/Build";
import Patentpia from "@pages/Patentpia";
import Patent from "@pages/Patentpia/Patent";
import ConvergenceSearch from "@pages/ConvergenceSearch";

import PatentConSearch from "@pages/ConvergenceSearch/Patent";
import CompanyConSearch from "@pages/ConvergenceSearch/Company";
import ReseacherConSearch from "@pages/ConvergenceSearch/Reseacher";
import NewsConSearch from "@pages/ConvergenceSearch/News";
import LawSuitConSearch from "@pages/ConvergenceSearch/Lawsuit";
import PatentDetail from "@pages/ConvergenceSearch/Patent/PatentDetail";
import CompanyDetail from "@pages/ConvergenceSearch/Company/CompanyDetail";
import SalesTech from "@pages/ConvergenceSearch/SalesTech";
import Portfolio from "@pages/Patentpia/Portfolio";
import Event from "@pages/Patentpia/Event";
import CompanyRe from "@pages/Patentpia/companyRe";
import Reseacher from "@pages/Patentpia/Reseacher";
import Reports from "@pages/Reports";
import Brief from "@pages/Reports/Brief";
import Watch from "@pages/Reports/Watch";
import Nanum from "@pages/TechStorm/Nanum";
import Agent from "@pages/TechStorm/Agent";

const Router = () => {
    return (
        <BrowserRouter basename="">
            <Routes>
                <Route path="/dash" element={<Main />} />
                <Route path="/search" element={<Search />} />
                <Route path="/apps/tech" element={<TechStrom />}>
                    <Route path="trade" element={<TechTrad />} />
                    <Route path="nanum" element={<Nanum />} />
                    <Route path="agent" element={<Agent />} />
                </Route>
                <Route path="/apps/build" element={<Build />}>
                    <Route path="" element={<PatentSearch />} /> {/* 특허탐색 */}
                    <Route path="industry" element={<IndustrySearch />} /> {/* 산업탐색 */}
                    <Route path="company" element={<CompanySearch />} /> {/* 기업탐색 */}
                </Route>
                <Route path="/apps/patentpia" element={<Patentpia />}>
                    <Route path="" element={<Patent />} />
                    {/* <Route path="company" element={<PatentPiaCompany />} /> */}
                    <Route path="company" element={<CompanyRe />} />
                    <Route path="event" element={<Event />} />
                    <Route path="portfolio" element={<Portfolio />} />
                    <Route path="researcher" element={<Reseacher />} />
                    <Route path="network" element={<>network</>} />
                </Route>
                <Route path="/consearch" element={<ConvergenceSearch />}>
                    <Route path="patent" element={<PatentConSearch />} />
                    <Route path="company" element={<CompanyConSearch />} />
                    <Route path="reseacher" element={<ReseacherConSearch />} />
                    <Route path="news" element={<NewsConSearch />} />
                    <Route path="lawsuit" element={<LawSuitConSearch />} />
                    <Route path="sales" element={<SalesTech />} />
                    <Route path="company/:id" element={<CompanyDetail />} />
                </Route>
                <Route path="/consearch/patent/:id" element={<PatentDetail />} />
                <Route path="/*" element={<Navigate to="/dash" />} />
                <Route path="/reports" element={<Reports />}>
                    <Route path="brief" element={<Brief />} />
                    <Route path="watch" element={<Watch />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;

import { device } from "@assets/index";
import { styled } from "styled-components";

export const Wrap = styled.div`
    width: 100%;
    height: 148px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);
    padding-top: 23px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 20px;
    transition: all 0.4s;

    @media ${device.tablet} {
        height: auto;
    }

    .p__num {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 300;
        font-size: 40px;

        span {
            font-weight: 700;
        }
    }

    .c__first {
        display: flex;
        justify-content: space-between;
    }
    .p__title {
        p {
            display: inline-block;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #333;
        }

        .wrap_btn {
            position: relative;
            display: inline-block;
            top: 2px;
            margin-left: 8px;

            &:hover {
                .box_cloud {
                    display: block;
                }
            }

            .box_cloud {
                display: none;
                position: absolute;
                width: 160px;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
                top: -16px;
                left: 28px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.4;
                color: #333;
                background: #f2f2f7;
                padding: 10px 6px 10px;
                border-radius: 4px;
                box-sizing: border-box;

                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 16px;
                  transform: translateX(-100%) rotate(90deg);
                  border-width: 8px;
                  border-style: solid;
                  border-color: #f2f2f7 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
                  z-index: 1;
                }
            }
        }
    }

    .p__percentage {
        display: flex;
        align-items: center;

        svg {
            position: relative;
            top: 1px;
        }

        .text__percentage {
            margin-left: 6px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #45cb85;

            &.dwon {
                color: #f06548;
            }
        }
    }

    .c__snd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 37px;

        @media ${device.tablet} {
            margin-top: 18px;
        }

        .text__num {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 300;
            font-size: 40px;

            @media ${device.tablet} {
                font-size: 25px;
            }

            span {
                font-weight: 700;
            }
        }

        @media ${device.tablet} {
            img {
                width: 30px;
            }
        }
    }

    &:hover {
        transform: translateY(calc(-1.5rem / 5));
    }
`;

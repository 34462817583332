import QuestIcon from "@assets/img/icon-quest.png";
import { device } from "@assets/index";
import styled, { css } from "styled-components";

export const CloudDesc = styled.div`
    display: none;
    z-index: 5;
    position: absolute;
    font-size: 12px;
    line-height: 1.4;
    width: 150px;
    background: #edebe1;
    padding: 10px;
    top: 28px;
    left: -18%;

    @media ${device.laptopS} {
        left: initial;
        right: -15px;
    }

    &:before {
        content: "";
        position: absolute;
        top: -18px;
        left: 10px;
        transform: rotate(180deg);
        border-width: 10px;
        border-style: solid;
        border-color: #edebe1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        z-index: 1;

        @media ${device.laptopS} {
            left: initial;
            right: 14px;
        }
    }
`;

export const CardComp = styled.div<{ height: number | string }>`
    overflow: hidden;
    position: relative;
    height: ${(props) => props.height}px;
    background: #fff;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);

    ${(props) =>
        props.height === "auto" &&
        css`
            padding-bottom: 20px;
        `}

    &.overflow {
        padding-left: 10px;
        padding-right: 4px;
    }

    .inner {
        padding: 0 30px;
        box-sizing: border-box;

        &.pie {
            text-align: center;
            overflow-x: scroll;
        }
    }

    .c__wordcloud {
        position: relative;
        /* width: calc(100% - 60px); */
        width: 100%;
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -38%); */

        & > svg {
            /* width: calc(100% - 60px); */
            width: 100% !important;
        }
    }

    .p__titles {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 16px 16px 0;
        font-size: 0;

        strong {
            display: inline-block;
            vertical-align: middle;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 18px;
            color: #333;
        }

        .wrap_btn {
            margin: 2px 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                .wrap_cloud {
                    display: block;
                }
            }
        }

        button {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background-image: url(${QuestIcon});
            background-repeat: no-repeat;
            background-position: 50%;
            top: 1px;
        }
    }
`;

export const CardSlideComp = styled.div<{ height: number }>`
    position: relative;
    height: ${(props) => props.height}px;
    background: #fff;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);

    .outer__swiper {
        position: absolute;
        width: 100%;
        height: 100%;

        .swiper {
            height: 100%;
        }
    }

    .inner {
        padding: 0 30px;
        box-sizing: border-box;

        &.pie {
            text-align: center;
        }
    }
    .p__titles {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 16px 16px 0;
        font-size: 0;

        strong {
            display: inline-block;
            vertical-align: middle;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 18px;
            color: #333;
        }

        .wrap_btn {
            margin: 2px 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                .wrap_cloud {
                    display: block;
                }
            }
        }

        button {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background-image: url(${QuestIcon});
            background-repeat: no-repeat;
            background-position: 50%;
            top: 1px;
        }
    }
`;

export const GraphWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SoureceMoveBtn = styled.button`
    color: #fff;
    padding: 8px;
    font-size: 14px;
    margin: 16px 16px 0;
    background-color: #80818d;
`;

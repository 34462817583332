import { postCompanySearch, postCompetitorSearch } from "@api/patentSearch";
import { useMutation } from "@tanstack/react-query";
import { getDate } from "@utils/index";
import { useEffect, useState } from "react";

interface IProps {
    payLoad: {
        enterprise: { code: string };
        competitor: { code: string };
    };
    competitorItems: string;
}

interface ITempNation {
    data: number[];
    label: any[];
}

const getNation = (arr: any[]) => {
    let temp: ITempNation = {
        data: [],
        label: [],
    };
    for (const item in arr) {
        temp.data.push(arr[item].pat_appl_cnt);
        temp.label.push(arr[item].ctry_nm);
    }

    return temp;
};

const getIpc = (arr: any[]) => {
    let temp: ITempNation = {
        data: [],
        label: [],
    };
    for (const item in arr) {
        temp.data.push(arr[item].pat_appl_cnt);
        temp.label.push(arr[item].ipc);
    }

    return temp;
};

const useCompany = ({ payLoad, competitorItems }: IProps) => {
    const [didMount, setDidMount] = useState(false);
    const { enterprise, competitor } = payLoad;

    const [nation, setNation] = useState<ITempNation>({
        data: [],
        label: [],
    });
    const [ipc, setIpc] = useState<ITempNation>({
        data: [],
        label: [],
    });

    const today = getDate();
    const {
        mutate: enterMutate,
        data: enterData,
        isLoading: enterLoading,
    } = useMutation(() => postCompanySearch(enterprise.code, today), {
        onSuccess(data) {
            const nationTemp = getNation(data[1].data_set);
            const ipcTemp = getIpc(data[2].data_set);

            setNation(nationTemp);
            setIpc(ipcTemp);
        },
    });

    const {
        mutate: competeMutate,
        data: competeData,
        isLoading: competeLoading,
    } = useMutation(() => postCompanySearch(competitor.code, today), {
        onSuccess(data) {},
    });

    interface ICompetPatent {
        data: number[];
        label: string[];
    }
    interface ISkillData {
        name: string;
        data: number[][];
    }
    type TSkillDataSet = ISkillData[];

    interface IAiPointsData {
        name: string;
        data: number[];
    }
    interface IAiPoints {
        label: string[];
        data: IAiPointsData[];
    }

    const [competPatent, setCompetPatent] = useState<ICompetPatent>();
    const [skillDataSet, setSkillDataSet] = useState<TSkillDataSet>();
    const [aiPoints, setAiPoints] = useState<IAiPoints>();

    const { mutate: competitorMutate } = useMutation(() => postCompetitorSearch(competitorItems, today), {
        onSuccess(data) {
            const temp1 = {
                data: data.DATA[0].data_set.map((item: any) => item.pat_appl_cnt),
                label: data.DATA[0].data_set.map((item: any) => item.com_nm),
            };

            setCompetPatent(temp1);

            const temp2 = data.DATA[1].data_set.map((item: any) => ({
                name: item.com_nm,
                data: [[item.pfs, item.cpp]],
            }));

            if (temp2.length > 1) {
                const averageData = temp2
                    .reduce(
                        (accumulator: any, entry: any) => {
                            const entryData = entry.data[0];
                            accumulator[0] += entryData[0];
                            accumulator[1] += entryData[1];
                            return accumulator;
                        },
                        [0, 0],
                    )
                    .map((value: any) => Math.round((value / temp2.length) * 100) / 100);

                const result = [
                    ...temp2,
                    {
                        name: "평균",
                        data: [averageData],
                    },
                ];

                setSkillDataSet(result);
            } else {
                setSkillDataSet(temp2);
            }

            const labelsTemp: string[] = [];

            for (const item of data.DATA[2].data_set) {
                if (!labelsTemp.includes(item.ipc)) {
                    labelsTemp.push(item.ipc);
                }
            }

            const temp3 = data.DATA[2].data_set.reduce((result: { name: string; data: number[] }[], item: any) => {
                const existingCompany = result.find((company) => company.name === item.com_nm);
                if (existingCompany) {
                    const ipcIndex = labelsTemp.indexOf(item.ipc);
                    if (ipcIndex !== -1) {
                        existingCompany.data[ipcIndex] = item.ai;
                    }
                } else {
                    const newData = Array(labelsTemp.length).fill(0);
                    const ipcIndex = labelsTemp.indexOf(item.ipc);
                    if (ipcIndex !== -1) {
                        newData[ipcIndex] = item.ai;
                    }
                    result.push({ name: item.com_nm, data: newData });
                }
                return result;
            }, []);

            const limitedResult = temp3.map((item: any) => ({
                name: item.name,
                data: item.data.slice(0, 25),
            }));

            setAiPoints({
                label: labelsTemp.slice(0, 25),
                data: limitedResult,
            });
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && enterprise.code !== "") {
            enterMutate();
        }
    }, [enterprise.code, didMount]);

    useEffect(() => {
        if (didMount && competitor.code !== "") {
            competeMutate();
        }
    }, [competitor.code, didMount]);

    useEffect(() => {
        if (didMount && competitorItems !== "") {
            competitorMutate();
        }
    }, [competitorItems, didMount]);

    return {
        nation,
        ipc,
        enterData,
        enterLoading,
        competeData,
        competeLoading,
        competPatent,
        skillDataSet,
        aiPoints,
    };
};

export default useCompany;

import DefaultTechImage from "@assets/img/default-main-img.png";
import config from "@config";
import { useTechStrom } from "@hooks/index";
import { commaNumber, formatThumbnail, handleText } from "@utils/index";
import { ITechContent } from "~types/types";
import { ProfileImg } from "./style";

const BuyableTech = () => {
    const { techData, isLoading } = useTechStrom("", "app");

    return (
        <>
            <div className="c__title">
                <strong>구매가능기술 {!isLoading && techData !== undefined && `(${techData.content.length})`}</strong>
                <button onClick={() => window.open(`${config.TECHSTORMURL}/technology/transfer`, "_blank")} />
            </div>
            {!isLoading && (
                <ul className="item__buy">
                    {techData &&
                        techData.content.slice(0, 3).map((item: ITechContent) => (
                            <li
                                key={item.techIdx}
                                className="list__buy"
                                onClick={() =>
                                    window.open(
                                        `${config.TECHSTORMURL}/technology/transfer/detail/${item.techIdx}`,
                                        "_blank",
                                    )
                                }
                            >
                                <ProfileImg
                                    $url={
                                        (item.techFileList &&
                                            item.techFileList.find(
                                                (elem) => elem.fileCd === "TFC0001" && formatThumbnail(elem.fileUrl),
                                            )?.fileUrl) ||
                                        DefaultTechImage
                                    }
                                />

                                <div className="p__text">
                                    <ul className="item__kinds">
                                        {item.scienceTechCdDesc && <li>{item.scienceTechCdDesc}</li>}
                                        {item.industryTechCdDesc && <li>{item.industryTechCdDesc}</li>}
                                        {item.typeCdDesc && <li>{item.typeCdDesc}</li>}
                                    </ul>
                                    <p className="p__desc">{handleText(item.techName, 17)}</p>
                                    {item.trade.priceTypeCd === "TPT0001" ? (
                                        <p className="p__price">가격협의</p>
                                    ) : (
                                        <p className="p__price">{commaNumber(item.trade.price)}원</p>
                                    )}
                                </div>
                            </li>
                        ))}
                </ul>
            )}
        </>
    );
};

export default BuyableTech;

import {
    ICompanyDetail,
    ICompanyList,
    ICompanyListProps,
    IRelatedImg,
    IRelatedKeyWord,
    ISimilProps,
    ISimilarIndustry,
    RelatedImgProps,
} from "~types/types";
import { AxiosCompany, AxiosCompanyDetail } from "@api/index";

export const patchCompanyApi = async (props: ICompanyListProps): Promise<ICompanyList> =>
    AxiosCompany.patch(`?page=${props.page}&size=${props.size}&sort=`, props.data).then((res) => res.data.data);

export const getCompanyKeyWords = async (keyWord: string): Promise<IRelatedKeyWord> =>
    AxiosCompany.get(`keywords?keyword=${keyWord}`).then((res) => res.data);

export const patchRelatedImg = async (imgProps: RelatedImgProps): Promise<IRelatedImg> =>
    AxiosCompany.patch(`/product?page=${imgProps.page}&size=${imgProps.size}`, imgProps.data).then((res) => res.data);

export const patchCompanyDetail = async (id: string): Promise<ICompanyDetail> =>
    AxiosCompanyDetail.patch(`/num`, {
        id: id,
    }).then((res) => res.data);

export const getSimilarIndustry = async (similProps: ISimilProps): Promise<ISimilarIndustry> =>
    AxiosCompanyDetail.get(
        `/similar-industry?businessCategoryCode=${similProps.businessCategoryCode}&size=${similProps.size}&id=${similProps.id}`,
    ).then((res) => res.data);

import { styled } from "styled-components";

import BreadArrow from "@assets/icon/bread-arrow.svg";
import { device } from "@assets/index";

export const Wrap = styled.div`
    .l__banner {
        position: relative;
        width: 100%;
        height: 240px;
        background-color: aliceblue;
    }

    .l__content {
        width: 100%;
        min-height: calc(100vh - 310px);
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 10px;
        padding-bottom: 20px;
        background-color: #f2f2f7;
    }

    .l__common {
        position: relative;
        padding-right: 10px;
        box-sizing: border-box;

        .b__category {
            position: absolute;
            display: inline-block;
            padding: 0 16px 0;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 36px;
            color: #fff;
            background-color: #3a3f7c;
            border-radius: 2px;
            top: 0;
            right: 10px;

            @media ${device.tablet} {
                position: relative;
                margin-top: 6px;
                right: 0;
            }
        }
        .p__keyword {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            line-height: 1.4;
            color: #333;
        }

        .p__ipc {
            padding-top: 15px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: #333;
        }

        .item__bread {
            padding-top: 14px;
            padding-right: 10px;
            font-size: 0;
            text-align: left;

            li {
                position: relative;
                display: inline-block;
                margin-left: 21px;
                vertical-align: middle;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #666;

                &:first-child {
                    margin-left: 0;
                }

                &:after {
                    position: absolute;
                    content: "";
                    width: 7px;
                    height: 11px;
                    background-image: url(${BreadArrow});
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 50% 50%;
                    top: 3px;
                    right: -14px;
                }

                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }

    .item__tabs {
        display: block;
        position: relative;
        width: calc(100% - 10px);
        min-height: 62px;
        padding-top: 30px;
        border-bottom: 1px solid #ccc;
        font-size: 0;
        text-align: left;

        @media ${device.tabletL} {
            display: grid;
            row-gap: 10px;
            width: calc(100%);
            grid-template-columns: repeat(4, 1fr);
        }
        @media ${device.tablet} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${device.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        li {
            display: inline-block;
            vertical-align: middle;
            margin-left: 40px;

            @media ${device.tabletL} {
                margin-left: 0;
                text-align: center;
            }

            &:first-child {
                margin-left: 0;
            }

            &.active {
                a {
                    color: #3798ff;

                    &:after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: #3798ff;
                        left: 0;
                        bottom: 0;
                    }
                }
            }

            a {
                position: relative;
                display: block;
                padding-bottom: 15px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #333;
            }
        }
    }
`;

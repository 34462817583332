import { Link } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import { keyWordStorageAtom, patentPageAtom, searchPatentListAtom } from "@atoms";
import { getHighLight } from "../Company";
import Keywords from "./KeyWords";
import PageNation from "@components/PageNation";

const Patent = () => {
    const searchPatentList = useAtomValue(searchPatentListAtom);
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const [patentPage, setPatentPage] = useAtom(patentPageAtom);

    const handlePage = (page: number) => {
        setPatentPage((prev: any) => ({ ...prev, page: page }));
    };

    return (
        <div className="content-wrap Patent">
            <div className="company-wrap">
                {/*데이터가 없을때*/}
                {keyWordStorage === "" ||
                    (searchPatentList.content.length === 0 && (
                        <div className="wrap-empty">
                            <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                        </div>
                    ))}

                {/*데이터가 있을때*/}
                {searchPatentList && searchPatentList.content.length > 0 && (
                    <>
                        <ul className="company-list">
                            {searchPatentList.content.map((item: any) => (
                                <li className="company-item" key={item.id}>
                                    <div className="card-header">
                                        <div className="title">
                                            <Link
                                                to={`/consearch/patent/${item.applicate_number}`}
                                                className="word"
                                                title="viewdetails"
                                            >
                                                {getHighLight(item.invention_title, keyWordStorage)}
                                            </Link>

                                            {item.keyword.length > 0 && (
                                                <ul className="info">
                                                    {item.keyword.slice(0, 4).map((item: string, index: number) => (
                                                        <li className="info-item" key={index}>
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <p className="desc">{item.content}</p>
                                    <div className="tag">
                                        <p className="company">{item.applicant_kor_names}</p>
                                        <p className="date">{item.applicate_date}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        {searchPatentList.totalPages > 0 && (
                            <PageNation
                                totalPage={searchPatentList.totalPages}
                                currentPage={patentPage.page}
                                clickPage={handlePage}
                            />
                        )}
                    </>
                )}
            </div>

            <div className="gallery-container side">
                <Keywords />
            </div>
        </div>
    );
};

export default Patent;

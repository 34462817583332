import { IKakaoMapPosition, IKakaoMapProps, IKakaoMapResults } from "types/etcTypes";
import { useEffect, useState } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";

const KakaoMap = ({ add, height, width }: IKakaoMapProps) => {
    const [position, setPosition] = useState<IKakaoMapPosition>({
        x: 0,
        y: 0,
        status: "no",
    });
    useEffect(() => {
        if (add !== "") {
            const geocoder = new window.kakao.maps.services.Geocoder();

            geocoder.addressSearch(add, function (result: IKakaoMapResults[], status: string) {
                if (status === "OK") {
                    setPosition({
                        x: Number(result[0].x),
                        y: Number(result[0].y),
                        status: status,
                    });
                }
            });
        }
    }, [add]);

    return (
        <>
            {position.status === "OK" && (
                <Map
                    center={{
                        lat: position.y,
                        lng: position.x,
                    }}
                    style={{
                        width: `${width}px`,
                        height: `${height}px`,
                    }}
                    level={4}
                >
                    <MapMarker
                        position={{
                            lat: position.y,
                            lng: position.x,
                        }}
                    />
                </Map>
            )}
        </>
    );
};

export default KakaoMap;

import { useAtom } from "jotai";
import { useEffect, useState } from "react";

import { patentPiaCateAtom, patentPiaCateId, patentPiaCateOtherAtom, patentPiaCates, patentPiaCatesTemp } from "@atoms";
import { ICate } from "@hooks/usePatentPia";
import useTabs from "@hooks/useTabs";
import { useFetchFirstDepth } from "@queries/Patentpia/CatePop";
import { IPop, IResults } from "~types/Patentpia";
import { ICategory } from "~types/patentPiaTypes";

interface CatePopProps {
    results: IResults[];
    setResults: React.Dispatch<React.SetStateAction<[] | IResults[]>>;
}

export const useCatePop = ({ results, setResults }: CatePopProps) => {
    const [initNum] = useState(0);
    const [cate, setCate] = useState<ICate[]>([]);

    const [, setCates] = useAtom(patentPiaCates);
    const [catesTemp, setCatesTemp] = useAtom(patentPiaCatesTemp);
    const [, setId] = useAtom(patentPiaCateId);
    const [patentPiaCate, setPatentPiaCate] = useAtom(patentPiaCateAtom);
    const [, setPatentPiaCateOther] = useAtom(patentPiaCateOtherAtom);

    const { data, isFetching } = useFetchFirstDepth(patentPiaCate);
    const { currentItem, changeItem, currentIndex } = useTabs(initNum, results);

    useEffect(() => {
        if (!isFetching && data) {
            const temp: ICategory = JSON.parse(data.RESULT);
            setCate(temp.LOWER_CATE_INFO);
        }
    }, [data, isFetching]);

    useEffect(() => {
        if (results !== undefined && results.length > 0) {
            setCatesTemp((prev) => ({
                depth1: {
                    name: results[0].name ? results[0].name : "",
                    cateId: results[0].cateId ? results[0].cateId : "",
                },
                depth2: { name: "", cateId: "" },
                depth3: { name: "", cateId: "" },
            }));
        }
    }, [patentPiaCate, results, setCatesTemp]);

    const handleClick = (id: number, level: number, name: string, cateId: string) => {
        changeItem(id);
        setCatesTemp((prev) => ({
            depth1: { name: name, cateId: cateId },
            depth2: { name: "", cateId: "" },
            depth3: { name: "", cateId: "" },
        }));
    };

    const handleSelectCate = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {
            currentTarget: {
                dataset: { id },
            },
        } = event;

        if (id !== undefined) {
            setPatentPiaCate({
                cate: id,
            });
        }
    };

    const handleApply = ({ func }: IPop) => {
        setCates(catesTemp);
        if (catesTemp.depth3.cateId !== "") {
            setId(catesTemp.depth3.cateId);
        }

        if (catesTemp.depth3.cateId === "") {
            if (catesTemp.depth2.cateId !== "") {
                setId(catesTemp.depth2.cateId);
            } else {
                setId(catesTemp.depth1.cateId);
            }
        }

        setPatentPiaCateOther(patentPiaCate);

        func();
    };

    return {
        cate,
        currentItem,
        currentIndex,
        patentPiaCate,
        handleClick,
        handleApply,
        handleSelectCate,
    };
};

import { IRadarProps } from "types/etcTypes";
import { reSizeAtom } from "@atoms";
import { useAtom } from "jotai";
import ApexChart from "react-apexcharts";
import { styled } from "styled-components";

const Wrap = styled.div``;

interface IData {
    data: IRadarProps;
}

const colors = [
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
];

const Radar = ({ data }: IData) => {
    const [resizeWidth, setWidth] = useAtom(reSizeAtom);
    return (
        <Wrap>
            <ApexChart
                type="radar"
                height={400}
                series={data.data}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    xaxis: {
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        categories: data.label,
                    },
                    legend: {
                        position: resizeWidth < 768 ? "bottom" : "right",
                        horizontalAlign: "center",
                        formatter: (legendName: string) => {
                            return legendName;
                        },
                    },
                    colors: colors,
                }}
            />
        </Wrap>
    );
};

export default Radar;

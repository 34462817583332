import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { useEffect } from "react";
import { commaNumber } from "@utils/index";
import { IMapChartProps } from "types/etcTypes";

// console.log(am5geodata_worldLow); // 나라 코드

const MapChart = ({ data, height, index }: IMapChartProps) => {
    am5.addLicense("AM5C400641047");

    useEffect(() => {
        const root = am5.Root.new(`chart${index}`);

        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(am5map.MapChart.new(root, {}));

        const polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude: ["AQ"],
            }),
        );

        const bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                valueField: "value",
                calculateAggregates: true,
                polygonIdField: "id",
            }),
        );

        const circleTemplate = am5.Template.new({});

        const handleNumber = (num: string) => {
            const temp = commaNumber(Number(num));
            return temp;
        };

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            const container = am5.Container.new(root, {});
            // @ts-ignore
            const tempNumber = handleNumber(dataItem.dataContext.value);

            const circle = container.children.push(
                am5.Circle.new(
                    root,
                    {
                        radius: 28,
                        fillOpacity: 0.6,
                        fill: am5.color(0xaf1010),
                        cursorOverStyle: "pointer",
                        tooltipText: `{name}: [bold]${tempNumber}[/]`,
                    },
                    // @ts-ignore
                    circleTemplate,
                ),
            );

            const countryLabel = container.children.push(
                am5.Label.new(root, {
                    text: "{name}",
                    paddingLeft: 30,
                    populateText: true,
                    fontWeight: "bold",
                    fontSize: 13,
                    centerY: am5.p50,
                }),
            );

            circle.on("radius", function (radius) {
                countryLabel.set("x", radius);
            });

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true,
            });
        });

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    // @ts-ignore
                    text: handleNumber(dataItem.dataContext.value),
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center",
                }),
                dynamic: true,
            });
        });

        bubbleSeries.set("heatRules", [
            {
                target: circleTemplate,
                dataField: "value",
                min: 10000,
                max: 100000,
                minValue: 0,
                maxValue: 100000,
                key: "radius",
            },
        ]);

        bubbleSeries.data.setAll(data);

        return () => {
            root.dispose();
        };
    }, [data]);

    return <div id={`chart${index}`} style={{ width: "100%", height: `${height}px` }} />;
};

export default MapChart;

import { AgentWrap, BannerWrap, BuyWrap, ReWrap } from "./style";
import Traders from "./Traders";
import ResearchersComp from "./ResearchersComp";
import BuyableTech from "./BuyableTech";
import bannerImg from "@assets/img/new_banner_techstorm_2.png";
import config from "@config";

const Banner = () => {
    return (
        <>
            <BuyWrap>
                <BuyableTech />
            </BuyWrap>
            <ReWrap>
                <strong>연구자정보 TOP10</strong>
                <ResearchersComp />
            </ReWrap>
            <AgentWrap>
                <div className="c__title">
                    <strong>기술중개사</strong>
                    <button onClick={() => window.open(`${config.TECHSTORMURL}/tta`, "_blank")} />
                </div>

                <Traders />
            </AgentWrap>
            <BannerWrap onClick={() => window.open("https://tech-storm.io/", "_blank")}>
                <img src={bannerImg} alt="temp" />
            </BannerWrap>
        </>
    );
};

export default Banner;

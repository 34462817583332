import { patchPatentApi } from "@api/searchPatent";
import { useMutation } from "@tanstack/react-query";
import { IPatentPage, SetSearchPatentList } from "~types/ConvergenceSearch";

const PatentQueryKeys = {
    patent: () => ["patent"],
};

export const useFetchPatentData = (setSearchPatentList: SetSearchPatentList) => {
    return useMutation({
        mutationKey: PatentQueryKeys.patent(),
        mutationFn: (patentPage: IPatentPage) => patchPatentApi(patentPage),
        onSuccess: (data) => {
            setSearchPatentList(data);
        },
    });
};

import { IPieProps } from "types/etcTypes";
import { device } from "@assets/index";
import { reSizeAtom } from "@atoms";
import { useAtom } from "jotai";
import React from "react";
import ApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";

const Wrap = styled.div`
    display: inline-block;
    //padding-top: 35px;

    @media ${device.tablet} {
        padding-top: 35px;
    }

    .apexcharts-legend-series {
        text-align: left;
    }
`;

const Pie: React.FC<IPieProps> = ({ data, labels, width, isLegend }) => {
    const [resizeWidth, setWidth] = useAtom(reSizeAtom);
    const { pathname } = useLocation();

    return (
        <Wrap>
            <ApexChart
                type="donut"
                width={resizeWidth <= 500 || pathname.includes("dash") ? "250px" : `${width}px`}
                height={`${width}px`}
                series={data}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    labels: labels,
                    legend: {
                        show: isLegend,
                        showForSingleSeries: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: resizeWidth <= 500 || pathname.includes("dash") ? "bottom" : "right",
                        horizontalAlign: "left",
                        floating: false,
                        // @ts-ignore
                        formatter: function (seriesName, opts) {
                            return [seriesName.length > 8 ? `${seriesName.substring(0, 8)}...` : seriesName];
                        },
                    },
                    dataLabels: {
                        distributed: true,
                        style: {
                            colors: ["#fff"],
                            fontSize: "13px",
                        },
                        dropShadow: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "60%",
                                labels: {
                                    show: false,
                                    value: {
                                        show: true,
                                        fontSize: "20px",
                                        color: "#fff",
                                    },
                                },
                            },
                        },
                    },
                }}
            />
        </Wrap>
    );
};

export default Pie;

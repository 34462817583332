import Banners from "@components/Banners";
import Layout from "@components/Layout";

import BannerLogo from "@assets/img/logo-patentpia.png";
import BannerImg from "@assets/img/patentpia-banner.png";

import { ReactComponent as CopyIcon } from "@assets/icon/copy-icon.svg";
import { ReactComponent as HeartIcon } from "@assets/icon/heart-icon.svg";
import { ReactComponent as HomeIcon } from "@assets/icon/home-icon.svg";

import { usePatentpiaAction } from "@services/Patentpia";
import { Outlet } from "react-router-dom";
import CatePop from "./CatePop";
import Menu from "./Common/Menu";
import { Wrap } from "./style";

const Patentpia = () => {
    const { cates, isPop, pathname, handlePop } = usePatentpiaAction();

    return (
        <>
            <Layout>
                <Banners bannerimg={BannerImg} bannerlogo={BannerLogo}>
                    <div className="c__bannerText">
                        <div className="c__logwrap none-padding">
                            <img src={BannerLogo} alt="logo" />
                        </div>
                        <div className="c__textwrap">
                            <p className="p__title">전 세계 기술 관련 인사이트를 확인하십시요.</p>
                            <p className="p__desc">
                                40만+ 기술분야 및 800만+ 기술 키워드별 분석 콘텐츠가 사전에 준비되어 있습니다.
                            </p>
                        </div>
                    </div>

                    <ul className="item__menu">
                        <li className={`${pathname.includes("/apps/patentpia") && "active"}`} onClick={handlePop}>
                            분야별 카테고리
                        </li>
                    </ul>

                    <ul className="item__icons">
                        <li>
                            <HeartIcon />
                        </li>
                        <li onClick={() => window.open("https://www.ntb-patentpia.com/", "_blank")}>
                            <HomeIcon />
                        </li>
                        <li>
                            <CopyIcon />
                        </li>
                    </ul>
                </Banners>
                <Wrap>
                    <div className="l__content">
                        <div className="l__common">
                            {/* 분야별 카테고리에서 onchange시 value를 가져온다. */}
                            <p className="p__keyword">
                                {cates["depth1"].name}
                                {cates["depth2"].name !== "" ? ` > ${cates["depth2"].name}` : ""}
                                {cates["depth3"].name !== "" ? ` > ${cates["depth3"].name}` : ""}
                            </p>
                            {(cates["depth1"].name !== "" ||
                                cates["depth2"].name !== "" ||
                                cates["depth3"].name !== "") && (
                                <p className="p__ipc">
                                    {cates["depth3"].name || cates["depth2"].name || cates["depth1"].name}
                                </p>
                            )}

                            <ul className="item__bread">
                                <li>기본</li>
                                <li>
                                    {pathname === "/apps/patentpia" && "특허 동향 및 KPI"}
                                    {pathname === "/apps/patentpia/company" && "특허 보유 기업"}
                                    {pathname === "/apps/patentpia/event" && "중요 이벤트"}
                                    {pathname === "/apps/patentpia/portfolio" && "기술 포트폴리오"}
                                    {pathname === "/apps/patentpia/researcher" && "특허 발명 연구자"}
                                    {pathname === "/apps/patentpia/network" && "중요 네트워크"}
                                </li>
                            </ul>

                            <button
                                className="b__category"
                                onClick={() => window.open("https://www.ntb-patentpia.com/", "_blank")}
                            >
                                www.ntb-patentpia.com
                            </button>
                        </div>

                        <Menu />

                        <Outlet />
                    </div>
                </Wrap>
            </Layout>

            {/* {isPop && <PopUp func={handlePop} cate={cate} isLoading={isLoading} />} */}
            {isPop && <CatePop func={handlePop} />}
            {/* <CatePop func={handlePop} /> */}
        </>
    );
};

export default Patentpia;

import { Wrap } from "@components/Banners/style";
import { BannersProps } from "types/etcTypes";

const Banners = ({ children, bannerimg, bannerlogo }: BannersProps) => {
    return (
        <Wrap $bannerimg={bannerimg} $bannerlogo={bannerlogo}>
            {children}
        </Wrap>
    );
};

export default Banners;

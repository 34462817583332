import React, { Fragment, useEffect, useRef, useState } from "react";
import { Wrap } from "../style";
import { AnimatePresence, motion } from "framer-motion";
import Card from "./Card";
import useAgent from "./useAgent";
import LoadingComp from "@components/LoadingComp";
import PageNation from "@components/PageNation";
import { popVariants } from "@utils/index";

interface IData {
    all: string;
    name: string;
    affiliateName: string;
    techTraderNum: string;
    statusCds: string[];
}

const Agent = () => {
    const [btn, setBtn] = useState(false);
    const [btnName, setBtnName] = useState({
        id: "all",
        name: "전체",
    });
    const [data, setData] = useState<IData>({
        all: "",
        name: "",
        affiliateName: "",
        techTraderNum: "",
        statusCds: ["MSC0102"],
    });

    const [page, setPage] = useState(1);

    const { traderData, isLoading } = useAgent(data, page, 12);

    const [searchText, setSearchText] = useState("");
    const [keyWord, setKeyWord] = useState("");

    const searchInput = useRef<HTMLInputElement | null>(null);

    const handleBtn = () => {
        setBtn((prev) => !prev);
    };

    const handlePage = (num: number) => {
        setPage(num);
    };

    const clickSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {
            currentTarget: {
                dataset: { name },
            },
        } = event;

        if (name !== undefined) {
            if (name === "all")
                setBtnName({
                    id: "all",
                    name: "전체",
                });
            if (name === "name") setBtnName({ id: "name", name: "이름" });
            if (name === "affiliateName")
                setBtnName({
                    id: "affiliateName",
                    name: "소속명",
                });
        }

        setBtn(false);
    };

    const onChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            currentTarget: { value },
        } = event;

        setSearchText(value);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (event.key === "Enter") {
            const updatedData: IData = {
                ...data,
                all: "",
                name: "",
                affiliateName: "",
                techTraderNum: "",
            };
            setData(updatedData);
            setData((prev) => ({
                ...prev,
                [btnName.id]: searchText,
            }));
            setKeyWord(searchText);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.currentTarget.value !== "") {
            setSearchText("");
        }
    };

    const handleBtnClick = () => {
        const updatedData: IData = {
            ...data,
            all: "",
            name: "",
            affiliateName: "",
            techTraderNum: "",
        };
        setData(updatedData);
        setData((prev) => ({
            ...prev,
            [btnName.id]: searchText,
        }));
        setKeyWord(searchText);
    };

    useEffect(() => {
        if (keyWord) {
            setSearchText(keyWord);
        }
    }, [keyWord]);

    useEffect(() => {
        const clickWrapp = (event: any) => {
            if (
                searchInput.current &&
                document.activeElement !== searchInput.current &&
                !searchInput.current.contains(event.target)
            ) {
                if (keyWord !== "") {
                    setSearchText(keyWord);
                }
            }
        };

        document.addEventListener("click", clickWrapp);

        return () => {
            document.removeEventListener("click", clickWrapp);
        };
    }, [keyWord]);

    return (
        <Wrap>
            {isLoading ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                traderData !== undefined && (
                    <Fragment>
                        <div className="agent_title">
                            <p className="text">기술거래사 ({traderData.data.totalElements})</p>
                            <div className="box_inputs">
                                <div className="box_agent">
                                    <button className="btn" onClick={handleBtn}>
                                        {btnName.name}
                                        <span className={btn ? "arrow up" : "arrow"} />
                                    </button>
                                    <AnimatePresence>
                                        {btn && (
                                            <motion.ul
                                                className="list_btn"
                                                variants={popVariants}
                                                initial="initial"
                                                animate="visible"
                                                exit="leaving"
                                            >
                                                <li>
                                                    <button data-name="all" onClick={clickSelect}>
                                                        전체
                                                    </button>
                                                </li>
                                                <li>
                                                    <button data-name="name" onClick={clickSelect}>
                                                        이름
                                                    </button>
                                                </li>
                                                <li>
                                                    <button data-name="affiliateName" onClick={clickSelect}>
                                                        소속명
                                                    </button>
                                                </li>
                                            </motion.ul>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div className="box_agent">
                                    <input
                                        type="text"
                                        ref={searchInput}
                                        placeholder="검색어를 입력해주세요."
                                        onChange={onChanges}
                                        onKeyUp={handleKeyUp}
                                        value={searchText}
                                        onClick={handleClick}
                                    />
                                    <button className="btn_search" onClick={handleBtnClick} />
                                </div>
                            </div>
                        </div>

                        <div className="wrap_agent">
                            {traderData.data.content.length > 0 && (
                                <ul className="wrap_agent_list">
                                    {traderData.data.content.map((item: any, index: number) => (
                                        <Card key={index} data={item} />
                                    ))}
                                </ul>
                            )}
                        </div>
                    </Fragment>
                )
            )}

            <div className="wrap_nanum_pagination">
                <PageNation
                    totalPage={traderData?.data.totalPages || 1000000}
                    currentPage={page}
                    clickPage={handlePage}
                />
            </div>
        </Wrap>
    );
};

export default Agent;

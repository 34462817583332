import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import { Wrap } from "@pages/Patentpia/Common/style";
import { useEffect, useState } from "react";

import GraphComp from "@pages/Patentpia/Common/GraphComp";
import { CardComp, CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { handleDataUrl } from "../Common/utils";
interface IUrs {
    title: string;
    url: string;
    type: string;
    sourceUrl: string;
}

const HEIGHT = 600;

const groupCode = "07_00";

const Event = () => {
    const { dashTemp, dashTempLoading, dashFetching } = usePatentPia({ groupCode });
    const [urls, setUrls] = useState<IUrs[]>([]);

    useEffect(() => {
        if (dashTemp !== undefined && !dashFetching) {
            const dataUrl = handleDataUrl(dashTemp);
            setUrls(dataUrl);
        }
    }, [dashTemp, dashFetching]);

    const strings = [
        "해당 기술분야별 우리나라 년도별 특허 거래 동향",
        "해당 기술분야별 US, KR 년도별 특허 거래 동향",
        "해당 기술분야별 US, KR 특허 거래수",
        "해당 기술분야별 우리나라 년도별 국가 R&D 특허 동향",
    ];

    return (
        <Wrap>
            {dashTempLoading && dashFetching ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                urls !== undefined &&
                urls.length > 0 && (
                    <div className="l__cont two">
                        {urls.map((item, index) => (
                            <CardComp height={HEIGHT} key={index}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={index} />
                                </div>
                            </CardComp>
                        ))}
                    </div>
                )
            )}
        </Wrap>
    );
};

export default Event;

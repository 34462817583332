import {
    HandleButton,
    PatentClassification,
    SearchHeader,
    SearchPatentTable,
    SimilarNumber,
    SimilarPatentTable,
} from "@components/pages/SimilarPatentSearch";
import Comment from "@pages/Build/Comment";

import { SimilarPatentSearchWrap } from "@pages/Build/PatentSearch/SimilarPatentSearch/style";
import { WrapTableList } from "@pages/Build/style";
import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";
import { CardComp } from "@styles/Common/style";
import { useEffect } from "react";

export default function SimilarPatentSearch() {
    const { HandleResetPatentList } = useSimilarPatentSearchData();

    useEffect(() => {
        return () => HandleResetPatentList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SimilarPatentSearchWrap className="similar-patent-search-wrap">
                <WrapTableList className="similar-table">
                    <SearchPatentTable title="검색 특허" />
                    <SimilarPatentTable title="유사 특허" />
                </WrapTableList>
                <CardComp height={345} className="similar-filter-card">
                    <SearchHeader />
                    <div className="similar-filter-body">
                        <div className="c__form">
                            <PatentClassification />
                            <SimilarNumber />
                        </div>
                    </div>
                    <HandleButton />
                </CardComp>
            </SimilarPatentSearchWrap>
            <Comment>
                본 화면에서는 사용자가 확인하고 싶은 특허와 유사한 특허를 찾아주는 AI알고리즘 기반 서비스이며, 현재는
                한국특허만 지원하고 있습니다.
                <br />
                검색결과는 참고용이며 유사특허가 없을 경우 전혀 무관한 특허가 검색될 수 있으니, 정확한 선행기술조사는
                특허청 지정 선행기술조사 전문기관 이용을 추천합니다.
            </Comment>
        </>
    );
}

import { useState } from "react";

interface IProps {
    id: string | number;
}

const FavoComp = ({ id }: IProps) => {
    const [check, setCheck] = useState(false);
    const handleCheck = () => {
        setCheck((prev) => !prev);
    };

    return (
        <div className="c__fav">
            <input type="checkbox" id={`${id}`} checked={check} onChange={handleCheck} />
            <label htmlFor={`${id}`} />
        </div>
    );
};

export default FavoComp;

import Layout from "@components/Layout";

import CardComp from "@components/CardComp";
import Banner from "@pages/Main/Banner";
import Card from "@pages/Main/Card";
import Intro from "@pages/Main/Intro";
import List from "@pages/Main/Lists";

import CoreCorporation from "@components/CardComp/CoreCorporation";
import CountryPatentsOccurredCount from "@components/CardComp/CountryPatentsOccurredCount";
import useDash from "@hooks/useDash";
import { useMainDashBoardList } from "@services/Main/Lists";
import { Wrap } from "./style";

// const SelectBtn: () => JSX.Element = () => {
//     return (
//         <div className="c__select">
//             <span>SORT BY&nbsp;:&nbsp;</span>
//             <button>2023년</button>
//         </div>
//     );
// };

// const ExportReport: () => JSX.Element = () => {
//     return <button className="btn__export">Export Report</button>;
// };

const Main = () => {
    const {
        randNum,
        patentTrend,
        isLoading,
        atData,
        coreCompany,
        relatedPatent,
        numApplications,
        disputeNum,
        patentTrendTotal,
        transaction,
    } = useDash();
    const { listCount } = useMainDashBoardList({ randNum, transaction, disputeNum, numApplications });

    return (
        <Layout>
            <Wrap>
                <div className="l__main">
                    <Intro />
                    <div className="inner__main">
                        <div className="c__card">
                            {listCount.map((item) => (
                                <Card
                                    key={item.id}
                                    num={item.num}
                                    imgSrc={item.imgSrc}
                                    title={item.title}
                                    highRow={item.highRow}
                                    percentage={item.percentage}
                                    description={item.description}
                                />
                            ))}
                        </div>

                        <div className="c__company">
                            <CardComp title={"국가별 특허발생추이"}>
                                <CountryPatentsOccurredCount
                                    isLoading={isLoading}
                                    patentTrend={patentTrend}
                                    patentTrendTotal={patentTrendTotal}
                                />
                            </CardComp>
                            {/* <CardComp title={"핵심기업"} gagets={ExportReport}> */}
                            <CardComp title={"핵심기업"}>
                                <CoreCorporation coreCompany={coreCompany || {}} />
                            </CardComp>
                        </div>

                        <div className="c__lists">
                            <List atData={atData} relatedPatent={relatedPatent} />
                        </div>
                    </div>
                </div>

                <div className="l__banner">
                    <div className="inner__banner">
                        <Banner />
                    </div>
                </div>
            </Wrap>
        </Layout>
    );
};
export default Main;

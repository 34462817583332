import { styled } from "styled-components";

import Search from "@assets/icon/search-icon.svg";
import { ScrollBar } from "@styles/common";

import Arrow from "@assets/icon/agent_arrow.svg";
import HeartIconCheck from "@assets/icon/heart-icon-check.svg";
import HeartIcon from "@assets/icon/icon-heart.svg";
import NanumBg from "@assets/img/nanum_text_bg.png";
import { device } from "@assets/index";

export const Wrap = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 310px);
    padding: 20px 7px 20px 20px;
    background: #f2f2f7;

    @media ${device.tablet} {
        height: auto;
        padding: 20px;
    }

    .c__search {
        position: relative;
        text-align: right;
        padding-right: 13px;

        @media ${device.mobile} {
            text-align: left;
            padding-right: 0;
        }

        input {
            width: 300px;
            height: 36px;
            border: 1px solid #ced4da;
            border-radius: 3px;
            padding-left: 11px;
            padding-right: 25px;
            box-sizing: border-box;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 14px;
            color: #333;

            @media ${device.tablet} {
                width: 100%;
            }
        }

        button {
            position: absolute;
            width: 15px;
            height: 15px;
            background-image: url(${Search});
            background-repeat: no-repeat;
            background-position: 50% 50%;
            top: 10px;
            right: 24px;

            @media ${device.mobile} {
                right: 12px;
            }
        }
    }

    .l__list {
        margin-top: 8px;

        @media ${device.mobile} {
            margin-top: 16px;
        }

        strong {
            display: inline-block;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #333;
        }

        .c__list {
            padding-right: 8px;
            padding-bottom: 20px;
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            row-gap: 16px;
            column-gap: 16px;

            @media ${device.laptopS} {
                grid-template-columns: repeat(3, 1fr);
            }

            @media ${device.tabletL} {
                grid-template-columns: repeat(2, 1fr);
            }

            @media ${device.tablet} {
                overflow-x: initial;
                overflow-y: initial;
                height: auto;
                grid-template-columns: repeat(2, 1fr);
                padding-right: 0;
            }

            @media ${device.mobile} {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .c__loading {
            display: flex;
            width: 100%;
            height: calc(100vh - 374px);
            justify-content: center;
            align-items: center;
        }

        .text-empty {
            padding-top: 160px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #333;
            text-align: center;
        }
    }

    .wrap_nanum_tabs {
        width: calc(100% - 13px);
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        @media ${device.tablet} {
            border-bottom: none;
        }

        .list_tabs {
            display: flex;
            justify-content: left;
            height: 37px;

            @media ${device.tablet} {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                height: 74px;
            }

            li {
                margin-left: 40px;

                @media ${device.tablet} {
                    margin-left: 0;
                    text-align: center;
                    padding-top: 6px;
                    border-bottom: 1px solid #ccc;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .btn {
            position: relative;
            padding-bottom: 13px;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #333;

            &.active {
                color: #3798ff;

                &:after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #3798ff;
                    bottom: -1px;
                    left: 0;
                }
            }
        }
    }

    .wrap_pannel {
        margin-top: 24px;

        @media ${device.tablet} {
            margin-top: 48px;
        }

        .list__nanum {
            & + .list__nanum {
                margin-top: 36px;
            }
        }

        .box_state {
            .text {
                display: inline-block;
                line-height: 29px;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
            }

            .text_index {
                background: #151529;
                padding: 0 8px 0;
                border-radius: 6px 0 0 6px;
            }

            .text_state {
                padding: 0 8px 0;
                border-radius: 0 6px 6px 0;
                background: #3798ff;

                &.ing {
                    background: #3798ff;
                }

                &.end {
                    background: #bcbcbc;
                }

                &.announce {
                    background: #f14475;
                }
            }
        }

        .box_titles {
            display: flex;
            width: calc(100% - 13px);
            justify-content: space-between;

            @media ${device.tabletL} {
                flex-direction: column;
                width: 100%;
            }

            .text_title {
                padding-top: 24px;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 22px;
                font-weight: 700;
                color: #333;

                @media ${device.laptopS} {
                    line-height: 1.4;
                }
            }
        }

        .wrap_span {
            margin-top: 7px;
            display: flex;

            @media ${device.tabletL} {
                width: 100%;
                justify-content: space-between;
            }
            @media ${device.tablet} {
                flex-direction: column;
            }

            .box_span {
                .text_date {
                    text-align: right;
                    font-family: "Noto Sans KR", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #472cd2;
                }
                .outer_date {
                    overflow: hidden;
                    position: relative;
                    margin-top: 10px;
                    width: 320px;
                    height: 6px;
                    border-radius: 3px;
                    background: #bcbec8;

                    @media ${device.tablet} {
                        width: 100%;
                    }
                }
            }

            .box_date {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                .text {
                    font-family: "Noto Sans KR", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333;
                }
            }

            .text_count {
                margin-left: 24px;
                margin-top: 14px;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #333;

                @media ${device.tablet} {
                    margin-left: 0;
                }

                strong {
                    font-family: "Spoqa Han Sans Neo";
                    font-weight: 700;
                    font-size: 24px;
                }
            }
        }
    }

    .pannel_all {
        position: relative;
        ${ScrollBar}
        overflow-x: hidden;
        width: 100%;
        height: calc(100vh - 380px);

        @media ${device.tablet} {
            overflow-x: initial;
            overflow-y: initial;
            height: auto;
        }

        .c__loading {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    .wrap_nanum_list {
        margin-top: 18px;

        .list_nanum {
            padding-right: 8px;
            padding-bottom: 20px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            row-gap: 16px;
            column-gap: 16px;

            @media ${device.laptopS} {
                grid-template-columns: repeat(4, 1fr);
            }

            @media ${device.tabletL} {
                grid-template-columns: repeat(2, 1fr);
            }

            @media ${device.tablet} {
                padding-right: 0;
                grid-template-columns: repeat(2, 1fr);
            }

            @media ${device.mobile} {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .agent_title {
        display: flex;
        width: calc(100% - 13px);
        justify-content: space-between;

        @media ${device.tablet} {
            width: calc(100%);
            flex-direction: column;
        }

        .text {
            margin-top: 22px;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #333;
        }

        .box_inputs {
            font-size: 0;
            text-align: left;

            @media ${device.tablet} {
                margin-top: 20px;
            }

            .box_agent {
                position: relative;
                display: inline-block;
                vertical-align: top;
            }

            .btn {
                position: relative;
                width: 81px;
                height: 36px;
                background: #fff;
                box-sizing: border-box;
                border-top: 1px solid #ced4da;
                border-left: 1px solid #ced4da;
                border-bottom: 1px solid #ced4da;
                text-align: left;
                border-radius: 6px 0 0 6px;
                padding-left: 11px;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #9aa0a6;

                .arrow {
                    position: absolute;
                    width: 10px;
                    height: 6px;
                    top: 15px;
                    right: 10px;
                    background-image: url(${Arrow});
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                    transition: all 0.4s;
                    transform: rotate(0);

                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }

            .list_btn {
                z-index: 5;
                overflow: hidden;
                position: absolute;
                width: 100%;
                background: #fff;
                border-radius: 4px;
                box-shadow: 1px 1px 6px 0px gray;
                top: 40px;
                padding: 6px;

                li {
                    margin-top: 6px;

                    &:first-child {
                        margin-top: 0;
                    }

                    button {
                        font-family: "Noto Sans KR", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        color: #9aa0a6;
                    }
                }
            }

            input {
                width: 219px;
                height: 36px;
                padding-left: 11px;
                padding-right: 30px;
                border: 1px solid #ced4da;
                box-sizing: border-box;
                border-radius: 0 6px 6px 0;
                font-size: 14px;
                color: #81868b;

                @media ${device.tablet} {
                    width: calc(100vw - 121px);
                }

                &::placeholder {
                    font-family: "Noto Sans KR", sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    color: #9aa0a6;
                }
            }

            .btn_search {
                position: absolute;
                width: 14px;
                height: 15px;
                background-image: url(${Search});
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                top: 10px;
                right: 11px;
            }
        }
    }

    .wrap_agent_list {
        margin-top: 16px;
        ${ScrollBar}
        overflow-x: hidden;
        max-height: calc(100vh - 480px);
        padding-right: 8px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 16px;
        column-gap: 16px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(3, 1fr);
        }
        @media ${device.tabletL} {
            grid-template-columns: repeat(2, 1fr);
            max-height: none;
        }
        @media ${device.tablet} {
            overflow-x: initial;
            overflow-y: initial;

            padding-right: 0;
            grid-template-columns: repeat(2, 1fr);
        }
        @media ${device.mobile} {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .wrap_nanum_pagination {
        margin-top: 20px;
        width: 100%;
        bottom: 30px;
        left: 0;
    }

    .wrap_agent {
        overflow: hidden;
        min-height: calc(100vh - 480px);
    }

    .c__loading {
        display: flex;
        width: 100%;
        height: calc(100vh - 350px);
        justify-content: center;
        align-items: center;

        img {
            width: 120px;
            height: 160px;
        }
    }
`;

export const CardWrap = styled.div`
    overflow: hidden;
    height: 460px;
    border-radius: 7px;
    box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.1);

    @media ${device.tablet} {
        height: auto;
    }

    .c__imgOuter {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 190px;
        background-color: antiquewhite;
        border-bottom: 1px solid #e5e8eb;
        cursor: pointer;

        .c__imgInner {
            position: absolute;
            width: 100%;
            background: #444;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            img {
                width: 100%;
            }
        }

        .c__selling {
            position: absolute;
            /* width: 69px; */
            /* height: 36px; */
            padding: 9px 8px 9px;
            background: #6f54fb;
            border: 2px solid #fafafb;
            border-radius: 4px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
            top: 8px;
            right: 8px;
        }
    }

    .c__text {
        position: relative;
        background-color: #fff;
        padding: 0 16px 0;
        box-sizing: border-box;

        .c__assignment {
            position: absolute;
            display: inline-block;
            width: 102px;
            padding: 8px 0 8px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #000;
            text-align: center;
            border: 1px solid #000;
            border-radius: 15px;
            background: #fff;
            top: -15px;
            left: 16px;
        }
    }

    .item__skills {
        overflow: hidden;
        padding-top: 25px;
        padding-bottom: 16px;
        font-size: 0;
        text-align: left;
        max-height: 16px;

        li {
            position: relative;
            display: inline-block;
            margin-left: 16px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #04111d;

            @media ${device.tablet} {
                font-size: 14px;
                letter-spacing: -1px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 16px;
                background: #ccc;
                top: 0;
                right: -9px;
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    .c__title {
        width: 100%;
        height: 50px;
        margin-top: 13px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -1.5px;
        color: #04111d;

        @media ${device.tablet} {
            height: auto;
            font-size: 16px;
        }
    }

    .c__keyword {
        overflow: hidden;
        max-height: 12px;
        margin-top: 13px;
        font-size: 0;
        text-align: left;
        margin-left: -7px;

        @media ${device.tablet} {
            margin-top: 6px;
        }

        li {
            display: inline-block;
            margin-left: 7px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #666;
        }
    }

    .c__time {
        margin-top: 16px;

        .c__state {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #04111d;

            @media ${device.tablet} {
                font-size: 18px;
            }
        }

        .p__time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .p__dday {
                display: inline-block;
                width: 58px;
                padding: 4.5px 0 4.5px;
                border-radius: 3px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #a8a8a8;
                background: #f8f8f8;
                text-align: center;
            }

            .p__day {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #5230ff;

                @media ${device.tablet} {
                    font-size: 14px;
                }

                &.red {
                    color: #e31212;
                }
            }
        }
    }

    .c__seller {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 8px;
        padding-top: 17px;
        padding-bottom: 16px;

        &:after {
            content: "";
            position: absolute;
            width: calc(100% + 32px);
            height: 1px;
            background: #e5e8eb;
            top: 0;
            left: -8px;
        }

        .p__seller {
            font-size: 0;
            text-align: center;

            * {
                display: inline-block;
                vertical-align: middle;
            }

            span {
                margin-left: 8px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
            }

            img {
                width: 24px;
            }
        }

        .c__fav {
            position: relative;

            label {
                display: inline-block;
                width: 24px;
                height: 22px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                transition: all 0.1s;
                cursor: pointer;
            }

            input {
                display: none;
                position: absolute;
                width: 1px;
                height: 1px;

                & + label {
                    background-image: url(${HeartIcon});
                }

                &:checked {
                    & + label {
                        background-image: url(${HeartIconCheck});
                    }
                }
            }
        }
    }
`;

export const NanumCard = styled.li`
    overflow: hidden;
    height: 372px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    cursor: pointer;

    &:hover .wrap_img img {
        transform: scale(1.1);
        transition: 0.5s;
    }

    .wrap_img {
        overflow: hidden;
        position: relative;
        height: 190px;
        padding: 15px;
        background: #fff;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .box_text {
        font-family: "Noto Sans KR", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 182px;
        padding: 20px;
        background-image: url(${NanumBg});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        box-sizing: border-box;

        .text {
            text-align: center;
        }

        .text_title {
            font-size: 24px;
            font-weight: 700;
            color: #04111d;
        }

        .text_desc {
            margin-top: 26px;
            font-size: 14px;
            line-height: 18px;
            color: #666;
        }
    }
`;

export const AgentCard = styled.li`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    height: 220px;
    padding: 20px 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    box-sizing: border-box;

    @media ${device.tablet} {
        height: auto;
    }

    .box_name {
        display: flex;
        align-items: center;
        gap: 18px;
        cursor: pointer;

        @media ${device.tablet} {
            gap: 10px;
            flex-direction: column;
            align-items: flex-start;
        }

        .box_img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            flex-shrink: 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .box_no_img {
            width: 80px;
            height: 80px;
            flex-shrink: 0;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #ebedee;

            @media ${device.tablet} {
                display: block;
            }
        }

        .box_text {
            font-family: "Noto Sans KR", sans-serif;

            .text_name {
                font-size: 22px;
                font-weight: 500;
                color: #04111d;
            }

            .text_company {
                margin-top: 12px;
                font-size: 18px;
                line-height: 22px;
                font-weight: 400;
                color: #666;
            }
        }
    }

    .box_desc {
        display: flex;
        align-items: center;
        width: 100%;
        height: 65px;
        border-radius: 10px;
        padding: 6px 15px;
        background: #f4f6fe;
        font-size: 14px;
        line-height: 1.4;
        box-sizing: border-box;

        @media ${device.tablet} {
            margin-top: 12px;
        }
    }
`;

import { device } from "@assets/index";
import { ScrollBar } from "@styles/common";
import { styled } from "styled-components";

export const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 18px;
    column-gap: 16px;

    @media ${device.tabletL} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.tablet} {
        grid-template-columns: repeat(1, 1fr);
    }

    .c__item {
        position: relative;
        height: 277px;
        background: #fff;
        box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .c__has {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        row-gap: 15px;

        & > div {
            background: #fff;
            box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .p__title {
        display: inline-block;
        padding-top: 16px;
        padding-left: 16px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: #333;
    }

    .p__nums {
        padding-top: 32px;
        padding-left: 16px;
        font-family: "Spoqa Han Sans Neo";
        font-weight: 700;
        font-size: 50px;
        color: #333;

        @media ${device.tablet} {
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 30px;
            color: #333;
        }
    }

    .p__pie {
        position: relative;
        text-align: center;
    }

    .c__application {
        padding: 0 30px;
        box-sizing: border-box;
    }

    .c__applicantStatus {
        padding: 0 20px 0;
        top: 40px;
        box-sizing: border-box;

        .xAxis {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 80px;
            left: 10px;

            * {
                font-size: 13px;
            }
        }

        .yAxis {
            position: absolute;
            font-size: 13px;
            left: 50%;
            margin-left: -23px;
            bottom: 60px;
        }

        &.overflow {
            ${ScrollBar}
            height: 350px;
        }
    }

    .c__agent {
        position: absolute;
        width: 90%;
        margin: 0 5% 0;
        box-sizing: border-box;
    }

    .apexcharts-canvas{
        top:10px !important;
    }
`;

import { ReactComponent as Quest } from "@assets/icon/icon-quest.svg";

import useCounting from "@hooks/useCounting";
import { IMainCard } from "types/etcTypes";
import { Wrap } from "./style";

const Card = ({ title, highRow, percentage, num, imgSrc, description }: IMainCard) => {
    const checkNumber = num !== 0 ? num : 0;
    const { count } = useCounting(checkNumber, true, 10);

    return (
        <Wrap>
            <div>
                <div className="c__first">
                    <div className="p__title">
                        <p>{title}</p>
                        <div className="wrap_btn">
                            <button>
                                <Quest />
                            </button>
                            <div className="box_cloud">{description}</div>
                        </div>
                    </div>
                    {/* <div className="p__percentage">
                        {highRow === "high" && <Up />}
                        {highRow === "row" && <Down />}
                        <p className="text__percentage">{percentage} %</p>
                    </div> */}
                </div>

                <div className="c__snd">
                    <p className="text__num">
                        {/* <span>{commaNumber(num)}</span>건 */}
                        <span>{count}</span>건
                    </p>
                    <div>
                        <img src={imgSrc} alt={title} />
                    </div>
                </div>
            </div>
        </Wrap>
    );
};

export default Card;

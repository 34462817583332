import { patentPiaCateAtom } from "@atoms";
import { useFetchTwoOrThirdDepth } from "@queries/Patentpia/CatePop";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

const useCate = (id?: string, depth?: number) => {
    const [patentPiaCate] = useAtom(patentPiaCateAtom);
    const [didMount, setDidMount] = useState(false);
    const {
        data: depthData,
        isLoading: depthLoading,
        refetch: getData,
        isFetching: depthFetching,
    } = useFetchTwoOrThirdDepth(patentPiaCate, id ? id : "", depth ? depth : 0);

    useEffect(() => setDidMount(true), []);
    useEffect(() => {
        if (didMount && id !== undefined) getData();
    }, [didMount, getData, id, patentPiaCate.cate]);

    return {
        depthData,
        depthLoading,
        getData,
        depthFetching,
    };
};

export default useCate;

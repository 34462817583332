import { Link, Outlet, useLocation } from "react-router-dom";

import Layout from "@components/Layout";

import BannerLogo from "@assets/img/tech-banner-logo-new.jpg";
import BannerImg from "@assets/img/tech-banner.png";

import { ReactComponent as CopyIcon } from "@assets/icon/copy-icon.svg";
import { ReactComponent as HeartIcon } from "@assets/icon/heart-icon.svg";
import { ReactComponent as HomeIcon } from "@assets/icon/home-icon.svg";

import Banners from "@components/Banners";

const TechStrom = () => {
    const { pathname } = useLocation();

    return (
        <Layout>
            <Banners bannerimg={BannerImg} bannerlogo={BannerLogo}>
                <div className="c__bannerText">
                    <div className="c__logwrap">
                        <img src={BannerLogo} alt="logo" style={{ borderRadius: "10px" }} />
                    </div>
                    <div className="c__textwrap">
                        <p className="p__title">공공/민간 기술거래의 새로운 패러다임.</p>
                        <p className="p__desc">
                            인증서 증명을 통해 기술소유자의 가격정보, 상승가치 등 전체 거래 내역까지 빠르게
                            확인할수있으며,
                            <br />
                            가장 편하고 안전한 디지털 기술거래 플랫폼 서비스를 제공합니다.
                        </p>
                    </div>
                </div>

                <ul className="item__menu">
                    <li className={`${pathname.includes("/apps/tech/trade") && "active"}`}>
                        <Link to="/apps/tech/trade">기술거래</Link>
                    </li>
                    <li className={`${pathname.includes("/apps/tech/nanum") && "active"}`}>
                        <Link to="/apps/tech/nanum">기술나눔</Link>
                    </li>
                    <li className={`${pathname.includes("/apps/tech/agent") && "active"}`}>
                        <Link to="/apps/tech/agent">기술거래사</Link>
                    </li>
                </ul>

                <ul className="item__icons">
                    <li>
                        <HeartIcon />
                    </li>
                    <li>
                        <HomeIcon />
                    </li>
                    <li>
                        <CopyIcon />
                    </li>
                </ul>
            </Banners>

            <Outlet />
        </Layout>
    );
};

export default TechStrom;

import FireIcon from "@assets/icon/icon-fire.svg";
import CloseIcon from "@assets/img/icon-black-close.png";
import { device } from "@assets/index";
import { ScrollBar } from "@styles/common";
import { motion } from "framer-motion";
import { styled } from "styled-components";

export const Wrap = styled(motion.div)`
    z-index: 5;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .l__popUp {
        position: relative;
        overflow: hidden;
        width: 1270px;
        background-color: #fff;
        border-radius: 9px;

        @media ${device.tabletL} {
            overflow-y: scroll;
            width: 90%;
            height: 90vh;
        }

        .c__header {
            display: flex;
            justify-content: space-between;
            padding: 24px 24px 24px;
            border-bottom: 1px solid #cccccc;
            box-sizing: border-box;

            strong {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #000;
            }
            button {
                width: 11px;
                height: 11px;
                background: url(${CloseIcon}) no-repeat 50% 50%;
                background-size: cover;
            }
        }

        .l__cont {
            display: grid;
            grid-template-columns: 33.333% 66.666%;
            padding: 16px 16px 68px;
            box-sizing: border-box;

            @media ${device.tabletL} {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .l__list {
            @media ${device.tabletL} {
                & + .l__list {
                    margin-top: 16px;
                }
            }

            .text__title {
                display: block;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #333;
                padding-bottom: 16px;
                border-bottom: 1px solid #ccc;
            }

            .c__list,
            .outer__list {
                padding-right: 7px;
                box-sizing: border-box;

                .inner {
                    height: 370px;
                    padding-right: 6px;
                    row-gap: 4px;
                    ${ScrollBar}

                    @media ${device.tabletL} {
                        max-height: 200px;
                    }
                }

                .list__third {
                    display: flex;
                    flex-direction: column;
                    height: 370px;
                    padding-right: 6px;
                    ${ScrollBar}

                    @media ${device.tabletL} {
                        max-height: 200px;
                    }

                    li {
                        position: relative;
                        margin-top: 4px;

                        &:first-child {
                            margin-top: 0;
                        }

                        @media ${device.tabletL} {
                            padding-left: 5px;
                        }
                    }

                    label {
                        display: block;
                        text-align: left;
                        font-size: 0;
                        background: #fff;
                        border: 1px solid #e3e3e3;
                        cursor: pointer;

                        p {
                            display: inline-block;
                            padding: 5px 0;
                            line-height: 1.4;
                            vertical-align: middle;
                            font-family: "Noto Sans KR", sans-serif;
                            font-weight: 300;
                            font-size: 12px;
                            color: #333;
                            box-sizing: border-box;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }

                        .p__name {
                            width: 270px;
                            border-right: 1px solid #e3e3e3;
                            padding-left: 16px;
                            padding-right: 6px;
                            box-sizing: border-box;

                            @media ${device.tabletL} {
                                width: 200px;
                            }
                        }

                        .p__num1 {
                            width: 100px;
                            border-right: 1px solid #e3e3e3;
                            padding-right: 16px;
                            text-align: right;

                            @media ${device.tabletL} {
                                width: calc(100% - 240px);
                            }
                        }
                        .p__num2 {
                            width: calc(100% - 370px);
                            text-align: center;

                            @media ${device.tabletL} {
                                width: 40px;
                            }
                        }
                    }

                    input {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        opacity: 0;
                        top: 0;
                        left: 0;

                        &:checked {
                            & + label {
                                border: 1px solid #3798ff;
                            }
                        }
                    }
                }

                .b__tabs {
                    display: block;
                    padding-left: 11px;
                    text-align: left;
                    border: 1px solid #e3e3e3;
                    box-sizing: border-box;
                    cursor: pointer;
                    margin-top: 4px;

                    @media ${device.tabletL} {
                        padding-left: 5px;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &.active {
                        border: 1px solid #3798ff;
                    }

                    p {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 5px 0;
                        line-height: 1.4;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }

                    &.hot {
                        background: #f7f7f7;
                    }

                    .p__name {
                        width: 260px;
                        padding-left: 16px;
                        padding-right: 6px;
                        font-family: "Noto Sans KR", sans-serif;
                        font-weight: 300;
                        font-size: 12px;
                        color: #333;
                        border-right: 1px solid #e3e3e3;
                        box-sizing: border-box;

                        &.hot {
                            background-image: url(${FireIcon});
                            background-repeat: no-repeat;
                            background-position: 0 50%;
                        }

                        @media ${device.tabletL} {
                            width: 200px;
                        }
                    }

                    .p__num1 {
                        width: calc(100% - 290px);
                        padding-right: 14px;
                        font-family: "Noto Sans KR", sans-serif;
                        font-weight: 300;
                        font-size: 12px;
                        color: #333;
                        border-right: 1px solid #e3e3e3;
                        text-align: right;
                        box-sizing: border-box;

                        @media ${device.tabletL} {
                            width: calc(100% - 240px);
                        }
                    }

                    .p__num2 {
                        width: 30px;
                        font-family: "Noto Sans KR", sans-serif;
                        font-weight: 300;
                        font-size: 12px;
                        color: #333;
                        text-align: center;
                        box-sizing: border-box;

                        @media ${device.tabletL} {
                            width: 40px;
                        }
                    }
                }
            }
        }

        .btn_apply {
            position: absolute;
            width: 400px;
            height: 36px;
            border: 1px solid #1d25a0;
            background: #fff;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 14px;
            color: #1d25a0;
            right: 16px;
            bottom: 16px;
            box-sizing: border-box;
            transition: all 0.5s;

            @media ${device.tabletL} {
                position: relative;
                width: 90%;
                right: 0;
                left: 5%;
            }

            &:hover {
                border: 1px solid #1d25a0;
                background: #1d25a0;
                color: #fff;
            }
        }
    }

    .list_cate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        li {
            margin-left: 1px;

            &:first-child {
                margin-left: 0;
            }
        }
        button {
            width: 120px;
            height: 40px;
            background: #bcbec8;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #fff;

            @media ${device.tabletL} {
                width: 25vw;
            }

            &.active {
                background: #3b3f7c;
            }
        }
    }
`;

export const TwoDepthWrap = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;

    @media ${device.tabletL} {
        margin-top: 16px;
        grid-template-columns: repeat(1, 1fr);
    }
`;

import DraggableScrollX from "@components/DraggableScrollX";
import { ApplicantStatus } from "@components/Graph";
import NegativeValues from "@components/Graph/NegativeValues";
import ValueBased from "@components/Graph/ValueBased";
import LoadingComp from "@components/LoadingComp";
import Comment from "@pages/Build/Comment";
import { Content } from "@pages/Build/CompanySearch/style";
import { CardComp } from "@pages/Build/style";
import { CloudDesc } from "@styles/Common/style";
import useData from "../useData";

const Alls = () => {
    const { cagr, ksic1, groth, ksic2, isLoading, data } = useData("all");

    return (
        <>
            {isLoading || (data !== undefined && data.RETURN_CNT === 0) ? (
                <div className="l__loading_build">
                    <LoadingComp />
                </div>
            ) : (
                <Content>
                    <div className="l__itemReapeat2">
                        <CardComp height={400}>
                            <div className="p__titles">
                                <strong>한국시장규모 CAGR</strong>
                                <div className="wrap_btn">
                                    <button />
                                    <CloudDesc className="wrap_cloud">
                                        KSIC 산업분류(중분류) 별 최근 3개년 한국시장규모 CAGR(연간성장률)를 확인할 수
                                        있습니다. 자세히 보기 버튼을 클릭하시면 KSIC산업분류 필터를 사용하여 원하는
                                        분야의 연도별 시장규모를 탐색할 수 있습니다.
                                    </CloudDesc>
                                </div>
                            </div>
                            <div className="c__graph">
                                <DraggableScrollX>
                                    <NegativeValues data={cagr} width={1000} height={320} />
                                </DraggableScrollX>
                            </div>
                        </CardComp>

                        <CardComp height={400}>
                            <div className="p__titles">
                                <strong>특허 출원 상위 KSIC 산업분류 현황</strong>
                                <div className="wrap_btn">
                                    <button />
                                    <CloudDesc className="wrap_cloud">
                                        {" "}
                                        KSIC산업분류별로 해당 산업분야에 출원한 출원건수를 확인할 수 있습니다.
                                        자세히보기 버튼을 클릭하시면 다양한 필터를 사용하여 원하는 조건의 출원건수를
                                        파악할 수 있습니다.
                                    </CloudDesc>
                                </div>
                            </div>
                            {ksic1.data.length > 0 && (
                                <div className="c__graph overflow">
                                    <ApplicantStatus
                                        data={ksic1.data}
                                        labels={ksic1.label}
                                        xaxisTitle={"출원건수(건)"}
                                        yaxisTitle={""}
                                        height={5000}
                                    />
                                </div>
                            )}
                        </CardComp>

                        <CardComp height={400}>
                            <div className="p__titles">
                                <strong>해당산업의 기술성장단계</strong>
                                <div className="wrap_btn">
                                    <button />
                                    <CloudDesc className="wrap_cloud">
                                        대표로 제조업(C)만 보여집니다. 기술성장곡선은 연구개발 주체(출원인수)와
                                        결과물(출원건수)의 상관관계를 분석하여 기술의 성장단계를 나타냅니다. 자세한
                                        내용은 자세히보기 버튼을 클릭하여 산업별 기술성장 곡선을 확인해주세요.
                                    </CloudDesc>
                                </div>
                            </div>
                            <div className="c__chart">
                                <DraggableScrollX>
                                    {groth.length > 0 && (
                                        <ValueBased chartID={"chart2"} width={1000} height={300} data={groth} />
                                    )}
                                </DraggableScrollX>
                            </div>
                        </CardComp>

                        <CardComp height={400}>
                            <div className="p__titles">
                                <strong>KSIC 산업별 상위 특허보유기업 현황 </strong>
                                <div className="wrap_btn">
                                    <button />
                                    <CloudDesc className="wrap_cloud">
                                        대표로 제조업(C)만 보여집니다. 자세히보기 버튼을 클릭하시면 해당 산업의 출원인별
                                        출원건수를 확인할 수 있습니다.{" "}
                                    </CloudDesc>
                                </div>
                            </div>
                            <div className="c__graph overflow">
                                {ksic2.data.length > 0 && (
                                    <ApplicantStatus
                                        data={ksic2.data}
                                        labels={ksic2.label}
                                        xaxisTitle={"출원건수(건)"}
                                        yaxisTitle={"출원인"}
                                        height={1500}
                                    />
                                )}
                            </div>
                        </CardComp>
                    </div>
                    <Comment>
                        본 화면에서는 한국표준산업분류(KSIC)별로 국내 특허출원현황(공개특허기준) 및 대상기업의
                        특허출원수를 확인할 수 있습니다.
                        <br />
                        우측 상단 산업탐색 자세히보기를 클릭하시면 필터 기능을 통해 항목별로 자세히 확인할 수 있습니다.
                    </Comment>
                </Content>
            )}
        </>
    );
};

export default Alls;

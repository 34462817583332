import UnChecked from "@assets/img/checked-false.png";
import Checked from "@assets/img/checked-true.png";
import { styled } from "styled-components";

export const Wrap = styled.div`
    display: inline-block;

    input[type="checkbox"] {
        display: none;

        & + label {
            background-image: url(${UnChecked});
        }

        &:checked {
            & + label {
                background-image: url(${Checked});
            }
        }
    }

    label {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-indent: -9999999px;
    }
`;

import { dashSelectAtom } from "@atoms";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { ListRelations } from "../ListComp/style";
import { commaNumber, handleText } from "@utils/index";
import { OnlyLine } from "@components/Graph";
import PageNation from "./PageNation";

const RelatedCompanyTrend = ({ data }: any) => {
    const dashSelect = useAtomValue(dashSelectAtom);

    const OFFSET = 3;
    const SHOWPAGE = 5;
    const [pages, setPages] = useState({
        totalPage: 0,
        currentPage: 1,
    });

    const handleClick = (num: number) => {
        setPages((prev) => ({
            ...prev,
            currentPage: num,
        }));
    };

    useEffect(() => {
        if (data !== undefined && Object.keys(data).length > 0) {
            setPages((prev) => ({
                ...prev,
                totalPage: Math.round(data.list.length / SHOWPAGE),
            }));
        }
    }, [data]);

    useEffect(() => {
        setPages((prev) => ({
            ...prev,
            currentPage: 1,
        }));
    }, [dashSelect]);

    return (
        <>
            <div className="c__header">
                <strong>관련 기업 출원 추이</strong>
                {/* <button className="btn__export">Report</button> */}
            </div>
            <div className="c__list">
                {data !== undefined &&
                    Object.keys(data).length > 0 &&
                    (data.list.length === 0 ? (
                        <div className="box__empty">
                            <p>검색 결과가 없습니다.</p>
                        </div>
                    ) : (
                        <ul>
                            {data.list
                                .slice(SHOWPAGE * pages.currentPage - SHOWPAGE, SHOWPAGE * pages.currentPage)
                                .map((item: any, index: number) => (
                                    <ListRelations key={index}>
                                        <div>
                                            <strong>{handleText(item.com_nm, 16)}</strong>
                                            <ul className="p__infos">
                                                <li>{item.ksic}</li>
                                                <li>{item.com_scl}</li>
                                                <li>{item.addr}</li>
                                            </ul>
                                        </div>
                                        <div className="c__application">
                                            <div className="c__graph">
                                                <OnlyLine data={item.data.data} />
                                            </div>
                                            <div className="p__application">
                                                <p className="p__number">
                                                    {commaNumber(item.data.total)}
                                                    {item.data.total > 1000 ? "+" : ""}
                                                </p>
                                                <p className="p__unit">출원건수</p>
                                            </div>
                                        </div>
                                    </ListRelations>
                                ))}
                        </ul>
                    ))}
            </div>
            {data !== undefined && Object.keys(data).length > 0 && data.list.length > 0 && (
                <div className="c__footer">
                    <p className="text__footer">
                        <span>{data !== undefined && Object.keys(data).length > 0 && data.list.length}</span>개 결과 중{" "}
                        <span>5</span>개 표시
                    </p>

                    <PageNation
                        totalPage={pages.totalPage}
                        currentPage={pages.currentPage}
                        offset={OFFSET}
                        handleClick={handleClick}
                    />
                </div>
            )}
        </>
    );
};

export default RelatedCompanyTrend;

import Layout from "@components/Layout";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./style.scss";

const Reports = () => {
    const { pathname } = useLocation();

    return (
        <Layout>
            <div className="ReportsTab">
                <div className="ReportsTab-inner">
                    <ul className="list-tab-wrap">
                        <li className={`list-tab ${pathname === "/reports/brief" ? "active" : ""}`}>
                            <Link to="/reports/brief">산업기술 정책브리프</Link>
                        </li>
                        <li className={`list-tab ${pathname === "/reports/watch" ? "active" : ""}`}>
                            <Link to="/reports/watch">산업기술 동향워치</Link>
                        </li>
                    </ul>

                    <Outlet />
                </div>
            </div>
        </Layout>
    );
};

export default Reports;

import { postWipsMain } from "@api/mainApi";
import { getDispute, getRandd, getTransaction } from "@api/patentPia";
import { dashSelectAtom } from "@atoms";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IAtDataType, IPatentTrend, IPatentTrendData, IPatentTrendTotal } from "types/graphTypes";
import { IRandD } from "types/patentPiaDisputType";
import { IPatentPiaRes } from "types/patentPiaTypes";
import { IApplicationTrend, IAt1, ICoreCompany, IMergeAt, IOccurTrend } from "types/wipstypes";
import { IPostWipsMain, IRelatedPatent } from "~types/types";

const useDash = () => {
    const dashSelect = useAtomValue(dashSelectAtom);

    const [randNum, setRandNum] = useState<number>(0);
    const [transaction, setTransaction] = useState<number>(0);
    const [didMount, setDidMount] = useState(false);

    const [patentTrend, setPatentTrend] = useState<IPatentTrend>();
    const [patentTrendTotal, setPatentTrendTotal] = useState<IPatentTrendTotal[]>();
    const [atData, setAtData] = useState<IAtDataType>();

    const [coreCompany, setCoreCompany] = useState<ICoreCompany>();
    const [relatedPatent, setRelatedPatent] = useState<IRelatedPatent[]>();
    const [numApplications, setNumApplications] = useState<number>(0);

    const [disputeNum, setDisputeNum] = useState<string>("0");

    const { data: RandData } = useQuery(["r&d", dashSelect.id], () => getRandd(dashSelect.id), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        enabled: !!dashSelect.id,
        onSuccess(data) {
            const temp: IRandD = JSON.parse(data.RESULT);
            if (temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.length > 0) {
                setRandNum(Number(temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST[0].GOV_RND_PAT_TOT.VALUE));
            }
            if (temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.length === 0) {
                setRandNum(0);
            }
        },
        onError: (error: any) => {
            console.log(error);
        },
    });

    const { data: transactionData } = useQuery(["transaction", dashSelect.id], () => getTransaction(dashSelect.id), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
        cacheTime: 0,
        enabled: !!dashSelect.id,
        onSuccess: useCallback(
            (data: IPatentPiaRes) => {
                const temp = JSON.parse(data.RESULT);

                if (
                    temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST !== undefined ||
                    temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST !== null
                ) {
                    setTransaction(Number(temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST[0].ASNPAT_TOT.VALUE));
                }
                if (temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.length === 0) {
                    setTransaction(0);
                }
            },
            [setTransaction],
        ),
        onError: (error: any) => {
            console.log(error);
        },
    });

    // 윕스 전체
    const { mutate, isLoading } = useMutation(() => postWipsMain(dashSelect.name), {
        onSuccess: useCallback(
            (data: IPostWipsMain) => {
                setNumApplications(data.DATA[0].data_set.length === 0 ? 0 : data.DATA[0].data_set[0].pat_appl_cnt);

                // 국가별 특허 발생 추이
                const inputData: IOccurTrend[] = data.DATA[1].data_set;

                let uniqueData: number[] = [];
                inputData.forEach((item) => {
                    if (uniqueData.findIndex((existingItem) => existingItem === item.appl_yr) === -1) {
                        uniqueData.push(item.appl_yr);
                    }
                });
                const uniqueYearTemp = uniqueData.sort((a: number, b: number) => a - b);

                const resultTemp: IPatentTrendData[] = inputData.reduce(
                    (output: { name: string; data: number[] }[], item) => {
                        const existingEntry = output.find((entry) => entry.name === item.ctry_nm);
                        if (existingEntry) {
                            existingEntry.data[uniqueYearTemp.indexOf(item.appl_yr)] = item.pat_appl_cnt;
                        } else {
                            const newData: number[] = Array(uniqueYearTemp.length).fill(0);
                            newData[uniqueYearTemp.indexOf(item.appl_yr)] = item.pat_appl_cnt;
                            output.push({ name: item.ctry_nm, data: newData });
                        }
                        return output;
                    },
                    [],
                );

                const totalTemp = resultTemp.map((item) => ({
                    name: item.name,
                    data: item.data.reduce((acc: number, current: number) => acc + current, 0),
                }));

                setPatentTrendTotal(totalTemp);

                setPatentTrend({
                    label: uniqueYearTemp,
                    data: resultTemp,
                });

                // 핵심기업
                const tempCore: ICoreCompany = {
                    data: data.DATA[2].data_set.map((item: any) => Number(item.pat_appl_ratio)),
                    label: data.DATA[2].data_set.map((item: any) => item.com_nm),
                };

                setCoreCompany(tempCore);

                // 관련특허
                setRelatedPatent(data.DATA[3].data_set);

                // 관련 기업 출원 추이
                const at1: IAt1[] = data.DATA[4].data_set;
                const applicationTrend: IApplicationTrend[] = data.DATA[5].data_set;

                let uniqueAtYear: number[] = [];
                applicationTrend.forEach((element) => {
                    if (uniqueAtYear.findIndex((item) => item === element.appl_yr) === -1) {
                        uniqueAtYear.push(element.appl_yr);
                    }
                });

                const uniqueAtYearTemp = uniqueAtYear.sort((a: number, b: number) => a - b);
                const atResult = applicationTrend.reduce(
                    (result: { name: string; data: number[]; total: number }[], item: any) => {
                        const isExisting = result.find((elem) => elem.name === item.com_nm);
                        if (isExisting) {
                            isExisting.data[uniqueAtYearTemp.indexOf(item.appl_yr)] = item.pat_appl_cnt;
                        } else {
                            const newData = Array(uniqueAtYearTemp.length).fill(0);
                            newData[uniqueAtYearTemp.indexOf(item.appl_yr)] = item.pat_appl_cnt;
                            result.push({
                                name: item.com_nm,
                                data: newData,
                                total: item.all_pat_appl_cnt,
                            });
                        }
                        return result;
                    },
                    [],
                );

                const mergeAt: IMergeAt[] = at1.map((item) => {
                    const matchingData = atResult.find((item2) => item2.name === item.com_nm);
                    return { ...item, data: matchingData ? matchingData : null };
                });

                setAtData({
                    list: mergeAt,
                    label: uniqueAtYearTemp,
                });
            },
            [setNumApplications, setPatentTrendTotal, setPatentTrend, setCoreCompany, setRelatedPatent, setAtData],
        ),
    });

    // 소송정보
    const { isLoading: disputeLoading } = useQuery(
        ["dispute", "dash", dashSelect.name],
        async () => getDispute(dashSelect.originName, 25, 0),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            cacheTime: 0,
            enabled: !!dashSelect.name,
            onSuccess(data) {
                const temp = JSON.parse(data.RESULT);
                if (temp.CHART_DATA_RESULT !== null) {
                    setDisputeNum(temp.CHART_DATA_RESULT.TOTAL_COUNT);
                } else {
                    setDisputeNum("0");
                }
            },
            onError: (error: any) => {
                console.log(error);
            },
        },
    );

    const fetchData = useCallback(async () => {
        await mutate();
    }, [mutate]);

    const memoizedValues = useMemo(
        () => ({
            randNum,
            isLoading,
            patentTrend,
            atData,
            coreCompany,
            relatedPatent,
            numApplications,
            disputeNum,
            patentTrendTotal,
            transaction,
        }),
        [
            randNum,
            isLoading,
            patentTrend,
            atData,
            coreCompany,
            relatedPatent,
            numApplications,
            disputeNum,
            patentTrendTotal,
            transaction,
        ],
    );

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && dashSelect.name !== "") {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [didMount, dashSelect.name, fetchData]);

    return memoizedValues;
};

export default useDash;

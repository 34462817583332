import { Fragment } from "react";
import useMain from "../useMain";
import config from "@config";

const Traders = () => {
    const { traderLabel, traderData, traderLoading } = useMain();

    return (
        <>
            {!traderLoading && traderData !== undefined && (
                <ul className="item__agnet">
                    {traderData.data.content.map((item, index) => (
                        <li
                            key={item.techTrader.techTraderIdx}
                            className="list__agent"
                            onClick={() =>
                                window.open(`${config.TECHSTORMURL}/tta/detail/${item.techTrader.techTraderIdx}`)
                            }
                        >
                            <div>
                                <img
                                    src={
                                        item.attachList?.find((x) => x.attachCd === "MAC0101")?.fileUrl
                                            ? item.attachList?.find((x) => x.attachCd === "MAC0101")?.fileUrl
                                            : item.attachList[0].fileUrl
                                    }
                                    alt={item.name}
                                />
                            </div>
                            <div className="p__text">
                                <ul>
                                    <li className="p__name">{item.name}</li>
                                    <li className="p__belong">&nbsp;({item.techTrader.affiliateName})</li>
                                </ul>
                                <p className="p__skill">
                                    {item.techTrader.industryTechKeywords !== "" && (
                                        <span>
                                            {item.techTrader.industryTechKeywords.split(",").map((item, index) => (
                                                <Fragment key={index}>
                                                    {index > 0 && "/"}
                                                    {traderLabel.industry[item]}
                                                </Fragment>
                                            ))}
                                            {item.techTrader.scienceTechKeywords !== "" && ", "}
                                        </span>
                                    )}

                                    {item.techTrader.scienceTechKeywords !== "" && (
                                        <span>
                                            {item.techTrader.scienceTechKeywords.split(",").map((item, index) => (
                                                <Fragment key={index}>
                                                    {index > 0 && "/"}
                                                    {traderLabel.science[item]}
                                                </Fragment>
                                            ))}
                                        </span>
                                    )}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default Traders;

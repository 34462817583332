import { companyPageAtom, keyWordStorageAtom, searchCompanyListAtom } from "@atoms";
import PageNation from "@components/PageNation";
import { commaNumber } from "@utils/index";
import { useAtom, useAtomValue } from "jotai";
import { Link } from "react-router-dom";
import KeyWords from "./KeyWords";
import RelatedImg from "./RelatedImg";

export const getHighLight = (text: string, keyword: string) => {
    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
        part.toLowerCase() === keyword.toLowerCase() ? <strong key={index}>{part}</strong> : part,
    );
};

export const getAge = (date: string) => {
    if (date === "") {
        return "-";
    }
    const birthday = new Date(
        parseInt(date.substring(0, 4), 10), // 연도
        parseInt(date.substring(4, 6), 10) - 1, // 월
        parseInt(date.substring(6, 8), 10), // 일
    );

    const today = new Date(); // 현재 날짜

    let age = today.getFullYear() - birthday.getFullYear();

    return `${age}년`;
};

const Company = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const data = useAtomValue(searchCompanyListAtom);
    const [companyPage, setCompanyPage] = useAtom(companyPageAtom);

    const handlePage = (page: number) => {
        setCompanyPage((prev) => ({ ...prev, page: page }));
    };

    return (
        <div className="content-wrap Company">
            <div className="company-wrap">
                {/*데이터가 없을때*/}
                {keyWordStorage === "" ||
                    (data?.totalElements === 0 && (
                        <div className="wrap-empty">
                            <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                        </div>
                    ))}

                {/*데이터가 있을때*/}
                {data && data.content.length > 0 && (
                    <>
                        <ul className="company-list">
                            {data.content.map((item: any) => (
                                <li className="company-item" key={item.id}>
                                    <div className="card-header">
                                        <div className="title title02">
                                            <Link
                                                to={`/consearch/company/${item.id}`}
                                                className="word"
                                                title="viewdetails"
                                            >
                                                {getHighLight(item.company_name, keyWordStorage)}
                                            </Link>

                                            <ul className="info info02">
                                                {item.business_category_desc && (
                                                    <li className="info-item">{item.business_category_desc}</li>
                                                )}

                                                <li className="info-item">
                                                    {item.address ? item.address.split(" ")[0] : "-"}
                                                </li>
                                                <li className="info-item">{getAge(item.establishment_date)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p className="desc">
                                        {item.establishment_date === "" &&
                                        item.business_category_desc === "" &&
                                        item.finance_sales === null &&
                                        item.finance_operating_profit === null &&
                                        item.finance_sales_year === null
                                            ? "기업소개가 없습니다."
                                            : `
                                            ${
                                                item.establishment_date !== ""
                                                    ? `당사는 ${item.establishment_date.substring(
                                                          0,
                                                          4,
                                                      )}년 ${item.establishment_date.substring(
                                                          4,
                                                          6,
                                                      )}월 ${item.establishment_date.substring(
                                                          6,
                                                          8,
                                                      )}일에 설립한 업력 ${getAge(
                                                          item.establishment_date,
                                                      )}차 기업입니다.`
                                                    : ""
                                            }
                                            ${
                                                item.business_category_desc !== "" &&
                                                item.business_category_desc !== "대상아님"
                                                    ? `
                                            ${item.business_category_desc} 업종에 해당하는 ${
                                                item.company_size !== "" ? item.company_size : "기업"
                                            }입니다.`
                                                    : ""
                                            }
                                            ${
                                                item.employee_count !== ""
                                                    ? `당사의 직원 수는 ${item.employee_count}명입니다.`
                                                    : ""
                                            }
                                            ${
                                                item.finance_sales_year !== null &&
                                                item.finance_sales !== null &&
                                                item.finance_operating_profit !== null
                                                    ? `
                                            ${item.finance_sales_year}년도 매출은 ${commaNumber(
                                                Number(item.finance_sales),
                                            )}천원입니다. 영업이익은 ${commaNumber(
                                                Number(item.finance_operating_profit),
                                            )}천원입니다.
                                            `
                                                    : ""
                                            }

                                            `}
                                    </p>
                                    {item.category !== "" && <div className="tag">Tags : {item.category}</div>}
                                </li>
                            ))}
                        </ul>
                        {data.totalPages > 0 && (
                            <PageNation
                                totalPage={data.totalPages}
                                currentPage={companyPage.page}
                                clickPage={handlePage}
                            />
                        )}
                    </>
                )}
            </div>

            <div className="gallery-container side">
                <KeyWords />
                <RelatedImg />
            </div>
        </div>
    );
};

export default Company;

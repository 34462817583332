import GlobalStyle from "./styles/global";
import Routers from "./Routers";
import "./index.css";
import "../src/assets/scss/index.scss";

import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom } from "jotai";
import { reSizeAtom } from "@atoms";
import { useEffect } from "react";

function App() {
    const queryClient = new QueryClient();
    const [, setWidth] = useAtom(reSizeAtom);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [setWidth]);

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <Routers />
                </ThemeProvider>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </>
    );
}

export default App;

import { IGetTechIndusty, IPatchTrader, IPostWipsMain, ITrader } from "~types/types";
import { AxiosMainWips, AxiosTechStrom } from "@api/index";
import { getDate } from "@utils/index";

const today = getDate();

export const postWipsMain = async (keyword: string): Promise<IPostWipsMain> =>
    AxiosMainWips.post(`/main`, {
        inds: keyword,
        rqst_tm: today,
    }).then((res) => res.data);

export const patchTrader = async (data: IPatchTrader, page: number, size: number): Promise<ITrader> =>
    AxiosTechStrom.patch(`/techtrader/?page=${page}&size=${size}&sort=createDate,desc`, data).then((res) => res.data);

export const getTechIndusty = async (): Promise<IGetTechIndusty> =>
    AxiosTechStrom.get(`/code/industry`).then((res) => res.data);

export const getTechScience = async (): Promise<IGetTechIndusty> =>
    AxiosTechStrom.get(`/code/science`).then((res) => res.data);

import { useThirdDepth } from "@services/Patentpia/CatePop/ThirdDepth";

const ThirdDepth = ({ id }: { id: string }) => {
    const { handleRadioChange, results, selectedValue } = useThirdDepth(id);

    if (id === undefined) return null;

    return (
        <div className="outer__list">
            <ul className="list__third">
                {results.length > 0 &&
                    results.map((item) => (
                        <li key={item.id}>
                            <input
                                id={`${item.id}`}
                                type="radio"
                                name="subCate"
                                value={item.cateId}
                                checked={selectedValue === item.cateId}
                                onChange={() => handleRadioChange(item.id, item.ordLevel, item.name, item.cateId)}
                            />
                            <label htmlFor={`${item.id}`}>
                                <p className="p__name">{item.name}</p>
                                <p className="p__num1">{item.num1}</p>
                                <p className="p__num2">{item.num2}</p>
                            </label>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default ThirdDepth;

import { Wrap } from "./style";
import Card from "@pages/TechStorm/TechTrad/Card";
import { useAtomValue } from "jotai";
import { keyWordStorageAtom, searchSalesAtom } from "@atoms";

const SalesTech = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const techData = useAtomValue(searchSalesAtom);

    return (
        <Wrap>
            {/*데이터가 없을때*/}
            {
                keyWordStorage === "" || techData.content.length === 0 &&
                <div className="wrap-empty">
                    <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                </div>
            }

            {/*데이터가 있을때*/}
            {
                techData && techData.content.length > 0 &&
                <>
                    <strong className="t__title">판매기술({techData.totalElements})</strong>
                    <div className="l__list">
                        {techData.content.map((item: any) => (
                            <Card key={item.techIdx} data={item} />
                        ))}
                    </div>
                </>
            }
        </Wrap>
    );
};

export default SalesTech;

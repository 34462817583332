import useResearchers from "./useResearchers";
import LoadingComp from "@components/LoadingComp";

const ResearchersComp = () => {
    const { realData, isLoading } = useResearchers();

    return (
        <>
            {isLoading && (
                <div className="c__laoding">
                    <LoadingComp />
                </div>
            )}
            {!isLoading && (
                <ul>
                    {realData.map((item, index) => (
                        <li key={index}>
                            <div className="c__research">
                                <span className="p__index">{index + 1}.</span>
                                <span className="p__name">{item.name}</span>
                                <span className="p__company">{item.involved}</span>
                            </div>

                            <span className="p__num">{item.patCnt}</span>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default ResearchersComp;

import { device } from "@assets/index";
import { ScrollBar } from "@styles/common";
import { styled } from "styled-components";

export const Wrap = styled.div`
    padding: 0px 20px;

    .t__title {
        display: block;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #333;
        text-align: left;
    }

    .l__list {
        ${ScrollBar}
        max-height: calc(100vh - 231px);
        padding-right: 5px;
        padding-bottom: 20px;
        margin-top: 15px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 16px;
        row-gap: 16px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${device.tabletL} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.tablet} {
            overflow-x: initial;
            overflow-y: initial;
            height: auto;
            grid-template-columns: repeat(1, 1fr);
            padding-right: 0;
        }
    }

    .outer__salses {
        height: calc(100vh - 203px);

        &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

import Comment from "@pages/Build/Comment";
import { CardComp, WrapTableList } from "@pages/Build/style";
import { Content } from "../style";

import { enterIsViewAtom } from "@atoms";
import { ApplicantStatus, Pie } from "@components/Graph";
import useCounting from "@hooks/useCounting";
import { CloudDesc } from "@styles/Common/style";
import { commaNumber, popVariants } from "@utils/index";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { IPostWipsMainData } from "~types/types";

interface ICountComp {
    title: string;
    num: number;
}

const CountComp = ({ title, num }: ICountComp) => {
    const { count } = useCounting(num, true, 15);
    return (
        <div className="c__count">
            <strong>{title}</strong>
            <p>
                {count}
                <span>건</span>
            </p>
        </div>
    );
};

interface INation {
    data: number[] | [];
    label: any[] | [];
}
interface IProps {
    data: IPostWipsMainData[];
    loading: boolean;
    nation: INation;
    ipc: INation;
}

const EnterPrise = ({ data, loading, nation, ipc }: IProps) => {
    const [isView, setIsView] = useAtom(enterIsViewAtom);

    const handleResult = () => {
        if (!isView) {
            setIsView(true);
        }
    };

    return (
        <>
            {loading ? (
                <></>
            ) : (
                data !== undefined &&
                data.length > 0 && (
                    <>
                        <WrapTableList>
                            <p className="c__total">
                                Total : <span>{data[0].data_set.length}</span>건
                            </p>
                            <div className="c__table">
                                <table>
                                    <colgroup>
                                        <col width="18%" />
                                        <col width="12.5%" />
                                        <col width="12.5%" />
                                        <col width="12.5%" className="not_mobile" />
                                        <col width="32%" className="not_mobile" />
                                        <col width="12.5%" className="not_mobile" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>기업명</th>
                                            <th>기업규모 </th>
                                            <th>기업형태</th>
                                            <th className="not_mobile">종업원수</th>
                                            <th className="not_mobile">대표업종</th>
                                            <th className="not_mobile">기업소재지</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data[0].data_set.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td>{item.com_nm}</td>
                                                <td>{item.com_scl}</td>
                                                <td>{item.com_mode}</td>
                                                <td className="not_mobile">
                                                    {item.emp_cnt === null
                                                        ? "-"
                                                        : `${commaNumber(Number(item.emp_cnt))}명`}
                                                </td>
                                                <td className="not_mobile">{item.ksic}</td>
                                                <td className="not_mobile">{item.com_rgn}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="c__btn">
                                <button onClick={handleResult}>결과보기</button>
                            </div>
                        </WrapTableList>

                        <Content>
                            <AnimatePresence>
                                {isView && (
                                    <motion.div
                                        variants={popVariants}
                                        initial="initial"
                                        animate="visible"
                                        exit="leaving"
                                        className="l__items"
                                    >
                                        <div className="c__has">
                                            <CountComp
                                                title={"특허출원 건수(건)"}
                                                num={
                                                    data[0].data_set[0].pat_appl_cnt
                                                        ? data[0].data_set[0].pat_appl_cnt
                                                        : 0
                                                }
                                            />
                                            <CountComp
                                                title={"특허등록 건수(건)"}
                                                num={
                                                    data[0].data_set[0].pat_grt_cnt
                                                        ? data[0].data_set[0].pat_grt_cnt
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <CardComp height={400}>
                                            <div className="p__titles">
                                                <strong>국가별 특허출원 현황</strong>
                                                <div className="wrap_btn">
                                                    <button />
                                                    <CloudDesc className="wrap_cloud">
                                                        선택 기업의 특허청 국가별 특허출원 현황을 확인할 수 있습니다.
                                                    </CloudDesc>
                                                </div>
                                            </div>
                                            <div className="c__pie">
                                                {Object.keys(nation).includes("data") &&
                                                Object.keys(nation).includes("label") ? (
                                                    <Pie
                                                        data={nation.data}
                                                        labels={nation.label}
                                                        width={430}
                                                        isLegend={true}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </CardComp>
                                        <CardComp height={400}>
                                            <div className="p__titles">
                                                <strong>IPC특허분류별 출원건수</strong>
                                                <div className="wrap_btn">
                                                    <button />
                                                    <CloudDesc className="wrap_cloud">
                                                        선택 기업의 IPC특허분류별 출원건수를 확인 할 수 있습니다.
                                                    </CloudDesc>
                                                </div>
                                            </div>
                                            <div>
                                                {Object.keys(ipc).includes("data") &&
                                                Object.keys(ipc).includes("label") ? (
                                                    <ApplicantStatus
                                                        data={ipc.data}
                                                        labels={ipc.label}
                                                        xaxisTitle={"출원건수(건)"}
                                                        yaxisTitle={"IPC특허분류"}
                                                        height={320}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </CardComp>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </Content>
                    </>
                )
            )}
            <Comment>
                본 화면에서는 우측상단 검색박스를 통해 국내 100대 기업에 대한 간단한 기업정보와 국내외 5개국(한국, 미국,
                일본, 유럽, 중국)의 특허출원현황(공개특허기준)을 살펴볼 수 있습니다.
                <br />
                우측 상단 기업검색 자세히보기를 클릭하시면 더 많은 기업정보(특허보유기업, 외감기업 기준)를 확인하실 수
                있습니다.
            </Comment>
        </>
    );
};

export default EnterPrise;

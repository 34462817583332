import { menuCategoriesData } from "@constants/categories";
import { useLayoutMenu } from "@services/Layout/Menu";
import { maxPopVariants } from "@utils/index";
import { AnimatePresence, motion } from "framer-motion";
import SMenu from "../SMenu";
import AppsMenu from "./AppsMenu";
import Logo from "./Logo";
import OtherMenu from "./OtherMenu";
import { Wrap } from "./style";

const Menu = ({ $iswide }: Record<string, boolean>) => {
    const { handleMenu, isVisible, menuState, pathname } = useLayoutMenu();

    return (
        <Wrap $iswide={$iswide}>
            {$iswide && (
                <>
                    <Logo />
                    <p className="p__menu">MENU</p>
                    <ul className="item-menu">
                        {menuCategoriesData.map((categories) => {
                            return categories.app ? (
                                <li key={categories.app[0].id}>
                                    <button
                                        className={`b__menu apps ${
                                            menuState.apps || pathname.includes("apps") ? `active` : ``
                                        }`}
                                        data-name="apps"
                                        onClick={handleMenu}
                                    >
                                        Apps
                                        <span className="p__arrow" />
                                    </button>
                                    <AnimatePresence>
                                        {menuState.apps && (
                                            <motion.div
                                                className="motion__wrap"
                                                variants={maxPopVariants}
                                                initial={isVisible ? "initial" : "visible"}
                                                animate="visible"
                                                exit="leaving"
                                            >
                                                <ul className="list__2depth">
                                                    {categories.app.map((apps) => (
                                                        <AppsMenu
                                                            key={apps.id}
                                                            id={apps.id}
                                                            includesVal={apps.includesVal}
                                                            label={apps.label}
                                                            path={apps.path}
                                                            pathname={pathname}
                                                            state={apps.state}
                                                        />
                                                    ))}
                                                </ul>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </li>
                            ) : (
                                <OtherMenu
                                    key={categories.id}
                                    id={categories.id}
                                    includesVal={categories.includesVal}
                                    label={categories.label}
                                    path={categories.path}
                                    pathname={pathname}
                                    className={categories.className}
                                />
                            );
                        })}
                    </ul>
                </>
            )}

            {!$iswide && <SMenu menuState={menuState} />}
        </Wrap>
    );
};

export default Menu;

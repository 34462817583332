import { patentPiaCates, patentPiaCatesTemp } from "@atoms";
import useCate from "@hooks/useCate";
import { ICate } from "@hooks/usePatentPia";
import useTabs from "@hooks/useTabs";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { IResults } from "~types/Patentpia";
import { ICateGoryParse } from "~types/patentPiaTypes";

export const useTwoDepth = (cateId: string) => {
    const [cates] = useAtom(patentPiaCates);
    const [, setCatesTemp] = useAtom(patentPiaCatesTemp);

    const { depthData, depthLoading, depthFetching } = useCate(cateId, 2);
    const [results, setResults] = useState<IResults[] | []>([]);
    const [cate, setCate] = useState<ICate[]>([]);
    const [initNum, setInitNum] = useState<number | string>("x");

    useEffect(() => {
        if (!depthLoading && !depthFetching) {
            if (depthData !== undefined) {
                const temp: ICateGoryParse = JSON.parse(depthData.RESULT);
                setCate(temp.LOWER_CATE_INFO);
                changeItem("x");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depthLoading, depthFetching]);

    useEffect(() => {
        if (cate.length > 0) {
            const temp = cate.map((item, index) => ({
                id: index,
                cateId: item.CATE_ID,
                name: item.CATE,
                num1: item.PAT_CNT_TOT,
                num2: item.SUB_CATE_CNT,
                hot: item.GRADE === "S" ? true : false,
                data: item.CATE_ID,
                ordLevel: item.ORD_LEVEL,
            }));

            setResults(temp);
        }
    }, [cate]);

    useEffect(() => {
        if (cates["depth2"].cateId !== "" && results.length > 0) {
            const index = results.findIndex((item) => item.cateId === cates["depth2"].cateId);
            setInitNum(index);
        } else {
            setInitNum("x");
        }
    }, [results, cates]);

    const { currentItem, changeItem, currentIndex } = useTabs(initNum, results);

    const handleClick = (id?: number, level?: number, name?: string, cateId?: string) => {
        if (id !== undefined && level !== undefined && name !== undefined && cateId !== undefined) {
            changeItem(id);
            setCatesTemp((prev) => ({
                ...prev,
                depth2: { name: name, cateId: cateId },
                depth3: { name: "", cateId: "" },
            }));
        }
    };
    return {
        results,
        handleClick,
        currentIndex,
        currentItem,
    };
};

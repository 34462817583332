import ThirdDepth from "@pages/Patentpia/CatePop/ThirdDepth";
import { useTwoDepth } from "@services/Patentpia/CatePop/TwoDepth";
import { TwoDepthWrap } from "../style";

const TwoDepth = ({ cateId }: { cateId: string }) => {
    const { currentIndex, currentItem, results, handleClick } = useTwoDepth(cateId);

    return (
        <TwoDepthWrap>
            <div className="l__list l__grid__colums">
                {/* <strong className="text__title">Step 2. 소재물질분야</strong> */}

                <div className="c__list">
                    <div className="inner">
                        {results.length > 0 &&
                            results.map((item) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleClick(item.id, item.ordLevel, item.name, item.cateId)}
                                    className={`b__tabs ${currentIndex === item.id ? "active" : ""} ${
                                        item.hot ? "hot" : ""
                                    }`}
                                >
                                    <p className={`p__name ${item.hot ? "hot" : ""}`}>{item.name}</p>
                                    <p className="p__num1">{item.num1}</p>
                                    <p className="p__num2">{Number(item.num2) > 0 ? `+${item.num2}` : item.num2}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            <div className="l__list l__grid__colums">
                {/* <strong className="text__title">Step 3. 제품부품 분야</strong> */}
                {currentIndex === "x" ? "" : <ThirdDepth id={currentItem?.data} />}
            </div>
        </TwoDepthWrap>
    );
};

export default TwoDepth;

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import GraphComp from "@pages/Patentpia/Common/GraphComp";
import { Wrap } from "@pages/Patentpia/Common/style";
import { CardComp, CardSlideComp, CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { useEffect, useState } from "react";
import { handleDataUrl } from "../Common/utils";

const groupCode = "11_00";

interface IUrs {
    title: string;
    url: string;
    type: string;
    chartGroup: string;
    sourceUrl: string;
}

const HEIGHT = 600;

const strings = [
    "해당 기술분야별 우리나라 핵심 연구자별 년도별 특허 출원 공개 동향",
    "해당 기술분야별 우리나라 핵심 연구자별 특허 출원수",
    "해당 기술분야별 우리나라 핵심 연구자별 년도별 점유율 동향",
    "해당 기술분야별 우리나라 핵심 연구자별 점유율",
    "해당 기술분야별 우리나라 전문성 높은 연구자별 특허수 vs. 연구자의 집중률(연구자의 전체 특허수 대비 해당 기술분야의 특허수)",
    "해당 기술분야별 우리나라 전문성 높은 연구자별 특허수 vs. 연구자의 집중률(연구자의 전체 특허수 대비 해당 기술분야의 특허수)",
    "해당 기술분야별 우리나라 전문성 높은 연구자별 특허 출원수",
    "해당 기술분야별 우리나라 전문성 높은 연구자별 특허 출원수",
];

const Reseacher = () => {
    const { dashTemp, dashTempLoading } = usePatentPia({ groupCode });
    const [urls, setUrls] = useState<IUrs[]>([]);

    useEffect(() => {
        if (dashTemp !== undefined) {
            const dataUrl = handleDataUrl(dashTemp);
            setUrls(dataUrl);
        }
    }, [dashTemp]);

    return (
        <Wrap>
            {urls.length === 0 && dashTempLoading ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                urls !== undefined &&
                urls.length > 0 && (
                    <div className="l__cont two">
                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[0].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[0]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[0].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="p__title"></div>
                            <div className="inner">
                                <GraphComp url={urls[0].url} type={urls[0].type} chartGroup={urls[0].chartGroup} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[1].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[1]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[1].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(1, 3).map((item, index) => (
                                        <SwiperSlide key={`1${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`1${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardSlideComp height={HEIGHT}>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(3, 5).map((item, index) => (
                                        <SwiperSlide key={`3${index}`}>
                                            <GraphWrapper>
                                                <div className="p__titles">
                                                    <strong>{item.title}</strong>
                                                    {index === 0 && (
                                                        <div className="wrap_btn">
                                                            <button />
                                                            <CloudDesc className="wrap_cloud">{strings[2]}</CloudDesc>
                                                        </div>
                                                    )}
                                                </div>
                                                <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                                    데이터 출처
                                                </SoureceMoveBtn>
                                            </GraphWrapper>
                                            <div className="inner">
                                                <GraphComp
                                                    url={item.url}
                                                    type={item.type}
                                                    chartGroup={item.chartGroup}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardSlideComp height={HEIGHT}>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(5, 7).map((item, index) => (
                                        <SwiperSlide key={`5${index}`}>
                                            <GraphWrapper>
                                                <div className="p__titles">
                                                    <strong>{item.title}</strong>
                                                    {index === 0 && (
                                                        <div className="wrap_btn">
                                                            <button />
                                                            <CloudDesc className="wrap_cloud">{strings[3]}</CloudDesc>
                                                        </div>
                                                    )}
                                                </div>
                                                <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                                    데이터 출처
                                                </SoureceMoveBtn>
                                            </GraphWrapper>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[7].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[4]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[7].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner pie">
                                <GraphComp url={urls[7].url} type={urls[7].type} isLegend={true} width={600} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[8].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[5]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[8].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(8, 10).map((item, index) => (
                                        <SwiperSlide key={`8${index}`}>
                                            <div className="inner">
                                                <GraphComp
                                                    url={item.url}
                                                    type={item.type}
                                                    chartGroup={item.chartGroup}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        <CardComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[10].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[6]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[10].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner pie">
                                <GraphComp url={urls[10].url} type={urls[10].type} />
                            </div>
                        </CardComp>

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[11].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[7]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[11].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(11, 13).map((item, index) => (
                                        <SwiperSlide key={`11${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>
                    </div>
                )
            )}
        </Wrap>
    );
};

export default Reseacher;

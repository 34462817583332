import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

import { cateBtns } from "@constants/categories";
import TwoDepth from "@pages/Patentpia/CatePop/TwoDepth";
import { useCatePop } from "@services/Patentpia/CatePop";
import { popVariants } from "@utils/index";
import { IPop, IResults } from "~types/Patentpia";
import { Wrap } from "./style";

const CatePop = ({ func }: IPop) => {
    const [results, setResults] = useState<IResults[] | []>([]);

    const { cate, currentIndex, currentItem, patentPiaCate, handleApply, handleClick, handleSelectCate } = useCatePop({
        results,
        setResults,
    });

    useEffect(() => {
        if (cate.length > 0) {
            const temp = cate.map((item, index) => ({
                id: index,
                cateId: item.CATE_ID,
                name: item.CATE,
                num1: item.PAT_CNT_TOT,
                num2: item.SUB_CATE_CNT,
                hot: item.GRADE === "S" ? true : false,
                data: <TwoDepth cateId={item.CATE_ID} />,
                ordLevel: item.ORD_LEVEL,
            }));

            setResults(temp);
        }
    }, [cate]);

    return (
        <AnimatePresence>
            <Wrap variants={popVariants} initial="initial" animate="visible" exit="leaving">
                <div className="l__popUp">
                    <div className="c__header">
                        <strong>분야별카테고리</strong>
                        <button onClick={func} />
                    </div>

                    <ul className="list_cate">
                        {cateBtns.map((item) => (
                            <li key={item.id}>
                                <button
                                    data-id={item.id}
                                    onClick={handleSelectCate}
                                    className={patentPiaCate.cate === item.id ? "active" : ""}
                                >
                                    {item.name}
                                </button>
                            </li>
                        ))}
                    </ul>

                    {/* 1depth */}
                    <div className="l__cont">
                        <div className="l__list">
                            <div className="c__list">
                                <div className="inner">
                                    {results.length > 0 &&
                                        results.map((item: any) => (
                                            <div
                                                key={item.id}
                                                onClick={() =>
                                                    handleClick(item.id, item.ordLevel, item.name, item.cateId)
                                                }
                                                className={`b__tabs ${currentIndex === item.id ? "active" : ""} ${
                                                    item.hot ? "hot" : ""
                                                }`}
                                            >
                                                <p className={`p__name ${item.hot ? "hot" : ""}`}>{item.name}</p>
                                                <p className="p__num1">{item.num1}</p>
                                                <p className="p__num2">
                                                    {Number(item.num2) > 0 ? `+${item.num2}` : item.num2}
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>

                        {/* 2, 3 depth */}
                        <div>{currentItem?.data}</div>
                    </div>

                    <button className="btn_apply" onClick={() => handleApply({ func })}>
                        적용
                    </button>
                </div>
            </Wrap>
        </AnimatePresence>
    );
};

export default CatePop;

import Layout from "@components/Layout";
import { Link } from "react-router-dom";
import { SearchIcon } from "@assets/svg/InterfacesIcon";
import { PrevIcon } from "@assets/svg/InterfacesIcon";
import { PrevIcon02 } from "@assets/svg/InterfacesIcon";
import { Wrap } from "@pages/Build/style";
import { MoreIcon } from "@assets/svg/InterfacesIcon";
import { getByDisplayValue } from "@testing-library/react";

import galleryImg01 from "@assets/img/gallery-img01.png";
import galleryImg02 from "@assets/img/gallery-img02.png";
import galleryImg03 from "@assets/img/gallery-img03.png";
import galleryImg04 from "@assets/img/gallery-img04.png";
import galleryImg05 from "@assets/img/gallery-img05.png";
import galleryImg06 from "@assets/img/gallery-img06.png";
import galleryImg07 from "@assets/img/gallery-img07.png";
import galleryImg08 from "@assets/img/gallery-img08.png";
import galleryImg09 from "@assets/img/gallery-img09.png";
import galleryImg10 from "@assets/img/gallery-img10.png";
import galleryImg11 from "@assets/img/gallery-img11.png";
import galleryImg12 from "@assets/img/gallery-img12.png";
import galleryImg13 from "@assets/img/gallery-img13.png";

const Search = () => {
    return (
        <Layout>
            {/* 특허 */}
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                {/* 탭부분(라우팅) */}
                                <div className="c__header">
                                    <div className="Tab">
                                        <Link to={"/"} className="Tab-item active">
                                            특허(10,000)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            기업(1,340)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            판매기술(1,103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            연구자(800)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            소송정보(103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            뉴스(2,034)
                                        </Link>
                                    </div>
                                </div>
                                <div className="content-wrap Patent">
                                    <div className="company-wrap">
                                        <ul className="company-list">
                                            <li className="company-item">
                                                <div className="card-header">
                                                    <div className="title">
                                                        <span className="word" title="viewdetails">
                                                            리튬<strong>2차전지</strong>
                                                        </span>
                                                        <ul className="info">
                                                            <li className="info-item">양극활물질</li>
                                                            <li className="info-item">제조방법</li>
                                                            <li className="info-item">양이온교환반응</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="desc">
                                                    본 발명은 비정상적 전압 강하 현상이 개선된 리튬 이차전지에 관한
                                                    것이다. 상기 리튬 이차전지는 음극 활물질을 포함하는 음극, 하기
                                                    화학식 1로 표시되는 양극 활물질을 포함하는 양극, 상기 음극 및 양극
                                                    사이에 개재된 세퍼레이터 및 비수전해액을 포함하며, 상기 비수전해액은
                                                    리튬염; 비수성 유기용매; 제1 첨가제로 하기 화학식 2로 표시되는
                                                    화합물; 및 제2
                                                </p>
                                                <div className="tag">
                                                    <p className="company">주식회사 엘지에너지솔루션</p>
                                                    <p className="date">2022-12-17</p>
                                                </div>
                                            </li>
                                            <li className="company-item">
                                                <div className="card-header">
                                                    <div className="title">
                                                        <span className="word" title="viewdetails">
                                                            리튬<strong>2차전지</strong>
                                                        </span>
                                                        <ul className="info">
                                                            <li className="info-item">양극활물질</li>
                                                            <li className="info-item">제조방법</li>
                                                            <li className="info-item">양이온교환반응</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="desc">
                                                    본 발명은 비정상적 전압 강하 현상이 개선된 리튬 이차전지에 관한
                                                    것이다. 상기 리튬 이차전지는 음극 활물질을 포함하는 음극, 하기
                                                    화학식 1로 표시되는 양극 활물질을 포함하는 양극, 상기 음극 및 양극
                                                    사이에 개재된 세퍼레이터 및 비수전해액을 포함하며, 상기 비수전해액은
                                                    리튬염; 비수성 유기용매; 제1 첨가제로 하기 화학식 2로 표시되는
                                                    화합물; 및 제2
                                                </p>
                                                <div className="tag">
                                                    <p className="company">주식회사 엘지에너지솔루션</p>
                                                    <p className="date">2022-12-17</p>
                                                </div>
                                            </li>
                                            <li className="company-item">
                                                <div className="card-header">
                                                    <div className="title">
                                                        <span className="word" title="viewdetails">
                                                            리튬<strong>2차전지</strong>
                                                        </span>
                                                        <ul className="info">
                                                            <li className="info-item">양극활물질</li>
                                                            <li className="info-item">제조방법</li>
                                                            <li className="info-item">양이온교환반응</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="desc">
                                                    본 발명은 비정상적 전압 강하 현상이 개선된 리튬 이차전지에 관한
                                                    것이다. 상기 리튬 이차전지는 음극 활물질을 포함하는 음극, 하기
                                                    화학식 1로 표시되는 양극 활물질을 포함하는 양극, 상기 음극 및 양극
                                                    사이에 개재된 세퍼레이터 및 비수전해액을 포함하며, 상기 비수전해액은
                                                    리튬염; 비수성 유기용매; 제1 첨가제로 하기 화학식 2로 표시되는
                                                    화합물; 및 제2
                                                </p>
                                                <div className="tag">
                                                    <p className="company">주식회사 엘지에너지솔루션</p>
                                                    <p className="date">2022-12-17</p>
                                                </div>
                                            </li>
                                            <li className="company-item">
                                                <div className="card-header">
                                                    <div className="title">
                                                        <span className="word" title="viewdetails">
                                                            리튬<strong>2차전지</strong>
                                                        </span>
                                                        <ul className="info">
                                                            <li className="info-item">양극활물질</li>
                                                            <li className="info-item">제조방법</li>
                                                            <li className="info-item">양이온교환반응</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="desc">
                                                    본 발명은 비정상적 전압 강하 현상이 개선된 리튬 이차전지에 관한
                                                    것이다. 상기 리튬 이차전지는 음극 활물질을 포함하는 음극, 하기
                                                    화학식 1로 표시되는 양극 활물질을 포함하는 양극, 상기 음극 및 양극
                                                    사이에 개재된 세퍼레이터 및 비수전해액을 포함하며, 상기 비수전해액은
                                                    리튬염; 비수성 유기용매; 제1 첨가제로 하기 화학식 2로 표시되는
                                                    화합물; 및 제2
                                                </p>
                                                <div className="tag">
                                                    <p className="company">주식회사 엘지에너지솔루션</p>
                                                    <p className="date">2022-12-17</p>
                                                </div>
                                            </li>
                                            <li className="company-item">
                                                <div className="card-header">
                                                    <div className="title">
                                                        <span className="word" title="viewdetails">
                                                            리튬<strong>2차전지</strong>
                                                        </span>
                                                        <ul className="info">
                                                            <li className="info-item">양극활물질</li>
                                                            <li className="info-item">제조방법</li>
                                                            <li className="info-item">양이온교환반응</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="desc">
                                                    본 발명은 비정상적 전압 강하 현상이 개선된 리튬 이차전지에 관한
                                                    것이다. 상기 리튬 이차전지는 음극 활물질을 포함하는 음극, 하기
                                                    화학식 1로 표시되는 양극 활물질을 포함하는 양극, 상기 음극 및 양극
                                                    사이에 개재된 세퍼레이터 및 비수전해액을 포함하며, 상기 비수전해액은
                                                    리튬염; 비수성 유기용매; 제1 첨가제로 하기 화학식 2로 표시되는
                                                    화합물; 및 제2
                                                </p>
                                                <div className="tag">
                                                    <p className="company">주식회사 엘지에너지솔루션</p>
                                                    <p className="date">2022-12-17</p>
                                                </div>
                                            </li>
                                        </ul>

                                        {/* <div className="Pagination-wrap"> */}
                                        <ul className="Pagination">
                                            <li className="Pagination-item prev">
                                                <PrevIcon />
                                            </li>
                                            <li className="Pagination-item active">1</li>
                                            <li className="Pagination-item">2</li>
                                            <li className="Pagination-item">3</li>
                                            <li className="Pagination-item">4</li>
                                            <li className="Pagination-item">5</li>
                                            <li className="Pagination-item next">
                                                <PrevIcon />
                                            </li>
                                        </ul>
                                        {/* </div> */}
                                    </div>

                                    <div className="gallery-container">
                                        <p className="sub-header">관련기술도면(238)</p>

                                        <div className="scroll-wrap">
                                            <ul className="gallery-list">
                                                <li>
                                                    <img src={galleryImg01} alt="blog post one" />
                                                    <h2>titlename01</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg02} alt="blog post two" />
                                                    <h2>titlename02</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg03} alt="blog post three" />
                                                    <h2>titlename03</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg04} alt="blog post four" />
                                                    <h2>titlename04</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg05} alt="blog post five" />
                                                    <h2>titlename05</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg06} alt="blog post six" />
                                                    <h2>titlename06</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg07} alt="blog post seven" />
                                                    <h2>titlename07</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg08} alt="blog post eight" />
                                                    <h2>titlename08</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg09} alt="blog post nine" />
                                                    <h2>titlename09</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg10} alt="blog post 10" />
                                                    <h2>titlename10</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg11} alt="blog post 11" />
                                                    <h2>titlename12</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg12} alt="blog post 12" />
                                                    <h2>titlename12</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                <li>
                                                    <img src={galleryImg13} alt="blog post 13" />
                                                    <h2>titlename13</h2>
                                                    <figcaption>KR2023847238904782</figcaption>
                                                </li>

                                                {/* <li>
                                                    <img
                                                        src="https://res.cloudinary.com/dkfptto8m/image/upload/v1674344276/logrocket-css-masonry/3.jpg"
                                                        alt="blog post nine"
                                                    />
                                                    <h2>titlename12</h2>
                                                    <figcaption>
                                                        KR2023847238904782
                                                    </figcaption>
                                                </li> */}
                                            </ul>
                                            {/* gallery-list */}
                                        </div>
                                        {/* gallery-list */}

                                        <button type="button" className="btn__default btn__more">
                                            더보기
                                            <MoreIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* listCard-content */}
                        </div>
                        {/* listCard */}
                    </div>
                    {/* box */}
                </div>
            </Wrap>

            {/* 기업 */}
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                {/* 탭부분(라우팅) */}
                                <div className="c__header">
                                    <div className="Tab">
                                        <Link to={"/"} className="Tab-item active">
                                            특허(10,000)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            기업(1,340)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            판매기술(1,103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            연구자(800)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            소송정보(103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            뉴스(2,034)
                                        </Link>
                                    </div>
                                </div>
                                <div className="content-wrap">
                                    <ul className="company-list">
                                        <li className="company-item">
                                            <div className="card-header">
                                                <div className="title title02">
                                                    <span className="word" title="viewdetails">
                                                        한국<strong>이차전지</strong>
                                                    </span>
                                                    <ul className="info info02">
                                                        <li className="info-item">축전지 제조업</li>
                                                        <li className="info-item">경상북도</li>
                                                        <li className="info-item">7년</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="desc">
                                                당사는 2017년 06월 15일에 설립한 업력 7년차 기업입니다. 축전지 제조업
                                                업종에 해당하는 중소기업입니다. 당사의 직원 수는 36명입니다. 2022년도
                                                매출은 28,033,956 천원입니다. 영업이익은 635,593 천원입니다.
                                            </p>
                                            <div className="tag">Tags:</div>
                                        </li>
                                        <li className="company-item">
                                            <div className="card-header">
                                                <div className="title title02">
                                                    <span className="word" title="viewdetails">
                                                        한국<strong>이차전지</strong>
                                                    </span>
                                                    <ul className="info info02">
                                                        <li className="info-item">축전지 제조업</li>
                                                        <li className="info-item">경상북도</li>
                                                        <li className="info-item">7년</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="desc">
                                                당사는 2017년 06월 15일에 설립한 업력 7년차 기업입니다. 축전지 제조업
                                                업종에 해당하는 중소기업입니다. 당사의 직원 수는 36명입니다. 2022년도
                                                매출은 28,033,956 천원입니다. 영업이익은 635,593 천원입니다.
                                            </p>
                                            <div className="tag">Tags:</div>
                                        </li>
                                        <li className="company-item">
                                            <div className="card-header">
                                                <div className="title title02">
                                                    <span className="word" title="viewdetails">
                                                        한국<strong>이차전지</strong>
                                                    </span>
                                                    <ul className="info info02">
                                                        <li className="info-item">축전지 제조업</li>
                                                        <li className="info-item">경상북도</li>
                                                        <li className="info-item">7년</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="desc">
                                                당사는 2017년 06월 15일에 설립한 업력 7년차 기업입니다. 축전지 제조업
                                                업종에 해당하는 중소기업입니다. 당사의 직원 수는 36명입니다. 2022년도
                                                매출은 28,033,956 천원입니다. 영업이익은 635,593 천원입니다.
                                            </p>
                                            <div className="tag">Tags:</div>
                                        </li>
                                        {/* <div className="Pagination-wrap"> */}
                                        <ul className="Pagination">
                                            <li className="Pagination-item prev">
                                                <PrevIcon />
                                            </li>
                                            <li className="Pagination-item active">1</li>
                                            <li className="Pagination-item">2</li>
                                            <li className="Pagination-item">3</li>
                                            <li className="Pagination-item">4</li>
                                            <li className="Pagination-item">5</li>
                                            <li className="Pagination-item next">
                                                <PrevIcon />
                                            </li>
                                        </ul>
                                        {/* </div> */}
                                    </ul>

                                    <div className="gallery-container side">
                                        <div className="right">
                                            <p className="sub-header">연관키워드</p>
                                            <div className="Scroll FilterKeyword">
                                                <ul className="keyword">
                                                    <li className="keyword-item">
                                                        <span style={{ display: "block" }}>이차전지및부품</span>
                                                        <span style={{ display: "block" }}>디스플레이</span>
                                                        <span style={{ display: "block" }}>기계</span>
                                                        <span style={{ display: "block" }}>정보통신기기</span>
                                                        <span style={{ display: "block" }}>특수목적용기계</span>
                                                        <span style={{ display: "block" }}>금형부품</span>
                                                        <span style={{ display: "block" }}>태양광시스템</span>
                                                        <span style={{ display: "block" }}>자동차 이차전지소재</span>
                                                        <span style={{ display: "block" }}>공장자동화설비 개발</span>
                                                        <span style={{ display: "block" }}>
                                                            이차전지관련 정밀금형 및 정밀기계부품
                                                        </span>
                                                        <span style={{ display: "block" }}>전기공사</span>
                                                        <span style={{ display: "block" }}>출력</span>
                                                        <span style={{ display: "block" }}>이차전지장비</span>
                                                        <span style={{ display: "block" }}>화학제품</span>
                                                        <span style={{ display: "block" }}>전기자전거</span>
                                                        <span style={{ display: "block" }}>
                                                            휴대용이차전지.캠핑장비류.캠핑트레일러
                                                        </span>
                                                        <span style={{ display: "block" }}>토너 제조</span>
                                                        <span style={{ display: "block" }}>
                                                            이차전지 도금표면처리 설비판매
                                                        </span>
                                                        <span style={{ display: "block" }}>이차전지 제조</span>
                                                        <span style={{ display: "block" }}>
                                                            화학물질 및 이차전지 재료 제조업
                                                        </span>
                                                    </li>
                                                </ul>
                                                {/* keyword */}
                                            </div>
                                            {/* Scroll FilterKeyword */}
                                        </div>

                                        <p className="sub-header">관련이미지(238)</p>
                                        <div className="image">
                                            <ul className="image-list">
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/3148623186/3148623186_2532.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/3038149444/3038149444_43526_41044.png"
                                                        alt="관련이미지 "
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/1238609157/1238609157_7586.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/1178151806/1178151806_37377_34884.png"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26202_24000.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26204_24002.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26201_23999.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26194_23992.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26195_23993.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26206_24004.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26207_24005.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26208_24006.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26209_24007.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                                <li className="image-item">
                                                    <img
                                                        src="https://collect-product.s3.ap-northeast-2.amazonaws.com/2208100490/2208100490_26198_23996.jpg"
                                                        alt="관련이미지"
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn__default btn__more">
                                            더보기
                                            <MoreIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* listCard-content */}
                        </div>
                        {/* listCard */}
                    </div>
                    {/* box */}
                </div>
            </Wrap>

            {/* 연구자 */}
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                {/* 탭부분(라우팅) */}
                                <div className="c__header">
                                    <div className="Tab">
                                        <Link to={"/"} className="Tab-item active">
                                            특허(10,000)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            기업(1,340)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            판매기술(1,103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            연구자(800)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            소송정보(103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            뉴스(2,034)
                                        </Link>
                                    </div>

                                    <div className="content-wrap">
                                        <div className="c__table">
                                            <div className="thead">
                                                <div className="tr">
                                                    <div className="td num">No</div>
                                                    <div className="td name">발명자</div>
                                                    <div className="td affiliation">소속기관</div>
                                                    <div className="td openPatent">공개 특허수</div>
                                                    <div className="td registratioPatent">등록 특허수</div>
                                                    <div className="td shareOpen">점유율 공개</div>
                                                    <div className="td concentrationRate">집중률</div>
                                                    <div className="td transactionPatent">거래 특허수</div>
                                                    <div className="td referee">심판 특허수</div>
                                                    <div className="td ximsiwan">심사관 피인용수</div>
                                                    <div className="td shareFC-E">점유율 FC-E</div>
                                                    <div className="td OverseasFam">해외Fam</div>
                                                </div>
                                            </div>
                                            <div className="tbody">
                                                <div className="tr">
                                                    <div className="td num">1</div>
                                                    <div className="td name">한영희</div>
                                                    <div className="td affiliation">한국전력공사</div>
                                                    <div className="td openPatent">23</div>
                                                    <div className="td registratioPatent">18</div>
                                                    <div className="td shareOpen">1.48%</div>
                                                    <div className="td concentrationRate">34.8%</div>
                                                    <div className="td transactionPatent">-</div>
                                                    <div className="td referee">-</div>
                                                    <div className="td ximsiwan">21</div>
                                                    <div className="td shareFC-E">3.29%</div>
                                                    <div className="td OverseasFam">4</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">2</div>
                                                    <div className="td name">한상철</div>
                                                    <div className="td affiliation">한국전력공사</div>
                                                    <div className="td openPatent">22</div>
                                                    <div className="td registratioPatent">18</div>
                                                    <div className="td shareOpen">1.41%</div>
                                                    <div className="td concentrationRate">31.9%</div>
                                                    <div className="td transactionPatent">-</div>
                                                    <div className="td referee">-</div>
                                                    <div className="td ximsiwan">21</div>
                                                    <div className="td shareFC-E">3.29%</div>
                                                    <div className="td OverseasFam">2</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">3</div>
                                                    <div className="td name">현옥배</div>
                                                    <div className="td affiliation">한국전력공사</div>
                                                    <div className="td openPatent">16</div>
                                                    <div className="td registratioPatent">12</div>
                                                    <div className="td shareOpen">1.03%</div>
                                                    <div className="td concentrationRate">53.3%</div>
                                                    <div className="td transactionPatent">-</div>
                                                    <div className="td referee">-</div>
                                                    <div className="td ximsiwan">17</div>
                                                    <div className="td shareFC-E">2.66%</div>
                                                    <div className="td OverseasFam">5</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">4</div>
                                                    <div className="td name">김혜림</div>
                                                    <div className="td affiliation">한국전력공사</div>
                                                    <div className="td openPatent">16</div>
                                                    <div className="td registratioPatent">12</div>
                                                    <div className="td shareOpen">1.03%</div>
                                                    <div className="td concentrationRate">47.1%</div>
                                                    <div className="td transactionPatent">-</div>
                                                    <div className="td referee">-</div>
                                                    <div className="td ximsiwan">15</div>
                                                    <div className="td shareFC-E">1.72%</div>
                                                    <div className="td OverseasFam">6</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">5</div>
                                                    <div className="td name">정세용</div>
                                                    <div className="td affiliation">한국전력공사</div>
                                                    <div className="td openPatent">16</div>
                                                    <div className="td registratioPatent">13</div>
                                                    <div className="td shareOpen">1.03%</div>
                                                    <div className="td concentrationRate">40%</div>
                                                    <div className="td transactionPatent">-</div>
                                                    <div className="td referee">-</div>
                                                    <div className="td ximsiwan">11</div>
                                                    <div className="td shareFC-E">2.66%</div>
                                                    <div className="td OverseasFam">1</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* c__table */}
                                    </div>
                                </div>
                            </div>
                            {/* listCard-content */}

                            {/* <div className="Pagination-wrap"> */}
                            <ul className="Pagination">
                                <li className="Pagination-item prev">
                                    <PrevIcon />
                                </li>{" "}
                                {/* 포토샵에 아이콘svg 없음 */}
                                <li className="Pagination-item active">1</li>
                                <li className="Pagination-item">2</li>
                                <li className="Pagination-item">3</li>
                                <li className="Pagination-item">4</li>
                                <li className="Pagination-item">5</li>
                                <li className="Pagination-item next">
                                    <PrevIcon />
                                </li>
                            </ul>
                            {/* </div> */}
                        </div>
                        {/* list-card */}
                    </div>
                    {/* box */}
                </div>
            </Wrap>

            {/* 소송정보 */}
            <Wrap className="search-wrap">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                {/* 탭부분(라우팅) */}
                                <div className="c__header">
                                    <div className="Tab">
                                        <Link to={"/"} className="Tab-item active">
                                            특허(10,000)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            기업(1,340)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            판매기술(1,103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            연구자(800)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            소송정보(103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            뉴스(2,034)
                                        </Link>
                                    </div>

                                    <div className="content-wrap">
                                        <div className="c__table lawsuit">
                                            <div className="thead">
                                                <div className="tr">
                                                    <div className="td num">No</div>
                                                    <div className="td registrationNumber">등록번호</div>
                                                    <div className="td refereeNumber">심판번호</div>
                                                    <div className="td refereeName">심판의 명칭</div>
                                                    <div className="td refereeType">심판유형</div>
                                                    <div className="td claimant">청구인</div>
                                                    <div className="td respondent">피청구인</div>
                                                    <div className="td submissionDate">심판제기일</div>
                                                    <div className="td submissionYear">심판 제기연도</div>
                                                    <div className="td claimant-NPE">청구인NPE 포함 여부</div>
                                                    <div className="td respondent-NPE">피청구인 NPE 포함 여부</div>
                                                </div>
                                            </div>
                                            <div className="tbody">
                                                <div className="tr">
                                                    <div className="td num">1</div>
                                                    <div className="td registrationNumber">1014707570000</div>
                                                    <div className="td refereeNumber">2023100000335</div>
                                                    <div className="td refereeName">
                                                        특허 제1470757호 권리범위확인(소극)
                                                    </div>
                                                    <div className="td refereeType">권리범위확인(소극적)</div>
                                                    <div className="td claimant">주식회사 어반베이스</div>
                                                    <div className="td respondent">(주)아키드로우</div>
                                                    <div className="td submissionDate">2023-01-13</div>
                                                    <div className="td submissionYear">2023</div>
                                                    <div className="td claimant-NPE">-</div>
                                                    <div className="td respondent-NPE">-</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">2</div>
                                                    <div className="td registrationNumber">1013388180000</div>
                                                    <div className="td refereeNumber">2022100002755</div>
                                                    <div className="td refereeName">특허 제1338818호 무효</div>
                                                    <div className="td refereeType">무효</div>
                                                    <div className="td claimant">나이앤틱, 인크</div>
                                                    <div className="td respondent">팬텍 주식회사</div>
                                                    <div className="td submissionDate">2022-10-06</div>
                                                    <div className="td submissionYear">2022</div>
                                                    <div className="td claimant-NPE">-</div>
                                                    <div className="td respondent-NPE">-</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">3</div>
                                                    <div className="td registrationNumber">1010991370000</div>
                                                    <div className="td refereeNumber">2022100001785</div>
                                                    <div className="td refereeName">특허 제1099137호 무효</div>
                                                    <div className="td refereeType">무효</div>
                                                    <div className="td claimant">나이앤틱, 인크</div>
                                                    <div className="td respondent">팬텍 주식회사</div>
                                                    <div className="td submissionDate">2022-06-23</div>
                                                    <div className="td submissionYear">2022</div>
                                                    <div className="td claimant-NPE">-</div>
                                                    <div className="td respondent-NPE">-</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">4</div>
                                                    <div className="td registrationNumber">1013175320000</div>
                                                    <div className="td refereeNumber">2021000009044</div>
                                                    <div className="td refereeName">특허 제1338818호 무효</div>
                                                    <div className="td refereeType">무효</div>
                                                    <div className="td claimant">나이앤틱, 인크</div>
                                                    <div className="td respondent">팬텍 주식회사</div>
                                                    <div className="td submissionDate">2022-03-31</div>
                                                    <div className="td submissionYear">2022</div>
                                                    <div className="td claimant-NPE">-</div>
                                                    <div className="td respondent-NPE">-</div>
                                                </div>
                                                <div className="tr">
                                                    <div className="td num">5</div>
                                                    <div className="td registrationNumber">1010963920000</div>
                                                    <div className="td refereeNumber">2021100003199</div>
                                                    <div className="td refereeName">특허 제1099137호 무효</div>
                                                    <div className="td refereeType">무효</div>
                                                    <div className="td claimant">나이앤틱, 인크</div>
                                                    <div className="td respondent">팬텍 주식회사</div>
                                                    <div className="td submissionDate">2021-10-28</div>
                                                    <div className="td submissionYear">2022</div>
                                                    <div className="td claimant-NPE">-</div>
                                                    <div className="td respondent-NPE">-</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* c__table */}
                                    </div>
                                </div>
                            </div>
                            {/* listCard-content */}

                            {/* <div className="Pagination-wrap"> */}
                            <ul className="Pagination">
                                <li className="Pagination-item prev">
                                    <PrevIcon />
                                </li>{" "}
                                {/* 포토샵에 아이콘svg 없음 */}
                                <li className="Pagination-item active">1</li>
                                <li className="Pagination-item">2</li>
                                <li className="Pagination-item">3</li>
                                <li className="Pagination-item">4</li>
                                <li className="Pagination-item">5</li>
                                <li className="Pagination-item next">
                                    <PrevIcon />
                                </li>
                            </ul>
                            {/* </div> */}
                        </div>
                        {/* list-card */}
                    </div>
                    {/* box */}
                </div>
            </Wrap>

            {/* 뉴스 */}
            <Wrap className="search-wrap news">
                <div className="l__main">
                    <div className="box p__search">
                        <div className="list-card">
                            <div className="listCard-content">
                                {/* 탭부분(라우팅) */}
                                <div className="c__header">
                                    <div className="Tab">
                                        <Link to={"/"} className="Tab-item active">
                                            특허(10,000)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            기업(1,340)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            판매기술(1,103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            연구자(800)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            소송정보(103)
                                        </Link>
                                        <Link to={"/"} className="Tab-item">
                                            뉴스(2,034)
                                        </Link>
                                    </div>

                                    <div className="content-wrap">
                                        <ul className="news">
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                            <li className="news-item">
                                                <div className="col">
                                                    <p className="title">
                                                        국민연금 어떤 종목 담았나…건설·식음료 줄이고 조선·항공·정유 늘려
                                                    </p>
                                                    <p className="text">
                                                        "국내 최대 기관 투자자인 국민연금공단이 주식 포트폴리오 조정에
                                                        나서며 그동안 시장에서 소외된 종목들을 주워 담기 시작했다."
                                                        <b>2차전지</b>와 초전도체 테마 광풍에 개인 투자자 수급이 일부
                                                        업종에 쏠린 상황에서 국민연금은 주가 부담이 적으면서 실적 개선이
                                                        가시화되는 종목을 골라 비중을 늘리고 있다. 반면 투자 심리가
                                                        악화된 업종 비중은 줄여나가는 중이다. 최근 붕괴 사고가 발생해
                                                        투자자 외면을 받는 건설주를 비롯해 정부의 물가 안정 기조에 마진
                                                        악화가 예상되는 식음료주도 비중을 대폭 축소했다. 개인 투자자와
                                                        달리 장기 투자 성향이 짙은 국민연금이 포트폴리오를 어떻게
                                                        조정하고 있는지 투자자 관심이 집중된다.업황 반등 예상되면 비중
                                                        늘려HD현대重·에쓰오일·대한항공8월 10일 금융감독원에 따르면
                                                        국민연금은 이달 들어 36개 종목의 보유 비중을 조정한다고
                                                        공시했다. 그중 15개 종목은 비중을 확대했으며, 21개 종목 비중은
                                                        줄였다.장기간 주가가 부진했던 종목 비중을 확대한 점이 눈길을
                                                        끈다. 특히 하반기 업황이 개선될 것으로 기대되는 업종은
                                                        적극적으로 포트폴리오에 담았다.조선주가 대표적이다. 국민연금은
                                                        HD현대중공업 지분을 기존 6.01%에서 6.38%로, 삼성중공업 지분을
                                                        7.04%에서 8.05%로 확대했다. 연초까지만 해도 주가가
                                                        지지부진했지만, 2분기부터 가파른 상승세를 보이는 종목들이다. 8월
                                                        10일 종가 기준 HD현대중공업과 삼성중공업의 4월 이후 주가
                                                        상승률은 각각 32%, 77%다.조선은 하반기 본격적인 업황 개선이
                                                        예상된다. 2000년대 중반 이후 20년 만에 초호황기에 접어들었다는
                                                        평가까지 나오는 상황이다. 신조선가 상승과 수주 호황으로 건조량이
                                                        증가하며 업체들의 실적도 개선될 것이라는 전망이다.정연승
                                                        NH투자증권 애널리스트는 “조선사들의 수주 호황으로 건조량은
                                                        2025년까지 계속해서 증가할 전망”이라며 “하반기부터 대형 조선사를
                                                        중심으로 업체들의 이익 개선이 본격화될 것”이라고
                                                        내다봤다.대한항공과 에쓰오일 비중을 늘린 점도 눈에 띈다.
                                                        국민연금은 대한항공 보유 비중을 6.27%에서 7.32%로, 에쓰오일
                                                        비중을 6.91%에서 7.29%로 확대했다.올 들어 대한항공 주가는 8월
                                                        10일까지 8% 상승하는 데 그쳤다. 같은 기간 코스피지수는 16%
                                                        상승했다. 화물 운임 하락과 유가 상승세가 주가 발목을 잡았다.
                                                        2분기 영업이익은 4680억원으로 전년 동기 대비 36% 감소했다.다만
                                                        이는 시장이 예측한 수준과 크게 다르지 않다. 화물 운임이 하락하며
                                                        화물 매출은 전년 동기 대비 56% 줄었으나, 여객 매출은 지난해 같은
                                                        기간보다 154% 증가했다. 성수기인 3분기 전망은 더 긍정적이다.
                                                        대한항공은 2분기 대비 3분기 국제선 여객 공급을 7% 정도 늘릴
                                                        것이라고 밝혔다. 올해 2분기 탑승률이 예상보다 높았다는 점을
                                                        감안할 때, 3분기에는 단가 상승과 수송량 증가를 기대할 수
                                                        있다.정유 업종도 하반기 업황 반등이 기대된다. 국내 대표 정유주로
                                                        꼽히는 에쓰오일은 2분기 영업이익이 364억원을 기록했다. 전년 동기
                                                        대비 무려 98% 감소한 수치다. 아시아 지역의 정제마진이 줄고, 유가
                                                        하락에 따른 재고 관련 손실 영향이 크다. 이로 인해 올해 주가도
                                                        6%가량 하락했다. 하지만 하반기에는 정제마진 회복이
                                                        예상된다.대신증권은 에쓰오일의 3분기 정제마진이 배럴당
                                                        8.25달러가 될 것으로 내다봤다. 2분기 정제마진 추정치가 배럴당
                                                        0.15달러라는 점을 고려하면 회복세가 뚜렷하다. 기업가치가 역사적
                                                        하단에 근접한 상태라는 점도 국민연금의 투자 심리를 자극한 것으로
                                                        보인다. 금융정보업체 에프앤가이드에 따르면 올해 실적 전망치를
                                                        기준으로 한 에쓰오일의 현재 주가순자산비율(PBR)은 0.9배
                                                        수준이다.최고운 한국투자증권 애널리스트는 “0.9배에 불과한 PBR도
                                                        코로나19 팬데믹이 발생한 직후 수준”이라며 “PBR이 1.3배까지
                                                        높아지면 주가는 10만원까지 도달할 수 있을 것”이라고
                                                        설명했다.국민연금은 8월 들어 한국항공우주, 파크시스템스,
                                                        세아베스틸지주, 현대백화점, 이녹스첨단소재, 리노공업,
                                                        아프리카TV, 하나금융지주, SK하이닉스, LG에너지솔루션, 호텔신라
                                                        등의 지분도 소폭 늘렸다.국민연금공단이 대한항공 지분을 기존
                                                        6.27%에서 7.32%로 확대했다고 8월 1일 공시했다.사진은 대한항공
                                                        보잉 787-9 여객기. (대한항공 제공)악재 낀 업종 비중
                                                        줄여HDC현산·하이트진로·롯데칠성업황이 살아날 것으로 예상되는
                                                        종목 비중은 늘린 반면, 악재가 낀 업종은 과감히
                                                        팔아치웠다.건설주가 대표적이다. 국민연금은 HDC현대산업개발
                                                        비중을 기존 6.5%에서 5.49%로 낮췄다. 지난 7월에는 GS건설 비중도
                                                        9.94%에서 7.35%로 대폭 축소한 바 있다. 최근 잇따른 건설사 사고로
                                                        건설주 투자 심리는 얼어붙은 상황이다. 지난해 HDC현대산업개발이
                                                        광주 서구에 짓던 아파트 외벽 붕괴 사고에 이어, 올해 4월에는
                                                        GS건설이 짓던 인천 검단신도시 아파트 지하주차장이 붕괴되는
                                                        사고가 발생했다. 반복되는 건설 사고에 정부는 9월 말까지 무량판
                                                        구조를 적용한 민간 아파트 293개 단지의 철근 누락 여부 등을
                                                        전수조사한다.하이트진로와 롯데칠성음료 비중을 줄인 점도 눈에
                                                        띈다. 하이트진로는 5.7%에서 4.67%로, 롯데칠성음료는 9.71%에서
                                                        9.35%로 지분율을 낮췄다. 하이트진로와 롯데칠성 등 식음료주 역시
                                                        외부 환경이 비우호적이다. 정부의 물가 안정 기조에 주요
                                                        라면·제과업체들이 제품 가격을 내렸다. 더군다나 최근 러시아의
                                                        곡물협정 탈퇴와 폭우 등으로 먹거리 물가가 상승하면서 식음료 업체
                                                        마진 악화 우려가 나오고 있다.상반기 주가 강세가 이어진
                                                        와이지엔터테인먼트 비중을 줄인 부분도 관심사다. 국민연금은
                                                        와이지엔터테인먼트 지분을 기존 7.12%에서 6.82%로 줄였다. 여기에
                                                        최근 와이지엔터테인먼트 목표주가를 하향 조정하는 증권사 보고서가
                                                        속속 발간되고 있다. 7월에만 메리츠증권(10만원 → 9만6000원),
                                                        유진투자증권(10만5000원 → 9만5000원), 한화투자증권(11만원 →
                                                        9만원)이 와이지엔터테인먼트 목표주가를 낮춰 잡았다. 오는 9월부터
                                                        걸그룹 블랙핑크가 휴식기에 들어가는 데다, 재계약 관련 이슈도
                                                        아직 명확히 해소되지 않았다는 이유에서다.국민연금은 그 외
                                                        CJ대한통운, 쌍용씨앤이, 동국홀딩스, GS리테일, GKL, 기아,
                                                        한화솔루션, 삼성생명, 케이티앤지, LG생활건강, LG화학, 셀트리온,
                                                        SK이노베이션, SK스퀘어, 풍산, 현대해상 등의 비중도 축소했다.[본
                                                        기사는 매경이코노미 제2223호 (2023.08.23~2023.08.29일자)
                                                        기사입니다]
                                                    </p>
                                                    <span className="time">17시간전</span>
                                                </div>
                                                <div className="image-wrap">
                                                    <img
                                                        src="https://imgnews.pstatic.net/image/024/2023/08/24/0000084131_001_20230824221601014.jpg"
                                                        alt="뉴스이미지"
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* content-wrap */}
                                </div>
                            </div>
                            {/* listCard-content */}
                        </div>
                        {/* list-card */}
                    </div>
                    {/* box */}
                </div>
            </Wrap>
        </Layout>
    );
};

export default Search;

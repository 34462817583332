import { ReactComponent as Logo } from "@assets/icon/logo.svg";
import { ReactComponent as Dash } from "@assets/icon/dash.svg";
import { ReactComponent as DashActive } from "@assets/icon/dash-active.svg";
import { ReactComponent as Search } from "@assets/icon/search.svg";
import { ReactComponent as SearchActive } from "@assets/icon/search-active.svg";
import { ReactComponent as Apps } from "@assets/icon/apps.svg";
import { ReactComponent as AppsActive } from "@assets/icon/apps-active.svg";
import { ReactComponent as Reports } from "@assets/icon/reports.svg";
import { ReactComponent as ReportsActive } from "@assets/icon/reports-active.svg";

import { styled } from "styled-components";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    padding-top: 18px;
    box-sizing: border-box;

    .c__logo_small {
        padding-left: 15px;
        a {
            display: inline-block;
        }
    }

    .c__text {
        margin-top: 36px;
        text-align: center;

        strong {
            font-size: 11px;
            font-weight: 500;
            color: #919197;
        }
    }

    .c__btns {
        display: flex;
        margin-top: 31px;
        flex-direction: column;

        .b_link {
            margin-top: 27px;
            text-align: center;
        }
    }
`;

interface IMenuState {
    menuState: { [key: string]: string | boolean };
}

const SMenu = ({ menuState }: IMenuState) => {
    return (
        <Wrap>
            <div className="c__logo_small">
                <Link to="/">
                    <Logo />
                </Link>
            </div>
            <div className="c__text">
                <strong>MENU</strong>
            </div>
            <div className="c__btns">
                <Link to="/dash" className="b_link">
                    {menuState.dash ? <DashActive /> : <Dash />}
                </Link>
                <Link to="/consearch/patent" className="b_link">
                    {menuState.search ? <SearchActive /> : <Search />}
                </Link>
                <Link to="/apps/tech/trade" className="b_link">
                    {menuState.apps ? <AppsActive /> : <Apps />}
                </Link>
                <Link to="/reports/brief" className="b_link">
                    {menuState.reports ? <ReportsActive /> : <Reports />}
                </Link>
            </div>
        </Wrap>
    );
};

export default SMenu;

import Layout from "@components/Layout";
import { Outlet, useLocation } from "react-router-dom";

import BannerImg from "@assets/img/build-patent-banner.png";
import BannerLogo from "@assets/img/logo-build.png";
import Banners from "@components/Banners";
import { Link } from "react-router-dom";

import { ReactComponent as CopyIcon } from "@assets/icon/copy-icon.svg";
import { ReactComponent as HeartIcon } from "@assets/icon/heart-icon.svg";
import { ReactComponent as HomeIcon } from "@assets/icon/home-icon.svg";

const Build = () => {
    const { pathname } = useLocation();

    return (
        <Layout>
            <Banners bannerimg={BannerImg} bannerlogo={BannerLogo}>
                <div className="c__bannerText">
                    <div className="c__logwrap none-padding">
                        <img src={BannerLogo} alt="logo" />
                    </div>
                    <div className="c__textwrap">
                        <p className="p__title">기술·산업·시장 동향을 분석하는 새로운 방법</p>
                        <p className="p__desc">
                            Build BI는 다양한 시각화 정보를 통해 쉽고 빠른 의사결정이 가능한 비즈니스 인텔리전스
                            서비스입니다.
                            <br />
                            특허 기반 융합 데이터 전체를 자유롭게 탐색하며 새로운 인사이트를 발견해보세요.
                        </p>
                    </div>
                </div>

                <ul className="item__menu">
                    <li className={`${pathname === "/apps/build" && "active"}`}>
                        <Link to="/apps/build">특허탐색</Link>
                    </li>
                    <li className={`${pathname.includes("/apps/build/industry") && "active"}`}>
                        <Link to="/apps/build/industry">산업탐색</Link>
                    </li>
                    <li className={`${pathname.includes("/apps/build/company") && "active"}`}>
                        <Link to="/apps/build/company">기업탐색</Link>
                    </li>
                </ul>

                <ul className="item__icons">
                    <li>
                        <HeartIcon />
                    </li>
                    <li>
                        <HomeIcon />
                    </li>
                    <li>
                        <CopyIcon />
                    </li>
                </ul>
            </Banners>

            <Outlet />
        </Layout>
    );
};

export default Build;

import { patchNewsApi } from "@api/newsApi";
import { getSimilarIndustry, patchCompanyDetail } from "@api/searchCompanyApi";
import { keyWordStorageAtom } from "@atoms";
import { useMutation, useQuery } from "@tanstack/react-query";
import { commaNumber } from "@utils/index";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";

const useCompanyDetail = (id?: string) => {
    const [didMount, setDidMount] = useState(false);
    const [props, setProps] = useState({
        data: {
            title: "",
            content: "",
        },
        page: 1,
        size: 5,
    });

    const [simil, setSimil] = useState({
        businessCategoryCode: "",
        size: 3,
        id: Number(id),
    });

    const [employee, setEmployee] = useState({
        thisEmployee: 0,
        lastEmployee: 0,
        thisCreateMonth: "",
        plusMinus: 0,
    });

    const setKeyWords = useSetAtom(keyWordStorageAtom);

    const [finance, setFinance] = useState({
        thisYear: {
            acctDt: "",
            salse: "",
            orProfit: "",
            netProfit: "",
            dept: "",
            deptRatio: "",
            deptStaus: "",
        },
        changes: {
            changeSales: {
                data: "",
                plusMinus: "",
            },
            changeOrProfit: {
                data: "",
                plusMinus: "",
            },
            changeNetProfit: {
                data: "",
                plusMinus: "",
            },
        },
    });

    const calcChangeRate = (thisYear: string, lastYear: string) => {
        if (thisYear && lastYear && lastYear !== "0") {
            return {
                data: Math.abs(
                    ((Math.abs(Number(thisYear)) - Math.abs(Number(lastYear))) / Number(lastYear)) * 100,
                ).toFixed(2),
                plusMinus: Number(thisYear) - Number(lastYear) >= 0 ? "plus" : "minus",
            };
        } else {
            return "";
        }
    };

    const {
        mutate: newsMutate,
        isLoading: newsLoading,
        data: newsData,
    } = useMutation((props: any) => patchNewsApi(props), {
        onSuccess: () => {
        },
    });

    const { mutate, isLoading, data } = useMutation((id: string) => patchCompanyDetail(id), {
        onSuccess: (datum) => {
            setProps((prev) => ({
                ...prev,
                data: {
                    title: datum.data.realCompanyName,
                    content: datum.data.realCompanyName,
                },
            }));
            setSimil((prev) => ({
                ...prev,
                businessCategoryCode: datum.data.businessCategoryCode,
                id: datum.data.id,
            }));

            // 기업소개 - 인력 증감
            const length = datum.data.companyEmployeeStatuses.length;
            if (length > 0) {
                const thisYearDate = datum.data.companyEmployeeStatuses[length - 1];
                const lastYearDate = `${
                    Number(thisYearDate.dataCreateMonth.substring(0, 4)) - 1
                }${thisYearDate.dataCreateMonth.substring(4, 6)}`;

                const isLastYear = datum.data.companyEmployeeStatuses.find(
                    (item) => item.dataCreateMonth === lastYearDate,
                );

                setEmployee({
                    thisEmployee: Number(thisYearDate.subscriberCnt),
                    lastEmployee: Number(isLastYear?.subscriberCnt),
                    thisCreateMonth: thisYearDate.dataCreateMonth,
                    plusMinus: Number(thisYearDate.subscriberCnt) - Number(isLastYear?.subscriberCnt),
                });
            }

            // 기업소개 - 재무정보
            if (datum.data.companyFinances.length > 0) {
                const thisYearObj = datum.data.companyFinances[0];
                const lastYearObj = datum.data.companyFinances[1];

                const thisYear = thisYearObj.acctDt;
                const thisSales = thisYearObj.sales; // 매출
                const thisOrProfit = thisYearObj.operatingProfit; // 영업이익
                const thisNetProfit = thisYearObj.netProfit; // 당기순이익
                const thisDept = thisYearObj.debtTotal; // 부채
                const thisDeptRatio = thisYearObj.financialDebtRatio; // 부채비율

                let lastSales: string = ""; // 매출
                let lastOrProfit: string = ""; // 영업이익
                let lastNetProfit: string = ""; // 당기순이익

                if (Object.keys(lastYearObj).length > 0) {
                    lastSales = lastYearObj.sales;
                    lastOrProfit = lastYearObj.operatingProfit;
                    lastNetProfit = lastYearObj.netProfit;
                }

                const changeSales = calcChangeRate(thisSales, lastSales);
                const changeOrProfit = calcChangeRate(thisOrProfit, lastOrProfit);
                const changeNetProfit = calcChangeRate(thisNetProfit, lastNetProfit);

                let deptStaus = "";

                if (Number(thisDeptRatio) > 500 || Number(thisDeptRatio) < 0) {
                    deptStaus = "매우 위험";
                } else if (Number(thisDeptRatio) > 300) {
                    deptStaus = "위험";
                } else if (Number(thisDeptRatio) > 200) {
                    deptStaus = "보통";
                } else if (Number(thisDeptRatio) > 100) {
                    deptStaus = "양호";
                } else {
                    deptStaus = "매우 양호";
                }

                if (changeSales && changeOrProfit && changeNetProfit) {
                    setFinance({
                        thisYear: {
                            acctDt: thisYear,
                            salse: thisSales,
                            orProfit: thisOrProfit,
                            netProfit: thisNetProfit,
                            dept: thisDept,
                            deptRatio: thisDeptRatio,
                            deptStaus: deptStaus,
                        },
                        changes: {
                            changeSales: changeSales,
                            changeOrProfit: changeOrProfit,
                            changeNetProfit: changeNetProfit,
                        },
                    });
                }
            }
        },
    });

    const { data: similData, isLoading: similLoading } = useQuery(
        ["similarIndustry", simil],
        () => getSimilarIndustry(simil),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            enabled: !!simil.businessCategoryCode && !!simil.id,
        },
    );

    useEffect(() => {
        setDidMount(true);
    }, []);
    useEffect(() => {
        if (id !== undefined && didMount) {
            mutate(id);
        }
    }, [id, didMount]);

    useEffect(() => {
        if (props.data.title !== "") {
            newsMutate(props);
        }
    }, [props.data.title]);

    const getNumber = (num: string) => {
        const temp = commaNumber(Math.round(Number(num) * 0.001));

        return temp;
    };

    const handleNewsMore = () => {
        setKeyWords(props.data.title);
        window.open("/consearch/news");
    };

    return {
        isLoading,
        newsLoading,
        data,
        getNumber,
        similData,
        similLoading,
        employee,
        finance,
        newsData,
        handleNewsMore,
    };
};

export default useCompanyDetail;

import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { DataItem } from "~types/PatentSearch/SimilarPatentSearch";
import { PatentPiaCateType, PatentPiaCatesDepthType } from "~types/Patentpia";
import { ICompanyList, IDashSelectAtom, INewsData, IPatentList, ITechData } from "~types/types";

export const dashSelectAtom = atom<IDashSelectAtom>({
    id: "63637",
    name: "반도체",
    name2: "반도체",
    originName: "반도체",
});

export const allPopAtom = atom({
    dash: false,
} as { [key: string]: boolean });

export const wideModeAtom = atomWithStorage<boolean>("wideMode", true);

export const patentPiaDataAtom = atom({} as { [key: string]: (number | string)[] | number | string });

export const keyWordStorageAtom = atomWithStorage<string>("keyword", "");

export const patentPiaCates = atom<PatentPiaCatesDepthType>({
    depth1: { name: "", cateId: "" },
    depth2: { name: "", cateId: "" },
    depth3: { name: "", cateId: "" },
});

export const patentPiaCatesTemp = atom<PatentPiaCatesDepthType>({
    depth1: { name: "", cateId: "" },
    depth2: { name: "", cateId: "" },
    depth3: { name: "", cateId: "" },
});

export const patentPiaCateId = atom<string>("");

// 검색 > news
export const searchNewsAtom = atom<INewsData>({
    totalPages: 0,
    totalElements: 0,
    last: false,
    number: 0,
    size: 10,
    first: true,
    numberOfElements: 0,
    empty: false,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    pageable: {
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        unpaged: false,
    },
    content: [],
});
export const newsPageAtom = atom({
    data: {
        title: "",
        content: "",
    },
    page: 1,
    size: 50,
});

// 검색 > 소송정보
export const searchDisputAtom = atom<any>({});

// 검색 > 연구자
export const searchResearcherAtom = atom<any>({});

// 검색 > 판매기술
export const searchSalesAtom = atom<ITechData>({
    totalPages: 0,
    totalElements: 0,
    last: false,
    number: 0,
    size: 10,
    first: true,
    numberOfElements: 0,
    empty: false,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    pageable: {
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        unpaged: false,
    },
    content: [],
});

// 검색 > 기업 : 리스트
export const searchCompanyListAtom = atom<ICompanyList>({
    totalPages: 0,
    totalElements: 0,
    last: false,
    number: 0,
    size: 10,
    first: true,
    numberOfElements: 0,
    empty: false,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    pageable: {
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        unpaged: false,
    },
    content: [],
});
export const companyPageAtom = atom({
    data: {
        companyName: "",
    },
    page: 1,
    size: 20,
});

export const enterIsViewAtom = atom(true);

// r검색 > 특허
export const searchPatentListAtom = atom<IPatentList>({
    totalPages: 0,
    totalElements: 0,
    last: false,
    number: 0,
    size: 10,
    first: true,
    numberOfElements: 0,
    empty: false,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    pageable: {
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        unpaged: false,
    },
    content: [],
});
export const patentPageAtom = atom({
    page: 1,
    size: 20,
    data: {
        title: "",
    },
});

export const reSizeAtom = atom<number>(window.innerWidth);

export const patentPiaCateAtom = atom({
    cate: "techCate",
} as PatentPiaCateType);

export const patentPiaCateOtherAtom = atom({
    cate: "techCate",
} as PatentPiaCateType);

export const competeIsViewAtom = atom(false);

export const nanumPageAtom = atom<number>(1);

export const researcherAtom = atom<number>(1);

export const researcherTotalPageAtom = atom<number>(10);

export const lawshitAtom = atom<number>(1);

export const lawshitTotalPageAtom = atom<number>(10);

/**
 * 특허탐색 > 유사특허검색
 */
export const patentSearchListAtom = atom<DataItem[][]>([]);
export const isDisabledAtom = atom(true);
export const selectOptionAtom = atom("n");
export const patentNumberAtom = atom("y");
export const patentNumberValueAtom = atom("");
export const selectedTextAtom = atom("출원 번호");

import { ISplineProps } from "types/etcTypes";
import { formatNumber } from "@utils/index";
import React from "react";
import ApexChart from "react-apexcharts";

const colors = ["#45cb85", "#4b38b3", "#f06548", "#a0b338", "#48d2f0"];

const Spline: React.FC<ISplineProps> = ({ data, label }) => {
    // 대쉬보드 국가별 특허발생추이
    return (
        <>
            <ApexChart
                type="area"
                width={"100%"}
                height={280}
                className="table-patent-posses"
                series={data}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        animations: {
                            enabled: true,
                            easing: "easeinout",
                            speed: 800,
                            animateGradually: {
                                enabled: true,
                                delay: 150,
                            },
                            dynamicAnimation: {
                                enabled: true,
                                speed: 350,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3,
                    },
                    colors: colors,
                    xaxis: {
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        categories: label,
                    },
                    yaxis: {
                        show: true,
                        labels: {
                            formatter: (val) => {
                                const xxx = formatNumber(val);
                                return xxx;
                            },
                        },
                    },
                }}
            />
        </>
    );
};

export default Spline;

import { styled } from "styled-components";

import AppsActive from "@assets/icon/apps-active.svg";
import Apps from "@assets/icon/apps.svg";
import ArrowActive from "@assets/icon/arrow-active.svg";
import Arrow from "@assets/icon/arrow.svg";
import DashActive from "@assets/icon/dash-active.svg";
import Dash from "@assets/icon/dash.svg";
import ReportsActive from "@assets/icon/reports-active.svg";
import Reports from "@assets/icon/reports.svg";
import SDashActive from "@assets/icon/s-dash-active.svg";
import SDash from "@assets/icon/s-dash.svg";
import SearchActive from "@assets/icon/search-active.svg";
import Search from "@assets/icon/search.svg";

export const Wrap = styled.div<{ $iswide: boolean }>`
    overflow: hidden;
    padding-left: ${(props) => (props.$iswide ? "20px" : "0")};
    box-sizing: border-box;

    .p__menu {
        padding-top: 36px;
        font-weight: 500;
        font-size: 11px;
        color: rgba(255, 255, 255, 0.4);
    }

    .item-menu {
        padding-top: 28px;
        width: 230px;

        li {
            margin-top: 28px;

            &:first-child {
                margin-top: 0;
            }
        }
        .b__menu {
            display: inline-block;
            position: relative;
            width: calc(100% - 25px);
            padding-left: 34px;
            text-align: left;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 14px;
            line-height: 21px;
            color: #919197;
            box-sizing: border-box;

            background-repeat: no-repeat;
            background-position:
                0 50%,
                100% 0;

            .p__arrow {
                position: absolute;
                width: 7px;
                height: 21px;
                top: 0;
                right: 0;
                background: url(${Arrow}) no-repeat 50% 50%;
                transition: all 0.5s;
            }

            &.dash {
                background-image: url(${Dash});
            }

            &.search {
                background-image: url(${Search});
            }

            &.apps {
                background-image: url(${Apps});
            }

            &.reports {
                background-image: url(${Reports});
            }

            &:hover {
                color: ${(props) => props.theme.allWhite};
                .p__arrow {
                    background: url(${ArrowActive}) no-repeat 50% 50%;
                }
            }
            &.active {
                color: ${(props) => props.theme.allWhite};
                .p__arrow {
                    background: url(${ArrowActive}) no-repeat 50% 50%;
                    transform-origin: center;
                    transform: rotate(90deg);
                }

                &.no-child {
                    .p__arrow {
                        background: url(${ArrowActive}) no-repeat 50% 50%;
                        transform-origin: center;
                        transform: rotate(0deg);
                    }
                }

                &.dash {
                    background-image: url(${DashActive});
                }

                &.search {
                    background-image: url(${SearchActive});
                }

                &.apps {
                    background-image: url(${AppsActive});
                }

                &.reports {
                    background-image: url(${ReportsActive});
                }
            }
        }
    }

    .list__2depth {
        padding-top: 23px;
        padding-left: 8px;

        li {
            margin-top: 26px;

            &:first-child {
                margin-top: 0;
            }
        }

        a {
            display: inline-block;
            width: 100%;
            padding-left: 28px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 12px;
            color: rgb(100, 100, 143);
            box-sizing: border-box;
            background-image: url(${SDash});
            background-repeat: no-repeat;
            background-position: 0 50%;

            &.active,
            &:hover {
                color: #ffffff;
                background-image: url(${SDashActive});
            }
        }
    }

    .motion__wrap {
        overflow: hidden;
    }
`;

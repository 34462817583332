import exampeImg from "@assets/img/naum_img.png";
import { NanumCard } from "../style";
import { Fragment, useEffect, useState } from "react";
import config from "@config";
import { handleText } from "@utils/index";

interface ICompanyList {
    techShareCompanyIdx: number;
    techShareIdx: number;
    totalTechShareTechCount: number;
    companyName: string;
    companyIntro: string;
    keywords: string;
    companyThumbnailFile: {
        techShareCompanyFileIdx: number;
        techShareCompanyIdx: number;
        fileName: string;
        fileExternalUrl: any;
        fileUrl: string;
        resizingImageUrlMap: {
            "478x298": string;
            "605x250": string;
        };
        filePath: string;
        fileSize: string;
        fileCd: string;
        fileCdDesc: string;
        updateDate: string;
        createDate: string;
    };
}
interface IProps {
    data: ICompanyList;
}
const Card = ({ data }: IProps) => {
    const [keyWords, setKeyWords] = useState<string[]>([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const keyWordTemp = data.keywords.split(",");
            setKeyWords(keyWordTemp);
        }
    }, [data]);

    return (
        <NanumCard
            onClick={() =>
                window.open(
                    `${config.TECHSTORMURL}/techshare/detail/${data.techShareIdx}?comIdx=${data.techShareCompanyIdx}`,
                )
            }
        >
            <div className="wrap_img">
                <img src={data.companyThumbnailFile.fileUrl} alt={data.companyName} />
            </div>
            <div className="box_text">
                <div>
                    <p className="text text_title">{data.companyName}</p>
                    {keyWords.length > 0 && (
                        <p className="text text_desc">{handleText(keyWords.map((item) => `#${item}`).join(" "), 40)}</p>
                    )}
                </div>
            </div>
        </NanumCard>
    );
};

export default Card;

import { IApplicantStatusProps } from "types/etcTypes";
import { COLOR } from "@colors";
import { handleText } from "@utils/index";
import React from "react";
import ApexChart from "react-apexcharts";
import { styled } from "styled-components";

const Wrap = styled.div``;

const ApplicantStatus: React.FC<IApplicantStatusProps> = ({ data, labels, xaxisTitle, yaxisTitle, height }) => {
    return (
        <Wrap>
            <ApexChart
                type="bar"
                width={"100%"}
                height={height}
                className="table-patent-posses"
                series={[
                    {
                        data: data,
                    },
                ]}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        animations: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            dataLabels: {
                                position: "top",
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: 60,
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#999"],
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                        },
                        formatter: (val) => {
                            return val.toLocaleString("en-US");
                        },
                    },

                    // colors: colors,
                    xaxis: {
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        categories: labels,
                        labels: {
                            show: false,
                        },
                        title: {
                            offsetX: -40,
                            offsetY: -10,
                            text: xaxisTitle,
                            style: {
                                color: "#666",
                                fontSize: "12px",
                                fontFamily: "Noto Sans KR",
                                fontWeight: 400,
                            },
                        },
                    },
                    yaxis: {
                        show: true,
                        labels: {
                            formatter: (val) => {
                                const xxx = handleText(`${val}`, 13);
                                return xxx;
                            },
                        },
                        title: {
                            text: yaxisTitle,
                            style: {
                                color: "#666",
                                fontSize: "12px",
                                fontFamily: "Noto Sans KR",
                                fontWeight: 400,
                            },
                        },
                    },
                    colors: [
                        // @ts-ignore
                        function ({ value, seriesIndex, dataPointIndex, w }) {
                            return COLOR[dataPointIndex % COLOR.length];
                        },
                    ],
                    grid: { show: false },
                }}
            />
        </Wrap>
    );
};

export default ApplicantStatus;

import useDraggableScrollX from "@services/DraggableScrollX";
import { IDraggableScrollXProps } from "types/etcTypes";
import { Container, Content } from "./style";

const DraggableScrollX = ({ children, width }: IDraggableScrollXProps) => {
    const { containerRef, contentRef, handleMouseDown } = useDraggableScrollX(width!);

    return (
        <Container ref={containerRef} onMouseDown={handleMouseDown}>
            <Content ref={contentRef} width={width}>
                {children}
            </Content>
        </Container>
    );
};

export default DraggableScrollX;

import config from "@config";
import axios from "axios";

export const AxiosInstance = axios.create({
    baseURL: config.BUILD_API,
    headers: config.BASE_HEADER,
    responseType: "json",
});

AxiosInstance.interceptors.request.use((config) => {
    return config;
});

AxiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosPatentPia = axios.create({
    baseURL: config.PATENTPIA_API,
    headers: config.BASE_HEADER,
});

AxiosPatentPia.interceptors.request.use((config) => {
    return config;
});

AxiosPatentPia.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosTechStrom = axios.create({
    baseURL: config.TECHSTORM_API,
    headers: config.BASE_HEADER,
});

AxiosTechStrom.interceptors.request.use((config) => {
    return config;
});

AxiosTechStrom.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosKakao = axios.create({
    timeout: 10000,
    baseURL: `https://dapi.kakao.com/v2`,
    headers: {
        ...config.BASE_HEADER,
        apiKey: "87adbd249b160a88a04e7a3f617591fd",
    },
});

AxiosKakao.interceptors.request.use((config) => {
    return config;
});

AxiosKakao.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosNews = axios.create({
    baseURL: config.NEWS_API,
    headers: config.BASE_HEADER,
});

AxiosNews.interceptors.request.use((config) => {
    return config;
});

AxiosNews.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosCompany = axios.create({
    baseURL: config.COMPANY_API,
    headers: config.BASE_HEADER,
});

AxiosCompany.interceptors.request.use((config) => {
    return config;
});

AxiosCompany.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);
export const AxiosCompanyDetail = axios.create({
    baseURL: config.COMPANY_DETAIL_API,
    headers: config.BASE_HEADER,
});
AxiosCompanyDetail.interceptors.request.use((config) => {
    return config;
});

AxiosCompanyDetail.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosMainWips = axios.create({
    baseURL: config.BUILD_API,
    headers: config.BASE_HEADER,
    responseType: "json",
});

AxiosMainWips.interceptors.request.use((config) => {
    return config;
});

AxiosMainWips.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosSearchPatnet = axios.create({
    baseURL: config.SEARCH_PATENT_API,
    headers: config.BASE_HEADER,
    responseType: "json",
});

AxiosSearchPatnet.interceptors.request.use((config) => {
    return config;
});

AxiosSearchPatnet.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosSearchPatentDetail = axios.create({
    baseURL: config.SEARCH_PATENT_DETAIL,
    headers: config.BASE_HEADER,
    responseType: "json",
});

AxiosSearchPatentDetail.interceptors.request.use((config) => {
    return config;
});

AxiosSearchPatentDetail.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const AxiosSearchInstance = axios.create({
    baseURL: config.SEARCH_API,
    headers: config.BASE_HEADER,
    responseType: "json",
});

AxiosSearchInstance.interceptors.request.use((config) => {
    return config;
});

AxiosSearchInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
        return Promise.reject(error.response);
    },
);

export const patentDataTemp = [
    {
        data_id: "1",
        data_set: [
            {
                pat_appl_cnt: 37433923,
            },
        ],
    },
    {
        data_id: "2",
        data_set: [
            {
                pat_grt_cnt: 18015166,
            },
        ],
    },
    {
        data_id: "3",
        data_set: [
            {
                appt_cnt: 1062799,
                ctry_nm: "중국(CNIPA)",
            },
            {
                appt_cnt: 266209,
                ctry_nm: "유럽(EPO)",
            },
            {
                appt_cnt: 221933,
                ctry_nm: "일본(JPO)",
            },
            {
                appt_cnt: 198724,
                ctry_nm: "한국(KIPO)",
            },
            {
                appt_cnt: 442111,
                ctry_nm: "미국(USPTO)",
            },
        ],
    },
    {
        data_id: "4",
        data_set: [
            {
                pat_appl_cnt: 968596,
                appl_yr: 2001,
            },
            {
                pat_appl_cnt: 984248,
                appl_yr: 2002,
            },
            {
                pat_appl_cnt: 1047220,
                appl_yr: 2003,
            },
            {
                pat_appl_cnt: 1116696,
                appl_yr: 2004,
            },
            {
                pat_appl_cnt: 1188667,
                appl_yr: 2005,
            },
            {
                pat_appl_cnt: 1221963,
                appl_yr: 2006,
            },
            {
                pat_appl_cnt: 1227543,
                appl_yr: 2007,
            },
            {
                pat_appl_cnt: 1242821,
                appl_yr: 2008,
            },
            {
                pat_appl_cnt: 1214968,
                appl_yr: 2009,
            },
            {
                pat_appl_cnt: 1296675,
                appl_yr: 2010,
            },
            {
                pat_appl_cnt: 1432972,
                appl_yr: 2011,
            },
            {
                pat_appl_cnt: 1595445,
                appl_yr: 2012,
            },
            {
                pat_appl_cnt: 1756353,
                appl_yr: 2013,
            },
            {
                pat_appl_cnt: 1856691,
                appl_yr: 2014,
            },
            {
                pat_appl_cnt: 1999173,
                appl_yr: 2015,
            },
            {
                pat_appl_cnt: 2212195,
                appl_yr: 2016,
            },
            {
                pat_appl_cnt: 2440510,
                appl_yr: 2017,
            },
            {
                pat_appl_cnt: 2584291,
                appl_yr: 2018,
            },
            {
                pat_appl_cnt: 2468776,
                appl_yr: 2019,
            },
            {
                pat_appl_cnt: 2621409,
                appl_yr: 2020,
            },
            {
                pat_appl_cnt: 2509938,
                appl_yr: 2021,
            },
            {
                pat_appl_cnt: 1865937,
                appl_yr: 2022,
            },
            {
                pat_appl_cnt: 580836,
                appl_yr: 2023,
            },
        ],
    },
    {
        data_id: "5",
        data_set: [
            {
                com_nm: "삼성전자(Samsung Electronics)",
                pat_appl_cnt: 414392,
                pat_grt_cnt: 231731,
            },
            {
                com_nm: "캐논(CANON)",
                pat_appl_cnt: 276215,
                pat_grt_cnt: 149444,
            },
            {
                com_nm: "파나소닉 홀딩스(Panasonic Holdings)",
                pat_appl_cnt: 220946,
                pat_grt_cnt: 100050,
            },
            {
                com_nm: "도요타모터(TOYOTA MOTOR)",
                pat_appl_cnt: 220538,
                pat_grt_cnt: 120056,
            },
            {
                com_nm: "엘지전자(LG Electronics)",
                pat_appl_cnt: 210013,
                pat_grt_cnt: 132479,
            },
            {
                com_nm: "도시바(TOSHIBA)",
                pat_appl_cnt: 184846,
                pat_grt_cnt: 93001,
            },
            {
                com_nm: "소니 그룹(Sony Group)",
                pat_appl_cnt: 180545,
                pat_grt_cnt: 99598,
            },
            {
                com_nm: "아이비엠(IBM)",
                pat_appl_cnt: 177349,
                pat_grt_cnt: 141993,
            },
            {
                com_nm: "화웨이테크놀로지스(HUAWEI TECHNOLOGIES)",
                pat_appl_cnt: 174400,
                pat_grt_cnt: 104901,
            },
            {
                com_nm: "세이코 엡슨(SEIKO EPSON)",
                pat_appl_cnt: 169434,
                pat_grt_cnt: 82415,
            },
            {
                com_nm: "미쓰비시일렉트릭(MITSUBISHI ELECTRIC)",
                pat_appl_cnt: 161721,
                pat_grt_cnt: 105026,
            },
            {
                com_nm: "퀄컴(QUALCOMM)",
                pat_appl_cnt: 139818,
                pat_grt_cnt: 84892,
            },
            {
                com_nm: "리코(RICOH)",
                pat_appl_cnt: 130716,
                pat_grt_cnt: 70254,
            },
            {
                com_nm: "샤프(SHARP)",
                pat_appl_cnt: 122764,
                pat_grt_cnt: 61470,
            },
            {
                com_nm: "후지쓰(FUJITSU)",
                pat_appl_cnt: 121249,
                pat_grt_cnt: 74017,
            },
            {
                com_nm: "필립스일렉트로닉스(PHILIPS)",
                pat_appl_cnt: 115160,
                pat_grt_cnt: 52764,
            },
            {
                com_nm: "히타치(HITACHI)",
                pat_appl_cnt: 110041,
                pat_grt_cnt: 62603,
            },
            {
                com_nm: "혼다모터(HONDA MOTOR)",
                pat_appl_cnt: 108490,
                pat_grt_cnt: 77238,
            },
            {
                com_nm: "덴소(DENSO)",
                pat_appl_cnt: 107413,
                pat_grt_cnt: 67134,
            },
            {
                com_nm: "현대자동차(Hyundai Motor Company)",
                pat_appl_cnt: 105016,
                pat_grt_cnt: 60676,
            },
            {
                com_nm: "후지필름(FUJIFILM)",
                pat_appl_cnt: 96725,
                pat_grt_cnt: 56456,
            },
            {
                com_nm: "엔이씨(NEC)",
                pat_appl_cnt: 94743,
                pat_grt_cnt: 53938,
            },
            {
                com_nm: "로베르트보쉬(Robert Bosch)",
                pat_appl_cnt: 89334,
                pat_grt_cnt: 53622,
            },
            {
                com_nm: "인텔(INTEL)",
                pat_appl_cnt: 88664,
                pat_grt_cnt: 61615,
            },
            {
                com_nm: "삼성디스플레이(SAMSUNG DISPLAY)",
                pat_appl_cnt: 83369,
                pat_grt_cnt: 46892,
            },
            {
                com_nm: "제너럴 일렉트릭(General Electric Company)",
                pat_appl_cnt: 82472,
                pat_grt_cnt: 50427,
            },
            {
                com_nm: "파나소닉 IP 매니지먼트(PANASONIC IP MANAGEMENT)",
                pat_appl_cnt: 78192,
                pat_grt_cnt: 44617,
            },
            {
                com_nm: "지티이(ZTE)",
                pat_appl_cnt: 75574,
                pat_grt_cnt: 39700,
            },
            {
                com_nm: "지멘스(SIEMENS AKTIENGESELLSCHAFT)",
                pat_appl_cnt: 71141,
                pat_grt_cnt: 40084,
            },
            {
                com_nm: "에스케이하이닉스(SK Hynix)",
                pat_appl_cnt: 68914,
                pat_grt_cnt: 37454,
            },
            {
                com_nm: "엘지디스플레이(LG Display)",
                pat_appl_cnt: 68734,
                pat_grt_cnt: 47069,
            },
            {
                com_nm: "후지제록스(FUJI XEROX)",
                pat_appl_cnt: 66871,
                pat_grt_cnt: 36269,
            },
            {
                com_nm: "에릭슨(ERICSSON TELEFON AB L M)",
                pat_appl_cnt: 64374,
                pat_grt_cnt: 42326,
            },
            {
                com_nm: "보에 테크놀로지 그룹(BOE TECHNOLOGY GROUP)",
                pat_appl_cnt: 62888,
                pat_grt_cnt: 36551,
            },
            {
                com_nm: "엘지화학(LG Chem)",
                pat_appl_cnt: 62695,
                pat_grt_cnt: 45693,
            },
            {
                com_nm: "중국석유화학(SINOPEC)",
                pat_appl_cnt: 60612,
                pat_grt_cnt: 37388,
            },
            {
                com_nm: "애플(APPLE COMPUTER)",
                pat_appl_cnt: 58572,
                pat_grt_cnt: 40832,
            },
            {
                com_nm: "타이완세미컨덕터메뉴팩처링(TAIWAN SEMICONDUCTOR MFG)",
                pat_appl_cnt: 56779,
                pat_grt_cnt: 39796,
            },
            {
                com_nm: "휴렛 팩커드 디벨롭먼트(HEWLETT PACKARD DEVELOPMENT COMPANY)",
                pat_appl_cnt: 56476,
                pat_grt_cnt: 38649,
            },
            {
                com_nm: "구글(GOOGLE)",
                pat_appl_cnt: 55447,
                pat_grt_cnt: 37641,
            },
            {
                com_nm: "닛산모터(NISSAN MOTOR)",
                pat_appl_cnt: 55401,
                pat_grt_cnt: 34757,
            },
            {
                com_nm: "한국전자통신연구원(ELECTRONICS AND TELECOMMUNICATIONS RESEARCH INSTITUTE)",
                pat_appl_cnt: 55035,
                pat_grt_cnt: 30900,
            },
            {
                com_nm: "삼성에스디아이(Samsung SDI)",
                pat_appl_cnt: 53467,
                pat_grt_cnt: 36425,
            },
            {
                com_nm: "산요일렉트릭(SANYO ELECTRIC)",
                pat_appl_cnt: 53322,
                pat_grt_cnt: 23675,
            },
            {
                com_nm: "절강대학교(ZHEJIANG UNIVERSITY)",
                pat_appl_cnt: 53276,
                pat_grt_cnt: 32083,
            },
            {
                com_nm: "오포 일렉트로닉스(OPPO Electronics)",
                pat_appl_cnt: 52956,
                pat_grt_cnt: 26389,
            },
            {
                com_nm: "브라더(BROTHER IND)",
                pat_appl_cnt: 52558,
                pat_grt_cnt: 36942,
            },
            {
                com_nm: "쓰리엠(3M Company)",
                pat_appl_cnt: 51983,
                pat_grt_cnt: 26745,
            },
            {
                com_nm: "스미토모일렉트릭인더스트리즈(SUMITOMO ELECTRIC INDUSTRIES)",
                pat_appl_cnt: 51104,
                pat_grt_cnt: 23462,
            },
            {
                com_nm: "교세라(KYOCERA)",
                pat_appl_cnt: 50579,
                pat_grt_cnt: 28730,
            },
            {
                com_nm: "바스프(BASF)[DE]",
                pat_appl_cnt: 50365,
                pat_grt_cnt: 25850,
            },
            {
                com_nm: "마이크로소프트 테크놀로지 라이센싱(Microsoft Technology Licensing)",
                pat_appl_cnt: 50243,
                pat_grt_cnt: 33571,
            },
            {
                com_nm: "코니카미놀타홀딩스(KONICA MINOLTA HOLDINGS)",
                pat_appl_cnt: 49647,
                pat_grt_cnt: 25054,
            },
            {
                com_nm: "한도다이 에네르기 겐뀨쇼(SEMICONDUCTOR TECHNOLOGY ACADEMIC RESEARCH CENTER)",
                pat_appl_cnt: 48698,
                pat_grt_cnt: 35798,
            },
            {
                com_nm: "마이크로소프트(MICROSOFT)",
                pat_appl_cnt: 48584,
                pat_grt_cnt: 31434,
            },
            {
                com_nm: "올림푸스(OLYMPUS)",
                pat_appl_cnt: 48313,
                pat_grt_cnt: 26039,
            },
            {
                com_nm: "그리 일렉트릭 어플라이언시즈(GREE ELECTRIC APPLIANCES)",
                pat_appl_cnt: 48145,
                pat_grt_cnt: 16467,
            },
            {
                com_nm: "엔티티(NIPPON TELEGRAPH & TELEPHONE(NTT))",
                pat_appl_cnt: 47769,
                pat_grt_cnt: 30186,
            },
            {
                com_nm: "다이닛폰프린팅(DAINIPPON PRINTING)",
                pat_appl_cnt: 47023,
                pat_grt_cnt: 25538,
            },
            {
                com_nm: "삼성전기(Samsung Electro Mechanics)",
                pat_appl_cnt: 45496,
                pat_grt_cnt: 24434,
            },
            {
                com_nm: "탕쉰 기술(TENCENT TECH SHENZHEN)",
                pat_appl_cnt: 45291,
                pat_grt_cnt: 26403,
            },
            {
                com_nm: "교세라 도큐멘트 솔루션즈(KYOCERA Document Solutions)",
                pat_appl_cnt: 44853,
                pat_grt_cnt: 25093,
            },
            {
                com_nm: "미쓰비시해비인더스트리즈(MITSUBISHI HEAVY INDUSTRIES)",
                pat_appl_cnt: 44322,
                pat_grt_cnt: 24729,
            },
            {
                com_nm: "칭화대학교(Tsinghua University)",
                pat_appl_cnt: 44040,
                pat_grt_cnt: 29155,
            },
            {
                com_nm: "마이크론 테크놀로지(MICRON TECHNOLOGY)",
                pat_appl_cnt: 43631,
                pat_grt_cnt: 32013,
            },
            {
                com_nm: "니폰스틸(NIPPON STEEL)",
                pat_appl_cnt: 42536,
                pat_grt_cnt: 30825,
            },
            {
                com_nm: "도쿄 일렉트론(TOKYO ELECTRON)",
                pat_appl_cnt: 42436,
                pat_grt_cnt: 26605,
            },
            {
                com_nm: "무라타매뉴팩처링(MURATA MANUFACTURING)",
                pat_appl_cnt: 42341,
                pat_grt_cnt: 28348,
            },
            {
                com_nm: "포드 글로벌 테크놀로지스(FORD GLOBAL TECH)",
                pat_appl_cnt: 41993,
                pat_grt_cnt: 28780,
            },
            {
                com_nm: "기아(KIA)",
                pat_appl_cnt: 41181,
                pat_grt_cnt: 19831,
            },
            {
                com_nm: "스미토모케미컬(SUMITOMO CHEMICAL)",
                pat_appl_cnt: 40779,
                pat_grt_cnt: 18214,
            },
            {
                com_nm: "니콘(NIPPON KOGAKU)",
                pat_appl_cnt: 38757,
                pat_grt_cnt: 18662,
            },
            {
                com_nm: "지엠 글로벌 테크놀러지 오퍼레이션스(GM GLOBAL TECHNOLOGY OPERATIONS)",
                pat_appl_cnt: 38316,
                pat_grt_cnt: 27451,
            },
            {
                com_nm: "니토덴코(NITTO DENKO)",
                pat_appl_cnt: 37544,
                pat_grt_cnt: 17869,
            },
            {
                com_nm: "아이신(AISIN)",
                pat_appl_cnt: 37358,
                pat_grt_cnt: 20870,
            },
            {
                com_nm: "보잉(BOEING)",
                pat_appl_cnt: 36864,
                pat_grt_cnt: 28434,
            },
            {
                com_nm: "허니웰(HONEYWELL)[US]",
                pat_appl_cnt: 36089,
                pat_grt_cnt: 21423,
            },
            {
                com_nm: "엔티티 도코모(NTT DOCOMO)",
                pat_appl_cnt: 35075,
                pat_grt_cnt: 19786,
            },
            {
                com_nm: "다이킨(DAIKIN INDUSTRIES)",
                pat_appl_cnt: 34827,
                pat_grt_cnt: 18894,
            },
            {
                com_nm: "르네사스일렉트로닉스(RENESAS ELECTRONICS)",
                pat_appl_cnt: 34727,
                pat_grt_cnt: 20701,
            },
            {
                com_nm: "하얼빈 공업대학(Harbin Institute of Technology)",
                pat_appl_cnt: 34520,
                pat_grt_cnt: 20882,
            },
            {
                com_nm: "프록터 앤드 갬블(PROCTER & GAMBLE (P&G))",
                pat_appl_cnt: 34280,
                pat_grt_cnt: 16640,
            },
            {
                com_nm: "동남대학(UNIV SOUTHEAST)",
                pat_appl_cnt: 34190,
                pat_grt_cnt: 18813,
            },
            {
                com_nm: "스테이트 그리드 오브 차이나(State Grid of China)",
                pat_appl_cnt: 34163,
                pat_grt_cnt: 15405,
            },
            {
                com_nm: "도레이인더스트리즈(TORAY INDUSTRIES)",
                pat_appl_cnt: 33559,
                pat_grt_cnt: 14912,
            },
            {
                com_nm: "엘지이노텍(LG Innotek)",
                pat_appl_cnt: 33421,
                pat_grt_cnt: 19929,
            },
            {
                com_nm: "사우쓰 차이나 유니버시티 오브 테크놀로지(SOUTH CHINA UNIVERSITY OF TECHNOLOGY)",
                pat_appl_cnt: 33231,
                pat_grt_cnt: 17963,
            },
            {
                com_nm: "제이에프이스틸(JFE STEEL)",
                pat_appl_cnt: 33003,
                pat_grt_cnt: 22539,
            },
            {
                com_nm: "어플라이드머티어리얼즈(APPLIED MATERIALS)",
                pat_appl_cnt: 32976,
                pat_grt_cnt: 17732,
            },
            {
                com_nm: "톈진 유니버시티(Tianjin University)",
                pat_appl_cnt: 32473,
                pat_grt_cnt: 14691,
            },
            {
                com_nm: "상해 교통 대학(UNIV SHANGHAI JIAOTONG)",
                pat_appl_cnt: 32434,
                pat_grt_cnt: 19070,
            },
            {
                com_nm: "브리지스톤(BRIDGESTONE)",
                pat_appl_cnt: 32243,
                pat_grt_cnt: 16622,
            },
            {
                com_nm: "야자키(YAZAKI)",
                pat_appl_cnt: 32153,
                pat_grt_cnt: 20173,
            },
            {
                com_nm: "티디케이(TDK)",
                pat_appl_cnt: 31990,
                pat_grt_cnt: 18781,
            },
            {
                com_nm: "도판프린팅(TOPPAN PRINTING)",
                pat_appl_cnt: 31750,
                pat_grt_cnt: 13574,
            },
            {
                com_nm: "산요붓상(SANYO)",
                pat_appl_cnt: 30915,
                pat_grt_cnt: 12336,
            },
            {
                com_nm: "카시오컴퓨터(CASIO COMPUTER)",
                pat_appl_cnt: 30007,
                pat_grt_cnt: 19051,
            },
            {
                com_nm: "카오(KAO)",
                pat_appl_cnt: 29899,
                pat_grt_cnt: 20264,
            },
            {
                com_nm: "히다치 아스테모(Hitachi Astemo)",
                pat_appl_cnt: 29522,
                pat_grt_cnt: 18787,
            },
            {
                com_nm: "산쿄(SANKYO)",
                pat_appl_cnt: 29513,
                pat_grt_cnt: 17970,
            },
        ],
    },
    {
        data_id: "6",
        data_set: [
            {
                pat_appl_cnt: 5530197,
                pat_grt_cnt: 2256798,
                ipc: "(A) 인간 필수품",
            },
            {
                pat_appl_cnt: 6156865,
                pat_grt_cnt: 2883313,
                ipc: "(B) 작업 수행 ; 운송",
            },
            {
                pat_appl_cnt: 4394313,
                pat_grt_cnt: 2072959,
                ipc: "(C) 화학 ; 야금학",
            },
            {
                pat_appl_cnt: 446307,
                pat_grt_cnt: 204828,
                ipc: "(D) 텍스타일 ; 종이",
            },
            {
                pat_appl_cnt: 1291125,
                pat_grt_cnt: 604833,
                ipc: "(E) 고정된 구성",
            },
            {
                pat_appl_cnt: 2754453,
                pat_grt_cnt: 1350349,
                ipc: "(F) 기계 공학 ; 조명 ; 가열 ; 무기 ; 블라스팅",
            },
            {
                pat_appl_cnt: 9100531,
                pat_grt_cnt: 4385367,
                ipc: "(G) 물리학",
            },
            {
                pat_appl_cnt: 7743924,
                pat_grt_cnt: 4251465,
                ipc: "(H) 전기",
            },
        ],
    },
    {
        data_id: "7",
        data_set: [
            {
                pat_appl_cnt: 286720,
                agt_ofc_nm: "북경집가지신산권대리유한공사",
                pat_grt_cnt: 136311,
                pat_grt_ratio: 47.54,
            },
            {
                pat_appl_cnt: 196696,
                agt_ofc_nm: "북경시류침률사사무소",
                pat_grt_cnt: 114623,
                pat_grt_ratio: 58.27,
            },
            {
                pat_appl_cnt: 193394,
                agt_ofc_nm: "김앤장 법률사무소",
                pat_grt_cnt: 103278,
                pat_grt_ratio: 53.4,
            },
            {
                pat_appl_cnt: 175010,
                agt_ofc_nm: "중국국제무역촉진위원회전리상표사무소",
                pat_grt_cnt: 116207,
                pat_grt_ratio: 66.4,
            },
            {
                pat_appl_cnt: 169793,
                agt_ofc_nm: "중과전리상표대리유한책임공사",
                pat_grt_cnt: 98614,
                pat_grt_ratio: 58.08,
            },
            {
                pat_appl_cnt: 146935,
                agt_ofc_nm: "북경삼우지신산권대리유한공사",
                pat_grt_cnt: 80957,
                pat_grt_ratio: 55.1,
            },
            {
                pat_appl_cnt: 139543,
                agt_ofc_nm: "상해전리상표사무소유한공사",
                pat_grt_cnt: 80117,
                pat_grt_ratio: 57.41,
            },
            {
                pat_appl_cnt: 134837,
                agt_ofc_nm: "영신전리상표대리유한공사",
                pat_grt_cnt: 79009,
                pat_grt_ratio: 58.6,
            },
            {
                pat_appl_cnt: 121751,
                agt_ofc_nm: "북경강신지신산권대리유한책임공사",
                pat_grt_cnt: 57611,
                pat_grt_ratio: 47.32,
            },
            {
                pat_appl_cnt: 121355,
                agt_ofc_nm: "북경품원전리대리유한공사",
                pat_grt_cnt: 42819,
                pat_grt_ratio: 35.28,
            },
            {
                pat_appl_cnt: 113742,
                agt_ofc_nm: "엄주삼배전리상표대리유한공사",
                pat_grt_cnt: 45879,
                pat_grt_ratio: 40.34,
            },
            {
                pat_appl_cnt: 110545,
                agt_ofc_nm: "중원신체지신산권대리유한책임공사",
                pat_grt_cnt: 65384,
                pat_grt_ratio: 59.15,
            },
            {
                pat_appl_cnt: 107997,
                agt_ofc_nm: "시가마사타케",
                pat_grt_cnt: 58592,
                pat_grt_ratio: 54.25,
            },
            {
                pat_appl_cnt: 105358,
                agt_ofc_nm: "리앤목 특허법인",
                pat_grt_cnt: 62954,
                pat_grt_ratio: 59.75,
            },
            {
                pat_appl_cnt: 95643,
                agt_ofc_nm: "이토오타다히코",
                pat_grt_cnt: 51345,
                pat_grt_ratio: 53.68,
            },
            {
                pat_appl_cnt: 92347,
                agt_ofc_nm: "유미 특허법인",
                pat_grt_cnt: 57646,
                pat_grt_ratio: 62.42,
            },
            {
                pat_appl_cnt: 92174,
                agt_ofc_nm: "북경시중자률사사무소",
                pat_grt_cnt: 53582,
                pat_grt_ratio: 58.13,
            },
            {
                pat_appl_cnt: 90939,
                agt_ofc_nm: "Sughrue Mion, PLLC",
                pat_grt_cnt: 76332,
                pat_grt_ratio: 83.94,
            },
            {
                pat_appl_cnt: 88061,
                agt_ofc_nm: "북경시김두률사사무소",
                pat_grt_cnt: 48068,
                pat_grt_ratio: 54.58,
            },
            {
                pat_appl_cnt: 87181,
                agt_ofc_nm: "쿠라타마사토시",
                pat_grt_cnt: 45561,
                pat_grt_ratio: 52.26,
            },
            {
                pat_appl_cnt: 86337,
                agt_ofc_nm: "북경로호지신산권대리유한공사",
                pat_grt_cnt: 40105,
                pat_grt_ratio: 46.45,
            },
            {
                pat_appl_cnt: 81330,
                agt_ofc_nm: "북경동립균성지신산권대리유한공사",
                pat_grt_cnt: 36950,
                pat_grt_ratio: 45.43,
            },
            {
                pat_appl_cnt: 79869,
                agt_ofc_nm: "무라야마야스히코",
                pat_grt_cnt: 43240,
                pat_grt_ratio: 54.14,
            },
            {
                pat_appl_cnt: 77534,
                agt_ofc_nm: "엄주가권전리상표사무소유한공사",
                pat_grt_cnt: 24083,
                pat_grt_ratio: 31.06,
            },
            {
                pat_appl_cnt: 77523,
                agt_ofc_nm: "제일 특허법인",
                pat_grt_cnt: 43743,
                pat_grt_ratio: 56.43,
            },
            {
                pat_appl_cnt: 77384,
                agt_ofc_nm: "남경경위전리상표대리유한공사",
                pat_grt_cnt: 32763,
                pat_grt_ratio: 42.34,
            },
            {
                pat_appl_cnt: 75436,
                agt_ofc_nm: "특허법인 코리아나",
                pat_grt_cnt: 41584,
                pat_grt_ratio: 55.12,
            },
            {
                pat_appl_cnt: 74447,
                agt_ofc_nm: "Birch, Stewart, Kolasch & Birch, LLP",
                pat_grt_cnt: 60697,
                pat_grt_ratio: 81.53,
            },
            {
                pat_appl_cnt: 73841,
                agt_ofc_nm: "아오키아츠시",
                pat_grt_cnt: 39858,
                pat_grt_ratio: 53.98,
            },
            {
                pat_appl_cnt: 73474,
                agt_ofc_nm: "엄주화진련합전리상표대리유한공사",
                pat_grt_cnt: 34607,
                pat_grt_ratio: 47.1,
            },
            {
                pat_appl_cnt: 72742,
                agt_ofc_nm: "북경은룡지신산권대리유한공사",
                pat_grt_cnt: 39326,
                pat_grt_ratio: 54.06,
            },
            {
                pat_appl_cnt: 68806,
                agt_ofc_nm: "미네타카시",
                pat_grt_cnt: 35445,
                pat_grt_ratio: 51.51,
            },
            {
                pat_appl_cnt: 68723,
                agt_ofc_nm: "나카무라마코토",
                pat_grt_cnt: 34396,
                pat_grt_ratio: 50.05,
            },
            {
                pat_appl_cnt: 68678,
                agt_ofc_nm: "HOFFMANN EITLE",
                pat_grt_cnt: 32096,
                pat_grt_ratio: 46.73,
            },
            {
                pat_appl_cnt: 68290,
                agt_ofc_nm: "이토오타다시게",
                pat_grt_cnt: 37023,
                pat_grt_ratio: 54.21,
            },
            {
                pat_appl_cnt: 67602,
                agt_ofc_nm: "Oblon, Spivak, McClelland, Maier & Neustadt, P.C.",
                pat_grt_cnt: 52925,
                pat_grt_ratio: 78.29,
            },
            {
                pat_appl_cnt: 67008,
                agt_ofc_nm: "온다 히로노부",
                pat_grt_cnt: 34740,
                pat_grt_ratio: 51.84,
            },
            {
                pat_appl_cnt: 65799,
                agt_ofc_nm: "후쿠하라요시히로",
                pat_grt_cnt: 34521,
                pat_grt_ratio: 52.46,
            },
            {
                pat_appl_cnt: 65442,
                agt_ofc_nm: "리인터내셔널 특허법률사무소",
                pat_grt_cnt: 35670,
                pat_grt_ratio: 54.51,
            },
            {
                pat_appl_cnt: 64813,
                agt_ofc_nm: "Fish & Richardson P.C.",
                pat_grt_cnt: 57611,
                pat_grt_ratio: 88.89,
            },
            {
                pat_appl_cnt: 64798,
                agt_ofc_nm: "온다 마코토",
                pat_grt_cnt: 34703,
                pat_grt_ratio: 53.56,
            },
            {
                pat_appl_cnt: 64127,
                agt_ofc_nm: "북경동체신항지신산권대리유한공사",
                pat_grt_cnt: 34290,
                pat_grt_ratio: 53.47,
            },
            {
                pat_appl_cnt: 63591,
                agt_ofc_nm: "무라마츠사다오",
                pat_grt_cnt: 30222,
                pat_grt_ratio: 47.53,
            },
            {
                pat_appl_cnt: 62599,
                agt_ofc_nm: "북경기개지신산권대리유한공사",
                pat_grt_cnt: 35326,
                pat_grt_ratio: 56.43,
            },
            {
                pat_appl_cnt: 62389,
                agt_ofc_nm: "미요시히데카즈",
                pat_grt_cnt: 28798,
                pat_grt_ratio: 46.16,
            },
            {
                pat_appl_cnt: 62306,
                agt_ofc_nm: "엄주월고전리상표대리유한공사",
                pat_grt_cnt: 26208,
                pat_grt_ratio: 42.06,
            },
            {
                pat_appl_cnt: 62176,
                agt_ofc_nm: "코오노테츠",
                pat_grt_cnt: 28688,
                pat_grt_ratio: 46.14,
            },
            {
                pat_appl_cnt: 61700,
                agt_ofc_nm: "남경종횡지신산권대리유한공사",
                pat_grt_cnt: 18938,
                pat_grt_ratio: 30.69,
            },
            {
                pat_appl_cnt: 59878,
                agt_ofc_nm: "나이토오히로키",
                pat_grt_cnt: 17729,
                pat_grt_ratio: 29.61,
            },
            {
                pat_appl_cnt: 59543,
                agt_ofc_nm: "안휘합비화신지신산권대리유한공사",
                pat_grt_cnt: 12751,
                pat_grt_ratio: 21.41,
            },
            {
                pat_appl_cnt: 59327,
                agt_ofc_nm: "북경안신방체지신산권대리유한공사",
                pat_grt_cnt: 29904,
                pat_grt_ratio: 50.41,
            },
            {
                pat_appl_cnt: 58846,
                agt_ofc_nm: "서안통대전리대리유한책임공사",
                pat_grt_cnt: 27653,
                pat_grt_ratio: 46.99,
            },
            {
                pat_appl_cnt: 58767,
                agt_ofc_nm: "북경중합성성지신산권대리유한공사",
                pat_grt_cnt: 19271,
                pat_grt_ratio: 32.79,
            },
            {
                pat_appl_cnt: 58718,
                agt_ofc_nm: "사네히로노부야",
                pat_grt_cnt: 31984,
                pat_grt_ratio: 54.47,
            },
            {
                pat_appl_cnt: 58001,
                agt_ofc_nm: "Harness, Dickey & Pierce, P.L.C.",
                pat_grt_cnt: 50373,
                pat_grt_ratio: 86.85,
            },
            {
                pat_appl_cnt: 57476,
                agt_ofc_nm: "사카이히로아키",
                pat_grt_cnt: 32923,
                pat_grt_ratio: 57.28,
            },
            {
                pat_appl_cnt: 55129,
                agt_ofc_nm: "하세가와요시키",
                pat_grt_cnt: 29377,
                pat_grt_ratio: 53.29,
            },
            {
                pat_appl_cnt: 54388,
                agt_ofc_nm: "노가와노부히사",
                pat_grt_cnt: 30923,
                pat_grt_ratio: 56.86,
            },
            {
                pat_appl_cnt: 53958,
                agt_ofc_nm: "심수시세기항정지신산권대리사무소",
                pat_grt_cnt: 19006,
                pat_grt_ratio: 35.22,
            },
            {
                pat_appl_cnt: 53858,
                agt_ofc_nm: "Knobbe, Martens, Olson & Bear, LLP",
                pat_grt_cnt: 45241,
                pat_grt_ratio: 84,
            },
            {
                pat_appl_cnt: 53562,
                agt_ofc_nm: "나카지마준",
                pat_grt_cnt: 25851,
                pat_grt_ratio: 48.26,
            },
            {
                pat_appl_cnt: 53076,
                agt_ofc_nm: "와타나베타카시",
                pat_grt_cnt: 27634,
                pat_grt_ratio: 52.06,
            },
            {
                pat_appl_cnt: 52883,
                agt_ofc_nm: "북경윤평지신산권대리유한공사",
                pat_grt_cnt: 25717,
                pat_grt_ratio: 48.63,
            },
            {
                pat_appl_cnt: 52798,
                agt_ofc_nm: "야마모토히데사쿠",
                pat_grt_cnt: 20943,
                pat_grt_ratio: 39.67,
            },
            {
                pat_appl_cnt: 51068,
                agt_ofc_nm: "특허법인 남앤남",
                pat_grt_cnt: 27179,
                pat_grt_ratio: 53.22,
            },
            {
                pat_appl_cnt: 51003,
                agt_ofc_nm: "카토오카즈아키",
                pat_grt_cnt: 26274,
                pat_grt_ratio: 51.51,
            },
            {
                pat_appl_cnt: 50699,
                agt_ofc_nm: "북경경창지신산권대리유한공사",
                pat_grt_cnt: 13434,
                pat_grt_ratio: 26.5,
            },
            {
                pat_appl_cnt: 49419,
                agt_ofc_nm: "모리시타나츠키",
                pat_grt_cnt: 19843,
                pat_grt_ratio: 40.15,
            },
            {
                pat_appl_cnt: 48546,
                agt_ofc_nm: "Cantor Colburn LLP",
                pat_grt_cnt: 44641,
                pat_grt_ratio: 91.96,
            },
            {
                pat_appl_cnt: 48406,
                agt_ofc_nm: "엄주시화학지신산권대리유한공사",
                pat_grt_cnt: 23444,
                pat_grt_ratio: 48.43,
            },
            {
                pat_appl_cnt: 48210,
                agt_ofc_nm: "특허법인 씨엔에스",
                pat_grt_cnt: 29561,
                pat_grt_ratio: 61.32,
            },
            {
                pat_appl_cnt: 47938,
                agt_ofc_nm: "한양 특허법인",
                pat_grt_cnt: 28614,
                pat_grt_ratio: 59.69,
            },
            {
                pat_appl_cnt: 47717,
                agt_ofc_nm: "항주구시전리사무소유한공사",
                pat_grt_cnt: 27097,
                pat_grt_ratio: 56.79,
            },
            {
                pat_appl_cnt: 47431,
                agt_ofc_nm: "코오노나오키",
                pat_grt_cnt: 27022,
                pat_grt_ratio: 56.97,
            },
            {
                pat_appl_cnt: 47008,
                agt_ofc_nm: "북경천호련합지신산권대리유한공사",
                pat_grt_cnt: 27208,
                pat_grt_ratio: 57.88,
            },
            {
                pat_appl_cnt: 46882,
                agt_ofc_nm: "이토오마사카즈",
                pat_grt_cnt: 21890,
                pat_grt_ratio: 46.69,
            },
            {
                pat_appl_cnt: 46023,
                agt_ofc_nm: "특허법인 아주",
                pat_grt_cnt: 28148,
                pat_grt_ratio: 61.16,
            },
            {
                pat_appl_cnt: 45949,
                agt_ofc_nm: "Foley & Lardner LLP",
                pat_grt_cnt: 43926,
                pat_grt_ratio: 95.6,
            },
            {
                pat_appl_cnt: 45902,
                agt_ofc_nm: "스즈에타케히코",
                pat_grt_cnt: 12848,
                pat_grt_ratio: 27.99,
            },
            {
                pat_appl_cnt: 45854,
                agt_ofc_nm: "제남골체지신산권대리유한공사",
                pat_grt_cnt: 23853,
                pat_grt_ratio: 52.02,
            },
            {
                pat_appl_cnt: 45707,
                agt_ofc_nm: "이와하시후미오",
                pat_grt_cnt: 7065,
                pat_grt_ratio: 15.46,
            },
            {
                pat_appl_cnt: 45703,
                agt_ofc_nm: "북경률성동업지신산권대리유한공사",
                pat_grt_cnt: 24565,
                pat_grt_ratio: 53.75,
            },
            {
                pat_appl_cnt: 45115,
                agt_ofc_nm: "박장원 특허법률사무소",
                pat_grt_cnt: 29004,
                pat_grt_ratio: 64.29,
            },
            {
                pat_appl_cnt: 44567,
                agt_ofc_nm: "Oblon, McClelland, Maier & Neustadt, L.L.P.",
                pat_grt_cnt: 44567,
                pat_grt_ratio: 100,
            },
            {
                pat_appl_cnt: 44468,
                agt_ofc_nm: "타카하시쇼오오",
                pat_grt_cnt: 25082,
                pat_grt_ratio: 56.4,
            },
            {
                pat_appl_cnt: 43785,
                agt_ofc_nm: "북경삼고영신지신산권대리유한책임공사",
                pat_grt_cnt: 25789,
                pat_grt_ratio: 58.9,
            },
            {
                pat_appl_cnt: 43595,
                agt_ofc_nm: "북경파특은지신산권대리유한공사",
                pat_grt_cnt: 19482,
                pat_grt_ratio: 44.69,
            },
            {
                pat_appl_cnt: 43514,
                agt_ofc_nm: "북경고옥률사사무소",
                pat_grt_cnt: 16555,
                pat_grt_ratio: 38.05,
            },
            {
                pat_appl_cnt: 43242,
                agt_ofc_nm: "상해과성지신산권대리유한공사",
                pat_grt_cnt: 15752,
                pat_grt_ratio: 36.43,
            },
            {
                pat_appl_cnt: 43163,
                agt_ofc_nm: "특허법인(유한)케이비케이",
                pat_grt_cnt: 26540,
                pat_grt_ratio: 61.49,
            },
            {
                pat_appl_cnt: 42412,
                agt_ofc_nm: "남경정련지신산권대리유한공사",
                pat_grt_cnt: 8063,
                pat_grt_ratio: 19.01,
            },
            {
                pat_appl_cnt: 41519,
                agt_ofc_nm: "북경명석지신산권대리유한공사",
                pat_grt_cnt: 21466,
                pat_grt_ratio: 51.7,
            },
            {
                pat_appl_cnt: 41271,
                agt_ofc_nm: "후쿠다히로시",
                pat_grt_cnt: 20418,
                pat_grt_ratio: 49.47,
            },
            {
                pat_appl_cnt: 41028,
                agt_ofc_nm: "아인젤 펠릭스라인하르트",
                pat_grt_cnt: 22989,
                pat_grt_ratio: 56.03,
            },
            {
                pat_appl_cnt: 40878,
                agt_ofc_nm: "타카하시슌이치",
                pat_grt_cnt: 18078,
                pat_grt_ratio: 44.22,
            },
            {
                pat_appl_cnt: 40468,
                agt_ofc_nm: "Nixon & Vanderhye P.C.",
                pat_grt_cnt: 33425,
                pat_grt_ratio: 82.6,
            },
            {
                pat_appl_cnt: 40462,
                agt_ofc_nm: "스나카와카츠루",
                pat_grt_cnt: 23201,
                pat_grt_ratio: 57.34,
            },
            {
                pat_appl_cnt: 40389,
                agt_ofc_nm: "나가노다이스케",
                pat_grt_cnt: 14825,
                pat_grt_ratio: 36.71,
            },
            {
                pat_appl_cnt: 40296,
                agt_ofc_nm: "특허법인 태평양",
                pat_grt_cnt: 20951,
                pat_grt_ratio: 51.99,
            },
            {
                pat_appl_cnt: 39904,
                agt_ofc_nm: "북경동방억사지신산권대리유한책임공사",
                pat_grt_cnt: 20311,
                pat_grt_ratio: 50.9,
            },
        ],
    },
];

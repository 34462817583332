import V1 from "@assets/img/v1.png";
import V2 from "@assets/img/v2.png";
import V3 from "@assets/img/v3.png";

export const searchCategoriesData = [
    { path: "/consearch/patent", label: "특허" },
    { path: "/consearch/company", label: "기업" },
    { path: "/consearch/sales", label: "판매기술" },
    { path: "/consearch/reseacher", label: "연구자" },
    { path: "/consearch/lawsuit", label: "분쟁정보" },
    { path: "/consearch/news", label: "뉴스" },
];
export const menuCategoriesData = [
    { id: 1, includesVal: "dash", path: "/dash", label: "대시보드", className: "dash no-child" },
    { id: 2, includesVal: "consearch", path: "/consearch/patent", label: "융합검색", className: "search" },
    {
        app: [
            { id: 3, includesVal: "tech", path: "/apps/tech/trade", label: "TechStorm-NTB" },
            { id: 4, includesVal: "build", path: "/apps/build", label: "Build" },
            { id: 5, includesVal: "patentpia", path: "/apps/patentpia", label: "patentpia", state: "03_00" },
        ],
    },
    { id: 6, includesVal: "reports", path: "/reports/brief", label: "Reports", className: "reports" },
];

export const imgData = [
    { id: 1, $hasImg: "true", $img: V1, path: "/apps/tech/trade" },
    { id: 2, $hasImg: "true", $img: V2, path: "/apps/build" },
    { id: 3, $hasImg: "true", $img: V3, path: "/apps/patentpia" },
    { id: 4, $hasImg: "false" },
    { id: 5, $hasImg: "false" },
    { id: 6, $hasImg: "false" },
    { id: 7, $hasImg: "false" },
    { id: 8, $hasImg: "false" },
    { id: 9, $hasImg: "false" },
    { id: 10, $hasImg: "false" },
    { id: 11, $hasImg: "false" },
    { id: 12, $hasImg: "false" },
    { id: 13, $hasImg: "false" },
    { id: 14, $hasImg: "false" },
    { id: 15, $hasImg: "false" },
    { id: 16, $hasImg: "false" },
    { id: 17, $hasImg: "false" },
    { id: 18, $hasImg: "false" },
    { id: 19, $hasImg: "false" },
    { id: 20, $hasImg: "false" },
    { id: 21, $hasImg: "false" },
    { id: 22, $hasImg: "false" },
    { id: 23, $hasImg: "false" },
    { id: 24, $hasImg: "false" },
];

export const cateBtns = [
    {
        id: "techCate",
        name: "기술분야",
    },
    {
        id: "matCate",
        name: "소재물질분야",
    },
    {
        id: "proCate",
        name: "제품부품분야",
    },
];

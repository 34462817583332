import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect } from "react";
import { IValueBasedProps } from "types/etcTypes";

const ValueBased = ({ chartID, width, height, data }: IValueBasedProps) => {
    data.sort((a, b) => a.x - b.x);

    am5.addLicense("AM5C400641047");

    useEffect(() => {
        const root = am5.Root.new(chartID);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelY: "zoomXY",
                pinchZoomX: true,
                pinchZoomY: true,
            }),
        );

        // @ts-ignore
        // chart.get("colors").set("step", 2);
        // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
        chart
            .get("colors")
            ?.set("colors", [
                am5.color(0xe2933a),
                am5.color(0x087f8c),
                am5.color(0x5aaa95),
                am5.color(0x86a873),
                am5.color(0xbb9f06),
            ]);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );

        xAxis.children.moveValue(
            am5.Label.new(root, {
                text: "기업 수(개)",
                x: am5.p50,
                centerX: am5.p50,
            }),
            xAxis.children.length - 1,
        );

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );

        yAxis.children.moveValue(
            am5.Label.new(root, {
                rotation: -90,
                text: "출원건수(건)",
                y: am5.p50,
                centerX: am5.p50,
            }),
            0,
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series0 = chart.series.push(
            am5xy.LineSeries.new(root, {
                calculateAggregates: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                valueXField: "x",
                valueField: "value",
                tooltip: am5.Tooltip.new(root, {
                    // labelText: "{title}: {value}",
                    labelText: "{title}",
                }),
            }),
        );

        // add series range
        // let seriesRangeDataItem1 = yAxis.makeDataItem({ value: 430000, endValue: 0 });
        // let seriesRange1 = series0.createAxisRange(seriesRangeDataItem1);

        // if (seriesRange1.fills && seriesRange1.strokes) {
        //     seriesRange1.fills.template.setAll({
        //         visible: true,
        //         opacity: 0.3,
        //     });
        //     seriesRange1.fills.template.set("fill", am5.color(0x000000));
        //     seriesRange1.strokes.template.set("stroke", am5.color(0x000000));
        // }

        // if (seriesRangeDataItem1 !== undefined) {
        //     //@ts-ignore
        //     seriesRangeDataItem1.get("grid").setAll({
        //         strokeOpacity: 1,
        //         visible: true,
        //         stroke: am5.color(0x000000),
        //         strokeDasharray: [2, 2],
        //     });
        //     //@ts-ignore
        //     seriesRangeDataItem1.get("label").setAll({
        //         location: 0,
        //         visible: true,
        //         text: "평균",
        //         inside: true,
        //         centerX: 0,
        //         centerY: am5.p100,
        //         fontWeight: "bold",
        //     });
        // }

        // let seriesRangeDataItem = xAxis.makeDataItem({});
        // let seriesRange = series0.createAxisRange(seriesRangeDataItem);
        // if (seriesRange.fills) {
        //     seriesRange.fills.template.setAll({
        //         visible: true,
        //         opacity: 0.3,
        //     });

        //     seriesRange.fills.template.set(
        //         "fillPattern",
        //         am5.LinePattern.new(root, {
        //             color: am5.color(0xff0000),
        //             rotation: 45,
        //             strokeWidth: 2,
        //             width: 2000,
        //             height: 2000,
        //             fill: am5.color(0xffffff),
        //         }),
        //     );
        // }

        // if (seriesRange.strokes) {
        //     seriesRange.strokes.template.set("stroke", am5.color(0xff0000));

        //     xAxis.onPrivate("max", function (value) {
        //         seriesRangeDataItem.set("endValue", 38000);
        //         seriesRangeDataItem.set("value", 38100);
        //     });
        // }

        // Add bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
        let circleTemplate = am5.Template.new({});
        series0.bullets.push(function () {
            let graphics = am5.Circle.new(
                root,
                {
                    fill: series0.get("fill"),
                },
                // @ts-ignore
                circleTemplate,
            );
            return am5.Bullet.new(root, {
                sprite: graphics,
            });
        });

        // Add heat rule
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series0.set("heatRules", [
            {
                target: circleTemplate,
                min: 3,
                max: 35,
                dataField: "value",
                key: "radius",
            },
        ]);

        // Create second series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series1 = chart.series.push(
            am5xy.LineSeries.new(root, {
                calculateAggregates: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y2",
                valueXField: "x2",
                valueField: "value",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{title} {value}",
                }),
            }),
        );

        // Add bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
        let starTemplate = am5.Template.new({});
        series1.bullets.push(function () {
            let graphics = am5.Star.new(
                root,
                {
                    fill: series1.get("fill"),
                    spikes: 8,
                    innerRadius: am5.percent(70),
                },
                // @ts-ignore
                starTemplate,
            );
            return am5.Bullet.new(root, {
                sprite: graphics,
            });
        });

        // Add heat rule
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series1.set("heatRules", [
            {
                target: starTemplate,
                min: 3,
                max: 50,
                dataField: "value",
                key: "radius",
            },
        ]);

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                snapToSeries: [series0, series1],
            }),
        );

        series0.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series0.appear(1000);

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartID]);
    return <div id={chartID} style={{ width: `${width}px`, height: `${height}px` }} />;
};

export default ValueBased;

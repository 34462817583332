/**
 * 빈 값 체크
 * 데이터 접근 시 옵셔널 체이닝으로 인한 타입 가드 체크
 * @param value
 * @returns
 */
export const isDefined = <T>(value: T | undefined): value is T => {
    return value !== undefined || value !== null;
};

export const formatNumberWithCommas = (count: number) => {
    if (count === undefined || count === null) return "0";
    if (!count) return count;
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0";
};
export const handleText = (text: string, limit: number) => {
    let temp = "";

    if (text.length > limit) {
        temp = `${text.substring(0, limit)}...`;
    } else {
        temp = text;
    }

    return temp;
};

export const formatNumber = (number: number) => {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // 숫자가 음수인 경우 처리
    const isNegative = number < 0;
    if (isNegative) {
        number = -number;
    }

    // 숫자가 0인 경우 처리
    if (number === 0) {
        return "0";
    }

    // 숫자를 지수 형태로 변환
    const exponent = Math.floor(Math.log10(number));
    const exponentIndex = Math.min(Math.floor(exponent / 3), SI_SYMBOL.length - 1);

    // 실제 숫자와 지수의 계산
    const mantissa = number / Math.pow(10, exponentIndex * 3);
    const abbreviation = SI_SYMBOL[exponentIndex];

    // 음수일 경우 처리
    const formattedNumber = isNegative ? "-" + mantissa : mantissa;

    return `${formattedNumber}${abbreviation}`;
};

export const commaNumber = (number: number | string) => {
    const cn = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

    return cn;
};

// 테크스톰 d-day

export const calDay = (date: string) => {
    setInterval(() => {}, 1000);
    const targetDate = new Date(`${date}`);
    const currentDate = new Date();

    const timeDiff = targetDate.valueOf() - currentDate.valueOf();

    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // 일
    const hoursDiff = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // 시간
    const minutesDiff = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)); // 분
    const secondsDiff = Math.floor((timeDiff % (1000 * 60)) / 1000); // 초

    if (daysDiff <= 0) {
        const formattedDday = `${date} 지났습니다.`;
        const isAbled = false;
        return { formattedDday, isAbled };
    } else {
        const formattedDday = `${daysDiff}일 ${hoursDiff < 10 ? `0${hoursDiff}` : hoursDiff}시 ${
            minutesDiff < 10 ? `0${minutesDiff}` : minutesDiff
        }분 ${secondsDiff < 10 ? `0${secondsDiff}` : secondsDiff}초`;
        const isAbled = true;
        return { formattedDday, isAbled };
    }
};

const addZero = (num: number) => {
    const stringNum = num < 10 ? `0${num}` : num;

    return stringNum;
};

export const getDate = () => {
    const toDay = new Date();
    const returnDate = `${toDay.getFullYear()}-${addZero(toDay.getMonth() + 1)}-${addZero(toDay.getDate())} ${addZero(
        toDay.getHours(),
    )}:${addZero(toDay.getMinutes())}:${addZero(toDay.getSeconds())}`;

    return returnDate;
};

export const popVariants = {
    initial: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
    leaving: {
        opacity: 0,
    },
};

export const maxPopVariants = {
    initial: {
        maxHeight: 0,
    },
    visible: {
        maxHeight: 300,
        transition: {
            type: "leanear",
            duration: 0.4,
        },
    },
    leaving: {
        maxHeight: 0,
        transition: {
            type: "leanear",
            duration: 0.4,
        },
    },
};

export const yPositionPopVariants = {
    initial: {
        opacity: 0,
        y: 5,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
    leaving: {
        opacity: 0,
        y: 5,
    },
};

export const timeFormat = (time: Date) => {
    return `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()} : ${
        time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
    } : ${time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds()}`;
};

// 유사특허검색 > 출원번호 포맷
const formatApplicationNumber = (input: string) => {
    return `${input.slice(0, 2)}-${input.slice(2, 6)}-${input.slice(6, 13)}`;
};

// 유사특허검색 > 등록번호 포맷
const formatRegistrationNumber = (input: string) => {
    return `${input.slice(0, 2)}-${input.slice(2, 9)}`;
};

export const formatSearchNumber = (type: string, search_num: string) => {
    return type === "y" ? formatApplicationNumber(search_num) : formatRegistrationNumber(search_num);
};

// Apps > 테크스톰 썸네일 이미지 확장자 포맷
export const formatThumbnail = (thumbnailUrl: string) => {
    const imageRegExp = /\.(png|jpg|jpge)$/;
    return imageRegExp.test(thumbnailUrl);
};

import { useEffect, useState } from "react";

interface IPageNation {
    totalPage: number;
    currentPage: number;
    offset: number;
    handleClick: (num: number) => void;
}

const PageNation = ({ totalPage, currentPage, offset, handleClick }: IPageNation) => {
    const [pagination, setPagination] = useState({
        pageOffset: offset,
        pageRange: [1],
    });

    const createPageRange = () => {
        const pageRange: number[] = [];
        const { pageOffset } = pagination;
        let startPage = 1,
            endPage = 1;

        if (currentPage > 2) {
            startPage = currentPage - 1;
        }
        endPage = startPage + pageOffset - 1;

        if (endPage > totalPage) {
            startPage = startPage - (endPage - totalPage);
            if (startPage < 1) startPage = 1;
            endPage = totalPage;
        }

        for (let i = startPage; i <= endPage; i++) {
            pageRange.push(i);
        }
        setPagination((state) => ({ ...state, pageRange }));
    };

    useEffect(() => {
        createPageRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPage]);

    return (
        <div className="p__pagination">
            {currentPage === 1 ? (
                <button className="b__prev disabled" disabled />
            ) : (
                <button className="b__prev" onClick={() => handleClick(currentPage - 1)} />
            )}

            {pagination.pageRange.map((p, p_idx) => (
                <button key={p_idx} onClick={() => handleClick(p)} className={currentPage === p ? "active" : ""}>
                    {p}
                </button>
            ))}
            {currentPage === totalPage ? (
                <button className="b__next disabled" disabled />
            ) : (
                <button className="b__next" onClick={() => handleClick(currentPage + 1)} />
            )}
        </div>
    );
};

export default PageNation;

import NoSearchResults from "@components/CardComp/NoSearchResults";
import LoadingComp from "@components/LoadingComp";
import { CoreCompany } from "@pages/Main/Graph";
import { PropsWithChildren } from "react";
import { ICoreCompany } from "~types/wipstypes";

const CoreCorporation = ({ coreCompany }: PropsWithChildren<{ coreCompany: ICoreCompany }>) => {
    let content;

    if (Object.keys(coreCompany).length === 0) {
        content = (
            <div className="c__loading">
                <LoadingComp />
            </div>
        );
    } else if (coreCompany.data.length === 0) {
        content = <NoSearchResults />;
    } else {
        content = <CoreCompany data={coreCompany.data} label={coreCompany.label} />;
    }

    return <div className="p__center">{content}</div>;
};

export default CoreCorporation;

export default function TableRow({ rowText, rowClass }: { rowText: string; rowClass: string }) {
    return (
        <div className="thead">
            <div className="tr">
                <div className="th num">No.</div>
                <div className="th appl-num">출원 번호</div>
                <div className="th inv-ti">발명의 명칭</div>
                <div className="th appl-com">대표 출원인</div>
                <div className={`th ${rowClass}`}>{rowText}</div>
            </div>
        </div>
    );
}

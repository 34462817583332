export const COLOR = [
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
];

export const AGENTCOLOR = [
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
    "#3898c9",
    "#ffcc0a",
    "#e69dab",
];

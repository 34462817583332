import { selectedTextAtom } from "@atoms";
import { AnimatePresence, motion } from "framer-motion";
import { useSetAtom } from "jotai";

interface SelectBoxProps {
    toggle: boolean;
    setToggle: (value: boolean) => void;
    setType: (value: string) => void;
    setSearch_num: (value: string) => void;
}

export default function SelectBox({ toggle, setToggle, setType, setSearch_num }: SelectBoxProps) {
    const setSelectedText = useSetAtom(selectedTextAtom);

    const handleSelect = (value: string) => {
        setSelectedText(value === "y" ? "출원 번호" : "등록 번호");
        setType(value);
        setToggle(false);
        setSearch_num("");
    };

    return (
        <AnimatePresence>
            {toggle && (
                <motion.ul
                    className="c__select-item"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <li>
                        <button onClick={() => handleSelect("y")}>출원 번호</button>
                    </li>
                    <li>
                        <button onClick={() => handleSelect("n")}>등록 번호</button>
                    </li>
                </motion.ul>
            )}
        </AnimatePresence>
    );
}

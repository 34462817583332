import { styled } from "styled-components";

import { ScrollBar } from "@styles/common";
import { device } from "@assets/index";

export const Wrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @media ${device.tabletL} {
        flex-direction: column;
    }

    align-items: flex-start;
    justify-content: center;
    width: 100%;
    /* height: calc(100vh - 95px); */
    background-color: ${(props) => props.theme.back};

    .l__main {
        width: calc(100% - 300px);

        @media ${device.tabletL} {
            width: 100%;
            overflow-y: initial;
            overflow-x: initial;
            padding-right: 20px;
        }

        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        padding-right: 7px;
        box-sizing: border-box;

        .inner__main {
            @media ${device.tabletL} {
                ${ScrollBar}
                height: calc(100vh - 174px);
                padding-right: 7px;
                overflow-y: initial;
                height: auto;
            }

            @media ${device.tabletL} {
                padding-right: 0;
            }

            padding-top: 24px;
        }
    }

    .l__banner {
        flex-basis: 300px;
        padding-top: 25px;
        padding-left: 16px;
        padding-right: 5px;
        background-color: #fff;
        border-left: 1px solid #ebebf0;
        box-sizing: border-box;

        @media ${device.tabletL} {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            background-color: transparent;
        }

        .inner__banner {
            ${ScrollBar}
            height: calc(100vh - 95px);
            padding-right: 5px;
            @media ${device.tabletL} {
                overflow-y: initial;
                height: auto;
                padding-right: 0;
            }
        }
    }

    .c__card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 17px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 17px;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .c__graph,
    .c__lists {
        display: flex;
        justify-content: space-between;
    }

    .c__intro {
        display: flex;
        justify-content: space-between;
        padding-right: 13px;

        @media ${device.tablet} {
            flex-direction: column;
            justify-content: left;
            padding-right: 0;
        }

        .p__intro {
            font-family: "Noto Sans KR", sans-serif;
            font-size: 18px;
            font-weight: 300;
            color: #333;

            span {
                font-weight: 500;
            }
        }

        .p__intro2 {
            margin-top: 10px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #666;
        }

        .c__select {
            position: relative;
            top: 4px;

            @media ${device.tablet} {
                margin-top: 16px;
            }

            .item__select {
                z-index: 5;
                overflow: hidden;
                position: absolute;
                width: 100%;
                background: #fff;
                border-radius: 4px;
                box-shadow: 1px 1px 6px 0px gray;
                top: 40px;

                li {
                    padding: 4px 15px 4px;
                    @media ${device.mobile} {
                        padding: 6px 15px 6px;
                    }

                    button {
                        width: 100%;
                        color: #666;
                        text-align: left;

                        @media ${device.mobile} {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .btn__select {
            position: relative;
            width: 248px;
            height: 38px;
            padding-left: 15px;
            padding-right: 26px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #299cdb;
            text-align: left;
            border: 1px solid #299cdb;
            border-radius: 3px;
            background-color: #fff;
            box-sizing: border-box;

            @media ${device.tablet} {
                width: 100%;
                font-size: 16px;
            }

            .c__arrow {
                position: absolute;
                top: 6px;
                right: 13px;
            }
        }

        .bg {
            position: absolute;
            width: 252px;
            height: 42px;
            background: #d9e2f8;
            top: -2px;
            left: -2px;
            border-radius: 4px;

            @media ${device.tablet} {
                width: 100%;
            }
        }
    }

    .c__graph {
        margin-top: 16px;
        display: flex;
    }

    .c__lists {
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 16px;
        }
    }

    .c__company {
        display: grid;
        grid-template-columns: 2.17fr 1fr;
        column-gap: 16px;
        margin-top: 16px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 16px;
        }
    }
`;

import { AxiosTechStrom } from "@api/index";
import { ITechData, ITechDataReq, ITechShare, ITechShareCount } from "~types/types";

interface PagingTechDataProps {
    page: number;
    size: number;
    keyWord: string;
}

// 테크스톰
export const patchTechStrom = async (requestBody: ITechDataReq): Promise<ITechData> =>
    AxiosTechStrom.patch(`/tech?page=1&size=20&sort=t.createDate,desc`, requestBody)
        .then((res) => res.data.data)
        .catch((error) => {
            console.log("error", error);
        });

export const fetchTechPagingData = async ({ page, size, keyWord }: PagingTechDataProps) => {
    const requestBody = {
        fileCds: [],
        hasPatentOnly: false,
        industryTechCds: [],
        maxPrice: 9007199254740991,
        minPrice: 0,
        regTypeCds: [],
        saleStatusCds: [],
        scienceTechCds: [],
        techCategoryCds: [],
        techContractCds: [],
        techName: keyWord,
        techTypeCds: [],
        tradeTypeCds: [],
    };

    const { data } = await AxiosTechStrom.patch(`/tech?page=${page}&size=${size}&sort=t.createDate,desc`, requestBody);
    return data.data;
};

export const patchTechShare = async (code: string): Promise<ITechShare> =>
    AxiosTechStrom.patch(`/techshare/list?page=1&size=10&sort=createDate,desc`, {
        statusCd: code,
    })
        .then((res) => res.data.data)
        .catch((error) => {
            console.log("error", error);
        });

export const getTechShareCount = async (): Promise<ITechShareCount> =>
    AxiosTechStrom.get(`/techshare/count`)
        .then((res) => res.data.data)
        .catch((error) => {
            console.log("error", error);
        });

import { styled } from "styled-components";

import Chain from "@assets/img/icon-green.png";
import Prev from "@assets/icon/swiper-prev.svg";
import Next from "@assets/icon/swiper-next.svg";
import prevSwiper from "@assets/img/prev.png";
import nextSwiper from "@assets/img/next.png";
import { ScrollBar } from "@styles/common";
import { device } from "@assets/index";

export const Wrap = styled.div`
    ${ScrollBar}
    height: calc(100vh - 500px);
    padding-top: 16px;
    padding-right: 4px;
    padding-bottom: 20px;

    @media ${device.tablet} {
        overflow-y: initial;
        height: auto;
    }

    .l__cont {
        margin-top: 16px;
        display: grid;
        grid-column-gap: 15px;

        &.two {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 16px;

            @media ${device.tabletL} {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.laptopS {
            @media ${device.laptopS} {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.three {
            grid-template-columns: 1fr 1fr 2fr;
        }

        &:first-child {
            margin-top: 0;
        }

        .c__cont {
            position: relative;
            height: 276px;
            background: #fff;
            box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
        }

        .c__cont2 {
            position: relative;
            height: 220px;
            background: #fff;
            box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
        }

        .c__cont3 {
            position: relative;
            height: 330px;
            background: #fff;
            box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .p__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;

        strong {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 18px;
            color: #333;
        }

        button {
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(${Chain});
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }
    }

    .l__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .c__flex {
            flex-basis: calc(50% - 7.5px);
            background-color: #fff;
        }
    }

    .wrap__swiper {
        width: 87%;
        display: inline-block;
        position: absolute;
        top: 99px;
        right: 0;

        .swiper {
            width: 100%;
            height: 177px;
        }

        .swiper-slide {
            width: 290px;
            height: 96px;
            background: #edf0fb;
            border-radius: 10px;
        }
        .swiper-pagination {
            position: absolute;
            font-size: 0;
            text-align: left;
            display: inline-block;
            bottom: 10px;
            right: 38px;

            span {
                display: inline-block;
                vertical-align: middle;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #333;
            }
        }

        .swiper-button-prev {
            z-index: 10;
            position: absolute;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            background-image: url(${Prev});
            bottom: 9px;
            right: 74px;
            cursor: pointer;
        }
        .swiper-button-next {
            z-index: 10;
            position: absolute;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            background-image: url(${Next});
            bottom: 9px;
            right: 16px;
            cursor: pointer;
        }
    }

    .wrap__simpleSwiper {
        position: absolute;
        width: calc(84%);
        left: 50%;
        top: 138px;
        transform: translate(-50%, -50%);
        /* padding: 0 24px 0; */

        .swiper-slide {
            display: block;
            height: 70px;
            text-align: center;
        }

        .t__title {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #333;
        }

        .t__num {
            margin-top: 12px;
            font-family: "Spoqa Han Sans Neo";
            font-weight: 700;
            font-size: 40px;
            color: #333;
        }

        .swiper-button-prev {
            z-index: 10;
            position: absolute;
            width: 10px;
            height: 17px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            background-image: url(${Prev});
            top: 26px;
            left: 0;
            cursor: pointer;
        }
        .swiper-button-next {
            z-index: 10;
            position: absolute;
            width: 10px;
            height: 17px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            background-image: url(${Next});
            top: 26px;
            right: 0;
            cursor: pointer;
        }
    }

    .l__gauges {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 16px;

        @media ${device.tablet} {
            grid-template-columns: repeat(1, 1fr);
        }

        .outer {
            text-align: center;
        }
        .inner {
            display: inline-block;
            max-width: 370px;
        }
    }

    .c__loading {
        z-index: 5;
        position: absolute;
        display: flex;
        width: calc(100% - 60px);
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .patentPiaCompanySwiper {
        .swiper-button-prev,
        .swiper-button-next {
            z-index: 10;
            position: absolute;
            width: 22px;
            height: 20px;
            top: 50%;
            margin-top: -10px;
            background-position: 50%;
            background-repeat: no-repeat;
            cursor: pointer;

            &.swiper-button-disabled {
                display: none;
            }
        }

        .swiper-button-prev {
            background-image: url(${prevSwiper});
            left: 18px;
        }

        .swiper-button-next {
            background-image: url(${nextSwiper});
            right: 18px;
        }
    }
`;

import useTabs from "@hooks/useTabs";

import { Wrap } from "../style";

import tooltipIcon from "@assets/icon/tooltip.svg";
import { CloudDesc } from "@styles/Common/style";
import Alls from "./Alls";
import Ntb from "./Ntb";
import SimilarPatentSearch from "./SimilarPatentSearch";

const allTabls = [
    {
        id: 0,
        name: "전체",
        data: <Alls />,
    },
    {
        id: 1,
        name: "NTB",
        data: <Ntb />,
    },
    {
        id: 2,
        name: "유사특허검색",
        data: <SimilarPatentSearch />,
    },
];

const PatentSearch = () => {
    // const [cate, setCate] = useState("KSIC 산업 대분류선택");
    // const [isSelect, setIsSelect] = useState(false);
    const { currentItem, changeItem, currentIndex } = useTabs(0, allTabls);

    return (
        <Wrap>
            <div className="c__content-header">
                <ul className="c__tab-menu">
                    {allTabls.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => changeItem(item.id)}
                            className={currentIndex === item.id ? "active tab-item" : "tab-item"}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
                <div className="c__content-state">
                    <div className="c__tooltip">
                        <div className="tooltip-title">
                            <p className="tooltip-text">데이터 제공 안내</p>
                            <button type="button" className="tooltip-icon">
                                <img src={tooltipIcon} alt="logo" />
                                <CloudDesc className="tooltip-desc">
                                    * 특허탐색 업데이트주기(NTB DB도 동일) <br />- 월2회, 매달 둘째주 금요일, 마지막주
                                    수요일
                                </CloudDesc>
                            </button>
                        </div>
                    </div>
                    <button
                        className="btn__solid"
                        onClick={() => window.open("https://focus.build-data.co.kr/NTB/board/OB014")}
                    >
                        특허탐색 자세히 보기
                    </button>
                </div>
            </div>

            <div className="c__content">{currentItem.data}</div>
        </Wrap>
    );
};

export default PatentSearch;

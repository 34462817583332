import { patchCompanyApi } from "@api/searchCompanyApi";
import { ICompanyListProps } from "~types/types";
import { companyPageAtom, keyWordStorageAtom, searchCompanyListAtom } from "@atoms";
import { useMutation } from "@tanstack/react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

export const useCompany = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const [dataTotalCount, setDataTotalCount] = useState<number>(0);
    const [didMount, setDidMount] = useState(false);
    const setSearchCompanyList = useSetAtom(searchCompanyListAtom);
    const [companyPage, setCompanyPage] = useAtom(companyPageAtom);

    const { mutate, isLoading, data } = useMutation((companyPage: ICompanyListProps) => patchCompanyApi(companyPage), {
        onSuccess: (data) => {
            setSearchCompanyList(data);
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (keyWordStorage !== "") {
            setCompanyPage((prev: any) => ({
                ...prev,
                data: {
                    companyName: keyWordStorage,
                },
                page: 1,
            }));
        }
    }, [keyWordStorage, setCompanyPage]);

    useEffect(() => {
        if (companyPage.data.companyName !== "" && didMount) {
            mutate(companyPage);
        }
    }, [companyPage, didMount, mutate]);

    useEffect(() => {
        setDataTotalCount(data?.totalElements ?? 0);
    }, [data?.totalElements]);

    return {
        data,
        isLoading,
        dataTotalCount,
    };
};

import iconView from "@assets/img/icon_view.png";
import modalClose from "@assets/img/icon_close.png";
import briefPopup01 from "@assets/img/bridf_popup.png";
import iconFile from "@assets/img/ico_file.png";
import { Link } from "react-router-dom";
import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { popVariants } from "@utils/index";

const Watch = () => {
    const [isModal, setIsModal] = useState(false);
    const [tempTitle, setTempTitle] = useState(0);

    const handleModal = (title: number) => {
        setTempTitle(title);
        setIsModal((prev) => !prev);
    };

    const tempData = [
        {
            title: "[정책브리프 2023-7] 글로벌 자율주행 정책 및 산업 동향",
            sticker: "산업기술 정책브리프",
            data: "2023-07-27",
            view: "732",
        },
        {
            title: "[정책브리프 2023-4] EU 전략기술 공급망 분석 및 재료 수요 예측",
            sticker: "산업기술 정책브리프",
            data: "2023-04-27",
            view: "320",
        },
        {
            title: "[정책브리프 2023-9] 글로벌 원자력 보급 과제와 대응 조치 고찰",
            sticker: "산업기술 정책브리프",
            data: "2023-10-05",
            view: "180",
        },
        {
            title: "[정책브리프 2023-8] 글로벌 핵심광물 시장 동향",
            sticker: "산업기술 정책브리프",
            data: "2023-09-06",
            view: "322",
        },
        {
            title: "[정책브리프 2023-2] 중국 산업 디지털화·친환경화 통합 발전 제언",
            sticker: "산업기술 정책브리프",
            data: "2023-03-03",
            view: "320",
        },
        {
            title: "[정책브리프 2023-6] 주요국 반도체 정책과 미 의회 역할 검토",
            sticker: "산업기술 정책브리프",
            data: "2023-06-30",
            view: "741",
        },
        {
            title: "[정책브리프 2023-5] 미국 국가반도체기술센터(NSTC)의 비전과 전략",
            sticker: "산업기술 정책브리프",
            data: "2023-05-31",
            view: "320",
        },
        {
            title: "[정책브리프 2023-1] 미국 바이오제조 증진을 위한 정책 권고",
            sticker: "산업기술 정책브리프",
            data: "2023-02-01",
            view: "776",
        },
    ];

    return (
        <div className="wrap">
            <div className="wrap-inner">
                <div className="B__etc">
                    <p className="posting">
                        총 게시글 <span>102</span>건
                    </p>
                    <p className="update">최근 업데이트 날짜 : 2023-07-11</p>
                </div>

                <div className="B__content">
                    <div className="B__list">
                        {tempData.map((item, index) => (
                            <div className="B__item" onClick={() => handleModal(index)} key={index}>
                                <div className="item-tit">
                                    <p className="tit">{item.title}</p>
                                    <p className="sticker">{item.sticker}</p>
                                </div>
                                <div className="item-etc">
                                    <p className="date">{item.data}</p>
                                    <p className="view">
                                        <img src={iconView} alt="view" />
                                        {item.view}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="B__Pagination-wrap">
                        <ul className="B__Pagination">
                            <li className="B__Pagination-item">
                                <button className="b__prev"></button>
                            </li>
                            <li className="B__Pagination-item active">
                                <button>1</button>
                            </li>
                            <li className="B__Pagination-item">
                                <button>2</button>
                            </li>
                            <li className="B__Pagination-item">
                                <button>3</button>
                            </li>
                            <li className="B__Pagination-item">
                                <button>4</button>
                            </li>
                            <li className="B__Pagination-item">
                                <button>5</button>
                            </li>
                            <li className="B__Pagination-item">
                                <button className="b__next"></button>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>

            <AnimatePresence>
                {isModal && (
                    <motion.div
                        variants={popVariants}
                        initial="initial"
                        animate="visible"
                        exit="leaving"
                        className="modalWrap"
                    >
                        <div className="modalBody">
                            <button className="closeBtn" onClick={() => setIsModal(false)}>
                                <img src={modalClose} alt="close" />
                            </button>{" "}
                            {/* 닫기 버튼 */}
                            <div className="m-tit-wrap">
                                <p className="m-tit">{tempData[tempTitle].title}</p>
                                <ul>
                                    <li>담당부서 : 기술동향조사실</li>
                                    <li>담당자 : 정휘상</li>
                                    <li>연락처 : 02-6009-3593</li>
                                    <li>분류 : 산업기술 정책브리프</li>
                                </ul>
                                <div className="m-etc">
                                    <p className="m-date">2023-02-01</p>
                                    <p className="m-view">
                                        <img src={iconView} alt="view" />
                                        776
                                    </p>
                                </div>
                            </div>
                            <div className="modalCon">
                                <ol>
                                    <li>Ⅰ. 서론</li>
                                    <li>Ⅱ. 반도체 산업 현황과 주요국 정책</li>
                                    <li>Ⅲ. 미 의회 고려사항</li>
                                    <li>Ⅳ. 결론 및 시사점</li>
                                    <li>
                                        원문정보 : CRS, Semiconductors and the CHIPS Act: The Global Context, 2023.5에서
                                        주요 내용을 요약 정리
                                    </li>
                                </ol>
                                <div className="img-wrap">
                                    <img src={briefPopup01} alt="briefPopup" />
                                </div>
                                <p className="file">
                                    <strong>첨부파일</strong>
                                    <a href={require("./brief_example.pdf")} download target="_self">
                                        <img src={iconFile} alt="pdfFile" />
                                        [정책브리프 2023-06]_주요국 반도체 정책과 미 의회 역할 검토_KIAT.pdf(1480KB)
                                    </a>
                                </p>
                                <div className="modalBtn-wrap">
                                    <span className="modalBtn">
                                        <Link to="">
                                            <input type="button" value="목록" />
                                        </Link>
                                    </span>
                                    <span className="modalBtn">
                                        <input type="button" value="링크복사" />
                                    </span>
                                    <span className="modalBtn">
                                        <input
                                            type="button"
                                            value="KIAT 정책브리핑 바로가기"
                                            onClick={() =>
                                                window.open(
                                                    "https://kiat.or.kr/front/board/boardContentsListPage.do?board_id=71&cate_id=ead10dcf36764e83811aba771a5674f9&MenuId=49e713206ef642d5a1f9012f06bcc4d9",
                                                    "_blank",
                                                )
                                            }
                                        />
                                    </span>
                                </div>
                                <div className="modalList">
                                    <ul>
                                        {tempTitle !== 0 && (
                                            <li className="mdLi">
                                                <strong>이전글</strong>
                                                {/* <Link to="">{tempData[tempTitle - 1].title}</Link> */}
                                                <button onClick={() => setTempTitle(tempTitle - 1)}>
                                                    {tempData[tempTitle - 1].title}
                                                </button>
                                            </li>
                                        )}

                                        {tempTitle !== tempData.length - 1 && (
                                            <li className="mdLi">
                                                <strong>다음글</strong>
                                                {/* <Link to="">{tempData[tempTitle + 1].title}</Link> */}
                                                <button onClick={() => setTempTitle(tempTitle + 1)}>
                                                    {tempData[tempTitle + 1].title}
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Watch;

import { styled } from "styled-components";

import Arrow from "@assets/icon/arrow-samll-icon.svg";
import { device } from "@assets/index";
interface IWrap {
    height: number;
}

export const Wrap = styled.div<IWrap>`
    position: relative;
    overflow: hidden;
    height: ${(props) => `${props.height}px`};
    background: #fff;
    border-radius: 3px;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);

    @media ${device.tabletL} {
        & + div {
            margin-top: 16px;
        }
    }

    @media ${device.tablet} {
        height: auto;
    }

    .c__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 15px 16px;
        border: 1px solid #e9ebec;

        strong {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #333;
        }

        .c__select {
            display: flex;
            align-items: center;

            span {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 700;
                font-size: 12px;
                color: #333;
            }

            button {
                padding-right: 17px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 300;
                font-size: 12px;
                color: #333;
                background-image: url(${Arrow});
                background-repeat: no-repeat;
                background-position: 100% 7px;
            }
        }

        .btn__export {
            width: 93px;
            height: 27px;
            border-radius: 3px;
            background: #e4e1f4;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            color: #6d40b5;
        }
    }

    .c__dash {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 26px;

        @media ${device.tablet} {
            flex-direction: column;
            align-items: start;
        }

        div {
            display: flex;
            position: relative;
            height: 31px;
            padding: 0 23px;
            justify-content: center;
            align-items: center;

            &:after {
                position: absolute;
                content: "";
                width: 1px;
                height: 31px;
                background: #e9ebec;
                top: 0;
                right: 0;

                @media ${device.tablet} {
                    display: none;
                }
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }

            .p__number {
                font-family: "Noto Sans KR", sans-serif;
                font-size: 16px;
                font-weight: 400;

                &.revenue {
                    color: #4b38b3;
                }

                &.expenses {
                    color: #495057;
                }
            }

            .p__unit {
                margin-left: 14px;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: #8f939f;
            }
        }
    }

    .p__center {
        width: 100%;
        text-align: center;
        /* position: relative; */
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
    }
    .c__loading {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        &.text {
            margin-top: 132px;
        }
    }
`;

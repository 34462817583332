import CloseBtn from "@assets/icon/pop-close.svg";
import { device } from "@assets/index";
import { ScrollBar } from "@styles/common";
import { styled } from "styled-components";

export const Wrap = styled.div`
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .l__pop {
        position: relative;
        width: 860px;
        height: 720px;
        padding: 55px 57px 56px;
        background-color: #dee1f2;
        border-radius: 15px;
        box-sizing: border-box;

        @media ${device.tablet} {
            width: 90%;
            height: auto;
            padding: 30px;
        }
    }

    .c__content {
        padding-top: 33px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 20px;
        column-gap: 30px;

        @media ${device.tablet} {
            ${ScrollBar}
            height: 400px;
            grid-template-columns: repeat(4, 1fr);
        }

        @media ${device.mobile} {
            ${ScrollBar}
            height: 400px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .c__title {
        .p__title {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 37px;
            color: #000;
            text-align: center;

            @media ${device.tablet} {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .p__update {
            padding-top: 27px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #000;
            text-align: center;
        }
    }

    .btn__close {
        position: absolute;
        display: block;
        width: 21px;
        height: 21px;
        background-image: url(${CloseBtn});
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        top: 24px;
        right: 24px;
    }
`;

export const List = styled.div<{ $hasImg: string; $img?: string }>`
    height: 100px;
    background-color: #d4d8ee;
    border-radius: 12px;
    background-image: url(${(props) => (props.$hasImg ? props.$img : "")});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: ${(props) => (props.$hasImg === "true" ? "none" : "1px dashed #eef0f8")};
`;

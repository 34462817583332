import { patchTechShare } from "@api/techstormApi";
import LoadingComp from "@components/LoadingComp";
import { useMutation } from "@tanstack/react-query";
import { handleText } from "@utils/index";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "./Card";

const ProgressBar = styled.div<{ $progress: number }>`
    position: absolute;
    width: ${(props) => props.$progress}%;
    height: 6px;
    left: 0;
    top: 0;
    background: #482dd2;
`;

const NanumComp = ({ type, code }: any) => {
    const [didMount, setDidMount] = useState(false);

    const { mutate, isLoading, data } = useMutation((code: string) => patchTechShare(code), {
        onSuccess(data) {},
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount) {
            mutate(code);
        }
    }, [didMount, code]);

    const getDate = (startDate: string, endDate: string) => {
        const now = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diff =
            Math.floor((end.getTime() - now.getTime()) / (24 * 60 * 60 * 1000)) > 0
                ? `+ ${Math.floor((end.getTime() - now.getTime()) / (24 * 60 * 60 * 1000))}`
                : `- ${Math.abs(Math.floor((end.getTime() - now.getTime()) / (24 * 60 * 60 * 1000)))}`;

        const date = now.getTime() > end.getTime() ? "마감" : `${diff}일`;

        const up = now.getTime() - start.getTime();
        const down = end.getTime() - start.getTime();

        const process = up > down ? 100 : (up / down) * 100;

        return {
            date: date,
            process: process,
        };
    };

    return (
        <div>
            <div className="pannel_all">
                {isLoading ? (
                    <div className="c__loading">
                        <LoadingComp />
                    </div>
                ) : (
                    data !== undefined && (
                        <div className="wrap_pannel">
                            {data.content.map((item) => (
                                <div className="list__nanum" key={item.techShareIdx}>
                                    <div className="box_state">
                                        <span className="text text_index">{item.times}</span>
                                        {item.statusCd === "TSS0102" && (
                                            <span className="text text_state ing">{item.statusCdDesc}</span>
                                        )}
                                        {item.statusCd === "TSS0103" && (
                                            <span className="text text_state end">{item.statusCdDesc}</span>
                                        )}
                                        {item.statusCd === "TSS0104" && (
                                            <span className="text text_state ing">{item.statusCdDesc}</span>
                                        )}
                                        {item.statusCd === "TSS0105" && (
                                            <span className="text text_state announce">{item.statusCdDesc}</span>
                                        )}
                                    </div>

                                    <div className="box_titles">
                                        <strong className="text_title">{handleText(item.subject, 30)}</strong>
                                        <div className="wrap_span">
                                            <div className="box_span">
                                                <p className="text_date">
                                                    {getDate(item.startDate, item.endDate).date}
                                                </p>
                                                <div className="outer_date">
                                                    <ProgressBar
                                                        $progress={getDate(item.startDate, item.endDate).process}
                                                    />
                                                </div>
                                                <div className="box_date">
                                                    <span className="text">{item.startDate.split("T")[0]}</span>
                                                    <span className="text">{item.endDate.split("T")[0]}</span>
                                                </div>
                                            </div>
                                            <p className="text_count">
                                                총&nbsp;:&nbsp;<strong>{item.totalTechShareTechCount}</strong>&nbsp;건
                                            </p>
                                        </div>
                                    </div>

                                    <div className="wrap_nanum_list">
                                        <ul className="list_nanum">
                                            {item.companyList.map((elem) => (
                                                <Card key={elem.techShareCompanyIdx} data={elem} />
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default NanumComp;

import { ScrollBarX } from "@styles/common";
import styled, { css } from "styled-components";

export const Container = styled.div`
    ${ScrollBarX}
    overflow-y: hidden;
    width: 100%;
    white-space: nowrap;
    position: relative;
`;

export const Content = styled.div<{ width: number | undefined }>`
    display: inline-block;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width}px;
        `}
    white-space: nowrap;
`;

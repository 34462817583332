import { RightHeaderElementProps } from "~types/Layout";
import Clock from "./Clock";

const RightHeaderElement = ({ handlePops }: RightHeaderElementProps) => {
    return (
        <div className="c__header">
            <div className="c__state">
                <p className="p__sever_state">
                    Server is <span className="t__blue">connected</span>
                </p>
                <p className="p__version">Version : 1.4.6.9</p>
                <Clock />
            </div>
            <div className="c__icons">
                <button className="b__square" onClick={handlePops} />
                <button className="b__bell">
                    <span className="p__num">3</span>
                </button>
            </div>
            <div className="c__user">
                <button className="b__user" />
                <p className="p__user">Off-line&nbsp;User</p>
            </div>
        </div>
    );
};

export default RightHeaderElement;

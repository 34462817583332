import RandD from "@assets/img/icon-rand.png";
import Patent from "@assets/img/patent-icon.png";
import Sue from "@assets/img/sue-icon.png";
import Trade from "@assets/img/trade-icon.png";

export const dashBoardList = [
    {
        id: 1,
        title: "국가R&D",
        description: "기술분야별 국가 R&D를 통한 특허출원공개건수",
        highRow: "high",
        percentage: 0,
        num: "randNum",
        imgSrc: RandD,
    },
    {
        id: 2,
        title: "거래건수",
        description: "기술분야별 특허 거래건수",
        highRow: "high",
        percentage: 0,
        num: "transaction",
        imgSrc: Trade,
    },
    {
        id: 3,
        title: "분쟁정보",
        description: "기술분야별 심판건수",
        highRow: "high",
        percentage: 0,
        num: "disputeNum",
        imgSrc: Sue,
    },
    {
        id: 4,
        title: "출원건수",
        description: "기술분야별 특허출원공개건수",
        highRow: "high",
        percentage: 0,
        num: "numApplications",
        imgSrc: Patent,
    },
];

import { Wrap } from "../style";
import { useQuery } from "@tanstack/react-query";
import { getTechShareCount } from "@api/techstormApi";
import useTabs from "@hooks/useTabs";
import NanumComp from "./NanumComp";

const Nanum = () => {
    const { isLoading: countLoading, data: countData } = useQuery(["shareCount"], () => getTechShareCount(), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 1,
        enabled: true,
        onSuccess(data) {},
    });

    const allTabs = [
        {
            id: 0,
            name: `전체(${!countLoading && countData ? countData.totalCount : 0})`,
            data: <NanumComp type={"all"} code={""} />,
        },
        {
            id: 1,
            name: `진행중(${!countLoading && countData ? countData.progressCount : 0})`,
            data: <NanumComp type={"progress"} code={"TSS0102"} />,
        },
        {
            id: 2,
            name: `마감(${!countLoading && countData ? countData.closingCount : 0})`,
            data: <NanumComp type={"closing"} code={"TSS0103"} />,
        },
        {
            id: 3,
            name: `심의중(${!countLoading && countData ? countData.deliberationCount : 0})`,
            data: <NanumComp type={"deliberation"} code={"TSS0104"} />,
        },
        {
            id: 4,
            name: `결과발표(${!countLoading && countData ? countData.resultAnnounceCount : 0})`,
            data: <NanumComp type={"resultAnnounc"} code={"TSS0105"} />,
        },
    ];

    const { currentIndex, currentItem, changeItem } = useTabs(0, allTabs);
    const handleTabs = (id: number) => {
        changeItem(id);
    };

    return (
        <Wrap>
            <div className="wrap_nanum_tabs">
                <ul className="list_tabs">
                    {allTabs.map((item) => (
                        <li key={item.id}>
                            <button
                                className={item.id === currentIndex ? "btn active" : "btn"}
                                onClick={() => handleTabs(item.id)}
                            >
                                {item.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {currentItem.data}
        </Wrap>
    );
};

export default Nanum;

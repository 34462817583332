import { IPatentList, IPatentListProps } from "~types/types";
import { AxiosSearchPatnet } from "@api/index";

export const patchPatentApi = async (patentPage: IPatentListProps): Promise<IPatentList> =>
    AxiosSearchPatnet.patch(
        `/search/list?page=${patentPage.page}&size=${patentPage.size}&sort=_score,desc`,
        patentPage.data,
    ).then((res) => res.data.data);

export const getPatentKeyWordApi = async (keyword: string): Promise<string[]> =>
    AxiosSearchPatnet.get(`/search/keyword/list?searchKeyword=${keyword}&size=20`).then((res) => res.data.data);

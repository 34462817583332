import { IScatterChartProps } from "types/etcTypes";
import { reSizeAtom } from "@atoms";
import { useAtom } from "jotai";
import ApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Wrap = styled.div`
    .p__tooltips {
        padding: 14px 14px 10px;
        background: #fff;
        box-sizing: border-box;

        .c__text {
            font-size: 0;
            text-align: left;
            vertical-align: middle;

            .c__dots {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            .p__text {
                position: relative;
                display: inline-block;
                font-size: 16px;
                top: -1px;
                margin-left: 6px;
            }
        }

        .list__text {
            margin-top: 10px;

            li {
                margin-top: 6px;
                font-size: 14px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
`;

const colors = [
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
];

const ScatterChart = ({ data, label, position, height }: IScatterChartProps) => {
    const { pathname } = useLocation();
    const [resizeWidth, setWidth] = useAtom(reSizeAtom);

    return (
        <Wrap>
            <ApexChart
                type="scatter"
                width={`100%`}
                height={height ? height : 500}
                series={data}
                options={
                    {
                        chart: {
                            zoom: {
                                enabled: false,
                            },
                            type: "scatter",
                            toolbar: {
                                show: false,
                            },
                        },
                        annotations: {
                            xaxis: [
                                {
                                    x: data[data.length - 1].data[0][0],
                                    strokeDashArray: 4,
                                    borderColor: pathname.includes("build") ? "rgb(0, 143, 251)" : "transparent",
                                },
                            ],
                            yaxis: [
                                {
                                    y: data[data.length - 1].data[0][1],
                                    strokeDashArray: 4,
                                    borderColor: pathname.includes("build") ? "rgb(0, 143, 251)" : "transparent",
                                },
                            ],
                        },
                        xaxis: {
                            tickAmount: 5,
                            title: {
                                text: label.x,
                            },
                            labels: {
                                formatter(value) {
                                    return Number.isInteger(value)
                                        ? value
                                        : pathname.includes("patentpia")
                                        ? Number(value).toFixed(0)
                                        : Number(value).toFixed(2);
                                },
                            },
                        },
                        yaxis: {
                            tickAmount: 10,
                            title: {
                                text: label.y,
                            },
                            // labels: {
                            //     formatter(value) {
                            //         return `${value}`;
                            //     },
                            // },
                        },
                        tooltip: {
                            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                                let html = `<div class="p__tooltips">`;
                                html += `<div class="c__text"><span style="background:${w.globals.colors[seriesIndex]};", class="c__dots" }} ></span><p class="p__text">${w.config.series[seriesIndex].name}</p></div>`;
                                html += `<ul class="list__text"><li>${label.x} : ${w.config.series[seriesIndex].data[dataPointIndex][0]}</li><li>${label.y} : ${w.config.series[seriesIndex].data[dataPointIndex][1]}</li></ul>`;
                                html += `</div>`;

                                return html;
                            },
                        },
                        legend: {
                            position: position ? (resizeWidth < 768 ? "bottom" : position) : "bottom",
                        },
                        colors: colors,
                    }

                    // chart: {
                    //   height: 350,
                    //   type: 'scatter',
                    //   zoom: {
                    //     enabled: true,
                    //     type: 'xy'
                    //   }
                    // },
                    // xaxis: {
                    //   tickAmount: 10,
                    //   labels: {
                    //     formatter: function(val) {
                    //       return parseFloat(val).toFixed(1)
                    //     }
                    //   }
                    // },
                    // yaxis: {
                    //   tickAmount: 7
                    // }
                }
            />
        </Wrap>
    );
};

export default ScatterChart;

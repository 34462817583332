import DraggableScrollX from "@components/DraggableScrollX";
import { Agent, ApplicationTrend, Pie } from "@components/Graph";
import ApplicantStatus from "@components/Graph/ApplicantStatus";
import LoadingComp from "@components/LoadingComp";
import useCounting from "@hooks/useCounting";
import Comment from "@pages/Build/Comment";
import useData from "@services/PatentSearch";
import { CardComp, CloudDesc } from "@styles/Common/style";
import { Wrap } from "../Alls/style";

const CountingComp = ({ title, num }: any) => {
    const { count } = useCounting(num, true, 3);

    return (
        <div>
            <strong className="p__title">{title}</strong>
            <p className="p__nums">
                <span>{count}</span>
                <span>건</span>
            </p>
        </div>
    );
};
const Ntb = () => {
    const { isLoading, patentData, pie, applicationTrend, applicantStatus, ipc, agent } = useData("ntb", "patent");

    return (
        <>
            {isLoading || (patentData !== undefined && patentData.RETURN_CNT === 0) ? (
                <div className="l__loading_build">
                    <LoadingComp />
                </div>
            ) : (
                patentData &&
                patentData.RETURN_CNT !== 0 && (
                    <>
                        <Wrap>
                            <div className="c__has">
                                <CountingComp
                                    title={"특허출원 건수(건)"}
                                    num={patentData?.DATA[0].data_set[0].NTB_appl_cnt}
                                />
                                <CountingComp
                                    title={"특허등록 건수(건)"}
                                    num={patentData?.DATA[1].data_set[0].NTB_grt_cnt}
                                />
                            </div>

                            <CardComp height={400}>
                                <div className="p__titles">
                                    <strong>국내외 특허현황</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">
                                            특허청 국가에 출원한 출원건수와 분포비율을 확인할 수 있습니다.
                                        </CloudDesc>
                                    </div>
                                </div>
                                <div className="p__pie">
                                    {pie.data.length > 0 && (
                                        <Pie data={pie.data} labels={pie.label} width={450} isLegend={true} />
                                    )}
                                </div>
                            </CardComp>

                            <CardComp height={400}>
                                <div className="p__titles">
                                    <strong>년도별 출원동향</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">
                                            출원연도별 출원건수를 확인할 수 있습니다. 자세히보기 버튼을 클릭하시면
                                            다양한 필터와 함께 연도별 출원 건수를 탐색할 수 있습니다.
                                        </CloudDesc>
                                    </div>
                                </div>
                                <div className="c__agent">
                                    <DraggableScrollX width={1000}>
                                        {applicationTrend.data.length > 0 && (
                                            <ApplicationTrend
                                                data={applicationTrend.data}
                                                label={applicationTrend.label}
                                                width={1000}
                                                height={320}
                                            />
                                        )}
                                    </DraggableScrollX>
                                </div>
                            </CardComp>

                            <CardComp height={400} className="overflow">
                                <div className="p__titles">
                                    <strong>주요출원인현황</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">
                                            출원인별 출원건수를 확인 할 수 있습니다. 자세히보기 버튼을 클릭하시면 다양한
                                            필터를 통해 원하는 조건의 특허 동향을 파악할 수 있습니다.
                                        </CloudDesc>
                                    </div>
                                </div>
                                <div className="c__applicantStatus overflow">
                                    {applicantStatus.data.length > 0 && (
                                        <ApplicantStatus
                                            data={applicantStatus.data}
                                            labels={applicantStatus.label}
                                            xaxisTitle={"출원건수(건)"}
                                            yaxisTitle={"출원인"}
                                            height={1900}
                                        />
                                    )}
                                </div>
                            </CardComp>
                            <CardComp height={400}>
                                <div className="p__titles">
                                    <strong>주요IPC현황</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">
                                            IPC특허분류별 출원건수를 확인 할 수 있습니다.
                                        </CloudDesc>
                                    </div>
                                </div>
                                <div className="c__applicantStatus">
                                    {ipc.data.length > 0 && (
                                        <ApplicantStatus
                                            data={ipc.data}
                                            labels={ipc.label}
                                            xaxisTitle={"출원건수(건)"}
                                            yaxisTitle={"IPC특허분류"}
                                            height={350}
                                        />
                                    )}
                                </div>
                            </CardComp>

                            <CardComp height={400}>
                                <div className="p__titles">
                                    <strong>대리인 등록률 현황</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">
                                            특허 대리인별 출원건수, 등록건수, 등록률을 확인 할 수 있습니다. 자세히 보기
                                            버튼을 클릭하시면 특허청국가, IPC특허분류 등의 필터를 사용하여 원하는
                                            조건에서 특허대리인의 출원/등록 정보를 확인할 수 있습니다.{" "}
                                        </CloudDesc>
                                    </div>
                                </div>
                                <div className="c__agent">
                                    <DraggableScrollX>
                                        {agent.data.length > 0 && (
                                            <Agent
                                                data={agent.data}
                                                label={agent.label}
                                                category={agent.category}
                                                height={320}
                                                width={2400}
                                            />
                                        )}
                                    </DraggableScrollX>
                                </div>
                            </CardComp>
                        </Wrap>
                    </>
                )
            )}

            <Comment>본 화면에서는 NTB기술은행에 등록된 모집단(공개특허기준)에 대한 특허출원현황을 보여줍니다.</Comment>
        </>
    );
};

export default Ntb;

import { fetchTechPagingData } from "@api/techstormApi";
import { searchSalesAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";

export const usePagingTecStormData = (page: number, size: number, keyWord: string) => {
    const setSearchSales = useSetAtom(searchSalesAtom);

    const { data, isLoading, isFetching } = useQuery(
        ["techData", keyWord, page],
        () => fetchTechPagingData({ page, size, keyWord }),
        {
            onSuccess: (data) => {
                setSearchSales(data.content);
            },
        },
    );

    const totalPages = data?.totalPages || 1;

    return {
        techData: data,
        isLoading,
        isFetching,
        totalPages,
    };
};

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import GraphComp from "@pages/Patentpia/Common/GraphComp";
import { Wrap } from "@pages/Patentpia/Common/style";
import { CardComp, CardSlideComp, CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { useEffect, useState } from "react";
import { handleDataUrl } from "../Common/utils";

const groupCode = "09_00";
interface IUrs {
    title: string;
    url: string;
    type: string;
    sourceUrl: string;
}

const HEIGHT = 600;

const strings = [
    "해당 기술분야별 우리나라 분기별 급성장 키워드의 특허 동향",
    "해당 기술분야별 우리나라 급성장 키워드의 특허 동향",
    "해당 기술분야별 우리나라 급성장 키워드의 특허 동향",
    "해당 기술분야별 우리나라 분기별 신규 등장 키워드의 특허 동향",
    "해당 기술분야별 우리나라 신규 등장 키워드의 특허 동향",
    "해당 기술분야별 우리나라 신규 등장 키워드의 특허 동향",
];

const Portfolio = () => {
    const { dashTemp, dashTempLoading } = usePatentPia({ groupCode });
    const [urls, setUrls] = useState<IUrs[]>([]);

    useEffect(() => {
        if (dashTemp !== undefined) {
            const dataUrl = handleDataUrl(dashTemp);
            setUrls(dataUrl);
        }
    }, [dashTemp]);

    return (
        <Wrap>
            {urls.length === 0 && dashTempLoading ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                urls !== undefined &&
                urls.length > 0 && (
                    <div className="l__cont two">
                        {urls.slice(0, 2).map((item, index) => (
                            <CardComp height={HEIGHT} key={`0${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} index={`0${index}`} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[2].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[2]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[2].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>

                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(2, 4).map((item, index) => (
                                        <SwiperSlide key={`2${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`2${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>

                        {urls.slice(4, 6).map((item, index) => (
                            <CardComp height={HEIGHT} key={`4${index}`}>
                                <GraphWrapper>
                                    <div className="p__titles">
                                        <strong>{item.title}</strong>
                                        <div className="wrap_btn">
                                            <button />
                                            <CloudDesc className="wrap_cloud">{strings[3 + index]}</CloudDesc>
                                        </div>
                                    </div>
                                    <SoureceMoveBtn onClick={() => window.open(`//${item.sourceUrl}`)}>
                                        데이터 출처
                                    </SoureceMoveBtn>
                                </GraphWrapper>
                                <div className="inner">
                                    <GraphComp url={item.url} type={item.type} />
                                </div>
                            </CardComp>
                        ))}

                        <CardSlideComp height={HEIGHT}>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>{urls[6].title}</strong>
                                    <div className="wrap_btn">
                                        <button />
                                        <CloudDesc className="wrap_cloud">{strings[5]}</CloudDesc>
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${urls[6].sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="outer__swiper">
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={0}
                                    loop={false}
                                    modules={[Navigation]}
                                    className="patentPiaCompanySwiper"
                                    navigation={true}
                                >
                                    {urls.slice(6, 8).map((item, index) => (
                                        <SwiperSlide key={`6${index}`}>
                                            <div className="inner">
                                                <GraphComp url={item.url} type={item.type} index={`6${index}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </CardSlideComp>
                    </div>
                )
            )}
        </Wrap>
    );
};

export default Portfolio;

import noLogo from "@assets/img/no-company-logo.png";
import newsImg01 from "@assets/img/CD-news_img.png";

import { PlusIcon } from "@assets/svg/InterfacesIcon";
import { MenuMoreIcon } from "@assets/svg/InterfacesIcon";

import useCounting from "@hooks/useCounting";
import KakaoMap from "@components/KakaoMap";

import { useParams } from "react-router-dom";
import { Fragment } from "react";
import LoadingComp from "@components/LoadingComp";
import styled from "styled-components";
import { commaNumber } from "@utils/index";
import { getAge } from ".";
import useCompanyDetail from "./useCompanyDetail";

interface ICountComp {
    title: string;
    num: number;
    color: string;
}

const UNIT = 0.001;

const WrapLoading = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    justify-content: center;
    align-items: center;
`;

const CountComp = ({ title, num, color }: ICountComp) => {
    const { count } = useCounting(num, true, 10);
    return (
        <div className="c__count">
            <strong>{title}</strong>
            <p className={`p__text p__${color}`}>{count}</p>
        </div>
    );
};

const CompanyDetail = () => {
    const { id } = useParams();
    const {
        isLoading,
        newsLoading,
        getNumber,
        similLoading,
        handleNewsMore,
        data,
        similData,
        employee,
        finance,
        newsData,
    } = useCompanyDetail(id);

    return (
        <>
            {isLoading || newsLoading ? (
                <WrapLoading>
                    <LoadingComp />
                </WrapLoading>
            ) : (
                <div className="list-card">
                    <div className="listCard-content">
                        {/* 상세시작 */}
                        <div className="CompanyDetail">
                            <div className="CD__header">
                                <div className="CD__logo-wrap">
                                    <div className="CD__logo">
                                        <div className="CD__logo-bg">
                                            {data?.data.logoUrl ? (
                                                <img src={data?.data.logoUrl} alt={data.data.realCompanyName} />
                                            ) : (
                                                <img src={noLogo} alt="logo" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="CD__tag">
                                        <div className="CD__tag-wrap">
                                            <p className="CD__type">{data?.data.companySize}</p>
                                            <p className="CD__stock">{data?.data.listingMarketDesc}</p>
                                        </div>
                                        <h3>{data?.data.companyName}</h3>
                                    </div>
                                </div>

                                <div className="CD__tab">
                                    <button className="CD__tab-item active">요약정보</button>
                                    <button className="CD__tab-item" disabled={true}>
                                        재무정보
                                    </button>
                                    <button className="CD__tab-item" disabled={true}>
                                        사업문의
                                    </button>
                                    <button className="CD__tab-item" disabled={true}>
                                        기술/제품
                                    </button>
                                    <button className="CD__tab-item" disabled={true}>
                                        기업뉴스
                                    </button>
                                </div>
                            </div>{" "}
                            {/* CD__header */}
                            <div className="CD__section">
                                <div className="CD-aside">
                                    <div className="section-header">
                                        <p className="title">기업정보</p>
                                    </div>
                                    <div className="CD-info-wrap">
                                        <div className="CD-group">
                                            <label className="CD-label">대표이사</label>
                                            <div className="CD-date">
                                                {data?.data.representationName ? data?.data.representationName : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">설립일</label>
                                            <div className="CD-date">
                                                {data?.data.establishmentDate
                                                    ? `${data?.data.establishmentDate.substring(
                                                          0,
                                                          4,
                                                      )}-${data?.data.establishmentDate.substring(
                                                          4,
                                                          6,
                                                      )}-${data?.data.establishmentDate.substring(6, 8)}`
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">직원수</label>
                                            <div className="CD-date">
                                                {data?.data.employeeCount
                                                    ? `${commaNumber(Number(data?.data.employeeCount))}명`
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">기업구분</label>
                                            <div className="CD-date">
                                                {data?.data.companySize ? data?.data.companySize : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">국가</label>
                                            <div className="CD-date">
                                                {data?.data.country ? data?.data.country : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">상장구분</label>
                                            <div className="CD-date">
                                                {data?.data.listingMarketDesc ? data?.data.listingMarketDesc : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">매출액</label>
                                            {data?.data.companyFinances !== undefined &&
                                            data?.data.companyFinances.length > 0 ? (
                                                <div className="CD-date">
                                                    {getNumber(
                                                        data?.data.companyFinances[0].sales
                                                            ? data?.data.companyFinances[0].sales
                                                            : "",
                                                    )}
                                                    (백만원)
                                                </div>
                                            ) : (
                                                <div className="CD-date">-</div>
                                            )}
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">키워드</label>
                                            <div className="CD-date">
                                                {data?.data.keyword ? data?.data.keyword : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">홈페이지</label>
                                            <div className="CD-date">
                                                {data?.data.homepage ? data?.data.homepage : "-"}
                                            </div>
                                        </div>
                                        <div className="CD-group">
                                            <label className="CD-label">주소</label>
                                            <div className="CD-date">
                                                {data?.data.address ? data?.data.address : "-"}
                                            </div>
                                        </div>
                                    </div>
                                    {data?.data.address && (
                                        <div className="map">
                                            <KakaoMap add={data.data.address} height={245} width={457} />
                                        </div>
                                    )}
                                </div>
                                <div className="CD-main">
                                    <div className="CD-intro">
                                        <div className="section-header">
                                            <p className="title">기업소개</p>
                                        </div>
                                        <p className="info">
                                            {data?.data.establishmentDate && (
                                                <>
                                                    <span>
                                                        당사는 {data.data.establishmentDate.substring(0, 4)}년{" "}
                                                        {data.data.establishmentDate.substring(4, 6)}월{" "}
                                                        {data.data.establishmentDate.substring(6, 8)}일에 설립한 업력{" "}
                                                        {getAge(data.data.establishmentDate)}차 기업입니다.
                                                    </span>
                                                    <br />
                                                </>
                                            )}
                                            {data?.data.category && (
                                                <>
                                                    <span>
                                                        {data?.data.category} 업종에 해당하는&nbsp;
                                                        {data.data.companySize ? data.data.companySize : "기업"}
                                                        입니다.
                                                    </span>
                                                    <br />
                                                </>
                                            )}
                                            {similData !== undefined && !similLoading && (
                                                <span>
                                                    동종업종에 해당하는 기업은{" "}
                                                    {similData.data.map((item, index) => (
                                                        <Fragment key={item.id}>
                                                            {index !== 0 && ", "}
                                                            {item.realCompanyName}
                                                        </Fragment>
                                                    ))}
                                                    이며,&nbsp;
                                                </span>
                                            )}
                                            {similData !== undefined && !similLoading && (
                                                <>
                                                    <span>
                                                        매출규모는 각각
                                                        {similData?.data.map((item, index) => (
                                                            <Fragment key={item.id}>
                                                                {index !== 0 && ", "}
                                                                {getNumber(item.sales)}(백만원)
                                                            </Fragment>
                                                        ))}
                                                        입니다.
                                                    </span>
                                                    <br />
                                                </>
                                            )}
                                            {data?.data.companyEmployeeStatuses !== undefined &&
                                                data?.data.companyEmployeeStatuses.length > 0 && (
                                                    <>
                                                        <span>
                                                            당사의 직원 수는 {employee.thisCreateMonth.substring(2, 4)}
                                                            년&nbsp;
                                                            {employee.thisCreateMonth.substring(4, 6)}월 기준{" "}
                                                            {employee.thisEmployee}명으로 1년 전과 비교했을 때&nbsp;
                                                            {employee.plusMinus === 0
                                                                ? "변화가 없습니다."
                                                                : `${Math.abs(employee.plusMinus)}명 ${
                                                                      employee.plusMinus > 0 ? "증가" : "감소"
                                                                  }하였습니다.`}
                                                        </span>
                                                        <br />
                                                    </>
                                                )}

                                            {data?.data.companyFinances !== undefined &&
                                                data.data.companyFinances.length > 0 && (
                                                    <>
                                                        <span>
                                                            {data.data.companyFinances[0].acctDt}년도 매출은{" "}
                                                            {commaNumber(
                                                                Math.round(
                                                                    Number(data.data.companyFinances[0].sales) * UNIT,
                                                                ),
                                                            )}
                                                            (백만원), 영업이익은{" "}
                                                            {commaNumber(
                                                                Math.round(
                                                                    Number(
                                                                        data.data.companyFinances[0].operatingProfit,
                                                                    ) * UNIT,
                                                                ),
                                                            )}
                                                            (백만원), 당기순이익은{" "}
                                                            {commaNumber(
                                                                Math.round(
                                                                    Number(data.data.companyFinances[0].netProfit) *
                                                                        UNIT,
                                                                ),
                                                            )}
                                                            (백만원)입니다.{" "}
                                                        </span>
                                                        <br />
                                                    </>
                                                )}
                                            {finance.thisYear.acctDt !== "" && (
                                                <>
                                                    <span>
                                                        전년도 대비 매출 {finance.changes.changeSales.data}%
                                                        {finance.changes.changeSales.plusMinus === "plus"
                                                            ? "증가"
                                                            : "감소"}
                                                        , 영업이익 {finance.changes.changeOrProfit.data}%{" "}
                                                        {finance.changes.changeOrProfit.plusMinus === "plus"
                                                            ? "증가"
                                                            : "감소"}
                                                        , 당기순이익 {finance.changes.changeNetProfit.data}%
                                                        {finance.changes.changeNetProfit.plusMinus === "plus"
                                                            ? "증가"
                                                            : "감소"}
                                                        하였습니다.{" "}
                                                    </span>
                                                    <br />
                                                </>
                                            )}

                                            {finance.thisYear.dept &&
                                                finance.thisYear.deptRatio &&
                                                finance.thisYear.deptStaus && (
                                                    <>
                                                        <span>
                                                            부채규모는{" "}
                                                            {commaNumber(
                                                                Math.round(Number(finance.thisYear.dept) * UNIT),
                                                            )}
                                                            (백만원)({finance.thisYear.deptRatio}%)으로 기업안정성
                                                            지표는 {finance.thisYear.deptStaus}
                                                            입니다.{" "}
                                                        </span>
                                                        <br />
                                                    </>
                                                )}
                                        </p>
                                    </div>

                                    {data?.data.companyFinances !== undefined &&
                                        data.data.companyFinances.length > 0 && (
                                            <div className="CD-finance">
                                                <div className="section-header">
                                                    <p className="title">
                                                        재무({data.data.companyFinances[0].acctDt}, 백만원) 기준
                                                    </p>
                                                </div>
                                                <div className="CD-items">
                                                    <div className="CD_has">
                                                        <CountComp
                                                            title={"매출"}
                                                            num={Math.round(
                                                                Number(data.data.companyFinances[0].sales) * UNIT,
                                                            )}
                                                            color={"black"}
                                                        />
                                                    </div>
                                                    <div className="CD_has">
                                                        <CountComp
                                                            title={"영업이익"}
                                                            num={Math.round(
                                                                Number(data.data.companyFinances[0].operatingProfit) *
                                                                    UNIT,
                                                            )}
                                                            color={"orange"}
                                                        />
                                                    </div>
                                                    <div className="CD_has">
                                                        <CountComp
                                                            title={"부채"}
                                                            num={Math.round(
                                                                Number(data.data.companyFinances[0].debtTotal) * UNIT,
                                                            )}
                                                            color={"grey"}
                                                        />
                                                    </div>
                                                    <div className="CD_has">
                                                        <CountComp
                                                            title={"순이익"}
                                                            num={Math.round(
                                                                Number(data.data.companyFinances[0].netProfit) * UNIT,
                                                            )}
                                                            color={"black"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    {newsData !== undefined && newsData?.content.length > 0 && (
                                        <div className="CD__news">
                                            <div className="section-header">
                                                <p className="title">기업뉴스</p>
                                                <button className="CDNewsbtn" onClick={handleNewsMore}>
                                                    <PlusIcon />
                                                    더보기
                                                </button>
                                            </div>

                                            <ul className="CD__news-list">
                                                {newsData?.content.map((item) => (
                                                    <li className="CD__news-item" key={item.id}>
                                                        <div className="CD-image-wrap">
                                                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                                            <img
                                                                src={
                                                                    item.image_url !== "no image"
                                                                        ? item.image_url
                                                                        : newsImg01
                                                                }
                                                                alt="news-image"
                                                            />
                                                        </div>
                                                        <div className="CD-col">
                                                            <p className="CD-title">{item.title}</p>
                                                            <p className="CD-text">
                                                                {item.content.length > 20
                                                                    ? `${item.content.substring(0, 100)}...`
                                                                    : item.content}
                                                            </p>
                                                            <span className="CD-date">
                                                                {item.create_date.split("T")[0]}
                                                            </span>
                                                        </div>
                                                        <button
                                                            className="MenuMore"
                                                            onClick={() => window.open(item.url)}
                                                        >
                                                            <MenuMoreIcon />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* CompanyDetail */}
                </div>
            )}
        </>
    );
};
export default CompanyDetail;

import { INegativeValuesProps } from "types/etcTypes";
import ApexChart from "react-apexcharts";

const colors = [
    "#4b75c1",
    "#e1762a",
    "#a3a3a3",
    "#f9bd21",
    "#659bd3",
    "#79a74c",
    "#2f4976",
    "#91440e",
    "#5a5a5a",
    "#c9b67f",
    "#c3d2e1",
    "#9fab93",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
    "#3a5d9e",
    "#c7631b",
    "#838383",
    "#c6971b",
    "#417ebf",
    "#63863f",
    "#91aada",
    "#a1b2d5",
    "#e3b494",
    "#b4b4b4",
    "#faca3f",
    "#82add8",
    "#94bc6b",
    "#35599c",
    "#94bc6b",
];

const NegativeValues = ({ data, width, height }: INegativeValuesProps) => {
    return (
        <ApexChart
            type={"bar"}
            width={`${width}px`}
            height={`${height}px`}
            series={[
                {
                    name: "CAGR",
                    data: data.data,
                },
            ]}
            options={{
                chart: {
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                yaxis: {
                    title: {
                        text: "3개년CAGR",
                        style: {
                            color: "#666",
                            fontSize: "12px",
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                        },
                    },
                    labels: {
                        formatter: function (y) {
                            return y.toFixed(2) + "%";
                        },
                    },
                },
                xaxis: {
                    title: {
                        text: "업종(중분류)",
                        style: {
                            color: "#666",
                            fontSize: "12px",
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                        },
                    },
                    // type: "datetime",
                    categories: data.label,
                    // labels: {
                    //     rotate: -90,
                    // },
                },
                colors: [
                    // @ts-ignore
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                        return colors[dataPointIndex] ? colors[dataPointIndex] : "#ff0";
                    },
                ],
                legend: {
                    show: true,
                    // position: "bottom",
                    // horizontalAlign: "center",
                    // width: 300,
                },
                dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#999"],
                        fontFamily: "Noto Sans KR",
                        fontWeight: 400,
                    },
                    formatter: (val) => {
                        return `${val}%`;
                    },
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
            }}
        />
    );
};

export default NegativeValues;

import { getDataChart } from "@api/patentPia";
import { patentPiaCateId } from "@atoms";
import { ApplicantStatus, LineDots, MapChart, Pie } from "@components/Graph";
import BarWithNegative from "@components/Graph/BarWithNegative";
import FlowerChart from "@components/Graph/FlowrChart";
import ScatterChart from "@components/Graph/ScatterChart";
import LoadingComp from "@components/LoadingComp";
import usePatentPia from "@hooks/usePatentPia";
import { CloudDesc, GraphWrapper, SoureceMoveBtn } from "@styles/Common/style";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { IDataChart } from "types/patentPiaTypes";
import GaugeComp from "./GaugeComp";
import SwipeSimple from "./SwipeSimple";
import { handleDataType } from "./utils";
import WordCloud from "./WordCloud";

interface IGraphComp {
    url: string;
    type: string;
    index?: number | string;
    isLegend?: boolean;
    width?: number;
    title?: string;
    chartGroup?: string;
    desc?: string;
    sourceUrl?: string;
}
interface IWords {
    text: string;
    value: number;
}
interface IWorld {
    id: string;
    name: string;
    value: string;
}
interface IRealData {
    xAxys?: any[];
    yAxys?: any[];
    yAxis?: any[];
    yData?: (number | null)[];
    flowerData?: any[];
    axisLabel?: { [key: string]: string };
    maxNum?: number;
    yDataNumbers?: number[];
    words?: IWords[];
    worldMap?: IWorld[];
}

const GraphComp = ({ url, type, index, isLegend, width, title, chartGroup, desc, sourceUrl }: IGraphComp) => {
    const [realData, setRealData] = useState<IRealData>();
    const { dashTempLoading, dashFetching } = usePatentPia({});
    const cateId = useAtomValue(patentPiaCateId);

    const { data, isLoading } = useQuery(["data", url, type], async () => getDataChart(url.split("kiat")[1]), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 1,
        enabled: !!url,
        cacheTime: 0,
        onSuccess(data) {},
    });

    useEffect(() => {
        if (data !== undefined) {
            const temp: IDataChart = JSON.parse(data.RESULT);
            handleDataType(temp, setRealData, chartGroup);
        }
    }, [data, isLoading]);

    let dataLength = 0;

    if (Array.isArray(realData)) {
        dataLength = realData.length;
    }

    return (
        <>
            {/* <div className="c__loading">
                <LoadingComp />
            </div> */}
            {isLoading && realData === undefined ? (
                <div className="c__loading">
                    <LoadingComp />
                </div>
            ) : (
                <>
                    {isLoading && dashTempLoading && (
                        <div className="c__loading">
                            <LoadingComp />
                        </div>
                    )}

                    {(type === "LINE" || type === "TREND") && realData !== undefined && (
                        <LineDots
                            data={realData.yAxis || []}
                            label={realData.xAxys || []}
                            height={450}
                            labelName={realData.axisLabel || {}}
                        />
                    )}
                    {type === "HORIZONTALBAR" && realData !== undefined && (
                        <ApplicantStatus
                            data={realData.yData || []}
                            labels={realData.xAxys || []}
                            xaxisTitle={""}
                            yaxisTitle={""}
                            height={500}
                        />
                    )}
                    {type === "WINDROSE" && realData !== undefined && (
                        <FlowerChart data={realData.flowerData || []} id={`flower${index}${cateId}`} height={550} />
                    )}

                    {/* {realData !== undefined && Array.isArray(realData.yAxys) && Array.isArray(realData.xAxys) && (
                        <>
                            <LineDots data={realData.yAxys} label={[]} height={500} />
                            <ul>{realData?.xAxys.map((item: any, index: number) => <li key={index}>{item}</li>)}</ul>
                        </>
                    )} */}

                    {type === "2X2MATRIX" && realData !== undefined && (
                        <ScatterChart data={realData.yAxis || []} label={realData.axisLabel || {}} />
                    )}

                    {type === "WING" && realData !== undefined && (
                        <BarWithNegative
                            data={realData.yAxis || []}
                            categories={realData.xAxys || []}
                            maxNum={realData.maxNum || 100}
                            height={500}
                        />
                    )}

                    {type === "PIE" && realData !== undefined && (
                        <Pie
                            data={realData.yDataNumbers || []}
                            labels={realData.xAxys || []}
                            width={width ? width : 600}
                            isLegend={isLegend}
                        />
                    )}

                    {type === "KEYKPI" && realData !== undefined && (
                        <div>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>
                                        {title}&nbsp;({dataLength})
                                    </strong>
                                    <div className="wrap_btn">
                                        <button />
                                        {desc && <CloudDesc className="wrap_cloud">{desc}</CloudDesc>}
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner">
                                <SwipeSimple data={realData} countable={true} />
                            </div>
                        </div>
                    )}

                    {type === "STRENGTHKPI" && realData !== undefined && (
                        <>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>
                                        {title}&nbsp;({dataLength})
                                    </strong>
                                    <div className="wrap_btn">
                                        <button />
                                        {desc && <CloudDesc className="wrap_cloud">{desc}</CloudDesc>}
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <div className="inner">
                                <SwipeSimple data={realData} countable={true} />
                            </div>
                        </>
                    )}

                    {type === "KEYGAUGE" && realData !== undefined && (
                        <>
                            <GraphWrapper>
                                <div className="p__titles">
                                    <strong>
                                        {title}&nbsp;({dataLength})
                                    </strong>
                                    <div className="wrap_btn">
                                        <button />
                                        {desc && <CloudDesc className="wrap_cloud">{desc}</CloudDesc>}
                                    </div>
                                </div>
                                <SoureceMoveBtn onClick={() => window.open(`//${sourceUrl}`)}>
                                    데이터 출처
                                </SoureceMoveBtn>
                            </GraphWrapper>
                            <GaugeComp data={realData || []} />
                        </>
                    )}

                    {type === "WORLDMAP" && realData !== undefined && (
                        <>
                            <MapChart data={realData.worldMap || []} height={500} index={Number(index) || 0} />
                        </>
                    )}

                    {type === "WORDCLOUD" && realData !== undefined && (
                        <>
                            <WordCloud words={realData.words || []} />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default GraphComp;

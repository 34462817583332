import { IApplicationTrendProps } from "types/etcTypes";
import React from "react";
import ApexChart from "react-apexcharts";

const ApplicationTrend: React.FC<IApplicationTrendProps> = ({ data, label, width, height }) => {
    return (
        <>
            <ApexChart
                type="area"
                width={`${width}px`}
                height={`${height}px`}
                className="table-patent-posses"
                series={[
                    {
                        name: "",
                        data: data,
                    },
                ]}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val.toLocaleString("en-US");
                        },
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3,
                    },
                    colors: [],
                    yaxis: {
                        show: true,
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        title: {
                            text: "출원건수",
                            style: {
                                color: "#666",
                                fontSize: "12px",
                                fontFamily: "Noto Sans KR",
                                fontWeight: 400,
                            },
                        },
                    },
                    xaxis: {
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        // labels: { show: false },
                        categories: label,
                        title: {
                            text: "출원연도",
                            style: {
                                color: "#666",
                                fontSize: "12px",
                                fontFamily: "Noto Sans KR",
                                fontWeight: 400,
                            },
                        },
                    },
                }}
            />
        </>
    );
};

export default ApplicationTrend;

import { useEffect, useState } from "react";

const useTabs = (initTab: number | string, allTabs: any[]) => {
    const [currentIndex, setCurrentIndex] = useState(initTab);

    useEffect(() => {
        setCurrentIndex(initTab);
    }, [initTab]);

    return {
        currentItem: typeof currentIndex === "number" ? allTabs[currentIndex] : "",
        changeItem: setCurrentIndex,
        currentIndex: currentIndex,
    };
};

export default useTabs;

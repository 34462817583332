import { device } from "@assets/index";
import { styled } from "styled-components";

export const Wrap = styled.div<{ $bannerimg: string; $bannerlogo: string }>`
    position: relative;
    width: 100%;
    height: 240px;
    background: #5a60a0;

    @media ${device.tabletL} {
        height: auto;
        padding-bottom: 30px;
    }

    background-image: url(${(props) => props.$bannerimg});
    background-repeat: no-repeat;
    background-size: cover;

    .c__bannerText {
        font-size: 0;
        padding-top: 20px;
        padding-left: 20px;

        @media ${device.mobile} {
            padding-right: 20px;
        }
    }

    .c__logwrap {
        display: inline-block;
        border-radius: 10px;
        background: #fff;
        vertical-align: middle;

        &.none-padding {
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            border-radius: 10px;
        }
    }

    .c__textwrap {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;

        @media ${device.tabletL} {
            display: block;
            margin-left: 0;
            margin-top: 20px;
        }

        .p__title {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #fff;
            @media ${device.tabletL} {
                line-height: 1.4;
            }
        }

        .p__desc {
            margin-top: 11px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
        }
    }
    .item__menu {
        position: absolute;
        left: 20px;
        bottom: 20px;
        font-size: 0;

        @media ${device.tabletL} {
            position: relative;
            left: 0;
            bottom: 0;
            margin-top: 20px;
            text-align: center;
        }

        li {
            display: inline-block;
            margin-left: 16px;
            padding: 10px 23px 11px;
            border-radius: 3px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
            cursor: pointer;

            @media ${device.mobile} {
                margin-left: 0;
            }

            &.active {
                background: rgba(255, 255, 255, 0.2);
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .item__icons {
        position: absolute;
        font-size: 0;
        background: #fff;
        text-align: left;
        overflow: hidden;
        border-radius: 8px;
        right: 20px;
        bottom: 20px;

        @media ${device.tablet} {
            position: relative;
            right: 0;
            bottom: 0;
            text-align: right;
            margin: 20px 12px 0;

            background: transparent;
        }

        li {
            display: inline-block;
            width: 48px;
            height: 50px;
            padding-top: 17px;
            vertical-align: middle;
            text-align: center;
            box-sizing: border-box;
            border-left: 2px solid #e9ebec;
            cursor: pointer;

            @media ${device.tablet} {
                background: #fff;
            }

            &:first-child {
                border-left: 2px solid #e9ebec;
                @media ${device.tablet} {
                    background: #fff;
                    border-radius: 8px 0 0 8px;
                }
            }
            &:nth-child(2) {
                padding-top: 16px;
            }
        }
    }
`;

import { patchTrader } from "@api/mainApi";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface IData {
    all: string;
    name: string;
    affiliateName: string;
    techTraderNum: string;
    statusCds: string[];
}

const useAgent = (data: IData, page: number, size: number) => {
    const [didMount, setDidMount] = useState(false);
    const { data: traderData, mutate, isLoading } = useMutation((data: IData) => patchTrader(data, page, size));
    useEffect(() => {
        setDidMount(true);
    }, []);
    useEffect(() => {
        if (didMount) {
            mutate(data);
        }
    }, [didMount, data, page]);
    return {
        traderData,
        isLoading,
    };
};

export default useAgent;

import { styled } from "styled-components";
import QuestIcon from "@assets/img/icon-quest.png";
import { device } from "@assets/index";

export const Content = styled.div`
    .l__items {
        display: grid;
        grid-template-columns: 1fr 1.85fr 1.85fr;
        column-gap: 16px;
        padding-top: 16px;

        @media ${device.laptopS} {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 16px;
        }
    }

    .l__itemReapeat2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 16px;
        column-gap: 16px;

        @media ${device.tabletL} {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .c__has {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        row-gap: 16px;

        .c__count {
            padding-top: 16px;
            padding-left: 16px;
            background-color: #fff;
            box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;

            strong {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 500;
                font-size: 18px;
                color: #333;

                @media ${device.mobile} {
                    font-size: 16px;
                }
            }

            p {
                padding-top: 16px;
                font-family: "Spoqa Han Sans Neo";
                font-weight: 700;
                font-size: 40px;
                color: #333;

                @media ${device.laptopS} {
                    font-size: 20px;
                    padding-bottom: 16px;
                }
            }
        }
    }

    .c__pie {
        text-align: center;
        margin-top: 20px;

        & > div {
            padding-top: 0;
            display: inline-block;
        }
    }

    .c__item {
        height: 381px;
        background: #fff;
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 16px;

        .p__titles {
            padding-top: 16px;
            padding-left: 16px;
            font-size: 0;

            strong {
                display: inline-block;
                vertical-align: middle;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 500;
                font-size: 18px;
                color: #333;
            }

            button {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin-left: 8px;
                background-image: url(${QuestIcon});
                background-repeat: no-repeat;
                background-position: 50%;
                top: 1px;
            }
        }
    }

    .c__scatter {
        position: relative;
        padding: 30px;
    }

    .c__radar {
        padding-top: 30px;
    }

    .c__application {
        text-align: center;
    }

    .c__chart {
        position: absolute;
        width: 90%;
        padding-top: 30px;
        margin: 0 0% 0;
        box-sizing: border-box;
    }

    .c__graph {
        position: absolute;
        width: 90%;
        margin: 0 5% 0;
        box-sizing: border-box;

        &.overflow {
            margin-top: 20px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 6px;
                padding-right: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #bcbec8;
                border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                // background-color: #000;
            }
            height: 300px;
        }
    }

    .apexcharts-canvas{
        top:15px !important;
    }    
`;

import { patchNewsApi } from "@api/newsApi";
import { INews } from "~types/types";
import { keyWordStorageAtom, newsPageAtom, searchNewsAtom } from "@atoms";
import { useMutation } from "@tanstack/react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

export const useNews = () => {
    const keyWord = useAtomValue(keyWordStorageAtom);

    const [dataTotalCount, setDataTotalCount] = useState<number>(0);
    const [didMount, setDidMount] = useState(false);
    const setSearchNewsAtom = useSetAtom(searchNewsAtom);

    const [newsPage, setNewsPage] = useAtom(newsPageAtom);

    const { mutate, isLoading, data } = useMutation((newsPage: INews) => patchNewsApi(newsPage), {
        onSuccess: (data) => {
            setSearchNewsAtom(data);
        },
    });

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (keyWord !== "") {
            setNewsPage((prev) => ({
                ...prev,
                page: 1,
                data: {
                    title: keyWord,
                    content: keyWord,
                },
            }));
        }
    }, [keyWord, setNewsPage]);

    useEffect(() => {
        if (newsPage.data.title !== "" && didMount) mutate(newsPage);
    }, [newsPage, didMount, keyWord, mutate]);

    useEffect(() => {
        setDataTotalCount(data?.totalElements ?? 0);
    }, [data]);

    const handlePage = (page: number) => setNewsPage((prev) => ({ ...prev, page: page }));

    return {
        mutate,
        isLoading,
        data,
        dataTotalCount,
        handlePage,
    };
};

import { device } from "@assets/index";
import { styled } from "styled-components";

export const ListRelations = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;

    &.hasLink {
        cursor: pointer;
    }

    strong {
        display: block;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #333;
    }

    .p__infos {
        margin-top: 8px;
        display: flex;
        justify-content: left;
        align-items: center;

        @media ${device.tablet} {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 10px;
        }

        li {
            position: relative;
            margin-left: 19px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            line-height: 13px;
            color: #666;

            @media ${device.tablet} {
                margin-left: 0;
            }

            &:after {
                position: absolute;
                content: "";
                width: 1px;
                height: 13px;
                background: #c3c3c3;
                top: 1px;
                right: -11px;
                @media ${device.tablet} {
                    display: none;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    .c__application {
        display: flex;
        justify-content: left;
        align-items: center;

        @media ${device.mobile} {
            flex-direction: column;
        }

        .p__application {
            width: 100px;
            text-align: right;
        }

        .p__number {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #333;
        }

        .p__unit {
            margin-top: 8px;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            color: #999;
        }

        .c__graph {
            position: relative;
            width: 80px;
            height: 36px;
            margin-top: 0;
        }
    }
`;

import { Pie, Spline } from "@components/Graph";
interface IData {
    data: any;
    label: any;
}

export const PatentOccurTrend = ({ data, label }: IData) => {
    return <Spline data={data} label={label} />;
};

export const CoreCompany = ({ data, label }: IData) => {
    return <Pie data={data} labels={label} width={450} isLegend={true} />;
};

const API = process.env.REACT_APP_API;
const API_KEY = process.env.REACT_APP_API_KEY;
const STATIC_RES = process.env.REACT_APP_MODE === "DEV" ? "devSTATIC" : "prodSTATIC";
const MODE = process.env.REACT_APP_MODE;
const PCODE = process.env.REACT_APP_PCODE;

const PATENTPIA_API = process.env.REACT_APP_PATENTPIA_API;

const KAKAO_KEY = process.env.REACT_APP_KAKAO_KEY;

const TECHSTORM_API = process.env.REACT_APP_TECH_API;

const NEWS_API = process.env.REACT_APP_API_COMPANY_NEWS_ES_URL;

const COMPANY_API = process.env.REACT_APP_API_COMPANY;
const COMPANY_DETAIL_API = process.env.REACT_APP_API_COMPANY_DETAIL;

const SEARCH_PATENT_API = process.env.REACT_APP_API_PATENT;

const SEARCH_PATENT_DETAIL = process.env.REACT_APP_API_PATENT_URL;

const BUILD_API = process.env.REACT_APP_BUILD_API;
const SEARCH_API = process.env.REACT_APP_SEARCH_API;

const TECHSTORMURL = process.env.REACT_APP_TECHSTORM_URL;

const BASE_HEADER = {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    API,
    API_KEY,
    STATIC_RES,
    MODE,
    PCODE,
    PATENTPIA_API,
    KAKAO_KEY,
    TECHSTORM_API,
    NEWS_API,
    COMPANY_API,
    COMPANY_DETAIL_API,
    SEARCH_PATENT_API,
    SEARCH_PATENT_DETAIL,
    BUILD_API,
    SEARCH_API,
    TECHSTORMURL,
    BASE_HEADER,
};

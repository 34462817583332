import { getReseacher } from "@api/patentPia";
import { IResearcherReturn, IResercherType } from "types/patentPiaTypes";
import { dashSelectAtom } from "@atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

const useResearchers = () => {
    const dashSelect = useAtomValue(dashSelectAtom);
    const [realData, setRealData] = useState<IResearcherReturn[]>([]);

    const { isLoading, refetch } = useQuery(
        ["reseacher", dashSelect.originName],
        async () => getReseacher(dashSelect.originName, 20, 0),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            cacheTime: 0,
            enabled: false,
            onSuccess(data) {
                const temp: IResercherType = JSON.parse(data.RESULT);

                if (temp.CHART_DATA_RESULT !== null) {
                    const tempArr = temp.CHART_DATA_RESULT.CONVERTED_RESULT_LIST.map((item, index) => ({
                        id: index,
                        name: item.INV_NAME.VALUE,
                        involved: item.CRH.VALUE,
                        patCnt: item.PAT_CNT_TOT.VALUE,
                    }));

                    const sorted = tempArr.sort(function (a: { patCnt: string }, b: { patCnt: string }) {
                        let nameA = Number(a.patCnt.toUpperCase());
                        let nameB = Number(b.patCnt.toUpperCase());

                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }

                        // 이름이 같을 경우
                        return 0;
                    });

                    setRealData(sorted);
                }
            },
            onError: (error: any) => {
                console.log(error);
            },
        },
    );

    useEffect(() => {
        if (dashSelect.originName !== undefined) {
            refetch();
        }
    }, [dashSelect.originName]);

    return {
        realData,
        isLoading,
    };
};

export default useResearchers;

import { getPatentKeyWordApi } from "@api/searchPatent";
import { keyWordStorageAtom } from "@atoms";
import LoadingComp from "@components/LoadingComp";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

const Keywords = () => {
    const [didMount, setDidMount] = useState(false);
    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    const { data, isLoading, refetch } = useQuery(
        ["patentKeyWord", keyWordStorage],
        () => getPatentKeyWordApi(keyWordStorage),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: 1,
            cacheTime: 0,
            enabled: false,
            onSuccess(data) {},
            onError: (error: any) => {
                console.log(error);
            },
        },
    );

    const handleKeyWords = (newKey: string) => {
        setKeyWordStorage(newKey);
    };

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount && keyWordStorage !== "") {
            refetch();
        }
    }, [didMount, keyWordStorage]);

    return (
        <div className="right">
            <p className="sub-header">연관키워드</p>
            {keyWordStorage !== "" && data?.length === 0 ? (
                <div className="wrap-empty">
                    <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                </div>
            ) : isLoading ? (
                <div className="cont-loading">
                    <LoadingComp />
                </div>
            ) : (
                data !== undefined && (
                    <>
                        <div className="Scroll FilterKeyword">
                            <div className="keyword">
                                <div className="keyword-item">
                                    {data.map((item, index) => (
                                        <button className="btn" key={index} onClick={() => handleKeyWords(item)}>
                                            {item}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )
            )}
        </div>
    );
};

export default Keywords;

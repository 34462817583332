import { allPopAtom, keyWordStorageAtom, reSizeAtom, wideModeAtom } from "@atoms";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";

export const useLayoutAction = (pathname = "") => {
    const [searchText, setSearchText] = useState("");
    const [ison, setIsOn] = useState(false);
    const [wideMode, setWideMode] = useAtom(wideModeAtom);
    const [reSize] = useAtom(reSizeAtom);
    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);
    const setPops = useSetAtom(allPopAtom);
    const searchInput = useRef<HTMLInputElement | null>(null);

    const handleNavi = useCallback(() => setWideMode((prev) => !prev), [setWideMode]);
    const handleMobile = useCallback(() => setIsOn((prev) => !prev), []);

    const handlePops = useCallback(() => {
        setPops((prev) => ({
            ...prev,
            dash: !prev["dash"],
        }));
    }, [setPops]);

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        setSearchText(event.target.value);
    };

    const handleLink = () => {
        if (
            (pathname.split("/")[pathname.split("/").length - 2] === "company" ||
                pathname.split("/")[pathname.split("/").length - 2] === "patent") &&
            pathname.split("/").length > 2
        ) {
            window.open("/consearch/company", "_self");
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (event.key === "Enter") {
            handleInput();
        }
    };

    const handleInput = () => {
        if (searchText === "") {
            alert("검색어를 입력하세요.");
            setKeyWordStorage(keyWordStorage);
            setSearchText(keyWordStorage);
        } else {
            setKeyWordStorage(searchText);
        }

        handleLink();
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.currentTarget.value !== "") {
            setSearchText("");
        }
    };

    const handleBtnClick = () => handleInput();

    useEffect(() => setWideMode(true), [setWideMode]);
    useEffect(() => (reSize < 1024 ? setWideMode(false) : setWideMode(true)), [reSize, setWideMode]);

    useEffect(() => {
        if (keyWordStorage) {
            setSearchText(keyWordStorage);
        }
    }, [keyWordStorage]);

    useEffect(() => {
        const clickWrap = (event: MouseEvent) => {
            if (
                searchInput.current &&
                document.activeElement !== searchInput.current &&
                !searchInput.current.contains(event.target as Node)
            ) {
                if (keyWordStorage !== "") {
                    setSearchText(keyWordStorage);
                }
            }
        };

        document.addEventListener("click", clickWrap);

        return () => {
            document.removeEventListener("click", clickWrap);
        };
    }, [keyWordStorage]);

    return {
        ison,
        reSize,
        wideMode,
        searchText,
        searchInput,
        handleNavi,
        handlePops,
        handleSearchText,
        handleKeyUp,
        handleClick,
        handleMobile,
        handleBtnClick,
        setIsOn,
    };
};

import { List, Wrap } from "@components/DashPop/style";
import { imgData } from "@constants/categories";
import { useLayoutAction } from "@services/Layout";

const DashPop = () => {
    const { handlePops } = useLayoutAction();

    return (
        <Wrap>
            <div className="l__pop">
                <div className="c__title">
                    <p className="p__title">
                        혁신적인 민간기술서비스를 통해
                        <br />
                        디지털 오픈이노베이션을 빠르게 수행해 보세요.{" "}
                    </p>
                    <p className="p__update">최근업데이트 2023-07-03</p>
                </div>
                <div className="c__content">
                    {imgData.map((item) => (
                        <List
                            key={item.id}
                            $hasImg={item.$hasImg}
                            $img={item.$img}
                            onClick={item.path ? () => window.open(item.path, "_self") : undefined}
                        />
                    ))}
                </div>
                <button className="btn__close" onClick={handlePops} />
            </div>
        </Wrap>
    );
};

export default DashPop;

import { keyWordStorageAtom } from "@atoms";
import { searchCategoriesData } from "@constants/categories";
import { useCompany, useDispute, useNews, useTechStrom } from "@hooks/index";
import { usePatent, useResearcher } from "@services/index";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { CountType } from "~types/ConvergenceSearch/index";

export const useSearchNaviInformation = () => {
    const [didMount, setDidMount] = useState(false);

    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    const { dataTotalCount: patentCount } = usePatent();
    const { dataTotalCount: companyCount } = useCompany();
    const { dataTotalCount: salesCount } = useTechStrom(keyWordStorage, "search");
    const { dataTotalCount: researcherCount } = useResearcher(20, 0);
    const { dataTotalCount: disputeCount } = useDispute(25, 0);
    const { dataTotalCount: newsCount } = useNews();

    const counts: CountType = {
        특허: patentCount,
        기업: companyCount,
        판매기술: salesCount,
        연구자: researcherCount,
        분쟁정보: disputeCount,
        뉴스: newsCount,
    };

    const searchInformationData = searchCategoriesData.map((category) => ({
        ...category,
        count: counts[category.label],
    }));

    useEffect(() => setDidMount(true), []);
    useEffect(() => {
        if (didMount) {
            if (keyWordStorage === "") {
                setKeyWordStorage("2차전지");
            }
        }
    }, [didMount, keyWordStorage, setKeyWordStorage]);

    return {
        searchInformationData,
    };
};

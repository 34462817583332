import LoadingComp from "@components/LoadingComp";
import { useAtom, useAtomValue } from "jotai";
import { keyWordStorageAtom, researcherAtom, researcherTotalPageAtom, searchResearcherAtom } from "@atoms";
import PageNation from "@components/PageNation";
import { getReseacher } from "@api/patentPia";
import { useEffect } from "react";

const Researcher = () => {
    const keyWordStorage = useAtomValue(keyWordStorageAtom);
    const [realData, setRealData] = useAtom(searchResearcherAtom);

    const limit = 20;
    const [page, setPage] = useAtom(researcherAtom);
    const totalPage = useAtomValue(researcherTotalPageAtom);

    const handlePage = (page: number) => {
        setPage(page);
        getReseacher(keyWordStorage, limit, limit * (page - 1)).then((res) => {
            const temp = JSON.parse(res.RESULT);
            setRealData(temp);
        });
    };

    useEffect(() => {
        return setPage(1);
    }, []);

    return (
        <div className="content-wrap Reseacher">
            <div className="Table-wrap">
                {/*데이터가 없을때*/}
                {
                    keyWordStorage === "" ||
                    (realData?.CHART_DATA_RESULT === null || realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length === 0) &&
                    <div className="wrap-empty">
                        <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                    </div>
                }

                {/*데이터가 있을때*/}
                {
                    !(realData?.CHART_DATA_RESULT === null || realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length === 0) &&
                    <>
                        <div className="Table researcher">
                            <ul className="tr thead">
                                <div className="tr">
                                    <div className="td order">No</div>
                                    <div className="td name">발명자</div>
                                    <div className="td com">소속기관(출원인)</div>
                                    <div className="td open">공개 특허수</div>
                                    <div className="td regi">등록 특허수</div>
                                    <div className="td share">점유율 공개</div>
                                    <div className="td concent">집중률</div>
                                    <div className="td tran">거래 특허수</div>
                                    <div className="td referee">심판 특허수</div>
                                    <div className="td cite">심사관 피인용수</div>
                                    <div className="td fce">점유율 FC-E</div>
                                    <div className="td fam">해외Fam</div>
                                </div>
                            </ul>

                            {Object.keys(realData).length !== 0 && (
                                <div className="Scroll">
                                    {realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.map((item: any, index: number) => (
                                        <div className="tr" key={index}>
                                            <div className="td order">{limit * (page - 1) + index + 1}</div>
                                            <div className="td name">{item.INV_NAME.VALUE}</div>
                                            <div className="td com">{item.CRH.VALUE}</div>
                                            <div className="td open">{item.PAT_CNT_TOT.VALUE}</div>
                                            <div className="td regi">{item.PAT_ISSUE_CNT_TOT.VALUE}</div>
                                            <div className="td share">{item.SHARE_INTECH_PAT_TOT.VALUE}%</div>
                                            <div className="td concent">{item.FOCUS_TOTECH_PAT_TOT.VALUE}%</div>
                                            <div className="td tran">
                                                {item.ASNPAT_TOT.VALUE === "" ? "-" : item.ASNPAT_TOT.VALUE}
                                            </div>
                                            <div className="td referee">
                                                {item.TRIALPAT_TOT.VALUE === "" ? "-" : item.TRIALPAT_TOT.VALUE}
                                            </div>
                                            <div className="td cite">{item.FCE_TOT.VALUE}</div>
                                            <div className="td fce">{item.SHARE_INTECH_FCE_TOT.VALUE}%</div>
                                            <div className="td fam">{item.FGNFAM_TOT.VALUE}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {
                                realData?.CHART_DATA_RESULT?.CONVERTED_RESULT_LIST.length > 0 &&
                                <div className="wrap_pagenation">
                                    <PageNation totalPage={totalPage} currentPage={page} clickPage={handlePage} />
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Researcher;

import { Link } from "react-router-dom";
import { OtherMenuProps } from "~types/Layout";

const OtherMenu = ({ id, includesVal, label, path, pathname, className }: OtherMenuProps) => {
    return (
        <li key={id}>
            <Link
                className={`b__menu ${className} ${pathname.includes(`${includesVal}`) ? "active" : ``}`}
                data-name={includesVal}
                to={`${path}`}
            >
                {label}
            </Link>
        </li>
    );
};

export default OtherMenu;

import { keyWordStorageAtom } from "@atoms";
import useKeyWords from "./useKeyWords";
import { useAtom } from "jotai";
import LoadingComp from "@components/LoadingComp";

const KeyWords = () => {
    const { kData, kLoading, handleKeyWords } = useKeyWords();
    const [keyWordStorage, setKeyWordStorage] = useAtom(keyWordStorageAtom);

    return (
        <div className="right">
            <p className="sub-header">연관키워드</p>
            {keyWordStorage === "" || kData === undefined ? (
                <div className="wrap-empty">
                    <p className="text">검색 결과가 없거나 검색어를 입력하지 않았습니다.</p>
                </div>
            ) : kLoading || kData === undefined ? (
                <div className="cont-loading">
                    <LoadingComp />
                </div>
            ) : kData.status === 100 ? (
                <div className="wrap-empty">
                    <p className="text">{kData.message}</p>
                </div>
            ) : (
                <div className="Scroll FilterKeyword">
                    <div className="keyword">
                        <div className="keyword-item">
                            {
                                kData.data?.map((item, index) => (
                                    <button className="btn" key={index} onClick={() => handleKeyWords(item)}>
                                        {item}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default KeyWords;

import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { AnimatePresence, motion } from "framer-motion";

import "swiper/css";

import { Pagination, Navigation } from "swiper/modules";
import useCounting from "@hooks/useCounting";
import { yPositionPopVariants } from "@utils/index";

const SwiperPage = ({ data }: any) => {
    const swiperSlide = useSwiperSlide();
    const { count } = useCounting(data.num, swiperSlide.isActive, 10);
    return (
        <>
            <p className="t__title">{data.title}</p>
            {swiperSlide.isActive && Number.isInteger(data.num) && <p className="t__num">{count}</p>}

            {swiperSlide.isActive && !Number.isInteger(data.num) && (
                <AnimatePresence>
                    <motion.p
                        className="t__num"
                        variants={yPositionPopVariants}
                        initial="initial"
                        animate="visible"
                        exit="leaving"
                        transition={{ delay: 0.3 }}
                    >
                        {data.num}
                    </motion.p>
                </AnimatePresence>
            )}
        </>
    );
};

const SwiperPageUnCountable = ({ data }: any) => {
    const swiperSlide = useSwiperSlide();
    return (
        <>
            <p className="t__title">{data.title}</p>

            <AnimatePresence>
                {swiperSlide.isActive && (
                    <motion.p
                        className="t__num"
                        variants={yPositionPopVariants}
                        initial="initial"
                        animate="visible"
                        exit="leaving"
                        transition={{ delay: 0.3 }}
                    >
                        {data.num}
                    </motion.p>
                )}
            </AnimatePresence>
        </>
    );
};

const SwipeSimple = ({ data, countable }: any) => {
    return (
        <div className="wrap__simpleSwiper">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={0}
                loop={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                navigation={true}
                // onSlideChange={(swiper) => console.log(swiper.realIndex)}
                // onSwiper={(swiper) => console.log(swiper.realIndex)}
            >
                {countable &&
                    data &&
                    data.map((item: any) => (
                        <SwiperSlide key={item.id}>
                            <SwiperPage data={item} />
                        </SwiperSlide>
                    ))}

                {!countable &&
                    data &&
                    data.map((item: any) => (
                        <SwiperSlide key={item.id}>
                            <SwiperPageUnCountable data={item} />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default SwipeSimple;

import { calDay, commaNumber, formatThumbnail, handleText } from "@utils/index";

import { useEffect, useState } from "react";

import { CardWrap } from "../style";

import DefaultTechImage from "@assets/img/default-main-img.png";
import config from "@config";
import styled from "styled-components";
import { ITechContent } from "~types/types";
import FavoComp from "./FavoComp";

import noUser from "@assets/img/no-user-img.png";

interface IProps {
    data: ITechContent;
}

const ImgBack = styled.div<{ $url: string }>`
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.$url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`;

const TechProfile = styled.div<{ $url: string }>`
    overflow: hidden;
    width: 24px;
    height: 24px;
    background-image: url(${(props) => props.$url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
`;

const Card = ({ data }: IProps) => {
    console.log("server conneted");
    const [type, setType] = useState<string[]>([]);

    const [day, setDay] = useState({
        day: "00일 00시 00분 00초",
        isAbled: true,
        isNull: false,
    });

    useEffect(() => {
        const types = [data.scienceTechCdDesc, data.industryTechCdDesc, data.typeCdDesc].filter(Boolean);
        setType(types);

        const intervalId = setInterval(() => {
            const { formattedDday, isAbled } = calDay(data.trade.saleEndDate);
            setDay({
                day: formattedDday,
                isAbled,
                isNull: !data.trade.saleEndDate,
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [data]);

    const techFile = data.techFileList?.find((elem) => elem.fileCd === "TFC0001");
    const techFileUrl = techFile && formatThumbnail(techFile.fileUrl) ? techFile.fileUrl : DefaultTechImage;
    const sellerProfileUrl = data.seller.attachList.find((item) => item.attachCd === "MAC0001")?.fileUrl || noUser;
    const priceText =
        data.trade.priceTypeCd === "TPT0001"
            ? "가격협의"
            : `${commaNumber(Number(data.trade.price))}${data.trade.unitCdDesc}`;

    return (
        <CardWrap onClick={() => window.open(`${config.TECHSTORMURL}/technology/transfer/detail/${data.techIdx}`)}>
            <div className="c__imgOuter">
                <ImgBack $url={techFileUrl} />
                <span className="c__selling">{data.trade.statusCdDesc}</span>
            </div>
            <div className="c__text">
                <span className="c__assignment">{data.contractCdDesc}</span>
                {type.length > 0 && (
                    <ul className="item__skills">
                        {type.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                )}
                <div className="c__title">{handleText(data.techName, 20)}</div>
                <ul className="c__keyword">
                    {data.keywords.split(",").map((item, index) => (
                        <li key={index}>#{item}</li>
                    ))}
                </ul>
                <div className="c__time">
                    <strong className="c__state">{priceText}</strong>
                    <div className="p__time">
                        <strong className="p__dday">D-day</strong>
                        <span className={`p__day ${!day.isAbled && "red"}`}>
                            {day.isNull ? "상시판매" : day.isAbled ? day.day : "종료"}
                        </span>
                    </div>
                </div>
                <div className="c__seller">
                    <div className="p__seller">
                        <TechProfile $url={sellerProfileUrl} />
                        <span>
                            {data.seller.nickname} ({data.seller.memberId})
                        </span>
                    </div>
                    <FavoComp id={data.techIdx} />
                </div>
            </div>
        </CardWrap>
    );
};

export default Card;

import { DeviceType } from "~types/types";

const sizes: Record<DeviceType, string> = {
    mobile: "400px",
    tablet: "768px",
    tabletL: "1100px",
    laptopS: "1400px",
};

export const device = {
    mobile: `screen and (max-width: ${sizes.mobile})`,
    tablet: `screen and (max-width: ${sizes.tablet})`,
    tabletL: `screen and (max-width: ${sizes.tabletL})`,
    laptopS: `screen and (max-width: ${sizes.laptopS})`,
};

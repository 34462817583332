import { PropsWithChildren } from "react";
import { CommentWrap } from "./style";

const Comment = ({ children }: PropsWithChildren) => {
    return (
        <CommentWrap>
            <strong>Comment</strong>
            <p>{children}</p>
        </CommentWrap>
    );
};

export default Comment;

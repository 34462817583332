import { Link } from "react-router-dom";
import { AppsMenuProps } from "~types/Layout";

const AppsMenu = ({ id, includesVal, label, path, pathname, state }: AppsMenuProps) => {
    return (
        <li key={id}>
            <Link to={path} className={`${pathname.includes(includesVal) && "active"}`} state={state}>
                {label}
            </Link>
        </li>
    );
};

export default AppsMenu;

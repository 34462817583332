import { Wrap } from "./style";
import RelatedCompanyTrend from "./RelatedCompanyTrend";
import RelatedPatentIllunex from "./RelatedPatentIllunex";
import { IRelatedPatent } from "~types/types";

interface IProps {
    atData: any;
    relatedPatent?: IRelatedPatent[];
}

const List = ({ atData, relatedPatent }: IProps) => {
    return (
        <>
            <Wrap>
                <RelatedPatentIllunex />
            </Wrap>
            {/* <Wrap>
                <RelatedPatent data={relatedPatent} />
            </Wrap> */}
            <Wrap>
                <RelatedCompanyTrend data={atData} />
            </Wrap>

            {/* <Wrap>
                <RelatedCompay data={relatedCompany} />
            </Wrap> */}
        </>
    );
};

export default List;

import useTabs from "@hooks/useTabs";

import {Wrap} from "../style";
import EnterPrise from "./EnterPrise";
import Competitor from "./Competitor";
import {useEffect, useRef, useState} from "react";
import useCompany from "./useCompany";
import {useSetAtom} from "jotai";
import {competeIsViewAtom} from "@atoms";
import tooltipIcon from "@assets/icon/tooltip.svg";
import {CloudDesc} from "@styles/Common/style";

interface IResults {
    name: string;
    code: string;
}

interface IList {
    id: string;
    companyName: string;
    companySize: string;
    conpanyState: string;
    companyEm: string;
    companyPresent: string;
    companyLocate: string;
}

const companyData = [
    {
        name: "삼성전자(Samsung Electronics)",
        code: "UN000001",
    },
    {
        name: "국민건강보험공단(National Health Insurance)",
        code: "U1011867",
    },
    {
        name: "한국전력공사(Korea Electric Power)",
        code: "UN000077",
    },
    {
        name: "현대자동차(Hyundai Motor Company)",
        code: "UN000003",
    },
    {
        name: "지에스칼텍스(GS Caltex)",
        code: "UN001813",
    },
    {
        name: "하나은행(KEB HANA BANK)",
        code: "UN001789",
    },
    {
        name: "한국산업은행(The Korea Development Bank)",
        code: "UN008489",
    },
    {
        name: "한국가스공사(Korea Gas)",
        code: "UN000631",
    },
    {
        name: "에스케이에너지(SK ENERGY)",
        code: "UN001027",
    },
    {
        name: "국민은행(Kookmin Bank)",
        code: "UN001207",
    },
    {
        name: "기아(KIA)",
        code: "UN000005",
    },
    {
        name: "에쓰오일(S Oil)",
        code: "UN005917",
    },
    {
        name: "에스케이하이닉스(SK Hynix)",
        code: "UN000007",
    },
    {
        name: "우리은행(WOORI BANK)",
        code: "UN001243",
    },
    {
        name: "포스코(POSCO)",
        code: "UN319231",
    },
    {
        name: "국민연금공단(National Pension Service)",
        code: "U1080407",
    },
    {
        name: "삼성생명보험(Samsung Life Insurance)",
        code: "UN008088",
    },
    {
        name: "현대모비스(HYUNDAI MOBIS)",
        code: "UN000028",
    },
    {
        name: "신한은행(Shinhan Bank)",
        code: "UN000335",
    },
    {
        name: "에이치디현대오일뱅크(HD HYUNDAI OILBANK)",
        code: "UN265739",
    },
    {
        name: "포스코인터내셔널(POSCO INTERNATIONAL)",
        code: "UN000446",
    },
    {
        name: "삼성디스플레이(SAMSUNG DISPLAY)",
        code: "UN001338",
    },
    {
        name: "엘지전자(LG Electronics)",
        code: "UN000002",
    },
    {
        name: "삼성물산(SAMSUNG C&T)",
        code: "UN000226",
    },
    {
        name: "쿠팡(Coupang)",
        code: "UN100005",
    },
    {
        name: "삼성화재해상보험(Samsung Fire & Marine Insurance)",
        code: "UN008089",
    },
    {
        name: "엘지디스플레이(LG Display)",
        code: "UN000010",
    },
    {
        name: "현대제철(HYUNDAI STEEL COMPANY)",
        code: "UN301146",
    },
    {
        name: "중소기업은행(Industrial Bank Of Korea)",
        code: "UN008012",
    },
    {
        name: "엘지화학(LG Chem)",
        code: "UN000021",
    },
    {
        name: "한국투자증권(Korea Investment & Securities)",
        code: "UN002940",
    },
    {
        name: "현대글로비스(Hyundai Glovis)",
        code: "UN004205",
    },
    {
        name: "한화생명보험(Hanwha Life Insurance)",
        code: "UN273346",
    },
    {
        name: "교보생명보험(Kyobo Life Insurance)",
        code: "UN008096",
    },
    {
        name: "현대해상화재보험(Hyundai Marine & Fire Insurance)",
        code: "UN008093",
    },
    {
        name: "디비손해보험(DB INSURANCE)",
        code: "UN008092",
    },
    {
        name: "한국토지주택공사(Korea Land & Housing)",
        code: "UN001852",
    },
    {
        name: "엘지이노텍(LG Innotek)",
        code: "UN000039",
    },
    {
        name: "케이티(KT)",
        code: "UN000037",
    },
    {
        name: "농협은행(NongHyup Bank)",
        code: "UN259669",
    },
    {
        name: "삼성에스디아이(Samsung SDI)",
        code: "UN000008",
    },
    {
        name: "미래에셋증권(MIRAE ASSET SECURITIES)",
        code: "UN008079",
    },
    {
        name: "롯데케미칼(LOTTE CHEMICAL)",
        code: "UN000567",
    },
    {
        name: "이마트(E MART)",
        code: "UN284835",
    },
    {
        name: "케이비손해보험(KB Insurance)",
        code: "UN008091",
    },
    {
        name: "케이비증권(KB Securities)",
        code: "UN008083",
    },
    {
        name: "한화토탈에너지스(Hanwha TotalEnergies Petrochemical)",
        code: "UN000159",
    },
    {
        name: "대한항공(Korean Air Lines)",
        code: "UN008275",
    },
    {
        name: "삼성증권(Samsung Securities)",
        code: "UN008009",
    },
    {
        name: "하나증권(Hana Securities)",
        code: "UN003898",
    },
    {
        name: "엘지유플러스(LG UPLUS)",
        code: "UN000172",
    },
    {
        name: "메리츠화재해상보험(Meritz Fire & Marine Insurance)",
        code: "UN278972",
    },
    {
        name: "에스케이텔레콤(SK Telecom)",
        code: "UN000018",
    },
    {
        name: "코리안리재보험(Korean Reinsurance Company)",
        code: "UN278658",
    },
    {
        name: "현대건설(Hyundai Engineering & Construction)",
        code: "UN000275",
    },
    {
        name: "에스케이지오센트릭(SK GEO CENTRIC)",
        code: "UN008560",
    },
    {
        name: "농협생명보험(Nonghyup Life Insurance)",
        code: "UN287669",
    },
    {
        name: "한국도로공사(Korea Expressway)",
        code: "UN000836",
    },
    {
        name: "엔에이치투자증권(NH INVESTMENT & SECURITIES)",
        code: "UN008085",
    },
    {
        name: "농협경제지주(Nonghyup Agribusiness Group)",
        code: "UN283015",
    },
    {
        name: "엘지에너지솔루션(LG Energy Solution)",
        code: "UN301584",
    },
    {
        name: "지에스리테일(GS Retail)",
        code: "UN007957",
    },
    {
        name: "엘에스엠앤엠(LS MNM)",
        code: "UN003179",
    },
    {
        name: "신한투자증권(SHINHAN SECURITIES)",
        code: "UN008084",
    },
    {
        name: "한국수력원자력(Korea Hydro & Nuclear Power)",
        code: "UN000286",
    },
    {
        name: "한화솔루션(HANWHA SOLUTIONS)",
        code: "UN265559",
    },
    {
        name: "대우건설(Daewoo Engineering & Construction)",
        code: "UN001066",
    },
    {
        name: "한국남부발전(Korea Southern Power)",
        code: "UN001841",
    },
    {
        name: "신한라이프생명보험(Shinhan Life Insurance)",
        code: "UN008007",
    },
    {
        name: "에이치디현대중공업(HD HYUNDAI HEAVY INDUSTRIES)",
        code: "UN000054",
    },
    {
        name: "한국지엠(GM Korea Company)",
        code: "UN000006",
    },
    {
        name: "한국남동발전(Korea South East Power)",
        code: "UN001441",
    },
    {
        name: "에스케이인천석유화학(SKheon Petrochem)",
        code: "UN274196",
    },
    {
        name: "롯데쇼핑(Lotte Shopping)",
        code: "UN007446",
    },
    {
        name: "한국중부발전(Korea Midland Power)",
        code: "UN001850",
    },
    {
        name: "지에스건설(GS Engineering & Construction)",
        code: "UN001414",
    },
    {
        name: "포스코홀딩스(POSCO Holdings)",
        code: "UN000011",
    },
    {
        name: "화경산업(Whakyung Industial)",
        code: "UN263657",
    },
    {
        name: "한화손해보험(Hanwha General Insurance)",
        code: "UN008094",
    },
    {
        name: "씨제이대한통운(CJ LOGISTICS)",
        code: "UN007718",
    },
    {
        name: "포스코이앤씨(POSCO E&C)",
        code: "UN000576",
    },
    {
        name: "한국서부발전(Korea Western Power)",
        code: "UN001444",
    },
    {
        name: "고려아연(Korea Zinc Company)",
        code: "UN006223",
    },
    {
        name: "서울올림픽기념국민체육진흥공단(Seoul Olympic Sports Promotion Foundation)",
        code: "U1007770",
    },
    {
        name: "키움증권(Kiwoom Securities)",
        code: "UN261784",
    },
    {
        name: "씨제이제일제당(CJ CheilJedang)",
        code: "UN131905",
    },
    {
        name: "동국홀딩스(DONGKUK HOLDINGS)",
        code: "UN006196",
    },
    {
        name: "이원(E1)",
        code: "UN007444",
    },
    {
        name: "현대트랜시스(HYUNDAI TRANSYS)",
        code: "UN001291",
    },
    {
        name: "현대엔지니어링(Hyundai Engineering)",
        code: "UN001452",
    },
    {
        name: "삼성전기(Samsung Electro Mechanics)",
        code: "UN000012",
    },
    {
        name: "에스케이가스(SK Gas)",
        code: "UN006777",
    },
    {
        name: "한국동서발전(Korea East West Power)",
        code: "UN001442",
    },
    {
        name: "여천엔씨씨(Yeochun NCC)",
        code: "UN003546",
    },
    {
        name: "현대위아(HYUNDAI WIA)",
        code: "UN000266",
    },
    {
        name: "삼성엔지니어링(Samsung Engineering)",
        code: "UN000585",
    },
    {
        name: "농업협동조합중앙회(National Agricultural Cooperative Federation)",
        code: "UN150403",
    },
    {
        name: "에스케이네트웍스(SK Networks)",
        code: "UN007406",
    },
    {
        name: "홈플러스(Homeplus)",
        code: "UN003283",
    },
    {
        name: "한국마사회(Korea Racing Association)",
        code: "U1004971",
    },
];

const CompanySearch = () => {
    const [payLoad, setPayLoad] = useState({
        enterprise: {code: "UN000001"},
        competitor: {code: "UN000001"},
    });
    const [searchText, setSearchText] = useState({
        enterprise: "삼성전자(Samsung Electronics)",
        competitor: "삼성전자(Samsung Electronics)",
    });
    const [keyWord, setKeyWord] = useState({
        enterprise: "삼성전자(Samsung Electronics)",
        competitor: "삼성전자(Samsung Electronics)",
    });
    const [result, setResult] = useState<IResults[]>([]);
    const [resultCompetitor, setResultCompetitor] = useState<IResults[]>([]);

    const [isResult, setIsResult] = useState(false);
    const [isResultCompet, setIsResultCompet] = useState(false);

    const [list, setList] = useState<IList[] | []>([]);
    const [uniqueArr, setUniqueArr] = useState<IList[]>([]);

    const [checkItems, setCheckItems] = useState<string[]>([]);
    const [competitorItems, setCompetitorItems] = useState<string>("");

    const searchInput = useRef<HTMLInputElement | null>(null);
    const searchCompetitorInput = useRef<HTMLInputElement | null>(null);

    const setCompeteIsViewAtom = useSetAtom(competeIsViewAtom);

    const {enterData, enterLoading, nation, ipc, competeData, competeLoading, competPatent, skillDataSet, aiPoints} =
        useCompany({
            payLoad,
            competitorItems,
        });

    const handleSingle = (checked: boolean, id: string) => {
        if (checked) {
            setCheckItems((prev) => [...prev, id]);
        } else {
            setCheckItems(checkItems.filter((elem) => elem !== id));
        }
    };

    const handleAll = (checked: boolean) => {
        if (checked) {
            const tempArray: string[] = [];
            uniqueArr.forEach((elem) => tempArray.push(elem.id));
            setCheckItems(tempArray);
        } else {
            setCheckItems([]);
        }
    };

    const handleRemoveAll = () => {
        setUniqueArr([]);
        setList([]);
        setCheckItems([]);
        setCompeteIsViewAtom(false);
        setSearchText((prev) => ({
            ...prev,
            competitor: "",
        }));
        setKeyWord((prev) => ({
            ...prev,
            competitor: "",
        }));
        setPayLoad((prev) => ({
            ...prev,
            competitor: {code: ""},
        }));
    };

    const handleRemove = () => {
        const temp = uniqueArr.filter((item) => !checkItems.includes(item.id));
        setUniqueArr(temp);
        setList(temp);
        setCheckItems([]);
        setCompeteIsViewAtom(false);
        setSearchText((prev) => ({
            ...prev,
            competitor: "",
        }));
        setKeyWord((prev) => ({
            ...prev,
            competitor: "",
        }));
        setPayLoad((prev) => ({
            ...prev,
            competitor: {code: ""},
        }));
    };

    const showCompetitor = () => {
        setCompetitorItems(checkItems.join("|"));
        setCompeteIsViewAtom(true);
    };

    const allTabls = [
        {
            id: 0,
            name: "기업검색",
            data: <EnterPrise data={enterData || []} loading={enterLoading} nation={nation} ipc={ipc}/>,
        },
        {
            id: 1,
            name: "경쟁사비교",
            data: (
                <Competitor
                    data={uniqueArr}
                    loading={competeLoading}
                    checkItems={checkItems}
                    competPatent={competPatent}
                    skillDataSet={skillDataSet}
                    aiPoints={aiPoints}
                    handleSingle={handleSingle}
                    handleAll={handleAll}
                    showCompetitor={showCompetitor}
                    handleRemove={handleRemove}
                    handleRemoveAll={handleRemoveAll}
                />
            ),
        },
    ];

    const {currentItem, changeItem, currentIndex} = useTabs(0, allTabls);

    useEffect(() => {
        let temp: any = [];

        if (currentIndex === 0) {
            temp = companyData.filter((item) => item.name.toLowerCase().includes(searchText.enterprise.toLowerCase()));
            setResult(temp);
        }
        if (currentIndex === 1) {
            temp = companyData.filter((item) => item.name.toLowerCase().includes(searchText.competitor.toLowerCase()));
            setResultCompetitor(temp);
        }
    }, [searchText]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (currentIndex === 0) {
            setSearchText((prev) => ({
                ...prev,
                enterprise: event.target.value,
            }));

            setIsResult(true);
        }
        if (currentIndex === 1) {
            setSearchText((prev) => ({
                ...prev,
                competitor: event.target.value,
            }));

            setIsResultCompet(true);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.currentTarget.value !== "") {
            if (currentIndex === 0) {
                setSearchText((prev) => ({
                    ...prev,
                    enterprise: "",
                }));
            }

            if (currentIndex === 1) {
                setSearchText((prev) => ({
                    ...prev,
                    competitor: "",
                }));
            }
        }
    };

    const handleChangeTab = (id: number) => {
        changeItem(id);
        setIsResult(false);
        setIsResultCompet(false);
    };

    const setNameCodeEnter = () => {
        if (currentIndex === 0 && searchText.enterprise !== "") {
            setKeyWord((prev) => ({
                ...prev,
                enterprise: result[0].name,
            }));
            setSearchText((prev) => ({
                ...prev,
                enterprise: result[0].name,
            }));
            setPayLoad((prev) => ({
                ...prev,
                enterprise: {code: result[0].code},
            }));
        }

        if (currentIndex === 1 && searchText.competitor !== "") {
            setKeyWord((prev) => ({
                ...prev,
                competitor: resultCompetitor[0].name,
            }));
            setSearchText((prev) => ({
                ...prev,
                competitor: resultCompetitor[0].name,
            }));
            setPayLoad((prev) => ({
                ...prev,
                competitor: {code: resultCompetitor[0].code},
            }));
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (event.key === "Enter" && result.length > 0 && currentIndex === 0) {
            setNameCodeEnter();
            // setKeyWord(searchText);
            setIsResult(false);
        }
        if (event.key === "Enter" && resultCompetitor.length > 0 && currentIndex === 1) {
            if (uniqueArr.length > 4) {
                alert("경쟁사 비교는 최대 5개 기업까지 가능합니다.");
                setIsResultCompet(false);
            } else {
                setNameCodeEnter();
                // setKeyWord(searchText);
                setIsResultCompet(false);
            }
        }
    };

    const handleListClick = (code: string, name: string) => {
        if (currentIndex === 0) {
            setIsResult(false);
            setSearchText((prev) => ({
                ...prev,
                enterprise: name,
            }));
            setKeyWord((prev) => ({
                ...prev,
                enterprise: name,
            }));
            setPayLoad((prev) => ({
                ...prev,
                enterprise: {code: code},
            }));
        }
        if (currentIndex === 1) {
            if (uniqueArr.length > 4) {
                alert("경쟁사 비교는 최대 5개 기업까지 가능합니다.");
                setIsResultCompet(false);
            } else {
                setIsResultCompet(false);
                setSearchText((prev) => ({
                    ...prev,
                    competitor: name,
                }));
                setKeyWord((prev) => ({
                    ...prev,
                    competitor: name,
                }));
                setPayLoad((prev) => ({
                    ...prev,
                    competitor: {code: code},
                }));
            }
        }
    };

    const clickSearch = () => {
        if (currentIndex === 0 && result.length > 0) {
            setNameCodeEnter();
        }
        if (resultCompetitor.length > 0 && currentIndex === 1) {
            setNameCodeEnter();
        }
    };

    useEffect(() => {
        const clickWrapp = (event: any) => {
            if (
                searchInput.current &&
                document.activeElement !== searchInput.current &&
                !searchInput.current.contains(event.target)
            ) {
                if (keyWord.enterprise !== "") {
                    setSearchText((prev) => ({
                        ...prev,
                        enterprise: keyWord.enterprise,
                    }));
                }
            }

            if (
                searchCompetitorInput.current &&
                document.activeElement !== searchCompetitorInput.current &&
                !searchCompetitorInput.current.contains(event.target)
            ) {
                if (keyWord.competitor !== "") {
                    setSearchText((prev) => ({
                        ...prev,
                        competitor: keyWord.competitor,
                    }));
                }
            }

            if (isResult) {
                setIsResult(false);
            }
            if (isResultCompet) {
                setIsResultCompet(false);
            }
        };

        document.addEventListener("click", clickWrapp);

        return () => {
            document.removeEventListener("click", clickWrapp);
        };
    }, [keyWord]);

    useEffect(() => {
        if (competeData !== undefined) {
            const temp = {
                id: payLoad.competitor.code,
                companyName: competeData[0].data_set[0].com_nm,
                companySize: competeData[0].data_set[0].com_scl,
                conpanyState: competeData[0].data_set[0].com_mode,
                companyEm: competeData[0].data_set[0].emp_cnt,
                companyPresent: competeData[0].data_set[0].ksic,
                companyLocate: competeData[0].data_set[0].com_rgn,
            };

            if (list.length === 0) {
                setList([temp]);
            } else {
                setList((prev) => [...prev, temp]);
            }
        }
    }, [competeData]);

    useEffect(() => {
        const uniqueSet = new Set(list.map((item) => item.id));
        const uniqueArray = Array.from(uniqueSet).map((id) => list.find((item) => item.id === id)) as IList[];

        setUniqueArr(uniqueArray);
    }, [list.length]);

    return (
        <Wrap>
            <div className="c__content-header">
                <ul className="c__tab-menu">
                    {allTabls.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => handleChangeTab(item.id)}
                            className={currentIndex === item.id ? "active tab-item" : "tab-item"}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
                <div className="c__content-state">
                    <div className="c__tooltip">
                        <div className="tooltip-title">
                            <p className="tooltip-text">
                                데이터 제공 안내
                            </p>
                            <button type="button" className="tooltip-icon">
                                <img src={tooltipIcon} alt="logo"/>
                                <CloudDesc className="tooltip-desc">
                                    * 기업탐색 업데이트주기 <br/>
                                    - 월1회 매달 마지막주 수요일
                                </CloudDesc>
                            </button>
                        </div>
                    </div>

                    {currentIndex === 0 && (
                        <>
                            <button
                                className="btn__solid"
                                onClick={() => window.open("https://focus.build-data.co.kr/NTB/board/OB020")}
                            >
                                기업검색 자세히 보기
                            </button>
                            <div className="c__search">
                                <input
                                    type="text"
                                    placeholder=""
                                    value={searchText.enterprise}
                                    onChange={handleSearch}
                                    onClick={handleClick}
                                    onKeyUp={handleKeyUp}
                                    ref={searchInput}
                                />
                                <button onClick={clickSearch}/>

                                {searchText.enterprise !== "" && isResult && currentIndex === 0 && (
                                    <div className="outer-result">
                                        <ul className="list-results">
                                            {result.length === 0 ? (
                                                <li>검색 결과가 없습니다.</li>
                                            ) : (
                                                result.map((item) => (
                                                    <li
                                                        key={item.code}
                                                        onClick={() => handleListClick(item.code, item.name)}
                                                    >
                                                        {item.name}
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {currentIndex === 1 && (
                        <>
                            <button
                                className="btn__solid"
                                onClick={() => window.open("https://focus.build-data.co.kr/NTB/board/OB021")}
                            >
                                경쟁사비교 자세히 보기
                            </button>
                            <div className="c__search-wrap">
                                <div className="c__search">
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={searchText.competitor}
                                        onChange={handleSearch}
                                        onClick={handleClick}
                                        onKeyUp={handleKeyUp}
                                        ref={searchCompetitorInput}
                                    />
                                    <button onClick={clickSearch}/>

                                    {searchText.competitor !== "" && isResultCompet && currentIndex === 1 && (
                                        <div className="outer-result">
                                            <ul className="list-results">
                                                {resultCompetitor.length === 0 ? (
                                                    <li>검색 결과가 없습니다.</li>
                                                ) : (
                                                    resultCompetitor.map((item) => (
                                                        <li
                                                            key={item.code}
                                                            onClick={() => handleListClick(item.code, item.name)}
                                                        >
                                                            {item.name}
                                                        </li>
                                                    ))
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <button className="btn__solid" onClick={clickSearch}>
                                    추가
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="c__content">{currentItem.data}</div>
        </Wrap>
    );
};

export default CompanySearch;

import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useLayoutMenu = () => {
    const { pathname } = useLocation();

    const [isVisible, setIsVisible] = useState(false);
    const [menuState, setMenuState] = useState({
        dash: pathname.includes("/dash"),
        search: pathname.includes("/consearch"),
        apps: pathname.includes("/apps"),
        reports: pathname.includes("/reports"),
    } as { [key: string]: boolean | string });

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {
            currentTarget: {
                dataset: { name },
            },
        } = event;

        if (name !== undefined) {
            setMenuState((prev) => ({
                ...prev,
                [name]: !prev[name],
            }));
            setIsVisible((prev) => !prev);
        }
    };
    return {
        pathname,
        isVisible,
        menuState,
        handleMenu,
    };
};

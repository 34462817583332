import { ResetIcon } from "@assets/svg/InterfacesIcon";
import { useSimilarPatentSearchData } from "@services/PatentSearch/SimilarPatentSearch";

export default function SearchHeader() {
    const { HandleResetPatentList } = useSimilarPatentSearchData();

    return (
        <div className="similar-filter-header">
            <p className="similar-title">검색조건</p>
            <button type="button" className="btn-reset" onClick={HandleResetPatentList}>
                <ResetIcon />
            </button>
        </div>
    );
}

import { getPatentPiaCatesApi, getPatentPiaOneApi } from "@api/patentPia";
import { useQuery } from "@tanstack/react-query";
import { PatentPiaCateType } from "~types/Patentpia";

const depthQueryKeys = {
    firstDepthKey: (patentPiaCate: PatentPiaCateType) => ["1depth_cate", patentPiaCate.cate],
    twoOrThirdDepthKey: (patentPiaCate: PatentPiaCateType, id: string, depth: number) => [
        id,
        `${depth}depth_cate`,
        patentPiaCate.cate,
    ],
};

export const useFetchFirstDepth = (patentPiaCate: PatentPiaCateType) => {
    return useQuery({
        queryKey: depthQueryKeys.firstDepthKey(patentPiaCate),
        queryFn: () => getPatentPiaOneApi(patentPiaCate.cate),
        onError: (error) => console.log(error),
        enabled: !!patentPiaCate.cate,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: 1,
    });
};

export const useFetchTwoOrThirdDepth = (patentPiaCate: PatentPiaCateType, id: string, depth: number) => {
    return useQuery({
        queryKey: depthQueryKeys.twoOrThirdDepthKey(patentPiaCate, id, depth),
        queryFn: () => getPatentPiaCatesApi(id ? id : "", patentPiaCate.cate),
        onError: (error) => console.log(error),
        enabled: false,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        cacheTime: 0,
        retry: 1,
    });
};

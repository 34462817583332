import usePatentPia from "@hooks/usePatentPia";
import { Link, useLocation } from "react-router-dom";
import useMenu from "./useMenu";

const Menu = () => {
    const { pathname } = useLocation();
    // const { menuList, menuIsLoading } = usePatentPia({});
    const { menuList, menuIsLoading, menuFetching } = useMenu();

    const getPath = (code: string) => {
        switch (code) {
            case "03_00":
                return "/apps/patentpia";
            case "05_00":
                return "/apps/patentpia/company";
            case "07_00":
                return "/apps/patentpia/event";
            case "09_00":
                return "/apps/patentpia/portfolio";
            case "11_00":
                return "/apps/patentpia/researcher";
            case "13_00":
                return "/apps/patentpia/network";
            default:
                return code;
        }
    };

    return (
        <>
            <ul className="item__tabs">
                {(!menuIsLoading || !menuFetching) &&
                    menuList.map((item) => (
                        <li
                            className={`${pathname === getPath(item.CHARTGROUP_CODE) ? "active" : ""}`}
                            key={item.CHARTGROUP_CODE}
                        >
                            <Link to={getPath(item.CHARTGROUP_CODE)} state={item.CHARTGROUP_CODE}>
                                {item.CHARTGROUP_TITLE}({item.CHART_LAYOUT_COUNT})
                            </Link>
                        </li>
                    ))}
            </ul>
        </>
    );
};

export default Menu;

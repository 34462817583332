import { styled } from "styled-components";

import Arrow from "@assets/icon/arrow-samll-icon.svg";
import Prev from "@assets/icon/prev-icon.svg";
import Next from "@assets/icon/next-icon.svg";
import { device } from "@assets/index";

export const Wrap = styled.div`
    position: relative;
    height: 405px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.1);

    @media ${device.tablet} {
        height: auto;
        padding-bottom: 60px;
    }

    .c__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 15px 16px;
        border: 1px solid #e9ebec;

        strong {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #333;
        }

        .c__select {
            display: flex;
            align-items: center;

            span {
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 700;
                font-size: 12px;
                color: #333;
            }

            button {
                padding-right: 17px;
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 300;
                font-size: 12px;
                color: #333;
                background-image: url(${Arrow});
                background-repeat: no-repeat;
                background-position: 100% 7px;
            }
        }

        .btn__export {
            width: 60px;
            height: 27px;
            border-radius: 3px;
            background: #e4e1f4;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            color: #6d40b5;
        }
    }

    .c__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        border-top: 1px solid #e9ebec;
        left: 0;
        bottom: 0;
        box-sizing: border-box;

        .text__footer {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            color: #878aa6;

            span {
                color: #333333;
            }
        }
    }

    .p__pagination {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 6px;

        button {
            width: 26px;
            height: 27px;
            border: 1px solid #e9ebec;
            background: #ffffff;
            border-radius: 2px;
            box-sizing: border-box;
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 300;
            font-size: 12px;
            color: #333;

            &.active {
                border: 1px solid #3798ff;
                background: #3798ff;
                color: #fff;
            }

            &:not(.b__prev, .b__next):hover {
                border: 1px solid #3798ff;
                background: #3798ff;
                color: #fff;
            }
        }
        .b__prev {
            background-image: url(${Prev});
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
        .b__next {
            background-image: url(${Next});
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    .c__list {
        margin-top: 5px;
    }

    .box__empty {
        position: absolute;
        display: flex;
        width: 100%;
        height: calc(100% - 46px);
        justify-content: center;
        align-items: center;
        top: 46px;
        left: 0;

        p {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #333;
        }
    }
`;
